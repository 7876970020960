<template>
  <div class="pers_profile logout">
    <p class="photo">
      <!-- TODO 프로필사진 -->
      <img class="cs-pointer" :src="getProfileImg()" @click="movePage('/my-info/setting/profile')">
      <!-- TODO 프로필 전환-->
    </p>
    <span v-if="!isEmpty(jwt) && !isEkmtc" class="change-profile" @click="updateProfile()"><button class="tbl_icon refresh"></button></span>
    <div class="info">
      <p class="name">
        <span v-if="isEmpty(profile)">{{ $t('msg.PERS010L020.000') }}</span>
        <span class="cs-pointer" v-else @click="movePage('/my-info/setting/profile')">{{ profile.picAlsNm }}</span>
        {{ $t('msg.PERS010L020.001') }} {{ profile.preUserId }}({{ profile.userCtrCd }})
      </p>
      <template v-if="isEmpty(jwt)">
        <p class="nts">{{ $t('msg.PERS010L020.002') }}<br /> {{ $t('msg.PERS010L020.003') }}</p>
        <p class="login_btn"><a class="button sm" href="javascript:void(0)" @click="openLoginForm()">{{ $t('msg.PERS010L020.004') }}</a></p>
      </template>
      <template v-else>
        <!--TODO 최종접속일 -->
        <p class="date">{{ $t('msg.PERS010L020.005') }} : <span>{{ loginDtm }}</span></p>
        <dl>
          <dt>{{ $t('msg.PERS010L020.006') }}</dt>
          <dd>{{ compName }}</dd>
        </dl>
      </template>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'
import MemberApi from '@/api/rest/user/member'
export default {
  name: 'QuickProfile',
  components: {

  },
  props: [
  ],
  data: function () {
    return {
        loginDtm: ''
    }
  },
  computed: {
    jwt () {
      return store.state.auth.jwt
    },
    profile () {
      return store.state.auth.selectedProfile
    },
    isEkmtc () {
      return sessionStorage.getItem('loginId').indexOf('EKMTC_') !== -1
    },
    compName () {
      return this.$store.getters['auth/compName']
    }
  },
  watch: {
    profile: {
      deep: true,
      handler () {
        setTimeout(() => {
          this.getLoginDtm()
        }, 500)
      }
    }
  },
  created () {
      this.getLoginDtm()
  },
  mounted: function () {

  },
  methods: {
    updateProfile () {
      this.$emit('child', { type: 'openSelectProfilePop' })
    },
    getLoginDtm () {
      let loginId = null
      if (sessionStorage.getItem('loginId') !== null) {
        loginId = sessionStorage.getItem('loginId').toUpperCase()

        MemberApi.findLoginDtm(loginId).then(response => {
          if (!this.isEmpty(response.data)) {
            this.loginDtm = response.data.substr(0, 4) + '.' + response.data.substr(4, 2) + '.' + response.data.substr(6, 2) +
              ' ' + response.data.substr(8, 2) + ':' + response.data.substr(10, 2) + ':' + response.data.substr(12, 2)
          }
        }).catch(e => {
          console.log(e)
        })
      }
    },
    getProfileImg () {
        let res = null
        if (!this.isEmpty(this.profile)) {
            if (!this.isEmpty(this.profile.picPoto)) {
                let image = new Image()
                image.src = 'data:image/png;base64,' + this.profile.picPoto
                res = image.src
            } else if (!this.isEmpty(this.profile.fileNm)) {
               try {
                res = require('@/assets/images/myin/' + this.profile.fileNm)
              } catch (error) {
                res = require('@/assets/images/pers/profile_noimg.png')
              }
            } else {
              res = require('@/assets/images/pers/profile_noimg.png')
            }
        } else {
            res = require('@/assets/images/pers/profile_noimg.png')
        }

        return res
    },
    openLoginForm () {
        store.commit('auth/requireLogin', true)
    },
    movePage (path) {
        this.$router.push(path).catch(() => {})
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>
<style scoped>
  .cs-pointer {
      cursor: pointer;
  }
  .change-profile {
    position: absolute;
    left: 85px;
    top: 100px;
    font-weight: bold;
    cursor: pointer;
  }
</style>
