<template>
  <div>
  </div>
</template>

<script>
export default ({
  mounted: function () {
    opener.setAddress({ zipNo: '11122', roadFullAddr: '서울 동대문구 을지로 입구' })
    self.close()
  }
})
</script>
