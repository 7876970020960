<template>
  <nav v-if="this.$breadcrumbs.length" aria-label="breadcrumb">
    <ul class="location">
      <li><a class="home">home</a></li>
      <li v-for="(crumb, key) in this.$breadcrumbs" :key="key" aria-current="page">
        <router-link
          v-if="crumb.meta.breadcrumb"
          :to="{ path: getPath(crumb) }"
        >
          {{ getBreadcrumb(crumb.meta.breadcrumb,crumb.meta.key) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'EBreadcrumbs',
  components: {
  },
  created () {
    // console.log('create')
  },
  methods: {
    getPath: function (crumb) {
      let path = crumb.path
      for (let _i = 0, _a = Object.entries(this.$route.params); _i < _a.length; _i++) {
        let _b = _a[_i]
        let key = _b[0]
        let value = _b[1]

        path = path.replace(':' + key, value)
      }

      if (crumb.meta.key === 'menu.MENU08.000') { // 나의정보 하위 메뉴는 nav '나의정보' 클릭시 회원정보 페이지로 이동
        path = '/my-info/member'
      }

      if (crumb.meta.key === 'menu.MENU06.000') { // 정산발행 하위 메뉴는 nav '정산/발행' 클릭시 정산발행 페이지로 이동
        path = '/calcIssue/calcIssueMain'
      }

      return path
    },
    getBreadcrumb: function (bc, key) {
      // console.log(' bread key = key ', key)
      if (key === undefined) { //키가없으면 그냥
        let name = bc
        if (typeof name === 'function') {
          name = name.call(this, this.$route.params)
        }
        if (typeof name === 'object') {
          name = name.label
        }
        return name
      } else { //키가잇으면 다국어
        return this.$i18n.t(key)
      }
    }
  }
}
</script>

<style scoped>

</style>
