import Send from '../../cp.client.js'

export default {
    cpCommonCodeList (param) {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/code',
                method: 'get',
                params: {
                    groupCd: param
                }
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    countryList () {
        return Send({
            url: '/bsa/common/countryList',
            method: 'get'
        })
    },
    countryListForEntry () {
        return Send({
            url: '/bsa/common/countryListForEntry',
            method: 'get'
        })
    },
    harborList (param) {
        return Send({
            url: '/bsa/common/harborList',
            method: 'get',
            params: {
                ctrCd: param
            }
        })
    },
    harborListForEntry (param) {
        return Send({
            url: '/bsa/common/harborListForEntry',
            method: 'get',
            params: {
                ctrCd: param
            }
        })
    },
    cstCatList (param) {
        return Send({
            url: '/bsa/common/cstCatList',
            method: 'get'
        })
    },
    cstSearchList (param) {
        return Send({
            url: '/bsa/common/cstList',
            method: 'get',
            params: {
                cstCatCd: param.cstCatCd,
                cstNo: param.cstNo,
                cstEnm: param.cstEnm,
                cstNm: param.cstNm,
                ctrNm: param.ctrNm,
                salUno: param.salUno
            }
        })
    },
    actionList () {
        return Send({
            url: '/bsa/common/actionList',
            method: 'get'
        })
    },
    cmdtGrpList () {
        return Send({
            url: '/bsa/common/cmdtGrpList',
            method: 'get'
        })
    },
    cmdtItemList (param) {
        return Send({
            url: '/bsa/common/cmdtItemList',
            method: 'get',
            params: {
                cmdtGrpCd: param
            }
        })
    },
    cntrSzTypList () {
        return Send({
            url: '/bsa/common/cntrSzTypList',
            method: 'get'
        })
    },
    profileList (param) {
        return Send({
            url: '/bsa/common/profileList',
            method: 'get',
            params: {
                cstNos: param
            }
        })
    },
    cstChooseList (param) {
        return Send({
            url: '/bsa/common/cstChooseList',
            method: 'get',
            params: {
                cstCatCd: param.cstCatCd,
                ships: param.ships
            }
        })
    },
    terminalList (countryCd, harborCd) {
        return Send({
            url: '/bsa/common/terminalList',
            method: 'get',
            params: {
                porCtr: countryCd,
                porPlc: harborCd
            }
        })
    },
    fairwayList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/fairwayList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    eVarYearWeekList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/eVarYearWeekList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    yearWeekList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/yearWeekList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    territoryList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/territoryList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    territoryFairwayList (param) {
        return Send({
            url: '/bsa/common/territoryFairwayList',
            method: 'get',
            params: {
                territory: param
            }
        })
    },
    salesList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/salesList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    adjRvnYearWeekList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/adjRvnYearWeekList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    shipNmList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/bsa/common/shipNmList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    bsaRole () {
      return Send({
        url: '/bsa/common/bsaRole',
        method: 'get'
      })
    },
    getCommonsCodesCustomer: function (cdNm) {
        return new Promise((resolve, reject) => {
          Send({
            url: '/bsa/common/customer',
            method: 'get',
            spinner: false,
            params: { cdNm: cdNm }
          }).then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
      }
}
