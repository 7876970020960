<template>
  <div class="popup_wrap" style="width:1100px; height:700px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.PERS010L022.001') }}</h1> <!-- 스케줄 변동 -->
      <div class="content_box">
        <h2 class="content_title">{{ $t('msg.PERS010L022.014') }}</h2><!-- 출항 예정시간 (POL ETD) / 출항 후 도착 예정시간 (POD ETA) 변경 건 -->

        <div>
          <span class="mr20">
            <input type="radio" id="all" name="exim" value="A" v-model="chkValue" @change="setInOutList">
            <label for="all"><span></span>{{ $t('msg.PERS010L022.002') }}</label> <!-- 전체 -->
          </span>
          <span class="mr20">
            <input type="radio" id="out" name="exim" value="O" v-model="chkValue" @change="setInOutList">
            <label for="out"><span></span>{{ $t('msg.PERS010L022.003') }}</label> <!-- 수출 -->
          </span>
          <span class="mr20">
            <input type="radio" id="in" name="exim" value="I" v-model="chkValue" @change="setInOutList">
            <label for="in"><span></span>{{ $t('msg.PERS010L022.012') }}</label> <!-- 수입 -->
          </span>
        </div>
        <!-- content_box -->
        <!-- <div class="content_scroll" style="max-height:500px;overflow-y:auto"> -->
        <table class="tbl_col mt10 scrtable">
          <colgroup>
            <col style="width:10%">
            <col style="width:14%">
            <col style="width:14%">

            <col style="width:11%">
            <col style="width:11%">
            <col style="width:6%">
            <col style="width:11%">
            <col style="width:11%">
            <col style="width:6%">

            <col style="width:6%">
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">Booking No.<br>B/L No.</th>
              <th rowspan="2">{{ $t('msg.PERS010L022.004') }}</th> <!-- 출발/도착 -->
              <th rowspan="2">VSL/VOY</th>
              <th colspan="3">{{ $t('msg.PERS010L022.006') }}</th><!-- 출발 ETD -->
              <th colspan="3">{{ $t('msg.PERS010L022.007') }}</th><!-- 도착 ETA -->
              <th rowspan="2">Notice</th>
            </tr>
            <tr>
              <th class="border_left">{{ $t('msg.PERS010L022.005') }}</th> <!-- 변경 전 -->
              <th>{{ $t('msg.PERS010L022.008') }}</th> <!-- 변경 후 -->
              <th>{{ $t('msg.PERS010L022.013') }}</th> <!-- 차이 -->
              <th>{{ $t('msg.PERS010L022.005') }}</th> <!-- 변경 전 -->
              <th>{{ $t('msg.PERS010L022.008') }}</th> <!-- 변경 후 -->
              <th>{{ $t('msg.PERS010L022.013') }}</th> <!-- 차이 -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in etdEtaList">
              <tr :key="index" v-if="Number(calDateHours(item.fstVslApoDtm, item.rvsdApoDtm).substring(1)) > 0">
                <td>
                  <p>{{ item.bkgNo }}</p>
                  <p>{{ item.blNo }}</p>
                </td>
                <td>
                  <p>{{ item.polPortNm }},{{ item.polCtrCd }}</p>
                  <p>{{ item.podPortNm }},{{ item.podCtrCd }}</p>
                </td>
                <td>
                  <p>{{ item.vslNm }} </p> <!-- KMCT TOKYO-->
                  <p>{{ item.voyNo }}</p>
                </td>
                <td>{{ item.fstVslDpoDtm }}</td>
                <td>{{ item.rvsdEtd }}</td>
                <td>{{ calDateHours(item.fstVslApoDtm, item.rvsdApoDtm) }}</td>
                <td>{{ item.lstVslApoDtm }}</td> <!-- 도착지 ETA 변경전-->
                <td>{{ item.rvsdEta }}</td> <!-- 도착지 ETA 변경후-->
                <td>{{ calDateHours(item.lstVslApoDtm, item.rvsdEta) }}</td>
                <td colspan="2" v-if="item.eiCatCd==='O'"><button class="tbl_icon pdf" @click="openPopup('DelayNoticeOutPop', item)">{{ $t('msg.PERS010L022.009') }}</button></td>
                <td colspan="2" v-else-if="item.eiCatCd==='I'"><button class="tbl_icon pdf" @click="openPopup('DelayNoticeInPop', item)">{{ $t('msg.PERS010L022.009') }}</button></td>
                <td colspan="2" v-else></td>
              </tr>
            </template>
          </tbody>
        </table>

        <h2 class="content_title">{{ $t('msg.PERS010L022.010') }}</h2> <!--선명/항차 변경 건-->
        <table class="tbl_col scrVsltable">
          <colgroup>
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:12%">
            <col style="width:10%">

            <col style="width:11%">
            <col style="width:11%">
            <col style="width:11%">
            <col style="width:11%">

            <col style="width:8%">
            <col style="width:6%">
          </colgroup>
          <thead>
            <tr>
              <th>B/L No.</th>
              <th>{{ $t('msg.PERS010L022.011') }}</th> <!--실화주 -->
              <th>{{ $t('msg.PERS010L022.004') }}</th> <!-- 출발/도착 -->
              <th>Kind</th>
              <th colspan="2">{{ $t('msg.PERS010L022.005') }}</th> <!--변경전 -->
              <th colspan="2">{{ $t('msg.PERS010L022.008') }}</th> <!--변경후 -->
              <th colspan="2">Notice</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in vslVoyList" :key="index">
            <tr>
              <td>{{ item.blNo }}</td>
              <td>{{ item.actShprCstEnm }}</td>
              <td>
                <p>{{ item.polPortNm }},{{ item.polCtrCd }}</p>
                <p>{{ item.podPortNm }},{{ item.podCtrCd }}</p>
              </td>
              <td>
                {{ item.bkgRollOverCatNm }}
              </td>
              <td colspan="2">
                <p>{{ item.bfVslNm }} {{ item.bfVoyNo }}</p>
                <p>POL ETD : {{ item.bfPolEtd }}</p>
                <p>POD ETA : {{ item.bfPodEta }}</p>
              </td>
              <td colspan="2">
                <p>{{ item.afVslNm }} {{ item.afVoyNo }}</p>
                <p>POL ETD : {{ item.afPolEtd }}</p>
                <p>POD ETA : {{ item.afPodEta }}</p>
              </td>
              <td colspan="2">
                <button class="tbl_icon pdf" @click="openPopup('VesselChangePop', item)">{{ $t('msg.PERS010L022.009') }}</button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- </div> -->
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
    <div id="schelayerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          :parent-info="popupParams"
          @close="closePopup"
        />
      </win-layer-pop>
    </div>
  </div><!-- popup_wrap // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import apiChangeInfo from '@/api/rest/schedule/changeInfo'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import moment from 'moment'

export default {
  name: 'ScheduleCompare',
  components: {
    WinLayerPop,
    DelayNoticeOutPop: () => import('@/pages/pdf/popup/DelayNoticePop'),
    DelayNoticeInPop: () => import('@/pages/pdf/popup/DelayNoticePop'),
    VesselChangePop: () => import('@/pages/pdf/popup/VesselChangePop')
  },
  filters: {
  },
  props: {
    // parentInfo: {
    //   type: Object,
    //   default: function () {
    //     return {

    //     }
    //   }
    // }
  },
  data () {
    return {
      customComponent: null,
      selectData: [],
      rowsCount: 0,
      items: [],
      etdEtaList: [],
      etdEtaListClone: [],
      vslVoyList: [],

      // 장소 이름 리스트
      placeList: [],
      params: {
        eiCatCd: '',
        cstNo: ''
      },
      chkValue: 'A',

      // pdf 변수
      pdf: {
        pdfUrl: '',
        emailUrl: '',
        emailFiles: [],
        pg: 1,
        pageCount: 0,
        currentPage: 0
      },
      pdfConfig: {
        config: {},
        idConfig: {}
      },
      param: {
        demDetCatCd: '01',
        calExts: 'BEAU2312520_20210701_83_622500_684750',
        eiCatCd: 'O',
        blNo: 'PUSE013769',
        invIssNo: ''
      },
      popupParams: {}
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    // parentInfo () {
    //   if (this.$props.parentInfo.blNo !== undefined) {
    //     this.setItems(this.$props.parentInfo)
    //   }
    // }
  },
  created () {
    this.init()
    console.log('# auth info : ', this.auth)
    console.log('# auth memberDetail : ', this.memberDetail)
  },
  mounted () {
    // this.init()
  },
  methods: {
    // 스케쥴 변동 내역 세팅
    async init () {
      let data = []
      this.params.cstNo = this.memberDetail.cstCd

      console.log('init () ::: ', this.params)

      apiChangeInfo.getScheduleChangeInfo(this.params)
        .then(response => {
          if (response.data) {
            console.log('response ::: ', response)

            data = response.data
            this.etdEtaList = data.etdEtaList
            this.etdEtaListClone = data.etdEtaList

            this.vslVoyList = data.vslVoyList

            console.log(' this.etdEtaList ::: ', this.etdEtaList)
            console.log(' this.vslVoyList ::: ', this.vslVoyList)
          }
        })
    },
    setInOutList (e) {
      let chkValue = ''

      if (this.$ekmtcCommon.isEmpty(e.target.value)) {
        chkValue = 'A'
      } else {
        chkValue = e.target.value
      }

      if (chkValue === 'O') {
        this.etdEtaList = this.etdEtaListClone

        this.etdEtaList = this.etdEtaList.filter((item, idx) => {
            return item.eiCatCd === 'O'
        })
      } else if (chkValue === 'I') {
        this.etdEtaList = this.etdEtaListClone

        this.etdEtaList = this.etdEtaList.filter((item, idx) => {
            return item.eiCatCd === 'I'
        })
      } else {
         this.etdEtaList = this.etdEtaListClone
      }
    },
    calDateHours (bef, aft) {
      const dateFormat = 'YYYY-MM-DD HH:mm:ss'
      let rtnTime = ''

      let fstVslApoDtm = moment(bef).format(dateFormat)
      let rvsdApoDtm = moment(aft).format(dateFormat)

      let diffHours = moment(aft).diff(bef, 'hours')

      // 분 계산
      let st = moment(bef, dateFormat)
      let end = moment(aft, dateFormat)

      let mi = (moment.duration(end.diff(st)).asMinutes() / 60) - diffHours
      mi = mi * 60

      let symbol = ''

      if (diffHours > 0) {
        symbol = '+'
      } else if (diffHours < 0) {
        symbol = '-'
      }

      //  rtnTime  = symbol + diffHours + 'h ' + mi + 'm'
       rtnTime = symbol + diffHours

      return rtnTime
    },
    async setItems (items) {
      let userId = this.auth.userId
      let serviceLang = this.auth.serviceLang
      this.param.blNo = items.blNo
      this.pdf.pdfUrl = SETTLE_URL + '/settle/invoice-print?blNo=' + items.blNo + '&calExts=' + items.calExts + '&demDetCatCd=' + items.demDetCatCd + '&eiCatCd=' + items.eiCatCd + '&invIssNo=' + items.invIssNo + '&userId=' + userId + '&serviceLang=' + serviceLang
      this.pdf.emailUrl = REPORT_URL + '/report/email/proforma-invoice?blNo=' + items.blNo + '&calExts=' + items.calExts + '&demDetCatCd=' + items.demDetCatCd + '&eiCatCd=' + items.eiCatCd + '&invIssNo=' + items.invIssNo + '&userId=' + userId + '&serviceLang=' + serviceLang
      this.pdf.emailFiles = [items.blNo + '.pdf']
      //'&ctrCd=' + ctrCd + '&bkgPlcCd=' + bkgPlcCd + '&compEname=' + userEname +
      console.log('# this.pdf : ', this.pdf)
    },
    openPopup (compNm, param) {
        switch (compNm) {
          case 'DelayNoticeOutPop':
            this.popupParams = {
                inOut: 'O',
                items: [
                  {
                    docNo: '01',
                    prtGubun: '28',
                    parm1: param.bkgNo, //'KR02892645'
                    port: param.portCd
                  }
                ]
            }
          break

          case 'DelayNoticeInPop':
            this.popupParams = {
            inOut: 'I',
            items: [
              {
                docNo: '01',
                prtGubun: '03BL',
                parm1: param.blNo,
                parm2: param.bkgNo,
                port: param.podPortCd

              }
            ]
            }
            // console.log('this.parentInfo ===> ', this.parentInfo)
          break

          case 'VesselChangePop':
            this.popupParams = {
              testUsrNo: '',
              inOut: 'O',
              items: [
                {
                  docNo: '19',
                  prtGubun: '29',
                  parm1: param.bkgNo,
                  port: param.portCd,
                  usrNo: this.auth.userId
                }
              ]
            }
            // console.log('this.parentInfo ===> ', this.parentInfo)
          break
        }

        this.customComponent = compNm
        this.parentInfo = this.popupParams[compNm]

        this.$ekmtcCommon.layerOpen('#schelayerPop' + ' > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#schelayerPop' + ' > .popup_dim')
    },

    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    test (v1) {
      console.log(v1)
      console.log(this.items)
    }

  }
}
</script>
<style scoped>
.scrtable {
  display: block;
  overflow: auto;
  max-height: 400px
}

.scrVsltable {
  display: block;
  overflow: auto;
  max-height: 400px
}

.popup_wrap::-webkit-scrollbar { display: none; }
.popup_wrap{ -ms-overflow-style: none; }

</style>
