import Send from '../../schedule.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  // getMySchedule - MY 스케줄 조회 - GET /schedule/my-schedule/
  getMySchedule (userId) {
    return Send({
      url: '/schedule/my-schedule/search',
      method: 'get'
    })
  },
  // deleteMySchedule - MY 스케줄 삭제 - DELETE /schedule/my-schedule/{schId}
  deleteMySchedule (schId) {
    return Send({
      url: '/schedule/my-schedule/' + schId,
      method: 'delete'
    })
  },
  // addMySchedule - MY 스케줄 추가 - POST /schedule/my-schedule/{userId}
  addMySchedule (param) {
    return Send({
      url: '/schedule/my-schedule',
      method: 'post',
      params: param
    })
  },
  // checkMySchedule - MY 스케줄 여부 체크 - GET /schedule/my-schedule/checkMySchedule
  checkMySchedule (param) {
    return Send({
      url: '/schedule/my-schedule/checkMySchedule',
      method: 'get',
      params: param
    })
  },
  // checkMySchedule4LT - MY 스케줄 여부 체크 for LongTerm - GET /schedule/my-schedule/checkMySchedule4LT
  checkMySchedule4LT (param) {
    return Send({
      url: '/schedule/my-schedule/checkMySchedule4LT',
      method: 'get',
      params: param
    })
  },
  // 퀵메뉴 추천 스케줄 조회
  getSuggestionSchedules () {
    return Send({
      url: '/schedule/my-schedule/suggestion-schedules',
      method: 'get'
    })
  },
  // 퀵메뉴 프로모션 스케줄 조회
  getPromotionSchedules () {
    return Send({
      url: '/schedule/my-schedule/promotion-schedules',
      method: 'get'
    })
  },
  // 프로모션 영업담당자 이메일 발송
  sendSalesEmail (param) {
    return Send({
      url: '/schedule/my-schedule/sendSalesEmail',
      method: 'post',
      params: param
    })
  },
  // 운임확인 팝업 할인 운임 조회
  getPromotionDcAmt (param) {
    return Send({
      url: '/schedule/my-schedule/promotionDcAmt',
      method: 'post',
      params: param
    })
  }
}
