var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `wrap wrap_${_vm.auth.serviceLang}` },
    [_c("Header"), _c("Content")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }