<template>
  <div class="content resize">
    <h2 class="mb-5">개인화 메인</h2>
    <!-- 팝업  -->
    <div style="width: 340px;">
      <schedule-compare
        :line-count="5"
      />
    </div>

    <div>
      <b-button variant="success" @click="test01">테스트</b-button>
    </div>
  </div>
</template>

<style scoped>
  .content {
    border: 0px solid red;
  }
</style>

<script>
import { rootComputed } from '@/store/helpers'
import ScheduleCompare from '@/components/schedule/ScheduleCompare'

export default {
  name: 'PersonalMain',
  components: {
    ScheduleCompare
  },
  props: {},
  data () {
    return {
      d1: ''
    }
  },
  computed: {
    ...rootComputed
  },
  mounted () {
  },
  methods: {
    test01 () {
      console.log(this.$uuid.v1())
    }
  }
}
</script>
