<template>
  <div class="wrap_util ">
    <!-- wrap_util  -->
    <p
      v-if="!isEmpty(profile)"
      class="member"
    >
      <span>{{ compName }}</span>,<br /><span>{{ picNm }}</span>{{ $t('msg.UTIL010G010.018') }}
    </p>

    <div class="kmtc"
         @mouseover="showTooltip('kmtc')" @mouseout="hideTooltip('kmtc')"
    >
      <a href="https://www.kmtc.co.kr" target="_blank">KMTC</a>
      <div id="kmtc"
           style="display:none; color:black; left:0; top:20px;"
           :style="currentServiceLang === 'KR' ? 'width:90px;' : 'width:77px;'"
           class="tooltip_wrap position_absolute"
      >
        <div class="kmtcCont text_left">
          <ul>
            <li>{{ $t('header.KMTC') }}</li>
          </ul>
        </div>
      </div>
    </div>

    <ul class="util">
      <!--<li><a href="#" class="mypage">마이페이지</a></li>-->
      <template v-if="!lock && (!isEmpty(profile) || isEmptyProfileLogin)">
        <li @mouseover="showTooltip('logout')" @mouseout="hideTooltip('logout')">
          <a @click="logout" class="logout cursor_pointer">로그아웃</a>
          <ul class="tooltip_wrap_util position_absolute">
            <li id="logout" class="utilCont"
                style="display:none; color:black; width:70px; top:20px;"
                :style="currentServiceLang === 'KR' ? 'width:70px;' : 'width:65px;'"
            >
              {{ $t('header.logout') }}
            </li>
          </ul>
        </li> <!-- 로그인 : class="login"  -->
      </template>
      <template v-else>
        <li @mouseover="showTooltip('login')" @mouseout="hideTooltip('login')">
          <a @click="changeLoginBoxStatus()" class="login cursor_pointer">로그인</a>
          <ul class="tooltip_wrap_util position_absolute">
            <li id="login" class="utilCont" style="display:none; color:black; width:70px; top:20px;">{{ $t('header.login') }}</li>
          </ul>
        </li> <!-- 로그인 : class="login"  -->
      </template>
      <!--<li><a href="#" class="color">컬러</a></li>-->
      <li @mouseover="showTooltip('sitemap')" @mouseout="hideTooltip('sitemap')">
        <a href="#" @click.prevent="showSitemap" style="text-indent:-5px;color:white">MENU</a>
        <span class="tooltip_wrap_util position_absolute">
          <li id="sitemap" class="sitemapCont position_absolute" style="display:none; color:black; width:70px; top:20px;">{{ $t('header.sitemap') }}</li>
        </span>
      </li>
    </ul>

    <div class="loginLayer_wrap" style="display:;" v-show="requireLogin" ref="loginLayer">
      <!-- loginLayer_wrap   -->
      <fieldset class="login_form">
        <h2 class="content_title">Login</h2>
        <div class="id_area">
          <label for="id" id="">아이디</label>
          <input type="text" id="id" name="id" placeholder="ID" maxlength="" v-model="form.userId" ref="loginIdInput">
        </div>
        <div class="pw_area">
          <i v-if="!pwdHideYn && form.userPwd" class="fa fa-eye fa" style="--fa-secondary-opacity: 0.80" @click="unhidePassword()"></i>
          <i v-else-if="pwdHideYn && form.userPwd" class="fa fa-eye-slash fa" style="--fa-secondary-opacity: 0.80" @click="unhidePassword()"></i>

          <label for="pw" id="">비밀번호</label>
          <input :type="!pwdHideYn ? 'password' : 'text'" id="pw" name="pw" placeholder="Password" maxlength="" v-model="form.userPwd" ref="loginPasswordInput" @keydown="handleKeydown($event)">
        </div>
        <div class="login_check_box">
          <input type="checkbox" id="login_chk" name="login_chk" v-model="saveUserId">
          <label for="login_chk"><span></span>Remember me</label>

          <!-- <span class="forgot">
            <a
              @click.prevent="$router.push(
                '/find-id-password')
                .then(()=> {store.commit('auth/requireLogin', !store.getters['auth/requireLogin'])}
                ).catch(()=>{}
                )"
            >Forgot Your User ID / Password?</a>
          </span> -->
        </div>
        <div class="btnarea">
          <a class="button blue sm" @click.prevent="submit">{{ $t('msg.UTIL010G010.019') }}</a>
          <!-- <a class="button gray sm" @click.prevent="checkLang">{{ $t('msg.UTIL010G010.020') }}</a> -->
        </div>
      </fieldset>
    </div>
    <!-- wrap_util //  -->

    <win-layer-pop class="no-search-result-pop">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="popupParams"
      />
    </win-layer-pop>

    <div id="login_select_profile">
      <win-layer-pop>
        <!--<SelectProfile :profile-list="profileList" @child="getChildData"></SelectProfile>-->
        <component
          v-if="customComponent"
          :is="customComponent"
          @child="getChildData"
          :profile-list="profileList"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'
import api from '@/api/services/auth'
import ProfileApi from '@/api/rest/user/profile'
import SelectProfile from '../SelectProfile'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import boards from '@/api/services/boards'
import trans from '@/api/rest/trans/trans'
import { keywords } from '@/api/search-menu'
import store from '@/store/index'
export default {
  name: 'BsaEHeaderUtil',
  components: {
    SelectProfile,
    WinLayerPop,
    'no-search-result-pop': () => import('../NoSearchResultPop')
  },
  data () {
    return {
      profileList: [],
      loginResp: {},
      form: {
        userId: '',
        userPwd: ''
      },
      upperForm: {
        userId: '',
        userPwd: ''
      },
      serviceLangs: {
        KOR: 'KR',
        ENG: 'EN',
        JPN: 'JP',
        CHN: 'CN'
      },
      customComponent: null,

      // 통합검색 결과 페이지 이동시 넘겨줄 데이터
      integratedSearchResult: {
        //  메뉴 리스트(메뉴 검색 결과)
        menuList: [],
        // FAQ/자료실/공지사항 리스트
        boardList: {}
      },
      // 메뉴 선택 팝업에 넘겨줄 데이터(Booking No & B/L No & Container No)
      popupParams: {},
      // isEmptyProfileLogin: false,

      loginFormEventFlag: false,
      compName: '',
      picNm: '',
      beforeLoginResp: {},
      isSaveUserId: false,

      addProfileList: [],
      loginApproval: false,
      showkmtc: false,
      pwdHideYn: false
    }
  },
  computed: {
    ...rootComputed,
    isEmptyProfileLogin () {
      return this.$store.getters['auth/getIsEmptyProfileLogin']
    },
    saveUserId: {
      set (v) {
        if (!v) {
          this.delSaveUserId()
          this.form.userId = ''
        }
        this.isSaveUserId = v
      },
      get () {
        return this.isSaveUserId
      }
    },
    currentServiceLang () {
      return this.serviceLangs[this.serviceLang]
    },
    requireLogin () {
      return this.$store.getters['auth/requireLogin']
    },
    loginedUserId () {
      return this.$store.getters['auth/auth']?.orgUserId ?? ''
    },
    requireLoginRtnPath () {
      return this.$store.getters['auth/requireLoginRtnPath']
    },
    profile () {
      return store.state.auth.selectedProfile
    },
    lock () {
      return this.$store.getters['auth/lock']
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.upperForm = {
          userId: this.form.userId.toUpperCase(),
          userPwd: this.form.userPwd
        }
      }
    },
    profile: {
      deep: true,
      handler () {
        setTimeout(() => {
          this.settingUserInfo()
        }, 500)
      }
    },
    requireLogin: function (v) {
      const loginLayer = this.$refs.loginLayer

      if (v) {
        this.loginFormEventFlag = true
        loginLayer.addEventListener('mousedown', this.stopProp)
        document.body.addEventListener('mousedown', this.changeLoginBoxStatus)

        setTimeout(() => {
            this.$refs.loginIdInput && this.$refs.loginIdInput.focus()
        }, 100)
      } else {
        this.loginFormEventFlag = true
        loginLayer.removeEventListener('mousedown', this.stopProp)
        document.body.removeEventListener('mousedown', this.changeLoginBoxStatus)
      }
    }
  },
  created () {
    if (process.env.VUE_APP_MODE !== 'PRD') {
      document.title = process.env.VUE_APP_MODE + '-Prophet'
    } else {
      document.title = 'Prophet'
    }

    if (!this.isEmpty(this.profile)) {
      this.settingUserInfo()
    } else {
      if (!this.lock) {
        this.bsaDelLogin()
      }
    }
  },
  async mounted () {
    this.form.userId = this.savedUserId || this.loginedUserId
    this.isSaveUserId = this.savedUserId !== ''

    //localStorage.clear()
    $('.lang').hover(function () {
      $('.lang>a').attr('class', 'on')
      $('.lang>ul').stop().slideDown(300)
    }, function () {
      $('.lang>a').attr('class', '')
      $('.lang>ul').stop().slideUp(300)
    })
  },
  methods: {
    ...rootMethods,
    changeLoginBoxStatus () {
      if (!this.$store.getters['auth/requireLogin']) {
        if (!this.loginFormEventFlag) {
          this.loginFormEventFlag = true
          this.$el.addEventListener('mousedown', this.stopProp)
          document.body.addEventListener('mousedown', this.changeLoginBoxStatus)
        }
      } else {
        if (this.loginFormEventFlag) {
          this.loginFormEventFlag = false
          this.$el.removeEventListener('mousedown', this.stopProp)
          document.body.removeEventListener('mousedown', this.changeLoginBoxStatus)
        }
      }

      this.$store.commit('auth/requireLogin', !this.$store.getters['auth/requireLogin'])
    },
    checkLang () {
      let locale = this.$i18n.locale

      this.$store.commit('auth/requireLogin', false)
      this.$router.push('/my-info/join-member-form').catch(() => {})
    },
    async logout () {
      // console.log('@@@@ enter logout')
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.UTIL010G010.017'), useConfirmBtn: true })) {
        // console.log('@@@@ yes')
        this.bsaDelLogin().then(resolve => {
          // console.log('@@@@ logout')
          // console.log('@@@@ updateIsEmptyProfileLogin', this.IsEmptyProfileLogin)
        })

        store.commit('auth/updateIsEmptyProfileLogin', false)

        setTimeout(() => {
          location.reload()
        }, 300)
      }
    },
    showSitemap () {
      this.$emit('sitemap', true)
    },
    changeServiceLang: function (serviceLang, serviceCtrCd) {
      this.updateServiceLang({ serviceLang: serviceLang, serviceCtrCd: serviceCtrCd })
      location.reload()
    },
    submit: function () {
      if (this.form.userId === '' && this.form.userPwd === '') {
        this.makeToast()
      } else {
        this.login('MAIN', null)
      }
    },
    login (param, data) {
      if (param === 'APPR' && data !== null) {
        // console.log('@@@@@ login ', data)
        // console.log('@@@@@ login ', data.id)

        this.upperForm.userId = data.id
        this.upperForm.userPwd = data.pw

        this.loginApproval = true
      }

      const promise = new Promise((resolve, reject) => {
        api.login(this.upperForm).then(resp => {
          let respCode = resp.headers.respcode
          if (respCode === 'C0000') {
            resolve(resp)
          } else {
            let respMsg = this.$i18n.t(`js.auth.${respCode}`)
            this.$store.commit('auth/requireLogin', false)
            this.$bvToast.toast(respMsg, {
              title: 'Notice',
              variant: 'danger',
              solid: true
            })
            reject(resp)
          }
        }).catch(e => {
          console.log(e)
        })
      })
      promise
        .then(loginResp => {
          this.updateServiceLang({ serviceLang: loginResp.data.serviceLang, serviceCtrCd: loginResp.data.userCtrCd })
          this.beforeLoginResp = loginResp
          let cstCd = null
          if (this.upperForm.userId.indexOf('EKMTC_') === -1) {
            sessionStorage.setItem('cstCd', loginResp.data.cstCd)
          } else {
            sessionStorage.setItem('cstCd', 'KMT013')
          }
          sessionStorage.setItem('loginId', this.upperForm.userId)
          sessionStorage.setItem('logCatCd', loginResp.data.logCatCd)

          return ProfileApi.getProfileList(this.upperForm.userId, loginResp.data.userCtrCd)
        })
        .then(profileResp => {
          // console.log('profileResp >>>> ', profileResp)
          this.profileList = this.parseProfileList(profileResp.data)
          let profileHistories = localStorage.getItem('profileHistory')
          let isExist = false
          let recentProfile = null
          let isOkNotZero = true

          if (this.isEmpty(profileHistories)) {
            profileHistories = []
          } else {
            profileHistories = JSON.parse(profileHistories)
          }
          for (let profile of profileHistories) {
            if (profile.loginId === this.upperForm.userId) {
              recentProfile = profile
              break
            }
          }

          if (recentProfile != null) {
            for (let i = 0; i < this.profileList.length; i++) {
              if (this.profileList[i].useYn === 'Y' && recentProfile.profile.picNo === this.profileList[i].picNo && recentProfile.profile.cstCatCd === this.profileList[i].cstCatCd) {
                isExist = true

                  // 약관동의 항목 체크
                  if (this.upperForm.userId.indexOf('EKMTC_') === -1 && this.profileList[i].mdtyPsnlInfCltnCnstYn !== 'Y') {
                    // 선택한 프로필의 필수동의 체크
                    isOkNotZero = false
                  }

                  if (!isOkNotZero) {
                      this.$router.push(
                      {
                        name: 'login-approval',
                        params: {
                          checkView: 'beforeProfileHeader',
                          profile: this.profileList[i],
                          profileIdx: i,
                          loginInfo: {
                            id: this.form.userId,
                            pw: this.form.userPwd
                          }
                        }
                      })
                      .catch(() => {})
                  } else {
                    this.profileLogin(i)
                  }

                break
              }
            }

            if (!isExist) {
              this.customComponent = SelectProfile
              this.$ekmtcCommon.layerOpen('#login_select_profile > .popup_dim')
            }
          } else {
            if (this.$route.params.checkView === 'beforeProfileHeader') {
              isExist = true
              setTimeout(() => {
                    this.profileLogin(this.$route.params.profileIdx)
                  }, 100)
            } else {
              if (!isExist) {
                this.customComponent = SelectProfile
                this.$ekmtcCommon.layerOpen('#login_select_profile > .popup_dim')
              }
            }
          }
        })
        .catch(e => {
          let respCode = this.beforeLoginResp.headers.respcode
          if (respCode !== 'C0000') {
            let respMsg = this.$i18n.t(`js.auth.${respCode}`)
            this.$store.commit('auth/requireLogin', false)
            this.$bvToast.toast(respMsg, {
              title: 'Notice',
              variant: 'danger',
              solid: true
            })
          }
        })
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    parseProfileList (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (this.isEmpty(arr[i].picPoto)) {
          arr[i].picPotoString = null
        } else {
          let image = new Image()
          image.src = 'data:image/png;base64,' + arr[i].picPoto
          arr[i].picPotoString = image.src
        }

        // 프로필 수출/수입 구분 변수추가
        arr[i].tmpEiCatCd = ''
      }
      return arr
    },
    getChildData (data) {
      let isOkNotZero = true

      switch (data.type) {
        case 'close':
          this.$ekmtcCommon.layerClose('#login_select_profile > .popup_dim')
          break
        case 'select':
          // 약관동의 항목 체크
          if (this.upperForm.userId.indexOf('EKMTC_') === -1 && this.profileList[data.payload.idx].mdtyPsnlInfCltnCnstYn !== 'Y') {
            // 선택한 프로필의 필수동의 체크
            isOkNotZero = false
          }
          if (!isOkNotZero) {
            this.$ekmtcCommon.layerClose('#login_select_profile > .popup_dim')

            this.$router.push(
            {
              name: 'login-approval',
              params: {
                checkView: 'beforeProfileHeader',
                profile: this.profileList[data.payload.idx],
                profileIdx: data.payload.idx,
                loginInfo: {
                            id: this.form.userId,
                            pw: this.form.userPwd
                          }
              }
            })
            .catch(() => {})
          } else {
            this.profileLogin(data.payload.idx)
          }
          break
        case 'addProfilePage':
          this.$store.commit('auth/requireLogin', false)
          this.$ekmtcCommon.layerClose('#login_select_profile > .popup_dim')
          this.saveLoginData(null)

          if (!this.$ekmtcCommon.isEmpty(data.payload)) {
            this.addProfileList = data.payload
          }
          break
      }
    },
    profileLogin (idx) {
      //console.log('@@@@@ profileLogin idx @@@@@', this.profileList[idx])
      //console.log(this.profileList[idx].userId)
      if (!this.isEmpty(this.profileList[idx].preUserId) && sessionStorage.getItem('loginId') !== this.profileList[idx].preUserId) {
        sessionStorage.setItem('loginId', this.profileList[idx].preUserId)
      }
      store.commit('auth/updateIsEmptyProfileLogin', false)

      let req = {
        userId: this.profileList[idx].userId,
        jwt: this.beforeLoginResp.data.jwt
      }

      api.profileLogin(req).then(resp => {
          this.loginResp = resp
        this.saveLoginData(idx)
      }).catch(e => {
        console.log(e)
      })
    },
    saveLoginData (profileIdx) {
      if (profileIdx === null) {
        this.loginResp = this.beforeLoginResp
      } else {
        // GA dataLayer 추가
        window.dataLayer[0].ga_user_id = this.profileList[profileIdx].userId
        window.dataLayer[0].is_login = 'Y'
        window.dataLayer[0].id = this.profileList[profileIdx].preUserId
        window.dataLayer[0].user_profile = this.profileList[profileIdx].picNm
        window.dataLayer.push({
          event: 'login',
          ga_user_id: this.profileList[profileIdx].userId,
          user_contact_region: this.profileList[profileIdx].userCtrCd,
          user_member_type: this.profileList[profileIdx].cstCatCd,
          user_pic: this.profileList[profileIdx].eiCatCd,
          is_login: 'Y',
          user_profile: this.profileList[profileIdx].picNm,
          id: this.profileList[profileIdx].preUserId
        })
      }
      store.commit('auth/loginResp', this.loginResp)

      let respCode = this.loginResp.headers.respcode
      let respMsg = this.$i18n.t(`js.auth.${respCode}`)
      if (respCode === 'C0000') {
        this.saveLogin({
          user: this.loginResp.data,
          jwt: this.upperForm.userId.indexOf('EKMTC_') === -1 ? this.loginResp.data.jwt : this.beforeLoginResp.data.jwt,
          savedUserId: this.saveUserId ? this.upperForm.userId : '',
          profile: profileIdx === null ? null : this.profileList[profileIdx],
          loginInfo: this.beforeLoginResp.data
        }).then(r => {
          if (profileIdx === null) {
            store.commit('auth/updateIsEmptyProfileLogin', true)

            setTimeout(() => {
              this.$router.push(
                {
                  name: 'profile',
                  params: {
                      checkView: 'fromHeader',
                      profile: 'N'
                  }
                })
            }, 100)
          } else {
            this.$ekmtcCommon.layerClose('#login_select_profile > .popup_dim')

            const requireLoginRtnPath = this.requireLoginRtnPath
            if (requireLoginRtnPath) {
              store.commit('auth/requireLoginRtnPath', '')
              if (requireLoginRtnPath === '##unlock##') {
                this.form.userId = ''
                this.form.userPwd = ''
                return
              }
              location.hash = requireLoginRtnPath
            }

            if (this.loginApproval) {
              setTimeout(() => {
                  this.$router.push('/bsaMain').catch(() => {})
              }, 300)
            } else {
              location.reload()
            }

            this.loginApproval = false
          }
        }).catch(r => {
          let respCode = this.loginResp.headers.respcode
          let respMsg = this.$i18n.t(`js.auth.${respCode}`)
          this.$store.commit('auth/requireLogin', false)
          this.$bvToast.toast(respMsg, {
            title: 'Notice',
            variant: 'danger',
            solid: true
          })
        })
      } else {
        this.$store.commit('auth/requireLogin', false)
        this.$bvToast.toast(respMsg, {
          title: 'Notice',
          variant: 'danger',
          solid: true
        })
      }
    },
    makeToast: function (variant = null) {
      this.$store.commit('auth/requireLogin', false)
      this.$bvToast.toast(this.$i18n.t('js.auth.C1001'), {
        title: 'Notice',
        variant: 'danger',
        solid: true
      })
    },
    // 팝업 열기
    openPopup (cmpNm) {
      this.customComponent = cmpNm
      this.$ekmtcCommon.layerOpen('.no-search-result-pop')
    },
    // 팝업 닫기
    async closePopup (vo) {
      if (!this.isEmpty(this.profile)) {
        // 로그인 상태
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.no-search-result-pop')
        if (vo !== undefined) {
          let query = {
            searchType: vo.searchType,
            keyword: vo.keyword,
            eiCatCd: vo.eiCatCd
          }

          if (vo.menu.routerName === 'progress') {
            query = {
              ...query,
              ...{
                workType: (vo.eiCatCd !== 'I' ? 'E' : 'I'),
                kind: '02'
              }
            }

            if (vo.menu.routerName === 'progress') {
              this.$store.commit('trans/updateSearchParams', query)
            }
          } else if (vo.menu.routerName === 'calcIssueMain') {
            query = {
              ...query,
              ...{
                dtKnd: 'BL',
                tab: '1'
              }
            }

            if (vo.menu.routerName === 'calcIssueMain') {
              this.$store.commit('settle/updateTabIndex', query)
            }
          } else if (vo.menu.routerName === 'FreeTimeRequest') {
            query = {
              ...query,
              ...{
                dtKnd: (vo.searchType !== 'CONTAINER' ? 'BL' : 'CN'),
                num: vo.keyword
              }
            }
          }

          if (vo.menu.routerName === vo.bgRouteName) {
            if (vo.menu.routerName === 'progress') {
              this.$store.commit('trans/updateSearchParams', query)
            }
          } else {
            this.$router.push({ name: vo.menu.routerName, query: query })
          }
        }
      } else {
        // 비로그인 상태
        if (vo !== undefined) {
          const chkLoginPage = ['progress', 'calcIssueMain']

          let query = {
            searchType: vo.searchType,
            keyword: vo.keyword,
            eiCatCd: vo.eiCatCd
          }

          if (chkLoginPage.includes(vo.menu.routerName)) {
            // 로그인 후 검색결과 화면으로 이동합니다.
            await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.UTIL010G010.004') })
          }

          this.customComponent = null
          this.$ekmtcCommon.layerClose('.no-search-result-pop')

          if (vo.menu.routerName === 'progress') {
            query = {
              ...query,
              ...{
                workType: (vo.eiCatCd !== 'I' ? 'E' : 'I'),
                kind: '02'
              }
            }
          } else if (vo.menu.routerName === 'calcIssueMain') {
            query = {
              ...query,
              ...{
                dtKnd: 'BL',
                tab: '1'
              }
            }
          } else if (vo.menu.routerName === 'FreeTimeRequest') {
            query = {
              ...query,
              ...{
                dtKnd: (vo.searchType !== 'CONTAINER' ? 'BL' : 'CN'),
                num: vo.keyword
              }
            }
          }

          this.$router.push({ name: vo.menu.routerName, query: query })
        } else {
          // 닫기버튼을 클릭했을 때
          this.customComponent = null
          this.$ekmtcCommon.layerClose('.no-search-result-pop')
        }
      }
    },
    stopProp (e) {
      e.stopPropagation()
    },
    async settingUserInfo () {
      // console.log('@@@@ enter settingUserInfo: ', this.auth.jwt)
      if (this.auth.jwt === undefined) return
      await api.info(this.auth.jwt).then(res => {
        const result = res.data

        if (result) {
          // console.log('@@@@ result: ', result)

          this.compName = result.compName ? result.compName : result.compEname
          this.$store.commit('auth/savedCompName', this.compName)
          this.$store.commit('auth/defaultAssign', {
            key: 'staffFlag',
            value: result.staffFlag ? result.staffFlag : ''
          })
          if (this.isEmpty(this.selectedProfile)) {
            this.picNm = '고객'
          } else {
            if (!this.isEmpty(this.selectedProfile.picAlsNm)) {
              this.picNm = this.selectedProfile.picAlsNm
            } else {
              if (!this.isEmpty(this.selectedProfile.picNm)) {
                this.picNm = this.selectedProfile.picNm
              }
            }
          }
        }
      })
    },
    handleKeydown (e) {
      // console.log('@@@@ e: ', e)
      if (e.keyCode === 13) {
        this.submit()
      }
    },
    showTooltip (id) {
      $('#' + id).show()
    },
    hideTooltip (id) {
      $('#' + id).hide()
    },
    unhidePassword () {
      this.pwdHideYn = !this.pwdHideYn
    }
  }
}
</script>

<style scoped>
  /*  inputClear  설정   */
  ::-ms-clear { display: none; }
  .inputClear { position: relative; }
  .icon_clear { position:absolute; top:5px; right:10px; cursor: pointer; display:inline-block; width: 14px;height: 14px; background:url(../../../assets/images/common/icon_clear.png) 0 0 no-repeat; }

 .tooltip_wrap .kmtcCont {
    margin: 0;
    padding: 6px 10px 5px 18px;
 }

.tooltip_wrap_util {
    z-index: 100;
    /* border: 1px solid #ccced9; */
    border-radius: 4px;
    background: #fff;
 }

 .tooltip_wrap_util .utilCont {
    margin: 0 !important;
    padding: 12px 1px 14px 10px;
 }
 .tooltip_wrap_util .searchCont {
    left:0;
    margin: 0 !important;
    padding: 12px 1px 14px 21px;
 }

 .tooltip_wrap_util .searchContOver {
    left:0;
    margin: 0 !important;
    padding: 12px 1px 14px 14px;
 }

 .tooltip_wrap_util .sitemapCont {
    position: absolute !important;
    margin: -20px -32px !important;
    padding: 12px 1px 14px 6px;
    z-index: 100;
    /* border: 1px solid #ccced9; */
    border-radius: 4px;
    background: #fff;
 }

 div.pw_area i{
    position: absolute;
    left: 86%;
    top: 83px;
    color: black;
    opacity: 0.8;
}

.member::after {
  visibility: hidden;
}

.kmtc::after {
  visibility: hidden;
}

</style>
