import Send from '../../schedule.client.js'

export default {
  getScheduleChangeInfo (param) {
    return new Promise((resolve, reject) => {
      Send({
            // url: 'http://localhost:9007/schedule/change/history',
            url: '/schedule/change/history',
            method: 'GET',
            params: {
              cstNo: param.cstNo
            }
            // params: params
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  }
}
