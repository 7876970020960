<template>
  <div class="header" style="background-color: #A985C6;">
    <!-- header -->
    <div class="inner_header">
      <h1 class="bsaLogo" style="left: 0px; top: 12px;"><a href="/#/" @click.prevent="$router.push('/bsaMain').catch(()=>{});">e-kmtc</a></h1>
      <div class="wrap_gnb">
        <ul class="bsaGnb">
          <li v-for="(vo, idx) in menus" :key="'top_menu_' + idx">
            <a v-if="vo.funcOpenType == null" :href="`#${vo.path}`" @click.prevent="$router.push(vo.path).catch(()=>{});" :class="{ on: vo.on }">{{ vo.title }}</a>
            <a v-else @click.prevent="menuOpen(vo.funcOpenType, vo.path)" class="cursor_pointer" :class="{ on: vo.on }">{{ vo.title }}</a>
          </li>
        </ul>
      </div>
      <e-header-util @sitemap="fnSitemap"></e-header-util>
    </div>
    <div class="bsa">
      <div class="sitemap">
        <div class="sitemap_header">
          <button type="button" class="bsa_sitemap_close" @click.prevent="fnSitemap(false)"><span class="offscreen">사이트맵 닫기</span></button>
        </div>
        <div class="sitemap_content">
          <div class="sm_con">
            <ul>
              <!-- <e-sitemap-menu :menu="menusAll[0]" @click="fnSitemap(false)"></e-sitemap-menu>
              <e-sitemap-menu :menu="menusAll[3]" @click="fnSitemap(false)"></e-sitemap-menu>
              <e-sitemap-menu :menu="menusAll[6]" @click="fnSitemap(false)"></e-sitemap-menu>
              <e-sitemap-menu :menu="menusCp[0]" @click="fnSitemap(false)"></e-sitemap-menu> -->
              <e-sitemap-menu :menu="menusBsa[0]" @click="fnSitemap(false)"></e-sitemap-menu>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- header // -->
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'
import EHeaderUtil from '@/components/layout/bsa/BsaEHeaderUtil'
import ESitemapMenu from '@/components/layout/ESitemapMenu'
import topmenu_bsa from '@/router/topmenu_bsa'
import bsaCommon from '@/api/rest/bsa/bsaCommonInfo'

export default {
  name: 'Header',
  components: { EHeaderUtil, ESitemapMenu },
  data () {
    return {
      menusAll: [],
      menusBsa: [],
      menus: [],
      bsaUser: 'N',
      kmtcSpotUserYn: 'N'
    }
  },
  computed: {
    ...rootComputed,
    serviceLang () {
      return this.$store.state.auth.serviceLang
    },
    staffFlag () {
      return this.$store.getters['auth/isStaff']
    }
  },
  watch: {
    $route (to, from) {
      this.setMenuOn(to.path)
    },
    serviceLang: function () {
      this.menus = this.setMenus()
    }
  },
  async created () {
    this.setMenuOn(this.$route.path)
    this.menusAll = []

    // BSA Role 에 맞게 BSA 메뉴를 설정
    bsaCommon.bsaRole().then(response => {
      this.bsaUser = response.data.bsaUser
      this.menus = this.setMenus()
      if (this.staffFlag) {
        if (this.bsaUser === 'Y') {
          this.menusBsa = [...topmenu_bsa]
          this.menusBsa[0].children[4].meta.sitemapHide = true
        }
      }
    }).catch(err => {
      console.log(err)
    })
    const staffFlag = this.$store.getters['auth/isStaff']
    console.log('@@@@ create staffFlag: ', staffFlag)
  },
  methods: {
    ...rootMethods,
    setMenus () {
      let menus = []
      console.log(this.bsaUser)
      if (this.bsaUser === 'Y') {
        menus = [
          { id: 0, title: this.$t('menu.MENUBSA.000'), path: '/bsa/bsa-Performance', on: false }, // BSA 실적데이터
          { id: 1, title: this.$t('menu.MENUBSA.030'), path: '/bsa/bsa-variables', on: false }, // BSA 외부변수
          { id: 2, title: this.$t('menu.MENUBSA.010'), path: '/bsa/bsa-totalData', on: false }, // BSA 집계데이터
          { id: 3, title: this.$t('menu.MENUBSA.020'), path: '/bsa/bsa-adjRvnTotal', on: false } // BSA 항차별 조정/항로수익집계
        ]
      }

      return menus
    },
    menuOpen (openType, url) {
      if (openType === 'calcIssue') { // 정산/발행
        this.$store.commit('settle/updateTabIndex', null) // 저장된 탭index 삭제
        this.$router.push(url).catch(() => {})
      }
    },
    setMenuOn (fullPath) {
      let path = '/'

      if (fullPath.includes('/')) {
        const arrPath = fullPath.split('/')

        if (arrPath.length >= 1) {
          path = '/bsa/' + arrPath[2]
        }
      }

      this.menus.forEach(menu => {
        menu.on = path !== '/' && (path === menu.path || menu.path.indexOf(path) === 0)
      })
    },
    fnSitemap (show) {
      if (show) {
        $('.sitemap').addClass('active')
        $('.wrap').append('<div class="dimmed"></div>')
        $('.dimmed').fadeIn(600)
        $('body,html').css('overflow', 'hidden')

        this.$el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.fnSitemapHide)
      } else {
        $('.sitemap').removeClass('active')
        $('.dimmed').remove()
        $('body,html').removeAttr('style')

        this.$el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.fnSitemapHide)
      }
    },
    fnSitemapHide () {
      this.fnSitemap(false)
    },
    stopProp (e) {
      e.stopPropagation()
    }
  }
}
</script>

<style>

.dimmed {
  z-index: 901 !important;
}
.bsa .sitemap {
  width: 340px;
  background-color: #A985C6;
}
.bsa .sitemap .sitemap_header {
  background-color: #A985C6;
}
.bsa .sitemap .sitemap_content {
  background-color: #A985C6;
}
</style>
