var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("body", { staticClass: "main" }, [
    _vm.auth.login && _vm.kmtcSpotUserYn === "Y" && _vm.kmtcSpotCNegoYn === "Y"
      ? _c("div", { staticClass: "main_banner" }, [
          _vm.isShow.close
            ? _c(
                "button",
                {
                  staticClass: "layer_close",
                  on: {
                    click: function ($event) {
                      return _vm.mainBannerClose()
                    },
                  },
                },
                [_vm._v("close")]
              )
            : _vm._e(),
          _vm.isShow.text
            ? _c("p", { staticClass: "text_desc" }, [
                _vm.isShow.link
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.clickBanner("kmtconC")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "bell_img",
                          attrs: {
                            src: require("../assets/images/main/bell.png"),
                          },
                        }),
                        _vm._v("  "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("msg.MAIN010G030.040")),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.auth.login && _vm.kmtcSpotUserYn === "Y" && _vm.kmtcSpotNegoYn === "Y"
      ? _c("div", { staticClass: "main_banner" }, [
          _vm.isShow.close
            ? _c(
                "button",
                {
                  staticClass: "layer_close",
                  on: {
                    click: function ($event) {
                      return _vm.mainBannerClose()
                    },
                  },
                },
                [_vm._v("close")]
              )
            : _vm._e(),
          _vm.isShow.text
            ? _c("p", { staticClass: "text_desc" }, [
                _vm.isShow.link
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.clickBanner("kmtcon")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "bell_img",
                          attrs: {
                            src: require("../assets/images/main/bell.png"),
                          },
                        }),
                        _vm._v("  "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("msg.MAIN010G030.036")),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "wrap",
        on: {
          click: function ($event) {
            return _vm.quickMenuClose($event)
          },
        },
      },
      [
        _c(
          "win-layer-pop",
          { staticClass: "main_inquiry_pop" },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.popupParams },
                  on: { close: _vm.closePopup },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "container_main" }, [
          _c("div", { staticClass: "quick_section" }, [
            _c("form", { attrs: { id: "frm_quick" } }, [
              _c("p", { staticClass: "txt" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.001")))]),
              ]),
              _c("ul", [
                _vm.serviceCtrCd === "KR"
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.quickMenuOpen("pickup")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "quick_icon quick01" }),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msg.MAIN010G030.002")),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "quick_sub",
                          style: {
                            display: [
                              _vm.quickMenu.pickup.openFlag ? "block" : "none",
                            ],
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "icon",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.quickMenuOpen("pickup")
                                },
                              },
                            },
                            [_c("span", { staticClass: "quick_icon quick01" })]
                          ),
                          _c("p", { staticClass: "cont" }, [
                            _c("span", { staticClass: "tit_label" }, [
                              _vm._v(_vm._s(_vm.$t("msg.MAIN010G030.003"))),
                            ]),
                            _c("span", { staticClass: "sch_form" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.quickMenu.pickup.keyword,
                                    expression: "quickMenu.pickup.keyword",
                                  },
                                ],
                                attrs: {
                                  id: "pickup_keyword",
                                  type: "text",
                                  placeholder: _vm.$t("msg.MAIN010G030.004"),
                                },
                                domProps: {
                                  value: _vm.quickMenu.pickup.keyword,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.quickMenu.pickup,
                                      "keyword",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "a",
                                {
                                  staticClass: "button blue",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.searchByQuickMenu("pickup")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.005")))]
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "sub_close" }, [
                            _c(
                              "a",
                              {
                                staticClass: "sub_close",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.quickMenuOpen("pickup")
                                  },
                                },
                              },
                              [_vm._v("닫기")]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.quickMenuOpen("demdet")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "quick_icon quick02" }),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("msg.MAIN010G030.006")),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "quick_sub",
                      style: {
                        display: [
                          _vm.quickMenu.demdet.openFlag ? "block" : "none",
                        ],
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "icon",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.quickMenuOpen("demdet")
                            },
                          },
                        },
                        [_c("span", { staticClass: "quick_icon quick02" })]
                      ),
                      _c("p", { staticClass: "cont" }, [
                        _c("span", { staticClass: "tit_label" }, [
                          _vm._v(_vm._s(_vm.$t("msg.MAIN010G030.007"))),
                        ]),
                        _c("span", { staticClass: "ei-cat-cd-radio" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eiCatCdRadio,
                                expression: "eiCatCdRadio",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              id: "quick-demdet-O",
                              value: "O",
                            },
                            domProps: {
                              checked: _vm._q(_vm.eiCatCdRadio, "O"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.eiCatCdRadio = "O"
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "quick-demdet-O" } }, [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.PERS010L022.003"))),
                          ]),
                        ]),
                        _c("span", { staticClass: "ei-cat-cd-radio" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eiCatCdRadio,
                                expression: "eiCatCdRadio",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              id: "quick-demdet-I",
                              value: "I",
                            },
                            domProps: {
                              checked: _vm._q(_vm.eiCatCdRadio, "I"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.eiCatCdRadio = "I"
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "quick-demdet-I" } }, [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.PERS010L022.012"))),
                          ]),
                        ]),
                        _c("span", { staticClass: "sch_form" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quickMenu.demdet.keyword,
                                expression: "quickMenu.demdet.keyword",
                              },
                            ],
                            attrs: {
                              id: "demdet_keyword",
                              type: "text",
                              placeholder: _vm.$t("msg.MAIN010G030.008"),
                            },
                            domProps: { value: _vm.quickMenu.demdet.keyword },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quickMenu.demdet,
                                  "keyword",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "button blue",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.searchByQuickMenu("demdet")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.009")))]
                          ),
                        ]),
                      ]),
                      _c("p", { staticClass: "sub_close" }, [
                        _c(
                          "a",
                          {
                            staticClass: "sub_close",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.quickMenuOpen("demdet")
                              },
                            },
                          },
                          [_vm._v("닫기")]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.quickMenuOpen("payment")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "quick_icon quick03" }),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("msg.ONIM050G010.001")),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "quick_sub",
                      style: {
                        display: [
                          _vm.quickMenu.payment.openFlag ? "block" : "none",
                        ],
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "icon",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.quickMenuOpen("payment")
                            },
                          },
                        },
                        [_c("span", { staticClass: "quick_icon quick03" })]
                      ),
                      _c("p", { staticClass: "cont" }, [
                        _c("span", { staticClass: "tit_label" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONIM050G010.001"))),
                        ]),
                        _c("span", { staticClass: "sch_form" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quickMenu.payment.keyword,
                                expression: "quickMenu.payment.keyword",
                              },
                            ],
                            attrs: {
                              id: "payment_keyword",
                              type: "text",
                              placeholder: _vm.$t("msg.MAIN010G030.012"),
                            },
                            domProps: { value: _vm.quickMenu.payment.keyword },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quickMenu.payment,
                                  "keyword",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "button blue",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.searchByQuickMenu("payment")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.013")))]
                          ),
                        ]),
                      ]),
                      _c("p", { staticClass: "sub_close" }, [
                        _c(
                          "a",
                          {
                            staticClass: "sub_close",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.quickMenuOpen("payment")
                              },
                            },
                          },
                          [_vm._v("닫기")]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm.serviceCtrCd === "KR"
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.quickMenuOpen("return")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "quick_icon quick04" }),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("msg.MAIN010G030.014"))),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "quick_sub",
                          style: {
                            display: [
                              _vm.quickMenu.return.openFlag ? "block" : "none",
                            ],
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "icon",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.quickMenuOpen("return")
                                },
                              },
                            },
                            [_c("span", { staticClass: "quick_icon quick04" })]
                          ),
                          _c("p", { staticClass: "cont" }, [
                            _c("span", { staticClass: "tit_label" }, [
                              _vm._v(_vm._s(_vm.$t("msg.MAIN010G030.014"))),
                            ]),
                            _c("span", { staticClass: "sch_form" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.quickMenu.return.keyword,
                                    expression: "quickMenu.return.keyword",
                                  },
                                ],
                                attrs: {
                                  id: "return_keyword",
                                  type: "text",
                                  placeholder: _vm.$t("msg.MAIN010G030.015"),
                                },
                                domProps: {
                                  value: _vm.quickMenu.return.keyword,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.quickMenu.return,
                                      "keyword",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "a",
                                {
                                  staticClass: "button blue",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.searchByQuickMenu("return")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.016")))]
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "sub_close" }, [
                            _c(
                              "a",
                              {
                                staticClass: "sub_close",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.quickMenuOpen("return")
                                  },
                                },
                              },
                              [_vm._v("닫기")]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.quickMenuOpen("cntrTareWeight")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "quick_icon quick06" }),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("CNTR Tare Weight")),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "quick_sub",
                      style: {
                        display: [
                          _vm.quickMenu.cntrTareWeight.openFlag
                            ? "block"
                            : "none",
                        ],
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "icon",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.quickMenuOpen("cntrTareWeight")
                            },
                          },
                        },
                        [_c("span", { staticClass: "quick_icon quick06" })]
                      ),
                      _c("p", { staticClass: "cont" }, [
                        _c("span", { staticClass: "tit_label" }, [
                          _vm._v(_vm._s(_vm.$t("CNTR Tare Weight"))),
                        ]),
                        _c("span", { staticClass: "sch_form" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quickMenu.cntrTareWeight.keyword,
                                expression: "quickMenu.cntrTareWeight.keyword",
                              },
                            ],
                            attrs: {
                              id: "cntrTareWeight_keyword",
                              type: "text",
                              placeholder: _vm.$t("msg.MAIN010G030.008"),
                            },
                            domProps: {
                              value: _vm.quickMenu.cntrTareWeight.keyword,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quickMenu.cntrTareWeight,
                                  "keyword",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "button blue",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.searchByQuickMenu("cntrTareWeight")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.016")))]
                          ),
                        ]),
                      ]),
                      _c("p", { staticClass: "sub_close" }, [
                        _c(
                          "a",
                          {
                            staticClass: "sub_close",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.quickMenuOpen("cntrTareWeight")
                              },
                            },
                          },
                          [_vm._v("닫기")]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.quickMenuOpen("lCharge")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "quick_icon quick05" }),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.$t("Local Charge")) },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "content_main" }, [
            _c("div", { staticClass: "visual_area" }, [
              _vm._m(0),
              _c("form", { attrs: { id: "frm_main" } }, [
                _c("div", { staticClass: "main_cont" }, [
                  _c("div", { staticClass: "cont" }, [
                    _vm.searchFlag === "S"
                      ? _c("div", { staticClass: "open" }, [
                          _c("span", { staticClass: "tit_label" }, [
                            _vm._v(_vm._s(_vm.$t("msg.MAIN010G030.017"))),
                          ]),
                          _c("div", { staticClass: "sch_form" }, [
                            _c(
                              "p",
                              { staticClass: "box d_inline_flex" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "tit",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "2px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnFocusInput("polPol")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.MAIN010G030.018"))
                                    ),
                                  ]
                                ),
                                _c("e-auto-complete-place", {
                                  staticClass: "w-100",
                                  attrs: {
                                    id: "polPol",
                                    "ctr-cd": _vm.scheduleForm.startCtrCd,
                                    "plc-cd": _vm.scheduleForm.startPlcCd,
                                    "is-char": true,
                                    "is-char-alert": true,
                                    "list-group-width": _vm.autoCompleteWidth,
                                  },
                                  on: { change: _vm.changePol },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "box d_inline_flex" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "tit",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "2px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnFocusInput("podPod")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.MAIN010G030.019"))
                                    ),
                                  ]
                                ),
                                _c("e-auto-complete-place", {
                                  staticClass: "w-100",
                                  attrs: {
                                    id: "podPod",
                                    "ctr-cd": _vm.scheduleForm.destCtrCd,
                                    "plc-cd": _vm.scheduleForm.destPlcCd,
                                    "is-char": true,
                                    "is-char-alert": true,
                                    "list-group-width": _vm.autoCompleteWidth,
                                  },
                                  on: { change: _vm.changePod },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "button blue",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.goSchedule()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.020")))]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.searchFlag !== "S"
                      ? _c(
                          "div",
                          {
                            staticClass: "off",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.chgSearchMenu("S")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "link_icon schdule",
                                attrs: { href: "#" },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.017")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "cont" }, [
                    _vm.searchFlag === "C"
                      ? _c(
                          "div",
                          {
                            staticClass: "open",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c("span", { staticClass: "tit_label" }, [
                              _vm._v(_vm._s(_vm.$t("msg.MAIN010G030.021"))),
                            ]),
                            _c("div", { staticClass: "sch_form case2" }, [
                              _c("p", { staticClass: "box" }, [
                                _c("span", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.cargoTrackingForm.keyword,
                                        expression: "cargoTrackingForm.keyword",
                                      },
                                    ],
                                    attrs: {
                                      id: "cargoKeyword",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "msg.MAIN010G030.022"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.cargoTrackingForm.keyword,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.cargoTrackingForm,
                                          "keyword",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "button blue",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.goCargoTracking()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.020")))]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.searchFlag !== "C"
                      ? _c(
                          "div",
                          {
                            staticClass: "off",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.chgSearchMenu("C")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "link_icon cargo",
                                attrs: { href: "#" },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.021")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "cont" }, [
                    _vm.searchFlag === "SC"
                      ? _c("div", { staticClass: "open" }, [
                          _c("span", { staticClass: "tit_label" }, [
                            _vm._v(_vm._s(_vm.$t("msg.MAIN010G030.024"))),
                          ]),
                          _c("div", { staticClass: "sch_form" }, [
                            _c(
                              "p",
                              { staticClass: "box d_inline_flex" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "tit",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "2px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnFocusInput("polPol2")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.MAIN010G030.025"))
                                    ),
                                  ]
                                ),
                                _c("e-auto-complete-place", {
                                  staticClass: "w-100",
                                  attrs: {
                                    id: "polPol2",
                                    "ctr-cd": _vm.shippingCostForm.porCtrCd,
                                    "plc-cd": _vm.shippingCostForm.porPlcCd,
                                    "is-char": true,
                                    "is-char-alert": true,
                                    "list-group-width": _vm.autoCompleteWidth,
                                  },
                                  on: { change: _vm.changePol2 },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "box d_inline_flex" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "tit",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "2px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnFocusInput("podPod2")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.MAIN010G030.026"))
                                    ),
                                  ]
                                ),
                                _c("e-auto-complete-place", {
                                  staticClass: "w-100",
                                  attrs: {
                                    id: "podPod2",
                                    "ctr-cd": _vm.shippingCostForm.dlyCtrCd,
                                    "plc-cd": _vm.shippingCostForm.dlyPlcCd,
                                    "is-char": true,
                                    "is-char-alert": true,
                                    "list-group-width": _vm.autoCompleteWidth,
                                  },
                                  on: { change: _vm.changePod2 },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "button blue",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fnSearchSurCharge()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.027")))]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.searchFlag !== "SC"
                      ? _c(
                          "div",
                          {
                            staticClass: "off",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.chgSearchMenu("SC")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "link_icon fare",
                                attrs: { href: "#" },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.024")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
            _vm.noticeList !== undefined && _vm.noticeList.length > 0
              ? _c(
                  "div",
                  { staticClass: "notice_area", attrs: { id: "notice_area" } },
                  [
                    _c("div", { staticClass: "title" }, [
                      _c("h1", [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.028")))]),
                      _c(
                        "a",
                        {
                          staticClass: "more",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$router
                                .push({ name: "notice" })
                                .catch(() => {})
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.029")))]
                      ),
                    ]),
                    _vm._l(_vm.noticeList, function (notice, idx) {
                      return _c(
                        "dl",
                        {
                          key: `${notice.noticeId}_${idx}`,
                          class: [idx === 0 ? "first" : ""],
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$router
                                .push({ name: "notice", query: notice })
                                .catch(() => {})
                            },
                          },
                        },
                        [
                          _c("dt", [
                            _c("div", { staticClass: "text_overflow" }, [
                              _vm._v(_vm._s(notice.title)),
                            ]),
                          ]),
                          _c("dd", [
                            _c("div", {
                              staticClass: "text_overflow",
                              domProps: { innerHTML: _vm._s(notice.content) },
                            }),
                          ]),
                          _c("dd", { staticClass: "date" }, [
                            _vm._v(_vm._s(notice.fstEntDtm)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "banner_area" }, [
              _c("div", { staticClass: "service" }, [
                _c("div", { staticClass: "slick_ban" }, [
                  _c("dl", {
                    staticClass: "banner11",
                    class: {
                      KR: _vm.auth.serviceLang === "KOR",
                      EN: _vm.auth.serviceLang === "ENG",
                      JP: _vm.auth.serviceLang === "JPN",
                      CN: _vm.auth.serviceLang === "CHN",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickBanner("mobile")
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "service" }, [
                _c("div", { staticClass: "slick_ban2" }, [
                  _c("dl", {
                    staticClass: "banner21",
                    class: {
                      KR: _vm.auth.serviceLang === "KOR",
                      EN: _vm.auth.serviceLang === "ENG",
                      JP: _vm.auth.serviceLang === "JPN",
                      CN: _vm.auth.serviceLang === "CHN",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickBanner("ongoing")
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "service" }, [
                _c("div", { staticClass: "slick_ban2" }, [
                  _c("dl", {
                    staticClass: "banner22",
                    class: {
                      KR: _vm.auth.serviceLang === "KOR",
                      EN: _vm.auth.serviceLang === "ENG",
                      JP: _vm.auth.serviceLang === "JPN",
                      CN: _vm.auth.serviceLang === "CHN",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickBanner("freight")
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "scroll_btn" }, [
      _c("a", { attrs: { href: "#notice_area" } }, [_vm._v("스크롤 버튼")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }