// 화물추적, 나의정보, 고객센터
import EmptyLayout from '@/layout/EmptyLayout'
import { checkPermission } from './permission'
import PersonalMain from '@/pages/personal/PersonalMain'

const routes = [
  {
    path: '/personal',
    component: PersonalMain,
    meta: {
      breadcrumb: 'Controll Panel'
    },
    children: [
      {
        path: 'test',
        name: 'test',
        component: EmptyLayout,
        meta: {
          breadcrumb: '테스트'
        }
      }
    ]
  }
]

export default routes
