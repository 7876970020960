import { mapState, mapGetters, mapActions } from 'vuex'

export const rootComputed = {
  console: () => console,
  ...mapState('auth', [
    'auth',
    'memberDetail',
    'loginInfo',
    'jwt',
    'serviceLang',
    'savedUserId',
    'selectedProfile'
  ],
  'savedProfileList'
  )
}
export const rootMethods = {
  ...mapActions('auth', [
    'saveLogin',
    'saveLoginAdmin',
    'delLogin',
    'bsaDelLogin',
    'delSaveUserId',
    'updateServiceLang'
  ],
  'saveProfileList')
}
