<template>
  <div class="per_recom" v-show="isSlick">
    <h2>{{ $t('msg.PERS010L020.059') }}</h2>
    <div class="recom_sch_area">
      <div class="indicator">
        <ul>
          <template v-for="(li, i) in scheduleList">
            <li :key="'schedule-indicator-' + i">
              <a
                :class="parseInt(currentIdx) === i ? 'on' : ''"
                href="javascript:void(0)"
                @click="clickIndicator(i)"
              >
              </a>
            </li>
          </template>
        </ul>
      </div>
      <Slick
        v-if="isSlick"
        ref="slick"
        :options="slickOptions"
        @afterChange="handleAfterChange"
      >
        <!-- 프로모션 스케쥴 배너 시작 -->
        <div class="recom_sch_promo" v-for="(schedule, i) in scheduleList" :key="'schedule-slick-' + i" @mousedown="mouseDown" @mouseup="mouseUp" @click="moveSchedulePage(i)">
          <p class="port">
<!--            <span>{{ schedule.polPortCd }} &nbsp;&raquo;&nbsp; {{ schedule.podPortCd }}</span>-->
            <span>{{ schedule.polPortCd }}</span>
            <span>{{ schedule.podPortCd }}</span>
          </p>
          <span v-if="schedule.salesCt !== 'Y'"> <!-- 영업담당자 문의 아니면 -->
            <p class="name">{{ schedule.info }}</p>
            <span v-if="schedule.info.length <= 32"><br></span>
            <span v-if="schedule.ofdcChk === 0"> <!-- offset 프로모션 -->
              <span v-if="schedule.DcType === '$'">
                <p class="dc">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#10022; {{ schedule.DcType }}{{ schedule.DcRate }}{{ $t('msg.PERS010L020.060') }} &#10022;</p><!-- /TEU 할인 -->
              </span>
              <span v-else>
                <p class="dc">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#10022; {{ schedule.DcRate }}{{ schedule.DcType }}{{ $t('msg.PERS010L020.060') }} &#10022;</p><!-- /TEU 할인 -->
              </span>
            </span>
            <span v-else> <!-- 직접운임등록 프로모션 -->
              <p class="dc">&nbsp;&nbsp;&nbsp;&nbsp;&#10022; {{ schedule.DcType }}{{ schedule.DcRate }} {{ $t('msg.PERS010L020.061') }} &#10022;</p><!-- 특가 -->
            </span>
          </span>
          <span v-else> <!-- 영업담당자 문의 -->
            <p class="name">{{ schedule.info }}</p>
            <span v-if="schedule.info.length <= 32"><br></span>
            <p class="dc">&#10022; {{ $t('msg.PERS010L020.062') }} &#10022;</p>
          </span>
        </div>
      </Slick>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import '@/../node_modules/slick-carousel/slick/slick.css'
import myScheduleApi from '@/api/rest/schedule/mySchedule'
import FreSurchargePop from '@/pages/schedule/popup/FreSurchargePop'
import schedules from '@/api/rest/schedule/schedule'

export default {
  name: 'PromotionSchedule',
  components: {
    Slick,
    FreSurchargePop
  },
  props: [
  ],
  data: function () {
    return {
      scheduleList: [],
      promotionList: [],
      oriScheduleList: [],
      currentIdx: 0,
      slickOptions: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: false,
        pauseOnHover: true
      },
      isSlick: false,
      isUpdater: false,
      isPreventClick: true,
      mX1: 0,
      mX2: 0,
      fromBkg: 'N',
      customComponentSch: null,
      selectFunc: null,
      parentInfoSch: {},
      popupParams: {
        FreSurchargePop: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          startDt: '',
          endDt: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: '',
          promotionChk: 'Y',
          ofCntrSize01: '',
          ofCntrType01: '',
          ofDcRate01: 0,
          ofDcType01: '',
          ofCntrSize02: '',
          ofCntrType02: '',
          ofDcRate02: 0,
          ofDcType02: '',
          dtCntrSize01: '',
          dtCntrType01: '',
          dtDcRate01: 0,
          dtDcType01: '',
          dtCntrSize02: '',
          dtCntrType02: '',
          dtDcRate02: 0,
          dtDcType02: ''
        }
      },
      bkgParams: {
        vslCd: '', // [0]선명코드
        voyNo: '', // [1]항차
        vslNm: '', // [2]선명
        polName: '', // [3]출발지 포트명
        podName: '', // [4]도착지 포트명
        etd: '', // [5]입항일
        etdTm: '', // [6]입항시간
        eta: '', // [7]출항일
        etaTm: '', // [8]출항시간
        polPortCd: '', // [9]출발지 포트코드
        podPortCd: '', // [10]도착지 포트코드
        polCtrCd: '', // [11]출발지 국가코드
        podCtrCd: '', // [12]도착지 국가코드
        cct: '', // [13] ?
        docCls: '', // [14]서류마감
        docPic: '', // [15]서류마감 담당자
        polTrmlCd: '', // [16]출발지 터미널 코드
        podTrmlCd: '', // [18]도착지 터미널 코드
        mrnNo: '', // [18]MRN Number
        callSign: '', // [19]Call sign
        hidCS008I: '', // [20]info (@_@로 붙어있는 정보)
        originInfo: '', // [20]info Backup (@_@로 붙어있는 정보)
        mfCls: '', // [21]EDI 신고마감
        cgoCls: '', // [22]반입마감
        cgoPic: '', // [23]반입마감 담당자
        finalEta: '', // [24]최종ETA
        cfsCls: '', // [25]cfs 반입마감
        cfsPic: '', // [26]cfs 반입마감 담당자
        bkgCloseYn: '', // [27] booking close 여부
        prrmSgEtd: '', // [28] ?
        polTrmlNm: '', // [29] 출발지 터미널명
        railCheck: '', // [30] rail booking 여부 ? >> schedule 처리 부분 없음(주석처리)
        frtAppNo: '', // [31] 운임 APPLICATION 번호 >> 견적운임조회
        logDtm: '', // [32] >> 견적운임조회
        srRest: '', // [33] SR 생성여부 >> 부킹 제약사항체크
        localDtm: '', // [34] 현재 시간 >> 부킹 제약사항체크
        vslRest: '', // [35] 선박 close 여부 >> 부킹 제약사항체크
        cgoRest: '' // [36] 반입 마감시한 >> 부킹 제약사항체크
      }
    }
  },
  computed: {

  },
  watch: {
  },
  created () {

  },
  mounted: function () {
     //this.reInit()
  },
  methods: {
    async moveSchedulePage (idx) {
      if (this.scheduleList[idx].salesCt === 'Y') {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.PERS010L020.063'), useConfirmBtn: true })) {
          myScheduleApi.sendSalesEmail(this.promotionList[idx]).then(res => {
            if (res.statusText === 'OK') {
              this.openAlert(this.$t('msg.PERS010L020.064')) // 메일 발송 성공
            } else {
              this.openAlert(this.$t('msg.PERS010L020.065')) // 메일 발송 실패
            }
          }).catch(e => {
            console.log(e)
          })
        }
      } else {
        if (!this.isPreventClick) {
          let schedule = JSON.parse(JSON.stringify(this.oriScheduleList[idx]))
          schedule.isUpdater = true
          this.$store.commit('schedule/updateSuggestionSchedule', schedule) // ScheduleLeg.vue -> watch로 schedule 배열 값 전달
          if (this.$route.path !== '/schedule/leg') {
            setTimeout(() => {
              this.$router.push('/schedule/leg')
            }, 100)
          }
        }
        //this.openPopup('FreSurchargePop', idx)
      }
    },
    openPopup (compNm, idx) {
      this.popupParams.FreSurchargePop.porCtrCd = this.promotionList[idx].porCtr //this.scheduleForm.startCtrCd
      this.popupParams.FreSurchargePop.porPlcCd = this.promotionList[idx].porPlc //this.detailResp2.pol
      this.popupParams.FreSurchargePop.dlyCtrCd = this.promotionList[idx].dlyCtr //this.scheduleForm.destCtrCd
      this.popupParams.FreSurchargePop.dlyPlcCd = this.promotionList[idx].dlyPlc //this.detailResp2.pod
      this.popupParams.FreSurchargePop.polNm = this.promotionList[idx].porEnm //this.detailResp2.polNm
      this.popupParams.FreSurchargePop.podNm = this.promotionList[idx].dlyEnm //this.detailResp2.podNm
      this.popupParams.FreSurchargePop.etd = this.promotionList[idx].rvsdDpoDt //this.detailResp2.etd
      this.popupParams.FreSurchargePop.frtEstnYn = 'I' //this.detailResp2.frtEstnYn
      this.popupParams.FreSurchargePop.vslCd = this.promotionList[idx].vslCd //this.detailResp2.vslCd
      this.popupParams.FreSurchargePop.voyNo = this.promotionList[idx].voyNo //this.detailResp2.voyNo
      this.popupParams.FreSurchargePop.rteCd = this.promotionList[idx].rteCd //this.detailResp2.rteCd
      this.popupParams.FreSurchargePop.frtResult = 'Y' //this.detailResp2.frtResult
      this.popupParams.FreSurchargePop.bkgClose = 'N' //this.detailResp2.bkgClose
      this.popupParams.FreSurchargePop.rmPopOpenFlag = 'Y'
      this.popupParams.FreSurchargePop.urlOrNot = false
      this.popupParams.FreSurchargePop.promotionChk = 'Y'
      this.selectFunc = ''

      // Prepaid, Collect에 따라 수출, 수입 구분함
      if (this.promotionList[idx].inOutCd === 'C') {
        this.popupParams.FreSurchargePop.eiCatCd = 'I' //this.eiCatCd
      } else {
        this.popupParams.FreSurchargePop.eiCatCd = 'O' //this.eiCatCd
      }

      // offset 컨테이너 사이즈
      this.popupParams.FreSurchargePop.ofCntrSize01 = this.promotionList[idx].ofCntrSize01
      this.popupParams.FreSurchargePop.ofCntrSize02 = this.promotionList[idx].ofCntrSize02

      // offset 컨테이너 타입
      this.popupParams.FreSurchargePop.ofCntrType01 = this.promotionList[idx].ofCntrType01
      this.popupParams.FreSurchargePop.ofCntrType02 = this.promotionList[idx].ofCntrType02

      // offset 할인 금액
      this.popupParams.FreSurchargePop.ofDcRate01 = this.promotionList[idx].ofDcRate01
      this.popupParams.FreSurchargePop.ofDcRate02 = this.promotionList[idx].ofDcRate02

      // offset 할인 종류
      this.popupParams.FreSurchargePop.ofDcType01 = this.promotionList[idx].ofDcType01
      this.popupParams.FreSurchargePop.ofDcType02 = this.promotionList[idx].ofDcType02

      // 직접할인 컨테이너 사이즈
      this.popupParams.FreSurchargePop.dtCntrSize01 = this.promotionList[idx].dtCntrSize01
      this.popupParams.FreSurchargePop.dtCntrSize02 = this.promotionList[idx].dtCntrSize02

      // 직접할인 컨테이너 타입
      this.popupParams.FreSurchargePop.dtCntrType01 = this.promotionList[idx].dtCntrType01
      this.popupParams.FreSurchargePop.dtCntrType02 = this.promotionList[idx].dtCntrType02

      // 직접할인 할인 금액
      this.popupParams.FreSurchargePop.dtDcRate01 = this.promotionList[idx].dtDcRate01
      this.popupParams.FreSurchargePop.dtDcRate02 = this.promotionList[idx].dtDcRate02

      // 직접할인 할인 종류
      this.popupParams.FreSurchargePop.dtDcType01 = this.promotionList[idx].dtDcType01
      this.popupParams.FreSurchargePop.dtDcType02 = this.promotionList[idx].dtDcType02

      if (this.customComponentSch !== null) {
        this.customComponentSch = null
        this.$ekmtcCommon.layerClose('.content_box > .popup_dim')
        elemSchPopup.style.display = 'none'
      }

      this.customComponentSch = compNm
      this.parentInfoSch = this.popupParams[compNm]
      this.$ekmtcCommon.layerOpen('#sch_popup_promo')
    },
    closePopup (obj) {
      const elemSchPopup = document.querySelector('#sch_popup_promo')
      this.customComponentSch = null
      this.$ekmtcCommon.layerClose('.content_box > .popup_dim')
      elemSchPopup.style.display = 'none'
    },
    getPromotionSchedules () {
      this.scheduleList = []
      this.isSlick = false
      let tempSchList = []

      myScheduleApi.getPromotionSchedules().then(res => {
        // 프로모션 배너 세팅용
        this.promotionList = res.data

        // 부킹화면 이동용
        for (let sch of res.data) {
          let obj = {
            podCtrCd: sch.dlyCtr,
            podPlcEnm: sch.dlyEnm,
            podPortCd: sch.dlyPlc,
            polCtrCd: sch.porCtr,
            polPlcEnm: sch.porEnm,
            polPortCd: sch.porPlc,
            rvsdDpoCd: sch.rvsdDpoDt,
            voyNo: sch.voyNo,
            vslCd: sch.vslCd,
            vslNm: sch.vslNm,
            promotionChk: 'Y',
            scenarioCd: sch.scenarioCd,
            promoNo: sch.promoNo,
            inOutCd: sch.inOutCd
          }
          tempSchList.push(obj)
        }

        if (tempSchList.length > 0) {
          this.oriScheduleList = tempSchList
        }

        this.makeScheduleList(res.data)
      }).catch(e => {
        console.log(e)
      })
    },
    makeScheduleList (arr) {
      let scheduleList = []
      for (let sch of arr) {
        let obj = {
          polPortCd: sch.porEnm, // 출발지 항구
          podPortCd: sch.dlyEnm, // 목적지 항구
          info: sch.vslCd + ' ' + sch.vslNm + ' ' + sch.voyNo + (this.$ekmtcCommon.isEmpty(sch.rvsdDpoDt) ? '' : (' (' + sch.rvsdDpoDt.substring(4, 6) + '/' + sch.rvsdDpoDt.substring(6, 8) + ')')), //선명, 항차번호, 항차명, 출항일자
          DcRate: this.$ekmtcCommon.isNotEmpty(sch.ofDcRate01) ? sch.ofDcRate01 : sch.dtDcRate01, // offset, 직접운임 구분하여 할인 가격 세팅
          DcType: this.$ekmtcCommon.isNotEmpty(sch.ofDcType01) ? sch.ofDcType01 : sch.dtDcType01, // offset, 직접운임 구분하여 할인 타입 세팅
          ofdcChk: this.$ekmtcCommon.isNotEmpty(sch.ofDcRate01) ? 0 : 1, // offset, 직접운임 구분자 세팅
          salesCt: sch.salesCt // 영업담당자 문의 세팅
        }
        console.log(obj)
        scheduleList.push(obj)
      }

      if (scheduleList.length > 0) {
        this.scheduleList = scheduleList
        this.isSlick = true
        setTimeout(() => {
          this.reInit()
        }, 200)
      }
    },
    clickIndicator (idx) {
      this.$refs.slick.goTo(idx, 0)
      this.currentIdx = idx
    },
    mouseDown (event) {
      this.mX1 = parseInt(event.clientX)
    },
    mouseUp (event) {
      this.mX2 = parseInt(event.clientX)
      if (this.getDiff() < 10) {
        this.isPreventClick = false
      } else {
        this.isPreventClick = true
      }
    },
    getDiff () {
      if (this.mX1 > this.mX2) {
        return this.mX1 - this.mX2
      } else {
        return this.mX2 - this.mX1
      }
    },
    handleAfterChange (event, slick, currentSlide) {
      this.currentIdx = currentSlide
    },
    reInit () {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$nextTick(() => {
            this.$refs.slick.reSlick()
        })
    },
    bookingBooking2 (vo) {
      this.isCollectTermBkg = false // 운임이 없는 부킹여부 (Collect Term 을 클릭하여 부킹하는 경우)

      const params = {
        asVslCd: vo.vslCd,
        asVoyNo: vo.voyNo,
        asPolPortCd: vo.pol,
        asPodPortCd: vo.pod1
      }

      schedules.checkBkgRestriction(params).then((data) => {
        this.bkgParams.srRest = data.data.srRest // [33] SR 생성여부 >> 부킹 제약사항체크
        this.bkgParams.localDtm = data.data.localDtm // [34] 현재 시간 >> 부킹 제약사항체크
        this.bkgParams.vslRest = data.data.vslRest // [35] 선박 close 여부 >> 부킹 제약사항체크
        this.bkgParams.cgoRest = data.data.cgoRest // [36] 반입 마감시한 >> 부킹 제약사항체크
      })

      this.bkgParams.frtAppNo = vo.frtAppNo
      //this.insertWB242H('03', this.logPgmCatCd, vo)
      //this.findSA271Yn()

      const obj = {
        params: this.bkgParams,
        schedule: this.bkgScheduleData,
        detail: this.detailResp2, //this.bkgDetailData
        isCollectTermBkg: this.isCollectTermBkg,
        urlParam: this.urlParam, // JamSin DSM
        urlOrNot: this.urlOrNot // JamSin DSM
      }

      this.$router.push({ name: 'booking-new', params: { scheduleObj: obj } }).catch(() => {})
    },
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: '확인',
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    }
  }
}
</script>
