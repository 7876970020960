var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "per_section" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.029")))]),
    _vm._v(" "),
    _c("div", { staticClass: "per_half" }, [
      _c("div", { staticClass: "dv col_5" }, [
        _c("p", { staticClass: "tit" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.030")))]),
          _vm._v(" "),
          _c("span", { staticClass: "trad" }, [
            _vm._v("(From " + _vm._s(_vm.countryDigitCode) + ")"),
          ]),
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.034")))]),
          _vm._v(" "),
          _c(
            "dd",
            {
              staticClass: "cs-pointer",
              on: {
                click: function ($event) {
                  return _vm.search("E", "01")
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.workingCnt.bkgDocCnt))]),
              _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.038"))),
            ]
          ),
        ]),
        _c("dl", [
          _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.035")))]),
          _vm._v(" "),
          _c(
            "dd",
            {
              staticClass: "cs-pointer",
              on: {
                click: function ($event) {
                  return _vm.search("E", "02")
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.workingCnt.polEtdCnt))]),
              _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.038"))),
            ]
          ),
        ]),
        _c("dl", [
          _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.036")))]),
          _vm._v(" "),
          _c(
            "dd",
            {
              staticClass: "cs-pointer",
              on: {
                click: function ($event) {
                  return _vm.search("E", "03")
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.workingCnt.polEtaCnt))]),
              _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.038"))),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "dv" }, [
        _c("p", { staticClass: "tit" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.031")))]),
          _vm._v(" "),
          _c("span", { staticClass: "trad" }, [
            _vm._v("(To " + _vm._s(_vm.countryDigitCode) + ")"),
          ]),
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.037")))]),
          _vm._v(" "),
          _c(
            "dd",
            {
              staticClass: "cs-pointer",
              on: {
                click: function ($event) {
                  return _vm.search("I")
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.workingCnt.podEtaCnt))]),
              _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.038"))),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }