var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      staticClass: "w-100",
      staticStyle: { height: "5px" },
      on: { mouseout: _vm.mouseout },
    }),
    _c("div", { staticClass: "pers_link" }, [
      _c("ul", { staticClass: "link_info" }, [
        _c("li", { style: _vm.liWidth.report }, [
          _c(
            "a",
            {
              class: _vm.activeMenu == "report" ? "on" : "",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.movePage("/my-info/report/report-info")
                },
                mouseover: function ($event) {
                  return _vm.mouseover("report")
                },
                mouseout: _vm.mouseout,
              },
            },
            [_vm._v("Report")]
          ),
        ]),
        _c("li", { style: _vm.liWidth.shipping }, [
          _c(
            "a",
            {
              class: _vm.activeMenu == "shipping" ? "on" : "",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.movePage("/shipping-cost/my")
                },
                mouseover: function ($event) {
                  return _vm.mouseover("shipping")
                },
                mouseout: _vm.mouseout,
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.009")))]
          ),
        ]),
        _c("li", { style: _vm.liWidth.news }, [
          _c(
            "a",
            {
              class: _vm.activeMenu == "news" ? "on" : "",
              on: {
                mouseover: function ($event) {
                  return _vm.mouseover("news")
                },
              },
            },
            [
              _vm._v(_vm._s(_vm.$t("msg.PERS010L020.011"))),
              _vm.isNewUnsolved || _vm.isNewReq || _vm.isNewChange
                ? _c("span", { staticClass: "ico" })
                : _vm._e(),
            ]
          ),
        ]),
        _vm.memberDetail.userCtrCd === "KR"
          ? _c("li", { style: _vm.liWidth.import }, [
              _c(
                "a",
                {
                  class: _vm.activeMenu == "import" ? "on" : "",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.movePage(
                        "/my-info/import-business-agency-information-main"
                      )
                    },
                    mouseover: function ($event) {
                      return _vm.mouseover("import")
                    },
                    mouseout: _vm.mouseout,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.012")))]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm.activeMenu == "news"
      ? _c(
          "div",
          {
            staticClass: "pers_noti",
            on: {
              mouseover: function ($event) {
                return _vm.mouseover("news")
              },
            },
          },
          [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("ScheduleChangeInfo")
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.013"))),
                    _vm.isNewChange
                      ? [_c("span", { staticClass: "ico" })]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.movePage("/working/unsolved")
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.014"))),
                    _vm.isNewUnsolved
                      ? [_c("span", { staticClass: "ico" })]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.movePage("/working/my-request")
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.015"))),
                    _vm.isNewReq
                      ? [_c("span", { staticClass: "ico" })]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", {
      staticClass: "w-100",
      staticStyle: { height: "5px" },
      on: { mouseout: _vm.mouseout },
    }),
    _c(
      "div",
      { attrs: { id: "winLayerPop" } },
      [
        _c(
          "win-layer-pop",
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.parentInfo },
                  on: { close: _vm.closePoup },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }