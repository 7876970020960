import Send from '../../schedule.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  // getVslScheduleInfo - 스케쥴 > 선박정보 및 스케줄 - GET /schedule/leg/vsl-schedule-info
  getVslScheduleInfo (data) {
    return Send({
      url: '/schedule/leg/vsl-schedule-info',
      method: 'get',
      spinner: false,
      params: {
        bound: data.bound,
        podPortCd: data.podPortCd,
        polPortCd: data.polPortCd,
        voyNo: data.voyNo,
        vslCd: data.vslCd,
        polTrmlCd: data.polTrmlCd,
        bkgNo: data.bkgNo
      }
    })
  },
  getVslScheduleInfoDetail (data) {
    return Send({
      url: '/schedule/leg/vsl-schedule-info-detail',
      method: 'get',
      params: {
        bound: data.bound,
        podPortCd: data.podPortCd,
        polPortCd: data.polPortCd,
        voyNo: data.voyNo,
        vslCd: data.vslCd,
        polTrmlCd: data.polTrmlCd
      }
    })
  },
  // searchVessel / 선박별 스케쥴에서 선박명 검색 / GET /schedule/vessel/search-vessel
  getVesselInfo: function (cdNm, ctrCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/schedule/vessel/search-vessel',
        method: 'get',
        spinner: false,
        params: { vslNm: cdNm, ctrCd: ctrCd }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // searchVesselSchedule / 선박별 Schedule 검색 / GET /schedule/vessel/search-schedule"
  getVesselSchedule (params) {
    return Send({
      url: '/schedule/vessel/search-schedule',
      method: 'get',
      params: params
    })
  },
  // getVslLocationInfo - 스케쥴 > 선박정보 및 스케줄 > 선박 위치 정보 - GET /schedule/leg/vsl-location-info
  getVslLocationInfo (params) {
    return Send({
      url: '/schedule/leg/vsl-location-info',
      method: 'get',
      params: params
    })
  },
  // getAllVslLocationInfo - CP > 모든 선박 위치 정보 - GET /schedule/leg/all-vsl-location-info
  getAllVslLocationInfo () {
    return Send({
      url: '/schedule/leg/all-vsl-location-info',
      method: 'get'
    })
  },
  // getPortLocationInfo - 스케쥴 > 구간 > 지도 팝업(항구 위치 정보) - GET /schedule/leg/port-location-info
  getPortLocationInfo (params) {
    return Send({
      url: '/schedule/leg/port-location-info',
      method: 'get',
      params: params
    })
  },
  // findServiceCourseInfo / 스케줄 > 구간 > Route 팝업(항로 위치 정보) / GET /schedule/vessel/route-info"
  getServiceCourseInfo (params) {
    return Send({
      url: '/schedule/vessel/route-info',
      method: 'get',
      params: params
    })
  },
  getServiceCourseInfoVer (params) {
    return Send({
      url: '/schedule/vessel/route-info-ver',
      method: 'get',
      params: params
    })
  },
  // popFreSurcharge / 스케줄 > 운임확인(1) 팝업 / GET /schedule/leg/pop-fre-surcharge
  getPopFreSurcharge (params) {
    return Send({
      url: '/schedule/leg/pop-fre-surcharge',
      method: 'get',
      params: params
    })
  },
  // insertAcceptQuote / 운임확인(2) 팝업 > 운임 확정 등록 / GET /schedule/leg/insert-accept-quote
  insertAcceptQuote (params) {
    return Send({
      url: '/schedule/leg/insert-accept-quote',
      method: 'get',
      params: params
    })
  },
  // requestQuote / 운임견적문의(Request a Quote) / GET /schedule/leg/request-quote
  requestQuote () {
    return Send({
      url: '/schedule/leg/request-quote',
      method: 'get'
    })
  },
  // findSalUno / 운임견적문의 > 영업사원조회 / GET /schedule/leg/find-sal-uno
  findSalUno () {
    return Send({
      url: '/schedule/leg/find-sal-uno',
      method: 'get'
    })
  },
  // insertRequestQuote / 운임견적문의 > requestQuote 등록 / GET /schedule/leg/insert-request-quote
  insertRequestQuote (data) {
    return Send({
      url: '/schedule/leg/insert-request-quote',
      method: 'post',
      data: data
    })
  },
  // portTerminalCyInquiry / PortTerminalCY 팝업 / GET /schedule/leg/port-terminal-cy
  getPortTerminalCY (params) {
    return Send({
      url: '/schedule/leg/port-terminal-cy',
      method: 'get',
      params: params
    })
  },
  //ICD 리스트
  getIcdList (formData) {
    return Send({
      url: '/schedule/leg/port-terminal-cy/icd-list/' + formData.asPortCd,
      method: 'get'
    })
  },
  // closeInfo / 선박정보 및 스케줄 > 마감 정보 리스트 / GET /schedule/leg/close-info
  getCloseInfoList (params) {
    return Send({
      url: '/schedule/leg/close-info',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  // closeInfo / 마감정보 > 마감 정보 코드 / GET /schedule/leg/close-info-code
  getCloseInfoCodes (params) {
    return Send({
      url: '/schedule/leg/close-info-code',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  // closeInfoCYOpen / 선박정보 및 스케줄 > 마감정보 ICC(Closing Time management - CY OPEN) / GET /schedule/leg/close-info-cy-open
  getCloseInfoCYOpen (params) {
    return Send({
      url: '/schedule/leg/close-info-cy-open',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  // closeInfoEirDt / 선박정보 및 스케줄 > 마감정보 ICC(Closing Time management - EIR Opening/EIR Closure) / GET /schedule/leg/close-info-eir-dt
  getCloseInfoEirDt (params) {
    return Send({
      url: '/schedule/leg/close-info-eir-dt',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  // 선급증서 다운로드
  certiFileDownload (reqRno) {
    return new Promise((resolve, reject) => {
      Send({
        url: `/schedule/leg/certi-file-download/${reqRno}`,
        method: 'get',
        responseType: 'blob'
      }).then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // popFreRateNoCntr / R/M 컨터이너 타입 조회 / GET /schedule/leg/pop-fre-rate-cntr
  popFreRateNoCntr (params) {
    return Send({
      url: '/schedule/leg/pop-fre-rate-cntr',
      method: 'get',
      params: params
    })
  },
  // popFreAppNoList / 스케줄 R/A No 조회 / GET /schedule/leg/pop-fre-app-no-list
  popFreAppNoList (params) {
    return Send({
      url: '/schedule/leg/pop-fre-app-no-list',
      method: 'get',
      params: params
    })
  },
  // getPorDlyScheduleInfo / 출발지/도착지 스케줄 정보 조회 / GET /schedule/leg/por-dly-schedule-info
  getPorDlyScheduleInfo (params) {
    return Send({
      url: '/schedule/leg/por-dly-schedule-info',
      method: 'get',
      params: params
    })
  },
  getVslDsmInfo (data) {
    return Send({
      url: '/schedule/leg/vsl-dsm-info',
      method: 'get',
      spinner: false,
      params: {
        polPortCd: data.polPlcCd,
        voyNo: data.voyNo,
        vslCd: data.vslCd
      }
    })
  },
  // kmtcSpotAcceptQuote / kmtc spot 운임 확정 등록 / GET /schedule/leg/kmtcspot-accept-quote
  kmtcSpotAcceptQuote (params) {
    return Send({
      url: '/schedule/leg/kmtcspot-accept-quote',
      method: 'get',
      params: params
    })
  }
}
