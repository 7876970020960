import Send from '../common.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //boards - 기타정보 > 자료실,FAQ 리스트 - GET /common/boards
  getBoardsList (formData) {
    return Send({
      url: '/common/boards',
      method: 'get',
      params: {
        tableId: formData.tableId || 'C',
        title: formData.title,
        contents: formData.contents,
        curPage: formData.curPage,
        pageSize: formData.pageSize,
        select: formData.select,
        catCd: formData.catCd,
        serviceCtrCd: formData.serviceCtrCd
      }
    })
  },
  //pdsDetail - 기타정보 > 자료실 상세 - GET /common/boards/pds/{messageIdSeq}
  getBoardsDetail (messageIdSeq, tableId, groupId, serviceCtrCd) {
    return Send({
      url: '/common/boards/' + (tableId === 'C' ? 'pds/' : 'faq/') + messageIdSeq,
      method: 'get',
      params: {
        tableId: tableId || 'C',
        groupId: groupId,
        serviceCtrCd: serviceCtrCd
      }
    })
  },
  //findCommonCode - 공통코드 가져오기 - GET /commons/codes/common
  getCode (formData) {
    return Send({
      url: '/commons/codes/common',
      method: 'get',
      params: {
        cdId: formData.cdId,
        arrCdId: formData.arrCdId || ''
      }
    })
  },
  //fileDownload - 파일 다운로드 - GET /commons/boards/{fileId}/file-download
  fileDownload (formData) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/common/boards/' + formData.fileId + '/file-download',
        method: 'get',
        responseType: 'blob',
        params: {
          messageIdSeq: formData.messageIdSeq,
          groupId: formData.groupId
        }
      }).then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  //boardsIntegratedSearch - 통합검색(자료실, FAQ, 공지사항) - GET /common/boards/integrated-search
  boardsIntegratedSearch (keyword, serviceCtrCd) {
    return Send({
      url: '/common/boards/integrated-search/' + keyword,
      method: 'get',
      params: {
        tableId: 'C',
        serviceCtrCd: serviceCtrCd
      }
    })
  }
}
