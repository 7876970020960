import Send from '../../trans.client.js'

export default {
  getUnsolvedCheckList (params) {
    return Send({
      url: '/trans/unsolved',
      method: 'get',
      params: params
    })
  }
}
