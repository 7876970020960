import Send from '../../user.client.js'

export default {

    checkId (data) {
        return Send({
            //url: 'http://localhost:9002/user/member/chkid',
            url: '/user/member/chkid',
            method: 'get',
            params: {
                userId: data
            }
        })
    },

    getCtrList () {
        return Send({
            //url: 'http://localhost:9002/user/member/ctr',
            url: '/user/member/ctr',
            method: 'get',
            params: {}
        })
    },
    joinMemberForm (data) {
        return Send({
        //   url: 'http://localhost:9002/user/member',
          url: '/user/member',
          method: 'post',
          data: data
        })
    },
    updateMemberApproval (data) {
        return Send({
        //   url: 'http://localhost:9002/user/member/adminLogin',
          url: '/user/member/approval',
          method: 'post',
          data: data
        })
    },
    getMemberInfo (data) {
        return Send({
              // url: 'http://localhost:9002/user/adminMod/info',
              url: '/user/adminMod/info',
              method: 'get',
              params: {
                  userId: data
              }
        })
    },
    passwordChange (data) {
        return Send({
                //url: 'http://localhost:9002/user/myinfo/password',
                url: '/user/myinfo/password',
                method: 'put',
                data: data
            })
    },
    memberQuit (data) {
        return Send({
        //   url: 'http://localhost:9002/user/myinfo/memberout',
          url: '/user/myinfo/memberout',
          method: 'put',
          data: data
        })
    },
    memberUpdate (data) {
        return Send({
          //url: 'http://localhost:9002/user/adminMod',
          url: '/user/adminMod/change',
          method: 'post',
          data: data
        })
    },
    findCrmYn (data) {
        return Send({
          //url: 'http://localhost:9002/user/member/crm',
          url: '/user/member/crm',
          method: 'get',
          params: {
            bizNo: data
          }
        })
    },
    getFileList (data) {
        return Send({
           //url: 'http://localhost:9002/user/adminMod/file',
           url: '/user/adminMod/file',
           method: 'get',
           params: {
                userId: data
           }
         })
    },
    getFileInfo (param) {
        return Send({
            //url: 'http://localhost:9006/trans/rfdg/finfo',
            url: '/user/adminMod/finfo',
            method: 'get',
            params: param
        })
    },
    findMemberId (data) {
        return Send({
            //url: 'http://localhost:9002/user/member/id',
            url: '/user/member/id',
            method: 'get',
            params: {
                bizNo: data.bizNo,
                userEmail: data.userEmail
            }
          })
    },
    findMemberPw (data) {
        return Send({
            // url: 'http://localhost:9002/user/member/pwd',
            url: '/user/member/pwd',
            method: 'get',
            params: {
                userId: data.userId,
                bizNo: data.bizNo,
                userEmail: data.userEmail
            }
          })
    },
    findLoginDtm (loginId) {
        return Send({
            // url: 'http://localhost:9002/user/member/login-dtm',
            url: '/user/member/login-dtm',
            method: 'get',
            params: {
                loginId: loginId //sessionStorage.getItem('loginId')
            }
          })
    }
}
