<template>
  <div :class="`wrap wrap_${auth.serviceLang}`">
    <Header></Header>
    <Content></Content>
  </div>
</template>
<script>
  import { rootComputed } from '@/store/helpers'
  import Header from '@/layout/bsa/BsaHeader'
  import Content from '@/layout/bsa/BsaContent'
  import Footer from '@/layout/bsa/BsaFooter'

  export default {
    components: {
      Content,
      Header,
      Footer
    },
    computed: {
      ...rootComputed
    },
    methods: {
    }
  }

</script>

<style>

</style>
