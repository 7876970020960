import Send from '../../trans.client.js'

export default {
  //B/L, D/O 발행 현황 정보 가져오기
  surrenderDoDetail (blNo) {
    return Send({
      url: `/trans/bl-do-payment/${blNo}`,
      method: 'get'
    })
  },
  //선적지 B/L 발급 문의 및 알림 팝업 정보
  popAlarmForm (params) {
    return Send({
      url: `/trans/bl-do-payment/${params.blNo}/surr`,
      method: 'get',
      params: params
    })
  },
  //선적지 B/L 발급 문의 및 알림 등록
  popAlarmSav (params) {
    return Send({
      url: `/trans/bl-do-payment/${params.blNo}/surr`,
      method: 'post',
      data: params
    })
  },
  simpleDoDetail (blNo) {
    return Send({
      url: `/trans/bl-do-payment/${blNo}/simple`,
      method: 'get',
      spinner: false
    })
  }
}
