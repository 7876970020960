var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "per_section" }, [
    _vm.memberDetail.userCtrCd === "KR"
      ? _c("h2", [_vm._v(_vm._s(_vm.$t("menu.MENU06.010")))])
      : _vm._e(),
    _vm.memberDetail.userCtrCd === "KR"
      ? _c("div", { staticClass: "chart_credit_limit" }, [
          _vm._m(0),
          _c("div", { staticClass: "remark_area" }, [
            _c("p", { staticClass: "tit" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("msg.PERS010L020.040")) + "(KRW)"),
              ]),
            ]),
            _c("ul", [
              _c("li", [
                _c("span", { staticClass: "ico1" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.041")))]),
                _c("span", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.numberFormat(_vm.useLimit))),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "ico2" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.042")))]),
                _c("span", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.numberFormat(_vm.rstLimit))),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "ico1" }),
                _c("span", { staticClass: "plus" }, [_vm._v("+")]),
                _c("span", { staticClass: "ico2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.043")))]),
                _c("span", { staticClass: "num" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(
                        parseInt(_vm.useLimit) + parseInt(_vm.rstLimit)
                      )
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.memberDetail.userCtrCd !== "JP"
      ? _c("div", [
          _c("p", { staticClass: "tit" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.044")))]),
          ]),
          _vm.vrAccount.usd != null
            ? _c("p", { staticClass: "bank" }, [
                _vm._v(" USD " + _vm._s(_vm.$t("msg.PERS010L020.045")) + " "),
                _c("span", { staticClass: "name" }, [
                  _vm._v(" " + _vm._s(_vm.vrAccount.usd.bankName) + " "),
                  _c("span", { staticClass: "no" }, [
                    _vm._v(_vm._s(_vm.vrAccount.usd.vrAcctNo)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.vrAccount.krw != null
            ? _c("p", { staticClass: "bank mb15" }, [
                _vm._v(" KRW " + _vm._s(_vm.$t("msg.PERS010L020.045")) + " "),
                _c("span", { staticClass: "name" }, [
                  _vm._v(" " + _vm._s(_vm.vrAccount.krw.bankName) + " "),
                  _c("span", { staticClass: "no" }, [
                    _vm._v(_vm._s(_vm.vrAccount.krw.vrAcctNo)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.vrAccount.krw == null || _vm.vrAccount.usd == null
            ? _c("div", { staticStyle: { margin: "10px 0px" } }, [
                _c(
                  "a",
                  {
                    staticClass: "button sm",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("VtlAccPop")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.051")))]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "per_half" }, [
      _c("div", { staticClass: "dv col_5" }, [
        _c("p", { staticClass: "tit" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.046")))]),
          _vm._v(" "),
          _c("span", { staticClass: "trad" }, [
            _vm._v("(From " + _vm._s(_vm.countryDigitCode) + ")"),
          ]),
        ]),
        _c("dl", { staticClass: "inline" }, [
          _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.048")))]),
          _c(
            "dd",
            {
              staticClass: "cs-pointer",
              on: {
                click: function ($event) {
                  return _vm.movePage("/calcIssue/calcIssueMain", "blToday")
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.notIssuedCount.blToday))])]
          ),
        ]),
        _c("dl", { staticClass: "inline" }, [
          _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.049")))]),
          _c(
            "dd",
            {
              staticClass: "cs-pointer",
              on: {
                click: function ($event) {
                  return _vm.movePage("/calcIssue/calcIssueMain", "blYesterday")
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.notIssuedCount.blYesterday))])]
          ),
        ]),
      ]),
      _c("div", { staticClass: "dv" }, [
        _c("p", { staticClass: "tit" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.047")))]),
          _vm._v(" "),
          _c("span", { staticClass: "trad" }, [
            _vm._v("(To " + _vm._s(_vm.countryDigitCode) + ")"),
          ]),
        ]),
        _c("dl", { staticClass: "inline" }, [
          _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.050")))]),
          _c(
            "dd",
            {
              staticClass: "cs-pointer",
              on: {
                click: function ($event) {
                  return _vm.movePage(
                    "/calcIssue/calcIssueMain",
                    "doCompletion"
                  )
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.notIssuedCount.doCompletion))])]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "graph_area",
        staticStyle: { width: "35%", "padding-top": "15px" },
      },
      [_c("canvas", { attrs: { id: "quickChart" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }