import Send from '../../schedule.client.js'

export default {
  getMyHistory (params) {
    return new Promise((resolve, reject) => {
      Send({
            url: '/schedule/compare/history',
            method: 'GET',
            data: {},
            params: params,
            spinner: false
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  }
}
