import Send from '../../trans.client.js'

// 주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  // findOnGoingList - 진행중인 업무 > 수입 - GET /trans/import/on-going
  getOnGoingList (params) {
    return Send({
      url: '/trans/import/on-going',
      method: 'get',
      params: params
    })
  },
  // findOnGoingImportRequestBtn - Request Btn 활성화 여부 체크
  getOnGoingImportRequestBtn (params) {
    return Send({
      url: '/trans/import/on-going/request-btn',
      method: 'post',
      data: params
    })
  },
  // findTaxInfo - 전자세금계산서 조회 - GET /trans/import/on-going/find-tax-info
  getTaxInfo (params) {
    return Send({
      url: '/trans/import/on-going/find-tax-info',
      spinner: false,
      method: 'get',
      params: params
    })
  },
  // findContainerInfo - 컨테이너 조회 - GET /trans/import/on-going/find-container-info
  findContainerInfo (params) {
    return Send({
      url: '/trans/import/on-going/find-container-info',
      spinner: false,
      method: 'get',
      params: params
    })
  },
  // findAIreportReceipt - Receipt 출력 - GET /trans/import/on-going/find-receipt-info
  findAIreportReceipt (params) {
    return Send({
      url: '/trans/import/on-going/find-receipt-info',
      // spinner: false,
      method: 'post',
      data: params
    })
  },
  // findInvoiceRno - Invoice - GET /trans/import/on-going/find-invoice-info
  findInvoiceRno (params) {
    return Send({
      url: '/trans/import/on-going/find-invoice-info',
      // spinner: false,
      method: 'post',
      data: params
    })
  },
  // findInspecCont - 진행중인 업무 > 수입 > 관리대상 - GET /trans/import/on-going/pop-inspec-cont
  findInspecCont (params) {
    return Send({
      url: '/trans/import/on-going/pop-inspec-cont',
      method: 'get',
      params: params
    })
  },
  // popCgoEntry - 진행중인 업무 > 수입 > 컨테이너 상세정보 - GET /trans/import/on-going/pop-cgo-entry
  popCgoEntry (params) {
    return Send({
      url: '/trans/import/on-going/pop-cgo-entry',
      method: 'get',
      params: params
    })
  },
  getUnsolvedList (params) {
    return Send({
      url: '/trans/import/unsolved',
      method: 'get',
      params: params
    })
  },
  getUnsolvedStats (params) {
    return Send({
      url: '/trans/import/unsolved/stats',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  getUnsolvedListAll (params) {
    console.log(params)
    return Send({
      url: '/trans/import/unsolved/all',
      method: 'get',
      params: params
    })
  },
  getDOPdfChk (params) {
    return Send({
      url: '/trans/import/on-going/do-pdf-chk',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  getInvAgentChk (params) {
    return Send({
      url: '/trans/import/on-going/inv-agent-chk',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  getVesselChangeReason (params) {
    return Send({
      url: '/trans/import/on-going/vessel-change-reason',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  getMsnYn (blNo) {
    return Send({
      url: '/trans/import/on-going/msn',
      method: 'get',
      spinner: false,
      params: {
        blNo: blNo
      }
    })
  },
  getTrackingInfo (params) {
    return Send({
      url: '/trans/import/on-going/tracking-info',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  getCntrList (params) {
    return Send({
      url: '/trans/import/on-going/cntr-list',
      method: 'get',
      params: params
    }).catch(err => {
      console.log(err)
    })
  }
}
