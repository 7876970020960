// 특수화물 선적문의, 운임, 정산/발급
import { checkPermission } from './permission'
const EmptyLayout = () => import(/* webpackChunkName: "topmenu04_06" */'@/layout/EmptyLayout')
const Oog = () => import(/* webpackChunkName: "topmenu04_06" */'@/pages/trans/OogList')
const Danger = () => import(/* webpackChunkName: "topmenu04_06" */'@/pages/trans/DangerShipList')
const SpecialRfDg = () => import(/* webpackChunkName: "topmenu04_06" */'@/pages/trans/SpecialRfDg')
const ShippingCostMain = () => import(/* webpackChunkName: "topmenu04_06" */'@/pages/schedule/ShippingCostMain')
const CalcIssueMain = () => import(/* webpackChunkName: "topmenu04_06" */'@/pages/settle/CalcIssueMain')
const MrWaiverList = () => import(/* webpackChunkName: "topmenu04_06" */'@/pages/settle/MrWaiverList')
const FreeTimeRequest = () => import(/* webpackChunkName: "topmenu04_06" */'@/pages/settle/FreeTimeRequest')

const beforeCheck = () => (to, from, next) => {
  let permit = checkPermission(to, from)
  if (permit.isPermit) {
    next()
  } else {
    next(permit.path)
  }
}

const routes = [
  {
    path: '/dg',
    redirect: '/dg/danger',
    component: EmptyLayout,
    meta: {
      breadcrumb: '특수화물 선적문의',
      key: 'menu.MENU04.000'
      // requiresAuth: true
    },
    children: [
      {
        path: 'danger',
        name: 'danger',
        component: Danger,
        meta: {
          breadcrumb: '위험물',
          key: 'menu.MENU04.010',
         requiresAuth: true
        }
      },
      {
        path: 'oog',
        name: 'oog',
        component: Oog,
        meta: {
          breadcrumb: 'OOG 화물',
          key: 'menu.MENU04.020',
         requiresAuth: true
        }
      },
      {
        path: 'specialRfDg',
        name: 'specialRfDg',
        component: SpecialRfDg,
        meta: {
          breadcrumb: 'RF 위험물 사전승인',
          key: 'menu.MENU04.030',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/shipping-cost',
    redirect: '/shipping-cost/inquiry',
    component: ShippingCostMain,
    meta: {
      breadcrumb: '운임',
      key: 'menu.MENU05.000',
      requiresAuth: true
    },
    children: [
      {
        path: 'inquiry',
        name: 'inquiry',
        meta: {
          breadcrumb: '운임문의',
          key: 'menu.MENU05.010',
          requiresAuth: true
        }
      },
      {
        path: 'my',
        name: 'my',
        meta: {
          breadcrumb: '나의 운임',
          key: 'menu.MENU05.020',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/calcIssue',
    component: EmptyLayout,
    meta: {
      breadcrumb: '정산/발행',
      key: 'menu.MENU06.000'
    },
    children: [
      {
        path: 'calcIssueMain',
        name: 'calcIssueMain',
        component: CalcIssueMain,
        meta: {
          breadcrumb: '정산/발급',
          key: 'menu.MENU06.010',
          requiresAuth: true
        }
      },
      {
        path: '',
        name: '',
        meta: {
          breadcrumb: '가상계좌',
          key: 'menu.MENU06.060',
          requiresAuth: true,
          sitemapHide: true
        }
      },
      {
        path: 'freetime-request',
        name: 'FreeTimeRequest',
        component: FreeTimeRequest,
        beforeEnter: beforeCheck(),
        meta: {
          breadcrumb: 'Freetime 요청',
          key: 'menu.MENU06.070'
        }
      },
      {
        path: 'mrWaiver',
        name: 'mrWaiver',
        component: MrWaiverList,
        meta: {
          breadcrumb: 'M&R Waiver',
          key: 'menu.MENU06.080',
          requiresAuth: true,
          serviceLangs: ['KOR']
        }
      }
    ]
  }
]

export default routes
