<template>
  <div
    class="popup_wrap"
    style="width:900px; height:600px;"
  >
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.SCHD010P040.001') }}</h1><!-- 선박정보 및 스케줄 -->
      <div class="content_box">
        <div class="flex_box">
          <h2 class="content_title">{{ vesselInfoDetail.vslNm }} {{ vesselInfoDetail.voyNoDisp }}</h2>
          <span class="ml_auto cal_btn">
            <a
              v-if="vslDelayReasonBtnFlag"
              class="button sm"
              href="#"
              @click.prevent="openPopup('ChangeNoticePop')"
            >{{ $t('msg.SCHD010P040.017') }}</a><!-- 지연사유서 PDF 다운로드 -->
          </span>
        </div>
        <table class="tbl_row">
          <colgroup>
            <col width="150"><col>
            <col width="150"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.SCHD010P040.002') }}</th><!-- Vessel Type -->
              <td>{{ vesselInfoDetail.vslType }}</td>
              <th>{{ $t('msg.SCHD010P040.003') }}</th><!-- IMO No. -->
              <td>{{ vesselInfoDetail.imoNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SCHD010P040.004') }}</th><!-- Vessel Owner -->
              <td>{{ vesselInfoDetail.vslOwner }}</td>
              <th>{{ $t('msg.SCHD010P040.005') }}</th><!-- Call Sign -->
              <td>{{ vesselInfoDetail.clsgNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SCHD010P040.006') }}</th><!-- Built (Delivered) -->
              <td>{{ $ekmtcCommon.changeDatePattern(vesselInfoDetail.dlyDt, '.') }}</td>
              <th>{{ $t('msg.SCHD010P040.007') }}</th><!-- Flag -->
              <td>{{ vesselInfoDetail.ntltCtrCd ? vesselInfoDetail.ntltCtrCd + ' (' + vesselInfoDetail.ctrEnm + ')' : vesselInfoDetail.ctrEnm }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SCHD010P040.008') }}</th><!-- Class -->
              <td>{{ vesselInfoDetail.clsfiNm }}</td>
              <th>{{ $t('msg.SCHD010P040.009') }}</th><!-- BREADTH -->
              <td>{{ vesselInfoDetail.breadth }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SCHD010P040.010') }}</th><!-- DEAD WEIGHT TONNAGE -->
              <td>{{ $ekmtcCommon.changeNumberFormat(vesselInfoDetail.dwgtTons, { isComma: true }) }}</td>
              <th>{{ $t('msg.SCHD010P040.011') }}</th><!-- GROSS TONNAGE -->
              <td>{{ $ekmtcCommon.changeNumberFormat(vesselInfoDetail.grsWt, { isComma: true }) }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SCHD010P040.012') }}</th><!-- CARGO CAPACITY -->
              <td>{{ vesselInfoDetail.nmnlTeu }}</td>
              <th>{{ $t('msg.SCHD010P040.013') }}</th><!-- NET REGISTER TONNAGE -->
              <td>{{ $ekmtcCommon.changeNumberFormat(vesselInfoDetail.netWt, { isComma: true }) }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SCHD010P040.014') }}</th><!-- LEGTH OVER ALL -->
              <td>{{ vesselInfoDetail.loaLen }}</td>
              <th>{{ $t('msg.SCHD010P040.015') }}</th><!-- 선급증서 -->
              <td>
                <!-- PDF 다운로드 -->
                <a
                  v-if="$ekmtcCommon.isNotEmpty(vesselInfoDetail.vslCertiFileNm)"
                  class="button sm"
                  href="#"
                  @click.prevent="certiFileDownload(vesselInfoDetail.atchFileRno)"
                >
                  {{ $t('msg.SCHD010P040.016') }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <table v-if="parentInfo.vesselSeq === 1" class="tbl_row mt10">
          <colgroup>
            <col width="150"><col><col width="150"><col>
          </colgroup>
          <tbody v-if="parentInfo.bound === 'O'">
            <tr>
              <th>{{ $t('msg.SCHD010P040.018') }}</th><!-- MRN No. -->
              <td>{{ vesselInfoDetail.mrnNo }}</td>
              <th>{{ $t('msg.SCHD010P040.019') }}</th><!-- MSN No. -->
              <td>{{ vesselInfoDetail.lstMsnNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SCHD010P040.020') }}</th><!-- Terminal -->
              <td>{{ vesselInfoDetail.polTrmlCd }}</td>
              <th>{{ $t('msg.SCHD010P040.021') }}</th><!-- Terminal VSL/VOY -->
              <td>{{ vesselInfoDetail.trmlVslCd }} / {{ vesselInfoDetail.trmlVoyNo }}</td>
            </tr>
          </tbody>
          <tbody v-if="parentInfo.bound === 'I'">
            <tr>
              <th>{{ $t('msg.SCHD010P040.018') }}</th><!-- MRN No. -->
              <td>{{ deadlineInfoDetail.mrnNo }}</td>
              <th>{{ $t('msg.SCHD010P040.019') }}</th><!-- MSN No. -->
              <td>{{ deadlineInfoDetail.msnNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONIM010P020.005') }}</th><!-- 적하목록마감 -->
              <td>{{ $ekmtcCommon.changeDatePattern(deadlineInfoDetail.anSendDt,'-') }}</td>
              <th>{{ $t('msg.ONIM010P020.006') }}</th><!-- 입항전신고마감 -->
              <td>{{ $ekmtcCommon.changeDatePattern(deadlineInfoDetail.mfSendDt,'-') }}</td>
            </tr>
          </tbody>
        </table>
        <!-- component start -->
        <close-info
          v-if="parentInfo.vesselSeq === 1 && parentInfo.bound === 'O'"
          ref="closeInfo"
          :parent-info="parentInfo"
          :vessel-info="vesselInfoDetail"
        />
        <!-- component finish -->
        <table class="tbl_col font_sm mt10">
          <colgroup>
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:9%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:9%">
            <col style="width:7%">
            <col style="width:10%">
            <col style="width:9%">
            <col style="width:7%">
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">{{ $t('msg.SCHD010P040.032') }}</th><!-- Port -->
              <th colspan="2">{{ $t('msg.SCHD010P040.033') }}</th><!-- Terminal -->
              <th rowspan="2">{{ $t('msg.SCHD010P040.034') }}</th><!-- Berth No. -->
              <th rowspan="2">{{ $t('msg.SCHD010P040.035') }}</th><!-- Skip -->
              <th colspan="3">{{ $t('msg.SCHD010P040.036') }}</th><!-- Proforma -->
              <th colspan="2">{{ $t('msg.SCHD010P040.037') }}</th><!-- Revised -->
              <th colspan="2">{{ $t('msg.SCHD010P040.038') }}</th><!-- Actual -->
              <th rowspan="2">{{ $t('msg.SCHD010P040.039') }}</th><!-- Delay -->
            </tr>
            <tr>
              <th class="border_left">{{ $t('msg.SCHD010P040.040') }}</th><!-- In -->
              <th>{{ $t('msg.SCHD010P040.041') }}</th><!-- Out -->
              <th>{{ $t('msg.SCHD010P040.042') }}</th><!-- ETA/ETD (Proforma) -->
              <th>{{ $t('msg.SCHD010P040.043') }}</th><!-- Day (Proforma) -->
              <th>{{ $t('msg.SCHD010P040.044') }}</th><!-- Time (Proforma) -->
              <th>{{ $t('msg.SCHD010P040.042') }}</th><!-- ETA/ETD (Revised) -->
              <th>{{ $t('msg.SCHD010P040.044') }}</th><!-- Time (Revised) -->
              <th>{{ $t('msg.SCHD010P040.045') }}</th><!-- Arrival/Departure (Actual) -->
              <th>{{ $t('msg.SCHD010P040.044') }}</th><!-- Time (Actual) -->
            </tr>
          </thead>
          <tbody v-if="vesselScheduleInfo.length > 0">
            <template
              v-for="item in vesselScheduleInfo"
            >
              <tr :key="item.prfmApoDt + item.prfmApoTm">
                <td rowspan="2" @mouseover="showTooltip" @mouseout="hideTooltip">
                  <!--<td rowspan="2">-->
                  {{ item.portCd }}
                  <div class="tooltip_wrap short position_absolute ts_tt" :style="{display: 'none', width:'200px', height:'50px', left:'200'}">
                    <div class="cont" style="padding: 15px;">
                      <ul>
                        <li>
                          {{ item.portNm }} , {{ item.ctrCd }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap -->
                </td><!-- Port -->
                <td rowspan="2">
                  {{ item.trmlCd }}
                </td><!-- Terminal In -->
                <td rowspan="2">{{ item.trmlCd }}</td><!-- Terminal Out -->
                <td rowspan="2">{{ item.apoBthNo }}</td><!-- Berth No. -->
                <td rowspan="2">{{ item.skpYn }}</td><!-- Skip -->
                <td>{{ $ekmtcCommon.changeDatePattern(item.prfmApoDt,'-') }}</td><!-- Proforma ETA Date -->
                <td>{{ item.prfmApoDay }}</td><!-- Proforma ETA Day -->
                <td>{{ item.prfmApoTm }}</td><!-- Proforma ETA Time -->
                <td>{{ $ekmtcCommon.changeDatePattern(item.rvsdApoDt,'-') }}</td><!-- Revised ETA Date -->
                <td>{{ item.rvsdApoTm }}</td><!-- Revised ETA Time -->
                <td>{{ $ekmtcCommon.changeDatePattern(item.apoActlDt,'-') }}</td><!-- Actual ETA Date -->
                <td>{{ item.apoActlTm }}</td><!-- Actual ETA Time -->
                <td>{{ item.etaDiff }}</td><!-- ETA Delay -->
              </tr>
              <tr :key="item.prfmDpoDt + item.prfmDpoTm">
                <td class="border_left">{{ $ekmtcCommon.changeDatePattern(item.prfmDpoDt,'-') }}</td><!-- Proforma ETD Date -->
                <td>{{ item.prfmDpoDay }}</td><!-- Proforma ETD Day -->
                <td>{{ item.prfmDpoTm }}</td><!-- Proforma ETD Time -->
                <td>{{ $ekmtcCommon.changeDatePattern(item.rvsdDpoDt,'-') }}</td><!-- Revised ETD Date -->
                <td>{{ item.rvsdDpoTm }}</td><!-- Revised ETD Time -->
                <td>{{ $ekmtcCommon.changeDatePattern(item.dpoUbthDt,'-') }}</td><!-- Actual ETD Date -->
                <td>{{ item.dpoUbthTm }}</td><!-- Actual ETD Time -->
                <td>{{ item.etdDiff }}</td><!-- ETD Delay -->
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div
        v-if="isVslLocation"
        class="content_box"
      >
        <div style="height:400px;" class="map_wrap">
          <location-info
            :parent-info="vslInfo"
          />
        </div>
      </div>
      <!-- 닫기 -->
      <div class="mt10 text_center">
        <a
          class="button gray lg"
          href="#"
          @click.prevent="$emit('close')"
        >
          {{ $t('msg.SCHD010P040.046') }}
        </a>
      </div>
      <win-layer-pop class="pdf_download_pop" style="z-index: 99999;">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="popupParams"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>

import schedule from '@/api/rest/schedule/schedule'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import LocationInfo from '@/pages/schedule/LocationInfo'
import arrivalNotice from '@/api/rest/trans/arrivalNotice'

export default {
  name: 'VesselScheduleInfoPop',
  components: {
    LocationInfo,
    'close-info': () => import('@/components/trans/CloseInfo.vue'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    ChangeNoticePop: () => import('@/pages/pdf/popup/ChangeNoticePop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          vslCd: '',
          voyNo: '',
          polPortCd: '',
          podPortCd: '',
          bound: '',
          polTrmlCd: '',
          menuCd: '',
          vesselSeq: 1
        }
      }
     }
  },
  data: function () {
    return {
      // PDF 다운로드 팝업 컴포넌트
      customComponent: null,
      // 선박 정보
      vesselInfoDetail: {
        mrnNo: ''
      },
      // 선박/항차 스케줄 정보
      vesselScheduleInfo: [],
      // 선박 위치 유무
      isVslLocation: false,
      // 선박 위치 호출 데이터
      vslInfo: {},
      // 선박 지연 여부
      vslDelayReasonBtnFlag: false,
      popupParams: {
        items: []
      },
      deadlineInfoDetail: {
        mrnNo: '',
        msnNo: '',
        anSendDt: '',
        mfSendDt: ''
      }
    }
  },
  watch: {
    parentInfo () {
      this.init()
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.$ekmtcCommon.isEmpty(this.parentInfo.vesselSeq)) {
        this.parentInfo.vesselSeq = 1
      }
      await this.getVslDelayReason()
      await this.getVslScheduleInfo(this.parentInfo)
      if (this.parentInfo.bound === 'I' && this.$ekmtcCommon.isNotEmpty(this.parentInfo.blNo)) {
        await this.getImportManagerDeadlineInfo(this.parentInfo.blNo)
      }
    },
    async getVslScheduleInfo (data) {
      await vslScheduleInfo.getVslScheduleInfo(data)
        .then(response => {
          if (response.status === 200) {
            // console.log('>>>>> getVslScheduleInfo <<<<<')
            // console.log(response)
            // 선박 상세정보 세팅
            this.vesselInfoDetail = { ...response.data.vesselInfoDetail }

            if (this.$ekmtcCommon.isNotEmpty(this.vesselInfoDetail.breadth)) {
              this.vesselInfoDetail.breadth = this.vesselInfoDetail.breadth + ' M'
            }
            if (this.$ekmtcCommon.isNotEmpty(this.vesselInfoDetail.nmnlTeu)) {
              this.vesselInfoDetail.nmnlTeu = this.$ekmtcCommon.changeNumberFormat(this.vesselInfoDetail.nmnlTeu, { isComma: true }) + ' TEU'
            }
            if (this.$ekmtcCommon.isNotEmpty(this.vesselInfoDetail.loaLen)) {
              this.vesselInfoDetail.loaLen = this.$ekmtcCommon.changeNumberFormat(this.vesselInfoDetail.loaLen, { isComma: true }) + ' M'
            }

            // 부모 컴포넌트에서 가져온 값 세팅
            // this.vesselInfoDetail.vslNmDisp = data.vslNm
            this.vesselInfoDetail.voyNoDisp = data.voyNo
            this.vesselInfoDetail.bkgDocCls = data.bkgDocCls
            this.vesselInfoDetail.bkgCgoCls = data.bkgCgoCls
            this.vesselInfoDetail.bkgMfCls = data.bkgMfCls
            this.vesselInfoDetail.cfsCls = data.cfsCls
            // this.vesselInfoDetail.mrnNo = (data.mrnNo || '')
            this.vesselInfoDetail.polTrmlCd = data.polTrmlCd
            if (data.polTrmlCd2 !== undefined) {
              this.vesselInfoDetail.polTrmlCd = data.polTrmlCd2
            }

             // 선박 위치 호출을 위한 데이터 세팅(하위 컴포넌트로 내려보낼 데이터)
            this.vslInfo = {
              // 업무 구분 코드(V=선박위치, P=항구위치)
              catCd: 'V',
              vslCd: this.vesselInfoDetail.vslCd,
              voyNo: this.vesselInfoDetail.voyNoDisp,
              vslNm: this.vesselInfoDetail.vslNm
            }
            this.isVslLocation = this.vesselInfoDetail.vslLocationYn === 'Y' ? !this.isVslLocation : this.isVslLocation

            // 항차 스케줄 세팅
            this.vesselScheduleInfo = response.data.vesselScheduleInfo

            this.vesselScheduleInfo.forEach(element => {
              if (this.$ekmtcCommon.isNotEmpty(element.prfmApoTm)) {
                element.prfmApoTm = String(element.prfmApoTm).substring(0, 2) + ':' + String(element.prfmApoTm).substring(2, 4)
              }
              if (this.$ekmtcCommon.isNotEmpty(element.rvsdApoTm)) {
                element.rvsdApoTm = String(element.rvsdApoTm).substring(0, 2) + ':' + String(element.rvsdApoTm).substring(2, 4)
              }
              if (this.$ekmtcCommon.isNotEmpty(element.apoActlTm)) {
                element.apoActlTm = String(element.apoActlTm).substring(0, 2) + ':' + String(element.apoActlTm).substring(2, 4)
              }
              if (this.$ekmtcCommon.isNotEmpty(element.prfmDpoTm)) {
                element.prfmDpoTm = String(element.prfmDpoTm).substring(0, 2) + ':' + String(element.prfmDpoTm).substring(2, 4)
              }
              if (this.$ekmtcCommon.isNotEmpty(element.rvsdDpoTm)) {
                element.rvsdDpoTm = String(element.rvsdDpoTm).substring(0, 2) + ':' + String(element.rvsdDpoTm).substring(2, 4)
              }
              if (this.$ekmtcCommon.isNotEmpty(element.dpoUbthTm)) {
                element.dpoUbthTm = String(element.dpoUbthTm).substring(0, 2) + ':' + String(element.dpoUbthTm).substring(2, 4)
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getVslDelayReason () {
      const params = {
        vslCd: this.parentInfo.vslCd,
        voyNo: this.parentInfo.voyNo,
        polPortCd: this.parentInfo.polPortCd,
        eiCatCd: this.parentInfo.bound
      }
      // test
      // const params = {
      //   vslCd: 'JBAS',
      //   voyNo: '2106E',
      //   polPortCd: 'INC',
      //   eiCatCd: 'O'
      // }

      await schedule.getVslDelayReason(params)
        .then(response => {
          const data = response.data
          if (data !== undefined && this.$ekmtcCommon.isNotEmpty(data)) {
            this.vslDelayReasonBtnFlag = !this.vslDelayReasonBtnFlag
          }
          // console.log('getVslDelayReason response >>>>', response)
          // console.log('getVslDelayReason vslDelayReasonBtnFlag >>>>', this.vslDelayReasonBtnFlag)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getImportManagerDeadlineInfo (blNo) {
      await arrivalNotice.findImportManagerDeadlineInfo(blNo).then(response => {
        this.deadlineInfoDetail = { ...response.data.deadlineInfoDetail }
      })
    },
    // PDF 다운로드 팝업 열기
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }

      this.customComponent = compNm
      this.popupParams.items = [
        {
          vslCd: this.parentInfo.vslCd,
          vslNm: this.vesselInfoDetail.vslNm,
          voyNo: this.parentInfo.voyNo,
          polPortCd: this.parentInfo.polPortCd,
          podPortCd: this.parentInfo.podPortCd,
          gubun: '99Vs',
          docNo: '',
          editTable: '',
          bound: this.parentInfo.bound
        }
      ]
      // test
      // this.popupParams.items = [
      //   {
      //     vslCd: 'JBAS',
      //     voyNo: '2106E',
      //     polPortCd: 'INC',
      //     podPortCd: 'NGO',
      //     gubun: '99Vs',
      //     docNo: '',
      //     editTable: ''
      //   }
      // ]

      this.$ekmtcCommon.layerOpen('.pdf_download_pop')
    },
    // PDF 다운로드 팝업 닫기
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.pdf_download_pop')
    },
    showTooltip (e) {
      const el = e.target
      //const parentEl = el.parentElement.parentElement
      const parentEl = el.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'inline-block'
    },
    hideTooltip (e) {
      const el = e.target
      const parentEl = el.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'none'
    },
    // 선급증서 다운로드
    certiFileDownload (reqRno) {
      vslScheduleInfo.certiFileDownload(reqRno)
        .then(response => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE에서 동작
            window.navigator.msSaveOrOpenBlob(response.data, this.vesselInfoDetail.vslCertiFileNm)
          } else {
            //CHROME에서 동작
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', this.vesselInfoDetail.vslCertiFileNm)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          }
        })
    }
  }
}
</script>
