<template>
  <div class="popup_wrap" style="width:900px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.PERS010L020.026') }} </h1>
      <div class="content_box">
        <div class="text-right">
          <b-button squared variant="primary" type="buttom" class="mr-5" @click="search">{{ $t('msg.MYIN040G010.009') }}</b-button> <!-- 검색 -->
          <b-button squared variant="success" type="buttom" class="" @click="preView">{{ $t('msg.ADD20220221.019') }}</b-button>
        </div>
        <table class="tbl_col">
          <colgroup>
            <col width="150px">
            <col>
            <col>
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONGO080G010.011') }}</th> <!-- 구분 -->
              <template v-if="infoData.titleInfo.length > 0">
                <th v-for="(item, index) in infoData.titleInfo" :key="index">{{ item.vslNm }} {{ item.voyNo }}</th>
              </template>
              <template v-else>
                <th v-for="item in scheduleData" :key="item.logRno"></th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text_left valign_top">
                <p>{{ $t('msg.ONEX010T010.056') }}</p> <!-- 서류마감 -->
                <p> {{ $t('msg.VOSD100_M4.011') }}</p> <!-- Container 반입마감 -->
                <p>VGM {{ $t('msg.CSBL100_M1.017') }}</p> <!-- 마감 -->
                <p>CFS {{ $t('msg.VOSD100_M4.010') }}</p> <!-- 반입마감 -->
                <p>EDI {{ $t('msg.ADD20220221.020') }} </p> <!-- 신고마감 -->
              </td>
              <template v-if="infoData.deadLineInfo.length > 0">
                <td v-for="(v, index) in infoData.deadLineInfo" :key="index" class="text_left valign_top">
                  <div v-show="v.bound === 'O'">
                    <p>{{ v.bkgDocCls }}</p>
                    <p>{{ v.bkgCgoCls }}</p>
                    <p>{{ v.bkgDocCls }}</p>
                    <p>{{ v.cfsCls }}</p>
                    <p>{{ v.bkgMfCls }}</p>
                  </div>
                </td>
              </template>
              <template v-else>
                <td v-for="item in scheduleData" :key="item.logRno"></td>
              </template>
            </tr>
            <tr>
              <td class="text_left valign_top">
                <P class="font_medium">{{ $t('msg.CSBL100_P1.005') }} </P> <!-- 출발 -->
                <p>- ETA</p>
                <p>- ETD</p>
                <p>- Terminal</p>
              </td>
              <template v-if="infoData.startInfo.length > 0">
                <td v-for="(v, index) in infoData.startInfo" :key="index" class="text_left valign_top">
                  <P class="font_medium">{{ v.polNm }}</P>
                  <p>{{ v.polEtbDT }}</p>
                  <p>{{ v.etdDT }}</p>
                  <p>{{ v.polTml }}</p>
                </td>
              </template>
              <template v-else>
                <td v-for="item in scheduleData" :key="item.logRno">-</td>
              </template>
            </tr>
            <tr v-show="maxDegree > 1">
              <td class="text_left valign_top">
                <P>T/S (1<sup>ST</sup>)</P>
                <p>- ETA</p> <!-- 도착예정일 -->
                <p>- ETD</p> <!-- 출항예정일 -->
                <p>- Terminal</p>
              </td>
              <template v-for="(v, index) in infoData.ts01Info">
                <td :key="index" :class="strTrim(v.podNm) !== '' ? 'text_left valign_top' : ''">
                  <template v-if="strTrim(v.podNm) !== ''">
                    <P class="">{{ v.podNm }}</P>
                    <p>{{ strTrim(v.polEtaDT) === '' ? '-' : v.polEtaDT }}</p>
                    <p>{{ strTrim(v.polEtbDT) === '' ? '-' : v.polEtbDT }}</p>
                    <p>{{ v.polTml }}</p>
                  </template>
                  <template v-else>-</template>
                </td>
              </template>
            </tr>
            <tr v-show="maxDegree > 2">
              <td class="text_left valign_top">
                <P>T/S (2<sup>ND</sup>)</P>
                <p>- ETA</p>
                <p>- ETD</p>
                <p>- Terminal</p>
              </td>
              <template v-for="(v, index) in infoData.ts02Info">
                <td :key="index" :class="strTrim(v.podNm) !== '' ? 'text_left valign_top' : ''">
                  <template v-if="strTrim(v.podNm) !== ''">
                    <P>{{ v.podNm }}</P>
                    <p>{{ strTrim(v.polEtbDT) === '' ? '-' : v.polEtbDT }}</p>
                    <p>{{ strTrim(v.etaDT) === '' ? '-' : v.etaDT }}</p>
                    <p>{{ v.polTml }}</p>
                  </template>
                  <template v-else>-</template>
                </td>
              </template>
            </tr>
            <tr v-show="maxDegree > 3">
              <td class="text_left valign_top">
                <P>T/S (3<sup>RD</sup>)</P>
                <p>- ETA</p>
                <p>- ETD</p>
                <p>- Terminal</p>
              </td>
              <template v-for="(v, index) in infoData.ts03Info">
                <td :key="index" :class="strTrim(v.podNm) !== '' ? 'text_left valign_top' : ''">
                  <template v-if="strTrim(v.podNm) !== ''">
                    <P>{{ v.podNm }}</P>
                    <p>{{ strTrim(v.polEtbDT) === '' ? '-' : v.polEtbDT }}</p>
                    <p>{{ strTrim(v.etaDT) === '' ? '-' : v.etaDT }}</p>
                    <p>{{ v.polTml }}</p>
                  </template>
                  <template v-else>-</template>
                </td>
              </template>
            </tr>
            <tr>
              <td class="text_left valign_top">
                <P class="font_medium">{{ $t('msg.CSBL100_P1.005') }}</P> <!-- 도착 -->
                <p>- ETA</p>
                <p>- Terminal</p>
              </td>
              <template v-if="infoData.arrivalInfo.length > 0">
                <td v-for="(v, index) in infoData.arrivalInfo" :key="index" class="text_left valign_top">
                  <P class="font_medium">{{ v.podNm }}</P>
                  <p>{{ v.polEtaDT }}</p>
                  <p>{{ v.podTml }}</p>
                </td>
              </template>
              <template v-else>
                <td v-for="item in scheduleData" :key="item.logRno">-</td>
              </template>
            </tr>
            <tr>
              <td class="text_left">
                Transit Time
              </td>
              <template v-if="infoData.etcInfo.length > 0">
                <td v-for="(v, index) in infoData.etcInfo" :key="index" class="text_left valign_top">
                  {{ v.transitTime }}
                </td>
              </template>
              <template v-else>
                <td v-for="item in scheduleData" :key="item.logRno">-</td>
              </template>
            </tr>
            <tr>
              <td class="text_left valign_top">
                <p>Call sign </p>
                <p>MRN No.</p>
                <p>{{ $t('btn.CMBTK044') }}</p> <!-- 관할세관 -->
              </td>
              <template v-if="infoData.etcInfo.length > 0">
                <td v-for="(v, index) in infoData.etcInfo" :key="index" class="text_left valign_top">
                  <p>{{ strTrim(v.callSign) === '' ? '-' : v.callSign }}</p>
                  <p>{{ strTrim(v.mrnNo) === '' ? '-' : v.mrnNo }}</p>
                  <p>{{ strTrim(v.jrstCstm) === '' ? '-' : v.jrstCstm }}</p>
                </td>
              </template>
              <template v-else>
                <td v-for="item in scheduleData" :key="item.logRno">-</td>
              </template>
            </tr>
            <tr>
              <td class="text_left valign_top">
                <p>MRN No.</p>
                <p>Call sign </p>
              </td>
              <template v-if="infoData.etcInfo.length > 0">
                <td v-for="(v, index) in infoData.etcInfo" :key="index" class="text_left valign_top">
                  <p>{{ strTrim(v.mrnNo) === '' ? '-' : v.mrnNo }}</p>
                  <p>{{ strTrim(v.callSign) === '' ? '-' : v.callSign }}</p>
                </td>
              </template>
              <template v-else>
                <td v-for="item in scheduleData" :key="item.logRno">-</td>
              </template>
            </tr>
            <tr>
              <td class="text_left">
                {{ $t('msg.CSBL100_P1.005') }} <!-- 선박정보 -->
              </td>
              <td>
                <a class="button sm">{{ $t('msg.CSBL100_P1.005') }} <!-- 선박정보 --></a>
              </td>
              <td>
                <a class="button sm">{{ $t('msg.CSBL100_P1.005') }} <!-- 선박정보 --></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import schedules from '@/api/rest/schedule/schedule'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'ScheduleComparePop',
  components: {},
  props: {
    selectData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      scheduleData: [],
      scheduleDetailList: [],
      scheduleDetailDisplay: [],
      infoData: {
        titleInfo: [],
        deadLineInfo: [],
        startInfo: [],
        ts01Info: [],
        ts02Info: [],
        ts03Info: [],
        arrivalInfo: [],
        etcInfo: [],
        tsCount: []
      },
      maxDegree: -1,
      lang: 'KOR',
      userCtrCd: 'KR',
      fromBkg: 'N',
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      yyyymmLeg: '',
      yyyymmdd: '',
      ctrCd: 'KR',
      commodityNm: '',
      commodityCd: '',
      calendarOrList: 'C',
      searchYear: '',
      searchMonth: '',
      startPlcCdList: [],
      myScheduleList: [],
      dates: [],
      currentYear: 0,
      currentMonth: 0,
      year: 0,
      month: 0,
      holidayList: [],
      bound: '',
      listSchedule: [],
      listScheduleBkg: [],
      listSchedule4List: [],
      todayStr2: '',
      rtnLegData: [],
      filterPolTrmlList: [],
      filterPodTrmlList: [],
      startPlcNameSel: [],
      destPlcNameSel: [],
      prevMonth: '',
      nextMonth: '',
      myScheduleOrNot: '',
      schId: 0,
      startPolName: '',
      destPodName: '',
      scheduleForm: {
        startPlcCd: '',
        startPlcName: '',
        destPlcCd: '',
        startCtrCd: '',
        destCtrCd: '',
        destPlcName: '',
        startPolName: '',
        destPodName: ''
      },
      scheduleDetailData: {
        pol2nd: '',
        pod2nd: '',
        etd2nd: ''
      },
      vessel2ndList: [],
      detailResp2: {
        tsDegree: '',
        vslNm1: '',
        voyNo: '',
        rteCd: '',
        polNm: '',
        podNm: '',
        pod1Nm: '',
        transitTime: '',
        transitTime1: '',
        polEtbDT: '',
        etdDT: '',
        polTml: '',
        polTmlCd: '',
        etaDT: '',
        podTml: '',
        vslNm2: '', // 2nd
        voyNo2: '',
        rteCd2: '',
        pod2Nm: '',
        transitTime2: '',
        polEtbDT2: '',
        polTml2: '',
        etaDT2: '',
        podTml2: '',
        vslNm3: '', // 3rd
        voyNo3: '',
        rteCd3: '',
        pod3Nm: '',
        transitTime3: '',
        polEtbDT3: '',
        polTml3: '',
        etaDT3: '',
        podTml3: '',
        vslNm4: '', // 4th
        voyNo4: '',
        rteCd4: '',
        pod4Nm: '',
        transitTime4: '',
        polEtbDT4: '',
        polTml4: '',
        etaDT4: '',
        podTml4: '',
        bkgDocCls: '',
        bkgCgoCls: '',
        bkgMfCls: '',
        cfsCls: '',
        mrnNo: '',
        callSign: '',
        jrstCstm: '',
        ts: '',
        vslCd: '',
        pol: '',
        pod: '',
        bkgClose: '',
        dtBkgYn: ''
      },
      tempDetailResp2: {
        vslNm2BK: '',
        voyNo2BK: '',
        rteCd2BK: '',
        transitTime2BK: '',
        polEtbDT2BK: '',
        etaDT2BK: ''
      },
      regParams: {
        bound: 'O',
        checkBkg: 'N',
        vslType01: '01',
        vslType03: '03',
        filterDirect: 'Y',
        filterTS: 'Y',
        rf: 'N'
      },
      showBkg: 'N',
      show2ndVessel: 'N',
      detailLayer2: 'N',
      pagingList: [],
      startIdx: 0,
      endIdx: 10,
      curPage: 1,
      finalPage: 0,
      pageSize: 10,
      clickMySchedule: 'N',
      clickDetailSearch: 'N',
      afterSearch: 'N',
      afterClickDetail: 'N',
      filterPolTrmlCd: [],
      filterPodTrmlCd: [],
      filterYn: 'N',
      filterDirect: '',
      filterTS: '',
      polTrmlStr: '',
      podTrmlStr: '',
      listIndexNo: 0,
      RFinfo: 'N',
      // Pop-up
      selectFunc: null,
      unnoDesc: '',
      parentInfoSch: {},
      customComponentSch: null,
      popupParams: {
        UnnoPop: {
          unno: '',
          cntrTypCd: 'GP',
          scheduleFlag: 'Y'
        },
        EmailSchedulePop: {
          polCtrCd: '',
          polPlcCd: '',
          polPlcNm: '',
          podCtrCd: '',
          podPlcCd: '',
          podPlcNm: '',
          eiCatCd: ''
        },
        RoutePop: {
          rteCd: '',
          vesselNm: ''
        },
        VesselScheduleInfoPop: {
          vslNm: '',
          vslCd: '',
          voyNo: '',
          polPortCd: '',
          podPortCd: '',
          bound: '',
          polTrmlCd: '',
          bkgDocCls: '',
          bkgCgoCls: '',
          bkgMfCls: '',
          cfsCls: '',
          mrnNo: ''
        },
        PortTerminalCYPop: {
          asPortCd: '',
          asCyCd: '',
          asSvcLang: '',
          asIcdPlcCd: '',
          flag: 'schedule'
        },
        FreSurchargePop: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          startDt: '',
          endDt: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: ''
        },
        FreSurchargePop2: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          startDt: '',
          endDt: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          vslNm: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: ''
        },
        RequestQuotePop: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: ''
        }
      },
      // Pop-up
      bkgParams: {
        vslCd: '', // [0]선명코드
        voyNo: '', // [1]항차
        vslNm: '', // [2]선명
        polName: '', // [3]출발지 포트명
        podName: '', // [4]도착지 포트명
        etd: '', // [5]입항일
        etdTm: '', // [6]입항시간
        eta: '', // [7]출항일
        etaTm: '', // [8]출항시간
        polPortCd: '', // [9]출발지 포트코드
        podPortCd: '', // [10]도착지 포트코드
        polCtrCd: '', // [11]출발지 국가코드
        podCtrCd: '', // [12]도착지 국가코드
        cct: '', // [13] ?
        docCls: '', // [14]서류마감
        docPic: '', // [15]서류마감 담당자
        polTrmlCd: '', // [16]출발지 터미널 코드
        podTrmlCd: '', // [18]도착지 터미널 코드
        mrnNo: '', // [18]MRN Number
        callSign: '', // [19]Call sign
        hidCS008I: '', // [20]info (@_@로 붙어있는 정보)
        originInfo: '', // [20]info Backup (@_@로 붙어있는 정보)
        mfCls: '', // [21]EDI 신고마감
        cgoCls: '', // [22]반입마감
        cgoPic: '', // [23]반입마감 담당자
        finalEta: '', // [24]최종ETA
        cfsCls: '', // [25]cfs 반입마감
        cfsPic: '', // [26]cfs 반입마감 담당자
        bkgCloseYn: '', // [27] booking close 여부
        prrmSgEtd: '', // [28] ?
        polTrmlNm: '', // [29] 출발지 터미널명
        railCheck: '', // [30] rail booking 여부 ? >> schedule 처리 부분 없음(주석처리)
        frtAppNo: '', // [31] 운임 APPLICATION 번호 >> 견적운임조회
        logDtm: '', // [32] >> 견적운임조회
        srRest: '', // [33] SR 생성여부 >> 부킹 제약사항체크
        localDtm: '', // [34] 현재 시간 >> 부킹 제약사항체크
        vslRest: '', // [35] 선박 close 여부 >> 부킹 제약사항체크
        cgoRest: '' // [36] 반입 마감시한 >> 부킹 제약사항체크
      },
      vessel2ndParam: '',
      bkgScheduleData: [],
      // bkgDetailData: [],
      raTsParam: '',
      effectDate: '',
      toDate: '',
      bkgOrNot: false,
      searchYN: 'N',
      frtResult: '',
      ohcsYn: 'N',
      jhcsYn: 'N',
      ihcsYn: 'N',
      oengHcsYn: 'N',
      bookingYN: 'N',
      freAppData: {},
      eiCatCd: '',
      popupVo: {},
      searchData: [],
      isSearchOk: false,
      sCount: 0
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    selectData (nv, ov) {
      this.scheduleData = nv
      this.search()
    },
    isSearchOk (nv, ov) {
      if (nv === true) {
        this.preView()
      }
    },
    sCount (nv, ov) {
      console.log('### count >> ', nv, ov)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.scheduleData = this.selectData
      this.search()
    },
    async search () {
      console.log('$$$$$$$$ getLegSchedule $$$$$$$$')
      this.isSearchOk = false
      this.searchData = []
      this.sCount = 0

      await this.scheduleData.forEach(tmp => {
        this.bound = tmp.eiCatCd

        const params = {
          startCtrCd: tmp.polCtrCd, //출발국가코드
          startPlcCd: tmp.polPortCd, //출발지역코드
          startPlcName: '', //출발지역명칭
          destCtrCd: tmp.podCtrCd, //도착지국가코드
          destPlcCd: tmp.podPortCd, //도착지지역코드
          destPlcName: '', //도착지지역명칭
          mobileYN: 'Y',
          startDate: tmp.dpoDt, //출항일자
          endDate: tmp.dpoDt, //출항일자
          bound: tmp.eiCatCd, //수출입구분
          pointChangeYN: '',
          filterPolCd: '',
          pointLength: '',
          searchYear: tmp.dpoDt.substring(0, 4),
          searchMonth: tmp.dpoDt.substring(4, 6),
          searchYN: 'Y',
          hiddestPlcCd: '',
          polTrmlStr: '', //선적항 터미널
          podTrmlStr: '', //양하항 터미널
          filterYn: 'Y', //FILTER 여부
          filterPodCd: '',
          filterTs: 'Y', //T/S FILTER 검색
          filterDirect: 'Y',
          filterTranMax: '0',
          filterTranMin: '0',
          hidstartPlcCd: '',
          main: 'N',
          legIdx: '0',
          vslType01: '',
          vslType03: '',
          unno: '',
          commodityCd: '',
          rf: '',
          eiCatCd: tmp.eiCatCd,
          calendarOrList: 'C'
        }
        // console.log('!! 검색조건 >>')
        // console.log(params)
        this.getLegSchedule(tmp, params)
        this.sCount++
      })
    },

    async getLegSchedule (logObj, params) {
      await schedules.getLegSchedule(params).then((response) => {
        if (response.data) {
          console.log('## getLegSchedule Ok', response.data.listSchedule)
          let legSchedules = response.data.listSchedule.filter(vo => { return logObj.vslCd === vo.vslCd && logObj.voyNo === vo.voyNo })
          if (legSchedules.length > 0) {
            this.searchData.push({ logData: logObj, legSchedule: legSchedules[0] })
          }
        }
      })
    },

    async fnDetailLayer2 (vo, logRno) {
      let rtn = {}
      this.show2ndVessel = 'N'
      // console.log('####### fnDetailLayer2 ####### ')
      let longParam = vo.ts + ';' +
                      vo.vslNm + ';' +
                      vo.vslCd + ';' +
                      vo.voyNo + ';' +
                      vo.transitTime + ';' +
                      vo.polEtb + ';' +
                      vo.polEtbTm + ';' +
                      vo.etd + ';' +
                      vo.etdTm + ';' +
                      vo.eta + ';' +
                      vo.etaTm + ';' +
                      vo.polTml + ';' +
                      vo.podTml + ';' +
                      vo.polPicTel + ';' +
                      vo.itrmlNm + ';' +
                      vo.podPicTel + ';' +
                      vo.closeTime + ';' +
                      vo.cct + ';' +
                      vo.mrnNo + ';' +
                      vo.callSign + ';' +
                      vo.bkgClose + ';' +
                      vo.mfCls + ';' +
                      vo.webCls + ';' +
                      vo.cgoCls + ';' +
                      vo.jrstCstm + ';' +
                      vo.info + ';' +
                      vo.pol + ';' +
                      vo.pod + ';' +
                      vo.bkgDocCls + ';' +
                      vo.bkgCgoCls + ';' +
                      vo.bkgMfCls + ';' +
                      vo.cfsCls + ';' +
                      vo.pod1 + ';' +
                      vo.podEtd1 + ';' +
                      vo.podTrml1 + ';' +
                      vo.podPicTel1 + ';' +
                      vo.etaTm1 + ';' +
                      vo.transitTime1 + ';' +
                      vo.pol1 + ';' +
                      vo.vslNm2 + ';' +
                      vo.vslCd2 + ';' +
                      vo.voyNo2 + ';' +
                      vo.polEtd2 + ';' +
                      vo.podEtd2 + ';' +
                      vo.polTrml2 + ';' +
                      vo.podTrml2 + ';' +
                      vo.polPicNm2 + ';' +
                      vo.polPicTel2 + ';' +
                      vo.podPicTel2 + ';' +
                      vo.etdTm2 + ';' +
                      vo.etaTm2 + ';' +
                      vo.transitTime2 + ';' +
                      vo.tsDegree + ';' +
                      vo.vslNm3 + ';' +
                      vo.vslCd3 + ';' +
                      vo.voyNo3 + ';' +
                      vo.polEtd3 + ';' +
                      vo.etdTm3 + ';' +
                      vo.polTrml3 + ';' +
                      vo.polPicTel3 + ';' +
                      vo.podEtd3 + ';' +
                      vo.etaTm3 + ';' +
                      vo.podTrml3 + ';' +
                      vo.podPicTel3 + ';' +
                      vo.transitTime3 + ';' +
                      vo.vslNm4 + ';' +
                      vo.vslCd4 + ';' +
                      vo.voyNo4 + ';' +
                      vo.polEtd4 + ';' +
                      vo.etdTm4 + ';' +
                      vo.polTrml4 + ';' +
                      vo.polPicTel4 + ';' +
                      vo.podEtd4 + ';' +
                      vo.etaTm4 + ';' +
                      vo.podTrml4 + ';' +
                      vo.podPicTel4 + ';' +
                      vo.transitTime4 + ';' +
                      vo.polTrml1 + ';' +
                      vo.pol2 + ';' +
                      vo.pod3 + ';' +
                      vo.pod4 + ';' +
                      vo.pod2 + ';' +
                      vo.bkgDocPic + ';' +
                      vo.bkgCgoPic + ';' +
                      vo.vslNm1 + ';' +
                      vo.pol3 + ';' +
                      vo.pol4 + ';' +
                      vo.bkgCfsPic + ';' +
                      vo.bkgVslCd + ';' +
                      vo.bkgVoyNo
      const params = {
        param: longParam,
        bound: 'O'
      }
      console.log(JSON.stringify(params))
      await schedules.getLegScheduleDetail(params).then((response) => {
        if (response.data) {
         this.scheduleDetailDisplay.push(
            {
              logRno: logRno,
              rtnData: response.data,
              vo: vo
            }
         )
         console.log('!! rtnData >>', response.data)
         this.fnDetailLayerResponse2(response.data, vo)
        }
        // if (this.calendarOrList === 'C') {
        //   this.bkgScheduleData = vo
        //   // this.bkgDetailData = rtnData.data
        //   this.fnDetailLayerResponse2(rtnData, vo)
        //   this.insertWB242H('02', vo)
        // } else {
        //   this.bkgScheduleData = vo
        //   // this.bkgDetailData = rtnData.data
        //   console.log('$$$$$ fnDetailLayer2 / this.bkgScheduleData $$$$$')
        //   console.log(this.bkgScheduleData)
        //   console.log('$$$$$ fnDetailLayer2 / this.bkgDetailData $$$$$')
        //   console.log(this.bkgDetailData)
        // }
      })
    },
    fnDetailLayerResponse2 (rtnData, vo) {
      //this.scheduleDetailDisplay
      console.log(`tsDegree >> ${vo.tsDegree}`)
      let tsDegree = vo.tsDegree
      if (tsDegree > this.maxDegree) {
        this.maxDegree = tsDegree
      }

      this.infoData.tsCount.push(vo.tsDegree)

      this.detailResp2.tsDegree = vo.tsDegree
      this.raTsParam = ''
      this.detailResp2.bound = ''
      this.detailResp2.ts = vo.ts
      this.detailResp2.vslCd = vo.vslCd
      this.detailResp2.pol = vo.pol
      this.detailResp2.pod = vo.pod
      this.detailResp2.etd = vo.etd
      this.detailResp2.vslNm = vo.vslNm
      this.detailResp2.bkgClose = vo.bkgClose
      // if (rtnData.bkgMdlCloseYn === 'Y') {
      //   this.detailResp2.bkgClose = 'Y'
      // }
      this.detailResp2.dtBkgYn = rtnData.dtBkgYn

      const titleInfo = {
        vslNm: vo.vslNm, // 선박명
        voyNo: vo.voyNo // 항차
      }
      this.infoData.titleInfo.push(titleInfo)

      // 1st Vessel
      this.detailResp2.vslNm1 = vo.vslNm1
      this.detailResp2.voyNo = vo.voyNo

      this.detailResp2.rteCd = rtnData.rteCd
      this.detailResp2.polNm = rtnData.polNm
      this.detailResp2.podNm = rtnData.podNm
      this.detailResp2.pod1Nm = rtnData.pod1Nm
      this.detailResp2.transitTime = vo.transitTime
      this.detailResp2.transitTime1 = vo.transitTime1
      this.detailResp2.polEtbDT = this.changeDate2Display(vo.polEtb, vo.polEtbTm, this.lang)
      this.detailResp2.etdDT = this.changeDate2Display(vo.etd, vo.etdTm, this.lang)
      this.detailResp2.polTml = this.fnTrmlText(vo.polTml, rtnData.polTmlNm)
      this.detailResp2.etaDT = this.changeDate2Display(vo.podEtd1, vo.etaTm1, this.lang)
      this.detailResp2.podTml = this.fnTrmlText(vo.podTrml1, rtnData.podTrml1Nm)
      this.detailResp2.polTmlCd = vo.polTml
      this.detailResp2.podTmlCd = vo.podTml

      const startInfo = {
        rteCd: rtnData.rteCd,
        polNm: rtnData.polNm,
        podNm: rtnData.podNm,
        pod1Nm: rtnData.pod1Nm,
        transitTime: vo.transitTime,
        transitTime1: vo.transitTime1,
        polEtbDT: this.changeDate2Display(vo.polEtb, vo.polEtbTm, this.lang),
        etdDT: this.changeDate2Display(vo.etd, vo.etdTm, this.lang),
        polTml: this.fnTrmlText(vo.polTml, rtnData.polTmlNm),
        etaDT: this.changeDate2Display(vo.podEtd1, vo.etaTm1, this.lang),
        podTml: this.fnTrmlText(vo.podTrml1, rtnData.podTrml1Nm),
        polTmlCd: vo.polTml,
        podTmlCd: vo.podTml
      }
      this.infoData.startInfo.push(startInfo)

      let ts01Info = {
        vslNm: '',
        vslCd: '',
        voyNo: '',
        rteCd: '',
        podNm: '',
        pod2Nm: '',
        transitTime2: '',
        transitTSTime2: '',
        polEtbDT: '',
        polEtaDT: '',
        polTml: '',
        polTmlCd2: '',
        etaDT: '',
        podTml: '',
        podTmlCd2: '',
        pol1: '',
        pod1: '',
        pol2: '',
        pod2: ''
      }
      let ts02Info = {
        vslNm: '',
        vslCd: '',
        voyNo: '',
        rteCd: '',
        podNm: '',
        pod3Nm: '',
        transitTime3: '',
        transitTSTime3: '',
        polEtbDT: '',
        polTml: '',
        polTmlCd3: '',
        etaDT: '',
        podTml: '',
        podTmlCd3: ''
      }
      let ts03Info = {
        vslNm: '',
        vslCd: '',
        voyNo: '',
        rteCd: '',
        podNm: '',
        pod4Nm: '',
        transitTime4: '',
        transitTSTime4: '',
        polEtbDT: '',
        polTml: '',
        polTmlCd: '',
        etaDT: '',
        podTml: '',
        podTmlCd: ''
      }
      if (vo.ts === 'Y') {
        // 2nd Vessel
        this.detailResp2.vslNm2 = vo.vslNm2
        this.detailResp2.vslCd2 = vo.vslCd2
        this.detailResp2.voyNo2 = vo.voyNo2
        this.detailResp2.rteCd2 = rtnData.rteCd2
        this.detailResp2.pod1Nm = rtnData.pod1Nm
        this.detailResp2.pod2Nm = rtnData.pod2Nm
        this.detailResp2.transitTime2 = vo.transitTime2
        this.detailResp2.transitTSTime2 = vo.transitTSTime2
        this.detailResp2.polEtbDT2 = this.changeDate2Display(vo.polEtd2, vo.etdTm2, this.lang)
        this.detailResp2.polTml2 = this.fnTrmlText(vo.polTrml2, rtnData.polTrml2Nm)
        this.detailResp2.polTmlCd2 = vo.polTrml2
        this.detailResp2.etaDT2 = this.changeDate2Display(vo.podEtd2, vo.etaTm2, this.lang)
        this.detailResp2.podTml2 = this.fnTrmlText(vo.podTrml2, rtnData.podTrml2Nm)
        this.detailResp2.podTmlCd2 = vo.podTrml2
        this.detailResp2.pol1 = vo.pol1
        this.detailResp2.pod1 = vo.pod1
        this.detailResp2.pol2 = vo.pol2
        this.detailResp2.pod2 = vo.pod2

        ts01Info = {
          vslNm: vo.vslNm2,
          vslCd: vo.vslCd2,
          voyNo: vo.voyNo2,
          rteCd: rtnData.rteCd2,
          podNm: rtnData.pod1Nm,
          pod2Nm: rtnData.pod2Nm,
          transitTime2: vo.transitTime2,
          transitTSTime2: vo.transitTSTime2,
          polEtbDT: this.changeDate2Display(vo.polEtd2, vo.etdTm2, this.lang),
          polEtaDT: this.changeDate2Display(vo.podEtd1, vo.etaTm1, this.lang),
          polTml: this.fnTrmlText(vo.polTrml2, rtnData.polTrml2Nm),
          polTmlCd2: vo.polTrml2,
          etaDT: this.changeDate2Display(vo.podEtd2, vo.etaTm2, this.lang),
          podTml: this.fnTrmlText(vo.podTrml2, rtnData.podTrml2Nm),
          podTmlCd2: vo.podTrml2,
          pol1: vo.pol1,
          pod1: vo.pod1,
          pol2: vo.pol2,
          pod2: vo.pod2
        }

        if (tsDegree >= 3) {
          this.detailResp2.vslNm3 = vo.vslNm3
          this.detailResp2.vslCd3 = vo.vslCd3
          this.detailResp2.voyNo3 = vo.voyNo3
          this.detailResp2.rteCd3 = rtnData.rteCd3
          this.detailResp2.pod2Nm = rtnData.pod2Nm
          this.detailResp2.pod3Nm = rtnData.pod3Nm
          this.detailResp2.transitTime3 = vo.transitTime3
          this.detailResp2.transitTSTime3 = vo.transitTSTime3
          this.detailResp2.polEtbDT3 = this.changeDate2Display(vo.polEtd3, vo.etdTm3, this.lang)
          this.detailResp2.polTml3 = this.fnTrmlText(vo.polTrml3, rtnData.polTrml3Nm)
          this.detailResp2.polTmlCd3 = vo.polTrml3
          this.detailResp2.etaDT3 = this.changeDate2Display(vo.podEtd3, vo.etaTm3, this.lang)
          this.detailResp2.podTml3 = this.fnTrmlText(vo.podTrml3, rtnData.podTrml3Nm)
          this.detailResp2.podTmlCd3 = vo.podTrml3

          ts02Info = {
            vslNm: vo.vslNm3,
            vslCd: vo.vslCd3,
            voyNo: vo.voyNo3,
            rteCd: rtnData.rteCd3,
            podNm: rtnData.pod2Nm,
            pod3Nm: rtnData.pod3Nm,
            transitTime3: vo.transitTime3,
            transitTSTime3: vo.transitTSTime3,
            polEtbDT: this.changeDate2Display(vo.polEtd3, vo.etdTm3, this.lang),
            polTml: this.fnTrmlText(vo.polTrml3, rtnData.polTrml3Nm),
            polTmlCd3: vo.polTrml3,
            etaDT: this.changeDate2Display(vo.podEtd3, vo.etaTm3, this.lang),
            podTml: this.fnTrmlText(vo.podTrml3, rtnData.podTrml3Nm),
            podTmlCd3: vo.podTrml3
          }
        }

        if (tsDegree >= 4) {
          this.detailResp2.vslNm4 = vo.vslNm4
          this.detailResp2.vslCd4 = vo.vslCd4
          this.detailResp2.voyNo4 = vo.voyNo4
          this.detailResp2.rteCd4 = rtnData.rteCd4
          this.detailResp2.pod3Nm = rtnData.pod3Nm
          this.detailResp2.pod4Nm = rtnData.pod4Nm
          this.detailResp2.transitTime4 = vo.transitTime4
          this.detailResp2.transitTSTime4 = vo.transitTSTime4
          this.detailResp2.polEtbDT4 = this.changeDate2Display(vo.polEtd4, vo.etdTm4, this.lang)
          this.detailResp2.polTml4 = this.fnTrmlText(vo.polTrml4, rtnData.polTrml4Nm)
          this.detailResp2.polTmlCd4 = vo.polTrml4
          this.detailResp2.etaDT4 = this.changeDate2Display(vo.podEtd4, vo.etaTm4, this.lang)
          this.detailResp2.podTml4 = this.fnTrmlText(vo.podTrml4, rtnData.podTrml4Nm)
          this.detailResp2.podTmlCd4 = vo.podTrml4

          ts03Info = {
            vslNm: vo.vslNm4,
            vslCd: vo.vslCd4,
            voyNo: vo.voyNo4,
            rteCd: rtnData.rteCd4,
            podNm: rtnData.pod3Nm,
            pod4Nm: rtnData.pod4Nm,
            transitTime4: vo.transitTime4,
            transitTSTime4: vo.transitTSTime4,
            polEtbDT: this.changeDate2Display(vo.polEtd4, vo.etdTm4, this.lang),
            polTml: this.fnTrmlText(vo.polTrml4, rtnData.polTrml4Nm),
            polTmlCd: vo.polTrml4,
            etaDT: this.changeDate2Display(vo.podEtd4, vo.etaTm4, this.lang),
            podTml: this.fnTrmlText(vo.podTrml4, rtnData.podTrml4Nm),
            podTmlCd: vo.podTrml4
          }
        }
      }
      this.infoData.ts01Info.push(ts01Info)
      this.infoData.ts02Info.push(ts02Info)
      this.infoData.ts03Info.push(ts03Info)

      // 서류마감
      this.detailResp2.bkgDocCls = this.changeDateTime2Display(vo.bkgDocCls, this.lang)

      // CY Open

      // VGM 마감 >> bkgDocCls 동일?

      // Container 반입마감
      this.detailResp2.bkgCgoCls = this.changeDateTime2Display(vo.bkgCgoCls, this.lang)

      // EDI 신고마감
      this.detailResp2.bkgMfCls = this.changeDateTime2Display(vo.bkgMfCls, this.lang)

      // CFS 반입마감
      this.detailResp2.cfsCls = this.changeDateTime2Display(vo.cfsCls, this.lang)

      // MRN No.
      this.detailResp2.mrnNo = vo.mrnNo

      // CALL SIGN
      this.detailResp2.callSign = vo.callSign

      // 관할세관
      this.detailResp2.jrstCstm = ''
      if (rtnData.jrstCstm) {
        this.detailResp2.jrstCstm = rtnData.jrstCstm
      }

      // 출항 Terminal >> polTml 동일

      this.infoData.deadLineInfo.push(
        {
          bound: this.bound,
          bkgDocCls: this.changeDateTime2Display(vo.bkgDocCls, this.lang), // 서류마감
          bkgCgoCls: this.changeDateTime2Display(vo.bkgCgoCls, this.lang), // Container 반입마감
          bkgMfCls: this.changeDateTime2Display(vo.bkgMfCls, this.lang), // EDI 신고마감
          cfsCls: this.changeDateTime2Display(vo.cfsCls, this.lang) // CFS 반입마감
        }
      )
      this.infoData.etcInfo.push(
        {
          transitTime: vo.transitTime,
          mrnNo: vo.mrnNo, // MRN No.
          jrstCstm: rtnData.jrstCstm, // 관할세관
          callSign: vo.callSign
        }
      )

      const etaDT1 = this.strTrim(vo.podEtd1) !== '' ? this.changeDate2Display(vo.podEtd1, vo.etaTm1, this.lang) : ''
      const etaDT2 = this.strTrim(vo.podEtd2) !== '' ? this.changeDate2Display(vo.podEtd2, vo.etaTm2, this.lang) : ''
      const etaDT3 = this.strTrim(vo.podEtd3) !== '' ? this.changeDate2Display(vo.podEtd3, vo.etaTm3, this.lang) : ''
      const etaDT4 = this.strTrim(vo.podEtd4) !== '' ? this.changeDate2Display(vo.podEtd4, vo.etaTm4, this.lang) : ''

      const etaDTList = [etaDT1, etaDT2, etaDT3, etaDT4]
      let lastEdaDt = ''
      etaDTList.filter(v => v !== '').forEach(v => {
        lastEdaDt = v
      })
      console.log('!! Time >>', etaDTList)

      const arrivalInfo = {
        rteCd: rtnData.rteCd,
        polNm: rtnData.polNm,
        podNm: rtnData.podNm,
        pod1Nm: rtnData.pod1Nm,
        transitTime: vo.transitTime,
        transitTime1: vo.transitTime1,
        polEtbDT: this.changeDate2Display(vo.polEtb, vo.polEtbTm, this.lang),
        polEtaDT: lastEdaDt,
        etdDT: this.changeDate2Display(vo.etd, vo.etdTm, this.lang),
        polTml: this.fnTrmlText(vo.polTml, rtnData.polTmlNm),
        etaDT: this.changeDate2Display(vo.podEtd1, vo.etaTm1, this.lang),
        podTml: this.fnTrmlText(vo.podTrml1, rtnData.podTrml1Nm),
        polTmlCd: vo.polTml,
        podTmlCd: vo.podTml
      }
      this.infoData.arrivalInfo.push(arrivalInfo)

      console.log('!! detailResp2 >>', this.detailResp2)
      console.log(JSON.stringify(vo))

      // 선박 변경 취소용
      // this.tempDetailResp2.vslNm2BK = this.detailResp2.vslNm2
      // this.tempDetailResp2.voyNo2BK = this.detailResp2.voyNo2
      // this.tempDetailResp2.rteCd2BK = this.detailResp2.rteCd2
      // this.tempDetailResp2.transitTime2BK = this.detailResp2.transitTime2
      // this.tempDetailResp2.polEtbDT2BK = this.detailResp2.polEtbDT2
      // this.tempDetailResp2.etaDT2BK = this.detailResp2.etaDT2

      // R/A check
      // if (this.auth.userId !== '') {
      //   this.popFreAppNoCheck()
      // }
      // R/A
      // this.detailResp2.frtResult = vo.frtResult
      // this.frtResult = vo.frtResult
      // this.detailResp2.strDt = vo.strDt
      // this.detailResp2.endDt = vo.endDt
      // this.detailResp2.frtAppNo = vo.frtAppNo
      // this.detailResp2.frtEstnYn = vo.frtEstnYn
      // this.detailResp2.reqRno = vo.reqRno
      // console.log('>>>>>>>>>> this.detailResp2 <<<<<<<<<<')
      // console.log(this.raTsParam)
      // console.log(this.detailResp2)

      // 상세정보 표시
      this.detailLayer2 = 'Y'
      if (vo.bkgClose === 'N') {
        this.afterClickDetail = 'Y'
      } else {
        this.afterClickDetail = 'N'
      }

      let finalEta = ''
      if (vo.ts === 'Y') {
        finalEta = vo.podEtd2 + vo.etaTm2
      } else {
        finalEta = vo.eta + vo.etaTm
      }

      this.bkgParams.vslCd = vo.vslCd // [0]선명코드
      this.bkgParams.voyNo = vo.voyNo // [1]항차
      this.bkgParams.vslNm = vo.vslNm // [2]선명
      this.bkgParams.polName = vo.polNm // [3]출발지 포트명
      this.bkgParams.podName = vo.podNm // [4]도착지 포트명
      this.bkgParams.etd = vo.etd // [5]입항일
      this.bkgParams.etdTm = vo.etdTm // [6]입항시간
      this.bkgParams.eta = vo.polEtb // [7]출항일
      this.bkgParams.etaTm = vo.polEtbTm // [8]출항시간
      this.bkgParams.polPortCd = vo.pol // [9]출발지 포트코드
      this.bkgParams.podPortCd = vo.pod // [10]도착지 포트코드
      this.bkgParams.polCtrCd = vo.polCtrCd // [11]출발지 국가코드
      this.bkgParams.podCtrCd = vo.podCtrCd // [12]도착지 국가코드
      this.bkgParams.cct = vo.cct // [13] ?
      this.bkgParams.docCls = vo.bkgDocCls // [14]서류마감
      this.bkgParams.docPic = vo.bkgDocPic // [15]서류마감 담당자
      this.bkgParams.polTrmlCd = vo.otrmlCd // [16]출발지 터미널 코드
      this.bkgParams.podTrmlCd = vo.itrmlCd // [18]도착지 터미널 코드
      this.bkgParams.mrnNo = vo.mrnNo // [18]MRN Number
      this.bkgParams.callSign = vo.callSign // [19]Call sign
      this.bkgParams.hidCS008I = vo.info // [20]info (@_@로 붙어있는 정보)
      this.bkgParams.originInfo = vo.info // [20]info Backup (@_@로 붙어있는 정보)
      this.bkgParams.mfCls = vo.bkgMfCls // [21]EDI 신고마감
      this.bkgParams.cgoCls = vo.bkgCgoCls // [22]반입마감
      this.bkgParams.cgoPic = vo.bkgCgoPic // [23]반입마감 담당자
      this.bkgParams.finalEta = finalEta // [24]최종ETA
      this.bkgParams.cfsCls = vo.cfsCls // [25]cfs 반입마감
      this.bkgParams.cfsPic = vo.bkgCfsPic // [26]cfs 반입마감 담당자
      this.bkgParams.bkgCloseYn = vo.bkgClose // [27] booking close 여부
      this.bkgParams.prrmSgEtd = vo.prrmSgEtd // [28] EDI 마감시간
      this.bkgParams.polTrmlNm = vo.otrmlNm // [29] 출발지 터미널명
      this.bkgParams.railCheck = '' // [30] rail booking 여부 ? >> schedule 처리 부분 없음(주석처리)
      // this.bkgParams.frtAppNo = '_notyet' // [31] 운임 APPLICATION 번호 >> 견적운임조회
      this.bkgParams.logDtm = '' // [32] >> 견적운임조회 >> _notyet
      // this.bkgParams.srRest = '_notyet' // [33] SR 생성여부 >> 부킹 제약사항체크
      // this.bkgParams.localDtm = '20210906151954_notyet' // [34] 현재 시간 >> 부킹 제약사항체크
      // this.bkgParams.vslRest = '0_notyet' // [35] 선박 close 여부 >> 부킹 제약사항체크
      // this.bkgParams.cgoRest = '202109071600_notyet' // [36] 반입 마감시한 >> 부킹 제약사항체크
      this.bkgParams.rfYn = this.regParams.rf

      console.log(this.bkgParams)
    },
    changeDate2Display (strDate, strTime, lang) {
      let rtnStrDate = ''
      if (!(strDate && strTime)) {
        strDate = ''
        strTime = ''
      }
      strDate = '' + strDate
      strTime = '' + strTime
      if (strDate.trim() === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
      }
      if (strTime.trim() === '') {
        rtnStrDate = ''
      } else {
        rtnStrDate = rtnStrDate + ' ' + strTime.substring(0, 2) + ':' + strTime.substring(2, 4)
      }
      return rtnStrDate
    },
    changeDateTime2Display (strDate, lang) {
      let rtnStrDate = ''
      if (!strDate) {
        strDate = ''
      }
      strDate = '' + strDate

      if (strDate.trim() === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
        rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
      }
      return rtnStrDate
    },
    fnTrmlText (trml, trml_nm) {
      var str = ''
      if (trml !== '') {
        str += trml
      } else {
        str = ''
      }
      if (trml_nm !== '' && trml_nm !== undefined) {
        str += ' (' + trml_nm + ')'
      } else if (trml_nm === undefined) {
        str = ''
      } else {
        str = ''
      }
      return str
    },
    preView () {
      console.log('%%%%%%%%%%%%%%%%% pre view')
      this.scheduleDetailDisplay = []
      this.infoData = {
        titleInfo: [],
        deadLineInfo: [],
        startInfo: [],
        ts01Info: [],
        ts02Info: [],
        ts03Info: [],
        arrivalInfo: [],
        etcInfo: [],
        tsCount: []
      }
      this.maxDegree = 0
      console.log('## searchData >>', this.searchData)

      this.searchData.forEach(v => {
        this.fnDetailLayer2(v.legSchedule, v.logData.logRno)
      })
      console.log('## infoData >>', this.infoData)
    },
    strTrim (str) {
      str = '' + str
      console.log(' Trim ', str.trim())
      return str.trim()
    }
  }
}
</script>
