var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "l-map",
    { attrs: { zoom: _vm.zoom, center: _vm.center, options: _vm.mapOptions } },
    [
      _c("l-tile-layer", {
        attrs: { url: _vm.url, attribution: _vm.attribution },
      }),
      _c("l-control", [
        _c("img", {
          staticStyle: { width: "150px" },
          attrs: { src: require("../../assets/images/schedule/kmtc_logo.gif") },
        }),
      ]),
      _vm.parentInfo.catCd === "V"
        ? _c("l-control", { attrs: { position: "bottomleft" } }, [
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("msg.SCHD010P040.047")) +
                  ":" +
                  _vm._s(_vm.vslUpdateTime)
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "v-rotated-marker",
        {
          attrs: {
            "lat-lng": _vm.makerLatLng,
            icon: _vm.icon,
            "rotation-angle": _vm.rotationAngle,
          },
        },
        [
          _vm.parentInfo.catCd === "V"
            ? _c("l-tooltip", [
                _vm._v(
                  " " +
                    _vm._s(_vm.parentInfo.vslNm) +
                    " / " +
                    _vm._s(_vm.parentInfo.voyNo) +
                    " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }