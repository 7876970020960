var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content resize" }, [
    _c("h2", { staticClass: "mb-5" }, [_vm._v("개인화 메인")]),
    _c(
      "div",
      { staticStyle: { width: "340px" } },
      [_c("schedule-compare", { attrs: { "line-count": 5 } })],
      1
    ),
    _c(
      "div",
      [
        _c(
          "b-button",
          { attrs: { variant: "success" }, on: { click: _vm.test01 } },
          [_vm._v("테스트")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }