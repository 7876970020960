<template>
  <router-view></router-view>
</template>

<script>

export default {
  components: {
  },
  methods: {
  }
}

</script>
