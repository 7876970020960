<template>
  <div class="per_section">
    <h2>{{ $t('msg.PERS010L020.029') }}</h2> <!-- 진행중인 업무 -->
    <div class="per_half">
      <div class="dv col_5">
        <p class="tit"><span>{{ $t('msg.PERS010L020.030') }}</span> <span class="trad">(From {{ countryDigitCode }})</span></p> <!-- 수출-->
        <dl>
          <dt>{{ $t('msg.PERS010L020.034') }}</dt> <!-- 수출서류마감 -->
          <dd class="cs-pointer" @click="search('E', '01')"><span>{{ workingCnt.bkgDocCnt }}</span> {{ $t('msg.PERS010L020.038') }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('msg.PERS010L020.035') }}</dt> <!-- 선적지 출항 -->
          <dd class="cs-pointer" @click="search('E', '02')"><span>{{ workingCnt.polEtdCnt }}</span> {{ $t('msg.PERS010L020.038') }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('msg.PERS010L020.036') }}</dt> <!-- 선적지 입항 -->
          <dd class="cs-pointer" @click="search('E', '03')"><span>{{ workingCnt.polEtaCnt }}</span> {{ $t('msg.PERS010L020.038') }}</dd>
        </dl>
      </div>
      <div class="dv">
        <p class="tit"><span>{{ $t('msg.PERS010L020.031') }}</span> <span class="trad">(To {{ countryDigitCode }})</span></p> <!-- 수입 -->
        <dl>
          <dt>{{ $t('msg.PERS010L020.037') }}</dt> <!-- 금일 입항 예정-->
          <dd class="cs-pointer" @click="search('I')"><span>{{ workingCnt.podEtaCnt }}</span> {{ $t('msg.PERS010L020.038') }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import oogApi from '@/api/rest/trans/oogs'
import countryCode from '@/plugins/country.json'
export default {
  name: 'Working',
  components: {

  },
  props: [
    'countryDigitCode'
  ],
  data: function () {
    return {
        sample: '',
        workingCnt: {
          bkgDocCnt: 0,
          polEtaCnt: 0,
          polEtdCnt: 0,
          podEtaCnt: 0,
          bkgDocBkgNo: [], //수출서류마감 BNK No String (Ex.INC701418,KAN2419812,PUS708804,PUS708811,PUS708812)
          polEtaBlNo: [], //선적지 입항 BL No String (Ex.INC701418,KAN2419812,PUS708804,PUS708811,PUS708812)
          polEtdBlNo: [], //선적지 출항 BL No String (Ex.KAN2419812,PUS708811,PUS708812)
          podEtaBlNo: [] //수입 금일입항예정 BL No String (Ex.KAN2419812,PUS708811,PUS708812)
        },
        isUpdater: false,
        countryCode: countryCode
    }
  },
  computed: {

  },
  watch: {
  },
  created () {

  },
  mounted: function () {

  },
  methods: {
    search (workType, type) {
        this.isUpdater = !this.isUpdater
        let kind = '01'
        let polBlNo = []
        let polBlNoYn = 'N'

        if (workType === 'E') {
           if (type === '01') {
             //kind = '04'
             kind = '02'
             polBlNo = this.workingCnt.bkgDocBkgNo
           } else if (type === '03') {
             if (this.$ekmtcCommon.isEmpty(this.workingCnt.polEtaBlNo)) {
               kind = '05'
             } else {
               kind = '02'
             }

             polBlNo = this.workingCnt.polEtaBlNo
           } else if (type === '02') {
             kind = '02'
             polBlNo = this.workingCnt.polEtdBlNo
           }
        } else if (workType === 'I') {
          kind = '02'
          polBlNo = this.workingCnt.podEtaBlNo
        }

        let searchParams = {
          workType: workType,
          kind: kind,
          fromDt: this.getToday(),
          toDt: this.getToday(),
          profileYn: 'Y',
          isUpdater: this.isUpdater,
          polBlNo: polBlNo,
          polBlNoYn: 'Y'
        }

        this.$store.commit('trans/updateSearchParams', searchParams)
        if (this.$route.path !== '/working/progress') {
          setTimeout(() => {
            this.$router.push('/working/progress')
          }, 100)
        }
    },
    getToday () {
      let today = new Date()
      let year = today.getFullYear() //연도
      let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
      let date = ('0' + today.getDate()).slice(-2) //날짜

      return year + month + date
    },
    getWorkingCnt () {
      let profileYn = 'Y'

      oogApi.getWorkingCnt(profileYn).then(response => {
        console.log('getWorkingCnt >>>> ', response)
        this.workingCnt = response.data
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>
<style scoped>
    .cs-pointer {
        cursor: pointer;
    }
</style>
