<template>
  <div class="per_section">
    <h2 v-if="memberDetail.userCtrCd === 'KR'">{{ $t('menu.MENU06.010') }}</h2>
    <div v-if="memberDetail.userCtrCd === 'KR'" class="chart_credit_limit">
      <div class="graph_area" style="width: 35%; padding-top: 15px;">
        <canvas id="quickChart"></canvas>
      </div>

      <div class="remark_area">
        <p class="tit"><span>{{ $t('msg.PERS010L020.040') }}(KRW)</span></p>
        <ul>
          <li>
            <span class="ico1"></span> <span>{{ $t('msg.PERS010L020.041') }}</span>
            <span class="num">{{ numberFormat(useLimit) }}</span>
          </li>
          <li>
            <span class="ico2"></span> <span>{{ $t('msg.PERS010L020.042') }}</span>
            <span class="num">{{ numberFormat(rstLimit) }}</span>
          </li>
          <li>
            <span class="ico1"></span><span class="plus">+</span><span class="ico2"></span><span>{{ $t('msg.PERS010L020.043') }}</span>
            <span class="num">{{ numberFormat(parseInt(useLimit) + parseInt(rstLimit)) }}</span>
          </li>
        </ul>
      </div>
    </div><!-- 신용한도 gr // -->

    <div v-if="memberDetail.userCtrCd !== 'JP'">
      <p class="tit"><span>{{ $t('msg.PERS010L020.044') }}</span></p>
      <p class="bank" v-if="vrAccount.usd != null">
        USD {{ $t('msg.PERS010L020.045') }}
        <span class="name">
          {{ vrAccount.usd.bankName }} <span class="no">{{ vrAccount.usd.vrAcctNo }}</span>
        </span>
      </p>
      <p class="bank mb15" v-if="vrAccount.krw != null">
        KRW  {{ $t('msg.PERS010L020.045') }}
        <span class="name">
          {{ vrAccount.krw.bankName }} <span class="no">{{ vrAccount.krw.vrAcctNo }}</span>
        </span>
      </p>
      <div v-if="vrAccount.krw == null || vrAccount.usd == null" style="margin: 10px 0px;">
        <a class="button sm" @click="openPopup('VtlAccPop')">{{ $t('msg.PERS010L020.051') }}</a>
      </div>
    </div>

    <div class="per_half">
      <!-- B/L 미발행 -->
      <div class="dv col_5">
        <p class="tit"><span>{{ $t('msg.PERS010L020.046') }}</span> <span class="trad">(From {{ countryDigitCode }})</span></p>
        <dl class="inline">
          <!-- 금일 출항-->
          <dt>{{ $t('msg.PERS010L020.048') }}</dt>
          <dd class="cs-pointer" @click="movePage('/calcIssue/calcIssueMain', 'blToday')"><span>{{ notIssuedCount.blToday }}</span></dd>
        </dl>
        <dl class="inline">
          <!-- 출항 완료-->
          <dt>{{ $t('msg.PERS010L020.049') }}</dt>
          <dd class="cs-pointer" @click="movePage('/calcIssue/calcIssueMain', 'blYesterday')"><span>{{ notIssuedCount.blYesterday }}</span></dd>
        </dl>
      </div>
      <!-- D/O 미발행 -->
      <div class="dv">
        <p class="tit"><span>{{ $t('msg.PERS010L020.047') }}</span> <span class="trad">(To {{ countryDigitCode }})</span></p>
        <dl class="inline">
          <!-- 입항 완료-->
          <dt>{{ $t('msg.PERS010L020.050') }}</dt>
          <dd class="cs-pointer" @click="movePage('/calcIssue/calcIssueMain', 'doCompletion')"><span>{{ notIssuedCount.doCompletion }}</span></dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'
import Doughnut from 'vue-chart.js'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'QuickCalcIssue',
  components: {},
  data: function () {
    return {
      isCompentLoaded: false,
      useLimit: 0,
      rstLimit: 0,
      strDt: '',
      endDt: '',
      vrAccount: {
        krw: null,
        usd: null
      },
      notIssuedCount: {
        blToday: 0,
        blYesterday: 0,
        doCompletion: 0
      },
      isUpdater: false
    }
  },
  computed: {
    ...rootComputed
  },
   props: [
    'countryDigitCode'
  ],
  watch: {},
  created () {},
  mounted: function () {
    if (this.auth.login) {
      this.searchData()
      this.getVrAccount()
    }
  },
  methods: {
    openPopup (compNm) {
      this.$emit('child', { type: 'openPop', payload: { compNm: compNm } })
    },
    formatDateYYYYMMDD (date) {
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return year + '' + month + '' + day
    },
    movePage (path, type) {
        if (path === '/calcIssue/calcIssueMain') {
          this.isUpdater = !this.isUpdater
          let now = new Date()
          let searchParams = {
            fromDt: '',
            toDt: '',
            termType: '01',
            isUpdater: this.isUpdater
          }
          if (type === 'blToday') {
            searchParams.dtKnd = 'DD'
            searchParams.eiCatCd = 'O'
            searchParams.toDt = this.formatDateYYYYMMDD(now)
            searchParams.fromDt = this.formatDateYYYYMMDD(now)
          } else if (type === 'blYesterday') {
            let yesterday = new Date(now.setDate(now.getDate() - 1))
            searchParams.dtKnd = 'DD'
            searchParams.eiCatCd = 'O'
            searchParams.toDt = this.formatDateYYYYMMDD(yesterday)
            searchParams.fromDt = this.formatDateYYYYMMDD(yesterday)
          } else if (type === 'doCompletion') {
            searchParams.dtKnd = 'AD'
            searchParams.eiCatCd = 'I'
            searchParams.toDt = this.formatDateYYYYMMDD(now)
            searchParams.fromDt = this.formatDateYYYYMMDD(new Date(now.setDate(now.getDate() - 180)))
          }
          this.$store.commit('settle/updateTabIndex', '1')
          this.$store.commit('settle/updateSearchParams', searchParams)
        }
        // setTimeout(() => {
        //    this.$router.push(path)
        // }, 200)
    },
    async findNotIssuedCount () {
      await CalcIssue.findNotIssuedCount().then(response => {
        this.notIssuedCount = response.data
      }).catch(e => {
        console.log(e)
      })
    },
    async getVrAccount () {
       await CalcIssue.getVrAccount().then(response => {
        for (let vrAccount of response.data) {
          if (vrAccount.currencyCode === 'WON') {
            this.vrAccount.krw = vrAccount
          } else if (vrAccount.currencyCode === 'USD') {
            this.vrAccount.usd = vrAccount
          }
        }
      }).catch(e => {
        console.log(e)
      })
    },
    async searchData () {
      await CalcIssue.getLimitInfo('')
        .then(response => {
          console.log(response)
          const info = response.data
          this.useLimit = Number(info.amtInfo.useLimit)
          this.rstLimit = Number(info.amtInfo.rstLimit)
          this.strDt = this.$ekmtcCommon.getDateToStrDelim(this.$ekmtcCommon.getStrToDate(info.limitInfo.gurStrDt), '.')
          this.endDt = this.$ekmtcCommon.getDateToStrDelim(this.$ekmtcCommon.getStrToDate(info.limitInfo.gurEndDt), '.')
          this.createChart()
          this.$emit('isLoadOk', {})
        })
        .catch(err => {
          console.log(err)
        })
    },
    createChart (obj) {
      let ctx = document.getElementById('quickChart')
      var quickChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [this.$t('msg.SETT010T010.063'), this.$t('msg.SETT010T010.064')],
          datasets: [
            {
              backgroundColor: [
                '#1c69e3',
                '#a5c8fc'
              ],
              data: [this.useLimit, this.rstLimit]
            }
          ]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      })
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
<style scoped>
    .cs-pointer {
        cursor: pointer;
    }
</style>
