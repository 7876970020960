<template>
  <div class="wrap">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'OpenPopupLayout'
}
</script>

<style scoped>
  .wrap { width: 'inherit'; }
</style>
