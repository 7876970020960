var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c(
        "win-layer-pop",
        { staticClass: "no-search-result-pop2" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.006"))),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("div", { staticClass: "schbox" }, [
          _c("span", { staticClass: "inputClear" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchKeyword,
                  expression: "searchKeyword",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "B/L No. / Cntr No. / Booking No. / Keyword",
              },
              domProps: { value: _vm.searchKeyword },
              on: {
                keyup: [
                  function ($event) {
                    $event.preventDefault()
                    return _vm.preventFirstInputWhiteSpace()
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.integratedSearch()
                  },
                ],
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchKeyword = $event.target.value
                },
              },
            }),
            _c("span", {
              class: ["icon_clear", !_vm.keywordClaerBtn ? "hidden" : ""],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clearKeyword()
                },
              },
            }),
          ]),
          _c(
            "a",
            {
              staticClass: "button",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.integratedSearch()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.UTIL010G010.007")))]
          ),
        ]),
        _c("div", { staticClass: "sch_section fir" }, [
          _c("p", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("msg.UTIL010G010.008")))]),
            _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.009"))),
            _vm._v(" : "),
            _c("span", { staticClass: "font_light" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.015"))),
              _vm._v(" "),
              _c("span", { staticClass: "num" }, [
                _vm._v(
                  _vm._s(
                    _vm.integratedSearchResult.menuList !== undefined &&
                      _vm.integratedSearchResult.menuList.length > 0
                      ? _vm.integratedSearchResult.menuList.length
                      : 0
                  )
                ),
              ]),
              _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.010"))),
            ]),
          ]),
          _vm.integratedSearchResult.menuList !== undefined &&
          _vm.integratedSearchResult.menuList.length > 0
            ? _c(
                "div",
                _vm._l(_vm.integratedSearchResult.menuList, function (menu) {
                  return _c("dl", { key: menu.routerName }, [
                    _c("dt", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$router.push({ name: menu.routerName })
                            },
                          },
                        },
                        [
                          _c("p", {
                            domProps: { innerHTML: _vm._s(menu.menuName) },
                          }),
                        ]
                      ),
                    ]),
                    _c("dd", [_vm._v(" " + _vm._s(menu.menuPath) + " ")]),
                  ])
                }),
                0
              )
            : _c("div", { staticClass: "nodata" }, [
                _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.011"))),
              ]),
        ]),
        _c("div", { staticClass: "sch_section" }, [
          _c("p", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("msg.UTIL010G010.012")))]),
            _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.009"))),
            _vm._v(" : "),
            _c("span", { staticClass: "font_light" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.015"))),
              _vm._v(" "),
              _c("span", { staticClass: "num" }, [
                _vm._v(
                  _vm._s(
                    _vm.integratedSearchResult.boardList.faqList !==
                      undefined &&
                      _vm.integratedSearchResult.boardList.faqList.length > 0
                      ? _vm.integratedSearchResult.boardList.faqList.length
                      : 0
                  )
                ),
              ]),
              _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.010"))),
            ]),
          ]),
          _vm.integratedSearchResult.boardList.faqList !== undefined &&
          _vm.integratedSearchResult.boardList.faqList.length > 0
            ? _c(
                "div",
                _vm._l(
                  _vm.integratedSearchResult.boardList.faqList,
                  function (faq) {
                    return _c("dl", { key: faq.messageIdSeq }, [
                      _c("dt", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.moveDetail(
                                  "faq",
                                  _vm.searchKeyword,
                                  faq
                                )
                              },
                            },
                          },
                          [
                            _c("p", {
                              domProps: { innerHTML: _vm._s(faq.title) },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeDatePattern(
                                faq.createDate,
                                "-"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("dd", {
                        staticClass: "text_overflow",
                        domProps: { innerHTML: _vm._s(faq.contents) },
                      }),
                    ])
                  }
                ),
                0
              )
            : _c("div", { staticClass: "nodata" }, [
                _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.011"))),
              ]),
        ]),
        _c("div", { staticClass: "sch_section" }, [
          _c("p", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("msg.UTIL010G010.013")))]),
            _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.009"))),
            _vm._v(" : "),
            _c("span", { staticClass: "font_light" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.015"))),
              _vm._v(" "),
              _c("span", { staticClass: "num" }, [
                _vm._v(
                  _vm._s(
                    _vm.integratedSearchResult.boardList.pdsList !==
                      undefined &&
                      _vm.integratedSearchResult.boardList.pdsList.length > 0
                      ? _vm.integratedSearchResult.boardList.pdsList.length
                      : 0
                  )
                ),
              ]),
              _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.010"))),
            ]),
          ]),
          _vm.integratedSearchResult.boardList.pdsList !== undefined &&
          _vm.integratedSearchResult.boardList.pdsList.length > 0
            ? _c(
                "div",
                _vm._l(
                  _vm.integratedSearchResult.boardList.pdsList,
                  function (pds) {
                    return _c("dl", { key: pds.messageIdSeq }, [
                      _c("dt", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.moveDetail(
                                  "pds",
                                  _vm.searchKeyword,
                                  pds
                                )
                              },
                            },
                          },
                          [
                            _c("p", {
                              domProps: { innerHTML: _vm._s(pds.title) },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeDatePattern(
                                pds.createDate,
                                "-"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("dd", {
                        staticClass: "text_overflow",
                        domProps: { innerHTML: _vm._s(pds.contents) },
                      }),
                    ])
                  }
                ),
                0
              )
            : _c("div", { staticClass: "nodata" }, [
                _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.011"))),
              ]),
        ]),
        _c("div", { staticClass: "sch_section" }, [
          _c("p", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("msg.UTIL010G010.014")))]),
            _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.009"))),
            _vm._v(" : "),
            _c("span", { staticClass: "font_light" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.015"))),
              _vm._v(" "),
              _c("span", { staticClass: "num" }, [
                _vm._v(
                  _vm._s(
                    _vm.integratedSearchResult.boardList.noticeList !==
                      undefined &&
                      _vm.integratedSearchResult.boardList.noticeList.length > 0
                      ? _vm.integratedSearchResult.boardList.noticeList.length
                      : 0
                  )
                ),
              ]),
              _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.010"))),
            ]),
          ]),
          _vm.integratedSearchResult.boardList.noticeList !== undefined &&
          _vm.integratedSearchResult.boardList.noticeList.length > 0
            ? _c(
                "div",
                _vm._l(
                  _vm.integratedSearchResult.boardList.noticeList,
                  function (notice) {
                    return _c(
                      "dl",
                      { key: notice.tableId + "_" + notice.noticeId },
                      [
                        _c("dt", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.moveDetail(
                                    "notice",
                                    _vm.searchKeyword,
                                    notice
                                  )
                                },
                              },
                            },
                            [
                              _c("p", {
                                domProps: { innerHTML: _vm._s(notice.title) },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.changeDatePattern(
                                  notice.createDate,
                                  "-"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("dd", {
                          staticClass: "text_overflow",
                          domProps: { innerHTML: _vm._s(notice.contents) },
                        }),
                      ]
                    )
                  }
                ),
                0
              )
            : _c("div", { staticClass: "nodata" }, [
                _vm._v(_vm._s(_vm.$t("msg.UTIL010G010.011"))),
              ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }