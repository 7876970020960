<template>
  <body class="bsaMain">
    <div class="wrap">
      <div class="container_main">
        <div class="content_main">
          <!-- content_main : -->
          <div class="visual_area">
            <!-- visual_area -->
            <form id="frm_main">
            </form>
          </div>
          <!-- visual_area // -->
        </div>
        <!-- content_main // -->
      </div>
      <!--  ##### container_main //  ##### -->
    </div>
  </body>
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
  name: 'Main',
  components: {
  },
  computed: {
    ...rootComputed
  },
  data () {
    return {
      autoCompleteWidth: '301px',
      serviceCtrCd: '',
      devYn: process.env.VUE_APP_MODE !== 'PRD'
    }
  },
  created () {
    if (this.serviceLang === 'KOR' || this.serviceLang === 'CHN') {
      this.autoCompleteWidth = '301px'
    } else if (this.serviceLang === 'JPN') {
      this.autoCompleteWidth = '286px'
    } else if (this.serviceLang === 'ENG') {
      this.autoCompleteWidth = '260px'
    }

    // 로그인 후 다시 메인으로 온 경우
    // 비로그인 상태에서 입력한 운임운의 탭(메인 가운데)에 입력한 출발/도착지를 들고와서 세팅 및 처리
    this.init()
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    async init () {
      // 1. 로그인 시 로그인한 유저의 국가정보
      // 2. 브라우저 접속한 국가정보
      // 3. 상단 드롭다운의 언어정보 (Default: KOR)
      // if (!this.auth.userId) {
      //   this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(
      //     localStorage.getItem('service_ctrcd')
      //   )
      //     ? localStorage.getItem('service_ctrcd')
      //     : localStorage.getItem('service_lang')
      // } else {
      //   this.serviceCtrCd = this.memberDetail.userCtrCd
      // }

      // KOR -> KR BSA는 직원 전용으로 사용 예정으로 KR로 FIX처리
      // if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      // }
    },
    stopProp (e) {
      e.stopPropagation()
    }
  }
}
</script>

<style lang="scss" scoped>

.wrap_ENG .main_cont .sch_form .tit {
  width: 100px !important;
}
.wrap_CHN .main_cont .sch_form .tit,
.wrap_JPN .main_cont .sch_form .tit {
  width: 67px !important;
}
.ei-cat-cd-radio {
  margin: 0px 9px;
  font-size: 13px;
}
</style>
