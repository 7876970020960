// 새로운 부킹, 스케줄, 진행중인 업무
const EmptyLayout = () => import(/* webpackChunkName: "topmenu01_03" */'@/layout/EmptyLayout')
const BookingNew = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/BookingNew')
const Oog = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/OogList')
const ScheduleMain = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/schedule/ScheduleMain')
const WorkingMain = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/WorkingMain')
const FreeTimePrint = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/settle/FreeTimePrintPop')
const OfficeAgent = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/settle/OfficeAgentPop')
const FreeTimeExtensReqPop = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/settle/popup/FreeTimeExtensReqPop')
const BookingMain = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/BookingMain')
const BookingNewSr = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/BookingNewSr')
const WorkingDetail = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/WorkingDetail')
const WorkingPickup = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/WorkingPickup')
const UploadFlatFile = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/UploadFlatFile')

const routes = [
  {
    path: '/booking-new',
    name: 'booking-new',
    component: BookingMain,
    props: true,
    meta: {
      breadcrumb: '새로운 부킹',
      key: 'menu.MENU01.000',
      requiresAuth: true
    }
  },
  {
    path: '/schedule',
    redirect: '/schedule/leg',
    component: ScheduleMain,
    meta: {
      breadcrumb: 'Schedule',
      key: 'menu.MENU02.000'
    },
    children: [
      {
        path: 'leg',
        name: 'leg',
        meta: {
          breadcrumb: '구간',
          key: 'menu.MENU02.010'
        }
      },
      {
        path: 'vessel',
        name: 'vessel',
        meta: {
          breadcrumb: '선박',
          key: 'menu.MENU02.020'
        }
      },
      {
        path: 'long-term',
        name: 'long-term',
        meta: {
          breadcrumb: '기간',
          key: 'menu.MENU02.030',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/working',
    redirect: '/working/progress',
    component: WorkingMain,
    props: true,
    meta: {
      breadcrumb: '진행중인 업무',
      key: 'menu.MENU03.000',
      requiresAuth: true
    },
    children: [
      {
        path: 'progress',
        name: 'progress',
        props: true,
        meta: {
          breadcrumb: 'On Going List',
          key: 'menu.MENU03.010',
          requiresAuth: true
        }
      },
      {
        path: 'unsolved',
        name: 'unsolved',
        meta: {
          breadcrumb: '미해결 업무',
          key: 'menu.MENU03.020',
          requiresAuth: true
        }
      },
      {
        path: 'my-request',
        name: 'my-request',
        props: true,
        meta: {
          breadcrumb: '나의 요청사항',
          key: 'menu.MENU03.030',
          requiresAuth: true
        }
      },
      // {
      //   path: 'rf-data',
      //   name: 'rf-data',
      //   meta: {
      //     breadcrumb: '냉동컨테이너 실시간 정보',
      //     key: 'menu.MENU03.040',
      //     requiresAuth: true
      //   }
      // },
      /* {
        path: 'upload-flat-file',
        name: 'upload-flat-file',
        component: UploadFlatFile,
        meta: {
          breadcrumb: 'Upload Flat File',
          key: 'men.CSDO900.010',
          requiresAuth: true
        }
      },
      /* {
        path: 'container-return',
        name: 'container-return',
        meta: {
          breadcrumb: '반납',
          key: '반납',
          requiresAuth: true,
          sitemapHide: true
        }
      }, */
      {
        path: 'pickup',
        name: 'pickup',
        component: WorkingPickup,
        meta: {
          breadcrumb: 'Pick Up 정보 변경',
          key: 'men.CSBK110.010',
          requiresAuth: true,
          sitemapHide: true
        }
      },
      {
        path: 'detail',
        redirect: '/working/progress',
        name: 'detail',
        component: WorkingDetail,
        props: true,
        meta: {
          breadcrumb: '상세',
          requiresAuth: true,
          sitemapHide: true,
          key: 'menu.MENU03.011'
        },
        children: [
          {
            path: 'booking-edit',
            name: 'booking-edit',
            props: true,
            meta: {
              breadcrumb: 'booking 수정',
              requiresAuth: true,
              sitemapHide: true,
              key: 'menu.MENU03.012'
            }
          },
          {
            path: 'bl',
            name: 'bl',
            props: true,
            meta: {
              breadcrumb: 'B/L',
              requiresAuth: true,
              sitemapHide: true
            }
          },
          {
            path: 'freight',
            name: 'freight',
            props: true,
            meta: {
              breadcrumb: 'Freight',
              requiresAuth: true,
              sitemapHide: true
            }
          },
          {
            path: 'free-time',
            name: 'free-time',
            props: true,
            meta: {
              breadcrumb: 'Free Time',
              requiresAuth: true,
              sitemapHide: true
            }
          },
          {
            path: 'bl-certificate',
            name: 'bl-certificate',
            props: true,
            meta: {
              breadcrumb: 'B/L Certificate',
              requiresAuth: true,
              sitemapHide: true
            }
          }
        ]
      }
    ]
  }
]

export default routes
