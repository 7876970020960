var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "autocomplete-form",
      on: {
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            )
              return null
            return _vm.onKeydown($event)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            )
              return null
            return _vm.onKeyup($event)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.onEnterClicked.apply(null, arguments)
          },
        ],
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          )
            return null
          return _vm.onKeyTab($event)
        },
        focusin: function ($event) {
          _vm.visible = true
        },
        focusout: function ($event) {
          return _vm.onFocusout($event)
        },
      },
    },
    [
      _c("div", { staticClass: "autocomplete-form-input-elements" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.text,
              expression: "text",
            },
          ],
          ref: "handleInput",
          staticClass: "w-100",
          attrs: {
            id: "autocomplete-form-input",
            autocomplete: "off",
            readonly: _vm.readOnly,
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            type: "text",
          },
          domProps: { value: _vm.text },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
              _vm.onInputHandler,
            ],
          },
        }),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.valuesShowing.length > 0,
              expression: "valuesShowing.length > 0",
            },
          ],
          staticClass: "list-group list-group-flush autocomplete-form-items",
          class: { hide: (_vm.disabled && _vm.visible) || !_vm.visible },
          style: {
            width: _vm.$ekmtcCommon.isNotEmpty(_vm.$props.listGroupWidth)
              ? _vm.$props.listGroupWidth
              : "",
          },
          attrs: { id: _vm.elemId },
        },
        [
          _vm._l(_vm.valuesShowing, function (val, index) {
            return [
              _vm.isSafari
                ? _c("button", {
                    key: "btn_" + index,
                    staticClass: "list-group-item list-group-item-action",
                    class: { active: _vm.selectedIndex == index },
                    attrs: { type: "button" },
                    domProps: { innerHTML: _vm._s(val) },
                    on: {
                      mousedown: function ($event) {
                        return _vm.onClickHandler($event, "mousedown")
                      },
                      mouseover: function ($event) {
                        _vm.selectedIndex = index
                      },
                    },
                  })
                : _c("button", {
                    key: "btn_" + index,
                    staticClass: "list-group-item list-group-item-action",
                    class: { active: _vm.selectedIndex == index },
                    attrs: { type: "button" },
                    domProps: { innerHTML: _vm._s(val) },
                    on: {
                      click: function ($event) {
                        return _vm.onClickHandler($event, "click")
                      },
                      mouseover: function ($event) {
                        _vm.selectedIndex = index
                      },
                    },
                  }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }