// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/common/icon_clear.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n/*  inputClear  설정   */\n[data-v-b4ef4f08]::-ms-clear {  display: none;\n}\n.inputClear[data-v-b4ef4f08] { position: relative;\n}\n.icon_clear[data-v-b4ef4f08] { position:absolute; top:5px; right:10px; cursor: pointer; display:inline-block; width: 14px;height: 14px; background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat;\n}\n.text_overflow[data-v-b4ef4f08] {\n  height: 42px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  -webkit-line-clamp: 2;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n}\n", ""]);
// Exports
module.exports = exports;
