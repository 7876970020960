const SampleLayout = () => import(/* webpackChunkName: "topmenu_sample" */'@/layout/SampleLayout')
const SampleGrid = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleGrid')
const SampleBbs = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleBbs')
const SampleDextUploader = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleDextUploader')
const SampleDateRangePicker = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleDateRangePicker')
const SampleMultiSelectBox = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleMultiSelectBox')
const SampleSelectCtrPlc = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleSelectCtrPlc')
const SampleDextEditor = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleDextEditor')
const SampleHome = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleHome')
const SamplePopUpList = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SamplePopUpList')
const SamplePdfViewer = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SamplePdfViewer')
const SampleSchedule = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleSchedule')
// const SampleProcessWork = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleProcessWork')
const i18n = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/i18n')
const SamplePopupItextList = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SamplePopupItextList')

const Main = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/Main')
const Login = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/Login')
const LoginApproval = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/user/LoginApproval')
const JoinMemberForm = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/user/JoinMemberForm')
const Check = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/user/Check')
const SearchdemurrageDetailForm = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/user/SearchdemurrageDetailForm')
const MrWaiverList = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/settle/MrWaiverList')
const DangerShipList = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/trans/DangerShipList')
const CpScenarioList = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/cp/CpScenarioList')
const ScenarioTargetSet = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/cp/ScenarioTargetSet')
const ActionListPop = () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/cp/popup/ActionListPop')
const ScenarioAction = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/ScenarioAction')
const FactorCodeMng = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/FactorCodeMng')
const ActionListMng = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/ActionListMng')
const ScenarioResultList = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/ScenarioResultList')
const CpCommCodeMng = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/CommonCodeMng')

const routes = [
    {
        path: '/sample',
        component: SampleLayout,
        meta: {
            breadcrumb: 'Sample',
            key: 'label.TEST01'
        },
        children: [
            {
                path: 'home',
                component: SampleHome,
                meta: {
                    breadcrumb: 'Home'
            }
            },
            {
                path: 'schedule',
                component: SampleSchedule,
                meta: {
                    breadcrumb: '스케줄'
                }
            },
            {
                path: 'working',
                name: 'sample_working',
                component: () => import('@/pages/sample/SampleWorkingMain'),
                meta: {
                  breadcrumb: '진행중인 업무',
                  key: 'menu.MENU03.000',
                  requiresAuth: true
                },
                children: [
                  {
                    path: 'progress',
                    name: 'sample_progress',
                    meta: {
                      breadcrumb: 'On Going List',
                      key: 'menu.MENU03.010',
                      requiresAuth: true
                    }
                  }
                ]
            },
            {
                path: 'compare-object',
                component: () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SampleCompareObject'),
                meta: {
                    breadcrumb: '진행중인 업무',
                    requiresAuth: true
                },
                children: [
                    {
                        path: 'tab1',
                        name: 'tab1',
                        meta: {
                            breadcrumb: 'Tab1'
                        }
                    },
                    {
                        path: 'tab2',
                        name: 'tab2',
                        meta: {
                            breadcrumb: 'Tab2'
                        }
                    },
                    {
                        path: 'tab3',
                        name: 'tab3',
                        meta: {
                            breadcrumb: 'Tab3'
                        }
                    }
                ]
            },
            {
                path: 'grid',
                component: SampleGrid,
                meta: {
                    breadcrumb: '그리드'
                }
            },
            {
                path: 'uploader',
                component: SampleDextUploader,
                meta: {
                    breadcrumb: '파일업로더'
                }
            },
            {
                path: 'editor',
                component: SampleDextEditor,
                meta: {
                    breadcrumb: '웹에디터'
                }
            },
            { path: 'bbs', component: SampleBbs },
            { path: 'i18n', component: i18n },
            { path: 'date-range-picker', component: SampleDateRangePicker },
            { path: 'multi-select-box', component: SampleMultiSelectBox },
            { path: 'sample-select-ctr-plc', component: SampleSelectCtrPlc },
            { path: 'itext-popup', component: SamplePopupItextList },
            {
              path: 'pdf-popup',
              component: () => import(/* webpackChunkName: "topmenu_sample" */'@/pages/sample/SamplePopupPdfList')
            },
            {
              path: 'popup',
              component: SamplePopUpList,
              meta: {
                    breadcrumb: '팝업리스트'
              },
              children: [
                {
                    path: 'gp',
                    name: 'gp',
                    meta: {
                        breadcrumb: 'DRY/GP'
                    }
                },
                {
                    path: 'rf',
                    name: 'rf',
                    meta: {
                        breadcrumb: 'RF'
                    }
                },
                {
                    path: 'ot',
                    name: 'ot',
                    meta: {
                        breadcrumb: 'OT'
                    }
                },
                {
                    path: 'fr',
                    name: 'fr',
                    meta: {
                        breadcrumb: 'FR'
                    }
                },
                {
                    path: 'sr',
                    name: 'sr',
                    meta: {
                        breadcrumb: 'SR'
                    }
                }
            ]
            },
            {
                path: 'main',
                component: Main,
                meta: {
                    requireAuth: true
                }
            },
            { path: 'login', component: Login },
            { path: 'pdf-viewer', component: SamplePdfViewer },
            { path: 'login-approval', component: LoginApproval },
            { path: 'join-member-form', component: JoinMemberForm },
            { path: 'searchdemurrage-detail-form', component: SearchdemurrageDetailForm },
            { path: 'check', component: Check },
            { path: 'mr-waiver-list', component: MrWaiverList },
            { path: 'danger-ship-list', component: DangerShipList },
            {
                path: 'cp-scenario-list',
                component: CpScenarioList,
                meta: {
                    breadcrumb: 'CP 시나리오 목록 작성',
                    key: 'menu.MENU03.000'
                }
            },
            {
                path: 'scenario-target-set/:scenarioCd',
                component: ScenarioTargetSet,
                meta: {
                    breadcrumb: '시나리오 타겟 설정',
                    key: 'menu.MENU03.000'
                }
            },
            {
                path: 'scenario-action/:scenarioCd',
                name: 'scenario-action',
                component: ScenarioAction,
                meta: {
                breadcrumb: ''//,
                //key: ''
                }
            },
            {
                path: 'factor-code',
                name: 'factor-code',
                component: FactorCodeMng,
                meta: {
                breadcrumb: '팩터리스트 관리'//,
                //key: 'menu.MENUCP.053'
                }
            },
            {
                path: 'action-code',
                name: 'action-code',
                component: ActionListMng,
                meta: {
                breadcrumb: '액션코드관리'//,
                //key: 'menu.MENUCP.053'
                }
            },
            {
                path: 'scenario-result',
                name: 'scenario-result',
                component: ScenarioResultList,
                meta: {
                breadcrumb: '시나리오 결과 조회'//,
                //key: 'menu.MENUCP.053'
                }
            },
            {
                path: 'common-code',
                name: 'common-code',
                component: CpCommCodeMng,
                meta: {
                breadcrumb: '공통코드 관리'//,
                //key: 'menu.MENUCP.051'
                }
            }
        ]
    }
]

export default routes
