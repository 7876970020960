import Send from '../../trans.client.js'
import commonUtils from '@/plugins/commonUtils'

export default {
  getOnGoingList (params) {
    return Send({
      url: '/trans/export/on-going',
      method: 'get',
      params: params,
      paramsSerializer: paramsObj => {
        const params = new URLSearchParams()
        for (const key in paramsObj) {
          if (Array.isArray(paramsObj[key]) && paramsObj[key].length === 0) {
            continue
          }
          params.append(key, paramsObj[key])
        }
        return params.toString()
      }
    }).catch(err => {
      console.log(err)
      commonUtils.screenToPdfUpload(params, 'getOnGoingList')
    })
  },
  getOnGoingReqColList (params) {
    return Send({
      url: '/trans/export/on-going/req-col-info',
      method: 'get',
      params: {
        blNo: params
      }
    }).catch(err => {
      console.log(err)
    })
  },
  getOnGoingRequestBtn (params) {
    return Send({
      url: '/trans/export/on-going/request-btn',
      method: 'post',
      data: params
    })
  },
  getOnGoingCntrInfo (params) {
    return Send({
      url: '/trans/export/on-going/cntr-info',
      method: 'get',
      params: params
    })
  },
  getOnGoingBkgCntrSts (params) {
    return Send({
      url: '/trans/export/on-going/bkg-cntr-sts',
      method: 'get',
      params: params
    })
  },
  getOnGoingCaReject (params) {
    return Send({
      url: '/trans/export/on-going/ca-reject',
      method: 'get',
      // spinner: false,
      params: params
    })
  },
  getOnGoingBlTax (params) {
    return Send({
      url: '/trans/export/on-going/bl-tax',
      method: 'get',
      params: params
    })
  },
  getUnsolvedList (params) {
    console.log(params)
    return Send({
      url: '/trans/export/unsolved',
      method: 'get',
      params: params
    })
  },
  getUnsolvedListAll (params) {
    console.log(params)
    return Send({
      url: '/trans/export/unsolved/all',
      method: 'get',
      params: params
    })
  },
  getUnsolvedStats (params) {
    return Send({
      url: '/trans/export/unsolved/stats',
      method: 'get',
      spinner: false,
      params: params
    })
  },
  getBlManagerInfo (params) {
    return Send({
      url: '/trans/export/on-going/bl-manager-info',
      method: 'get',
      params: params
    })
  },
  getBlIssueManagerInfo (params) {
    return Send({
      url: '/trans/export/on-going/bl-issue-manager-info',
      method: 'get',
      params: params
    })
  }
}
