<template>
  <div class="per_recom" v-show="isSlick">
    <h2>{{ $t('msg.PERS010L020.024') }}</h2>
    <div class="recom_sch_area">
      <div class="indicator">
        <ul>
          <template v-for="(li, i) in scheduleList">
            <li :key="'schedule-indicator-' + i">
              <a
                :class="parseInt(currentIdx) == i ? 'on' : ''"
                href="javascript:void(0)"
                @click="clickIndicator(i)"
              >
              </a>
            </li>
          </template>
        </ul>
      </div>
      <Slick
        v-if="isSlick"
        ref="slick"
        :options="slickOptions"
        @afterChange="handleAfterChange"
      >
        <div class="recom_sch" v-for="(schedule, i) in scheduleList" :key="'schedule-slick-' + i" @mousedown="mouseDown" @mouseup="mouseUp" @click="moveSchedulePage(i)">
          <p class="port">
            <span>{{ schedule.polPortCd }}</span>
            <span>{{ schedule.podPortCd }}</span>
          </p>
          <p class="name">{{ schedule.info }}</p>
        </div>
      </Slick>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import '@/../node_modules/slick-carousel/slick/slick.css'
import myScheduleApi from '@/api/rest/schedule/mySchedule'
export default {
  name: 'QuickSchedule',
  components: {
    Slick
  },
  props: [
  ],
  data: function () {
    return {
        scheduleList: [],
        oriScheduleList: [],
        currentIdx: 0,
        slickOptions: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          dots: false,
          arrows: false,
          pauseOnHover: true
        },
        isSlick: false,
        isUpdater: false,
        isPreventClick: true,
        mX1: 0,
        mX2: 0
    }
  },
  computed: {

  },
  watch: {
  },
  created () {

  },
  mounted: function () {
     //this.reInit()
  },
  methods: {
    moveSchedulePage (idx) {
      if (!this.isPreventClick) {
        this.isUpdater = !this.isUpdater
        let schedule = JSON.parse(JSON.stringify(this.oriScheduleList[idx]))
        schedule.isUpdater = this.isUpdater
        this.$store.commit('schedule/updateSuggestionSchedule', schedule)
        if (this.$route.path !== '/schedule/leg') {
          setTimeout(() => {
            this.$router.push('/schedule/leg')
          }, 100)
        }
      }
    },
    getSuggestionSchedules () {
      this.scheduleList = []
      this.isSlick = false
      myScheduleApi.getSuggestionSchedules().then(res => {
        this.oriScheduleList = res.data
        this.makeScheduleList(res.data)
      }).catch(e => {
        console.log(e)
      })
    },
    makeScheduleList (arr) {
      let scheduleList = []
      for (let sch of arr) {
        let obj = {
          polPortCd: sch.polPlcEnm,
          podPortCd: sch.podPlcEnm,
          info: sch.vslCd + ' ' + sch.voyNo + ' ' + sch.vslNm + (this.$ekmtcCommon.isEmpty(sch.rvsdDpoCd) ? '' : (' (' + sch.rvsdDpoCd.substring(4, 6) + '/' + sch.rvsdDpoCd.substring(6, 8) + ')'))
        }
        console.log(obj)
       scheduleList.push(obj)
      }

      if (scheduleList.length > 0) {
        this.scheduleList = scheduleList
        this.isSlick = true
        setTimeout(() => {
          this.reInit()
        }, 200)
      }
    },
    clickIndicator (idx) {
      this.$refs.slick.goTo(idx, 0)
      this.currentIdx = idx
    },
    mouseDown (event) {
      this.mX1 = parseInt(event.clientX)
    },
    mouseUp (event) {
      this.mX2 = parseInt(event.clientX)
      if (this.getDiff() < 10) {
        this.isPreventClick = false
      } else {
        this.isPreventClick = true
      }
    },
    getDiff () {
      if (this.mX1 > this.mX2) {
        return this.mX1 - this.mX2
      } else {
        return this.mX2 - this.mX1
      }
    },
    handleAfterChange (event, slick, currentSlide) {
      this.currentIdx = currentSlide
    },
    reInit () {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$nextTick(() => {
            this.$refs.slick.reSlick()
        })
    }
  }
}
</script>
