var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1100px", height: "700px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.PERS010L022.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.PERS010L022.014"))),
          ]),
          _c("div", [
            _c("span", { staticClass: "mr20" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.chkValue,
                    expression: "chkValue",
                  },
                ],
                attrs: { type: "radio", id: "all", name: "exim", value: "A" },
                domProps: { checked: _vm._q(_vm.chkValue, "A") },
                on: {
                  change: [
                    function ($event) {
                      _vm.chkValue = "A"
                    },
                    _vm.setInOutList,
                  ],
                },
              }),
              _c("label", { attrs: { for: "all" } }, [
                _c("span"),
                _vm._v(_vm._s(_vm.$t("msg.PERS010L022.002"))),
              ]),
            ]),
            _c("span", { staticClass: "mr20" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.chkValue,
                    expression: "chkValue",
                  },
                ],
                attrs: { type: "radio", id: "out", name: "exim", value: "O" },
                domProps: { checked: _vm._q(_vm.chkValue, "O") },
                on: {
                  change: [
                    function ($event) {
                      _vm.chkValue = "O"
                    },
                    _vm.setInOutList,
                  ],
                },
              }),
              _c("label", { attrs: { for: "out" } }, [
                _c("span"),
                _vm._v(_vm._s(_vm.$t("msg.PERS010L022.003"))),
              ]),
            ]),
            _c("span", { staticClass: "mr20" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.chkValue,
                    expression: "chkValue",
                  },
                ],
                attrs: { type: "radio", id: "in", name: "exim", value: "I" },
                domProps: { checked: _vm._q(_vm.chkValue, "I") },
                on: {
                  change: [
                    function ($event) {
                      _vm.chkValue = "I"
                    },
                    _vm.setInOutList,
                  ],
                },
              }),
              _c("label", { attrs: { for: "in" } }, [
                _c("span"),
                _vm._v(_vm._s(_vm.$t("msg.PERS010L022.012"))),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_col mt10 scrtable" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _vm._m(1),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.PERS010L022.004"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { rowspan: "2" } }, [_vm._v("VSL/VOY")]),
                _c("th", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.PERS010L022.006"))),
                ]),
                _c("th", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.PERS010L022.007"))),
                ]),
                _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Notice")]),
              ]),
              _c("tr", [
                _c("th", { staticClass: "border_left" }, [
                  _vm._v(_vm._s(_vm.$t("msg.PERS010L022.005"))),
                ]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.008")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.013")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.005")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.008")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.013")))]),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.etdEtaList, function (item, index) {
                  return [
                    Number(
                      _vm
                        .calDateHours(item.fstVslApoDtm, item.rvsdApoDtm)
                        .substring(1)
                    ) > 0
                      ? _c("tr", { key: index }, [
                          _c("td", [
                            _c("p", [_vm._v(_vm._s(item.bkgNo))]),
                            _c("p", [_vm._v(_vm._s(item.blNo))]),
                          ]),
                          _c("td", [
                            _c("p", [
                              _vm._v(
                                _vm._s(item.polPortNm) +
                                  "," +
                                  _vm._s(item.polCtrCd)
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(item.podPortNm) +
                                  "," +
                                  _vm._s(item.podCtrCd)
                              ),
                            ]),
                          ]),
                          _c("td", [
                            _c("p", [_vm._v(_vm._s(item.vslNm) + " ")]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(item.voyNo))]),
                          ]),
                          _c("td", [_vm._v(_vm._s(item.fstVslDpoDtm))]),
                          _c("td", [_vm._v(_vm._s(item.rvsdEtd))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.calDateHours(
                                  item.fstVslApoDtm,
                                  item.rvsdApoDtm
                                )
                              )
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(item.lstVslApoDtm))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.rvsdEta))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.calDateHours(
                                  item.lstVslApoDtm,
                                  item.rvsdEta
                                )
                              )
                            ),
                          ]),
                          item.eiCatCd === "O"
                            ? _c("td", { attrs: { colspan: "2" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "tbl_icon pdf",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPopup(
                                          "DelayNoticeOutPop",
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.PERS010L022.009"))
                                    ),
                                  ]
                                ),
                              ])
                            : item.eiCatCd === "I"
                            ? _c("td", { attrs: { colspan: "2" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "tbl_icon pdf",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPopup(
                                          "DelayNoticeInPop",
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.PERS010L022.009"))
                                    ),
                                  ]
                                ),
                              ])
                            : _c("td", { attrs: { colspan: "2" } }),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.PERS010L022.010"))),
          ]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "tbl_col scrVsltable" },
            [
              _vm._m(2),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("B/L No.")]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.011")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.004")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Kind")]),
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.PERS010L022.005"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.PERS010L022.008"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { colspan: "2" } }, [_vm._v("Notice")]),
                ]),
              ]),
              _vm._l(_vm.vslVoyList, function (item, index) {
                return _c("tbody", { key: index }, [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(item.blNo))]),
                    _c("td", [_vm._v(_vm._s(item.actShprCstEnm))]),
                    _c("td", [
                      _c("p", [
                        _vm._v(
                          _vm._s(item.polPortNm) + "," + _vm._s(item.polCtrCd)
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(item.podPortNm) + "," + _vm._s(item.podCtrCd)
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm._v(" " + _vm._s(item.bkgRollOverCatNm) + " "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(item.bfVslNm) + " " + _vm._s(item.bfVoyNo)
                        ),
                      ]),
                      _c("p", [_vm._v("POL ETD : " + _vm._s(item.bfPolEtd))]),
                      _c("p", [_vm._v("POD ETA : " + _vm._s(item.bfPodEta))]),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(item.afVslNm) + " " + _vm._s(item.afVoyNo)
                        ),
                      ]),
                      _c("p", [_vm._v("POL ETD : " + _vm._s(item.afPolEtd))]),
                      _c("p", [_vm._v("POD ETA : " + _vm._s(item.afPodEta))]),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon pdf",
                          on: {
                            click: function ($event) {
                              return _vm.openPopup("VesselChangePop", item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.PERS010L022.009")))]
                      ),
                    ]),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        { attrs: { id: "schelayerPop" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.popupParams },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { rowspan: "2" } }, [
      _vm._v("Booking No."),
      _c("br"),
      _vm._v("B/L No."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }