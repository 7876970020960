<template>
  <div class="popup_wrap" style="width:500px;">
    <!-- popup_wrap :  style="width:500px;" 참고용 / 개발시 삭제 해주 세요. . -->
    <button class="layer_close" @click="$emit('child', { type: 'close' })">close</button>
    <form id="frm">
      <div class="popup_cont" v-show="params.flag === '1'">
        <!-- popup_cont -->

        <h1 class="page_title">{{ $t('msg.ONEX070G100.001') }} - {{ params.flag }}/{{ $t('msg.ONEX070G100.002') }}</h1> <!-- 가상계좌 (고정형) 발급 , 3 단계 -->
        <div class="content_box">
          <!-- content_box -->

          <p>{{ $t('msg.ONEX070G100.003') }}</p>
          <table class="tbl_row mt5">
            <colgroup>
              <col width="150px"><col width="*">
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('art.CMATK019') }}</th> <!-- 사업자 등록번호 -->
                <td><input type="text" maxlength="10" id="bzrgNo" v-model="vtlAccInfo.bzrgNo" readonly></td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G100.004') }} </th> <!-- 사업자명 -->
                <td><input type="text" id="bsnNm" v-model="vtlAccInfo.bsnNm" readonly></td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div class="mt10 text_center">
          <a class="button gray lg" @click="$emit('child', { type: 'close' })">{{ $t('art.CMATK328') }}</a> <!-- 취소 -->
          <a class="button blue lg" @click.prevent="searchVtlInfo('1')">{{ $t('msg.ONEX070G100.006') }}</a> <!-- 다음 -->
        </div>
      </div><!-- popup_wrap // -->

      <div class="popup_cont" v-show="params.flag === '2'">
        <!-- popup_cont -->
        <h1 class="page_title">{{ $t('msg.ONEX070G100.001') }} - {{ params.flag }}/{{ $t('msg.ONEX070G100.002') }}</h1>
        <div class="content_box">
          <!-- content_box -->

          <p>{{ $t('msg.ONEX070G100.003') }}</p>

          <table class="tbl_row mt5">
            <colgroup>
              <col width="150px"><col width="*">
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX070G100.008') }}</th><!-- 은행 -->
                <td>
                  <select id="bankCode" v-model="params.bankCode">
                    <option value="004">{{ $t('msg.ONEX070G100.009') }}</option><!-- KB국민은행 -->
                  </select>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G100.012') }}</th><!-- 통화 -->
                <td>
                  <select id="currencyCode" v-model="params.currencyCode">
                    <option value="WON" v-if="won < 1">{{ $t('msg.ONEX070G100.010') }}</option><!-- 원화 -->
                    <option value="USD" v-if="usd < 1">{{ $t('msg.ONEX070G100.011') }}</option><!-- 달러 -->
                  </select>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G100.013') }}</th><!-- 예금주명 -->
                <td><input type="text" id="acctNm" v-model="params.acctNm"></td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->
        <div class="mt10 text_center">
          <a class="button gray lg" @click="$emit('child', { type: 'close' })">{{ $t('art.CMATK328') }}</a>
          <a class="button blue lg" @click.prevent="searchVtlInfo('2')">{{ $t('msg.ONEX070G100.006') }}</a>
        </div>
      </div><!-- popup_cont -->

      <div class="popup_cont" v-show="params.flag === '3'">
        <!-- popup_cont -->

        <h1 class="page_title">{{ $t('msg.ONEX070G100.001') }}- {{ params.flag }}/{{ $t('msg.ONEX070G100.002') }}</h1>
        <div class="content_box">
          <!-- content_box -->

          <p>{{ $t('msg.ONEX070G100.003') }}</p>

          <table class="tbl_row mt5">
            <colgroup>
              <col width="150px"><col width="*">
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX070G100.012') }}</th><!-- 통화 -->
                <td>{{ params.currencyCode === 'WON'? $t('msg.ONEX070G100.010') : $t('msg.ONEX070G100.011') }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G100.013') }}</th><!-- 예금주명 -->
                <td>{{ bzrg.acctNm }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G100.014') }}</th><!-- 가상계좌 -->
                <td>{{ bzrg.vrAcctNo }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G100.015') }}</th><!-- 계좌개설일자 -->
                <td>{{ bzrg.issueDtm }}</td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div class="mt10 text_center">
          <a class="button blue lg" @click="$emit('child', { type: 'close' })">{{ $t('msg.ONEX070G100.016') }}</a><!-- 완료 -->
        </div>
      </div><!-- popup_cont -->
    </form>
  </div>
</template>

<script>
import vtlAcc from '@/api/rest/settle/vtlAcc'

export default {
  name: 'BLAgentAddressPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          addrStr: ''
        }
      }
    }
  },
  data () {
    return {
      resultList: [],
      keyword: '',
      vtlAccInfo: {
        bsnNm: '',
        bzrgNo: ''
      },
      won: '',
      usd: '',
      acctNm: '',

      bzrg: {
        acctNm: '',
        vrAcctNo: '',
        issueDtm: ''
      },

      params: {
        flag: '',
        bankCode: '004',
        currencyCode: '',
        acctNm: ''
      }
    }
  },
  created () {
    // 초기 화면 세팅
    this.searchVtlInfo(this.params.flag)
  },
  methods: {
    // 가상계좌 발급 화면 단계별 조회
    searchVtlInfo (pFlag) {
      this.params.flag = pFlag
      console.log('params ==> ', this.params)

      vtlAcc.getVtlAccPopInfo(this.params).then(res => {
        const result = res.data

        console.log('=============searchVtlInfo==============')
        console.log(res)
        console.log(result)

        if (res.status === 200) {
          if (this.params.flag === '2') {
            const stepTwoOk = this.checkStepTwo()
            if (stepTwoOk) {
              this.params.flag = result.flag
            }
          } else {
              this.params.flag = result.flag
          }
        }

        if (result.info !== undefined && result.flag === '1') { // 1단계 화면
          this.vtlAccInfo.bzrgNo = result.info.bzrgNo
          this.vtlAccInfo.bsnNm = result.info.bsnNm
        }
        if (result.info !== undefined && result.flag === '2') { // 2단계 화면
          this.won = result.cnt1
          this.usd = result.cnt2

          this.params.currencyCode = this.won < 1 ? 'WON' : 'USD'
        }
        if (result.info !== undefined && result.flag === '3') { // 2단계 화면
          this.bzrg.acctNm = result.info.acctNm
          this.bzrg.vrAcctNo = result.info.vrAcctNo
          this.bzrg.issueDtm = result.info.issueDtm.substring(0, 4) + '.' + result.info.issueDtm.substring(4, 6) + '.' + result.info.issueDtm.substring(6, 8)
        }
        if (result.info !== undefined && result.flag === '4') { // 가상계좌 통화별 모두 발급 가능할 때 이동 화면

        }
      })
    },
    checkAcctNm () {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#acctNm')
      const val = elem.value

      let msg = ''
      let isOk = true

      if (val === '') {
        msg = '예금주명을 입력해주세요'
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkStepTwo () {
      const frm = document.querySelector('#frm')
      let isStepTwoOk = true

      if (!this.checkAcctNm()) {
        isStepTwoOk = false
      }

      return isStepTwoOk
    }
  }
}
</script>

<style scoped>
  .result_plc {cursor: pointer; color: #000;}
</style>
