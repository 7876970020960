var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "per_section" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.025")))]),
      _c("p", { staticClass: "schedule_btn" }, [
        _c(
          "button",
          {
            staticClass: "button sm",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.openPopup("ScheduleComparePopType01")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.026")))]
        ),
      ]),
      _c("table", { staticClass: "tbl_search" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("th", { staticStyle: { padding: "4px 5px 4px 0px" } }, [
              _vm._v(_vm._s(_vm.$t("msg.VOSD100.002"))),
            ]),
            _c(
              "td",
              [
                _c("e-auto-complete-place", {
                  attrs: {
                    "auto-focus": true,
                    id: "polPol",
                    "ctr-cd": _vm.pol.ctrCd,
                    "plc-cd": _vm.pol.plcCd,
                    "is-char": true,
                    "is-char-alert": true,
                  },
                  on: { change: _vm.changePol },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c("th", { staticStyle: { padding: "4px 5px 4px 0px" } }, [
              _vm._v(_vm._s(_vm.$t("msg.VOSD100.003"))),
            ]),
            _c(
              "td",
              [
                _c("e-auto-complete-place", {
                  attrs: {
                    id: "podPod",
                    "ctr-cd": _vm.pod.ctrCd,
                    "plc-cd": _vm.pod.plcCd,
                    "is-char": true,
                    "is-char-alert": true,
                  },
                  on: { change: _vm.changePod },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._l(_vm.items, function (item, index) {
        return _c("dl", { key: index, staticClass: "schedule" }, [
          _c("dt", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: item.chk,
                  expression: "item.chk",
                },
              ],
              attrs: {
                type: "checkbox",
                name: "schedule-chk",
                id: `schedule-chk-${item.logRno}`,
              },
              domProps: {
                checked: Array.isArray(item.chk)
                  ? _vm._i(item.chk, null) > -1
                  : item.chk,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = item.chk,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(item, "chk", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            item,
                            "chk",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(item, "chk", $$c)
                    }
                  },
                  _vm.test,
                ],
              },
            }),
            _c("label", { attrs: { for: `schedule-chk-${item.logRno}` } }, [
              _c("span", { staticClass: "offscreen" }, [
                _vm._v(_vm._s(item.logRno)),
              ]),
            ]),
            _vm._v(" " + _vm._s(item.vslNm) + " " + _vm._s(item.voyNo) + " "),
          ]),
          _c("dd", [
            _vm._v(" " + _vm._s(item.polPortNm) + " "),
            item.tsYn === "Y"
              ? _c("span", { staticClass: "arr" }, [_vm._v(">")])
              : _vm._e(),
            _vm._v(
              " " +
                _vm._s(item.tsYn === "Y" ? "(T/S) " + item.pod1PortNm : "") +
                " "
            ),
            _c("span", { staticClass: "arr" }, [_vm._v(">")]),
            _vm._v(" " + _vm._s(item.podPortNm) + " "),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "60px" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }