import { checkPermission } from './permission'

import store from '../store/index'
// 화물추적, 나의정보, 고객센터
const CargoTracking = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/trans/CargoTracking')
const EmptyLayout = () => import(/* webpackChunkName: "topmenu07_09" */'@/layout/EmptyLayout')
const VesselInfo = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/Vessel')
const MrnMsn = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/MrnMsn')
const Network = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/Network')
const VocHome = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/VocHome')
const VocReg = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/VocReg')
const VocDetail = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/VocDetail')
const FaqHome = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/faq/FaqHome')
const NoticeHome = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/NoticeHome')
const Pds = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/Pds')
const SamplePopUpList = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/sample/SamplePopUpList')
const ImptBsnesAgencInfoMain = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/ImptBsnesAgencInfoMain')
const ImptBsnesAgencInfo = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/ImptBsnesAgencInfo')
const MileageInfo = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/MyMileage')
const MyInfoMember = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/MyInfoMember')
const ReportMain = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/ReportMain')
const IntergisCfs = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/IntergisCfs')
const IntergisCfsImage = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/IntergisCfsImage')
const DemDetTariff = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/DemDetTariff')
const ExchangeRate = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/ExchangeRate')
const LocalCharge = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/LocalCharge')
const PortTerminalCy = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/PortTerminalCy')
const LoginApproval = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/LoginApproval')
const JoinMemberForm = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/JoinMemberForm')
const SearchdemurrageDetailForm = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/SearchdemurrageDetailForm')
const Check = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/Check')
const FindIdPassword = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/FindIdPassword')
const Setting = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/user/Setting')

const beforeCheck = () => (to, from, next) => {
  let permit = checkPermission(to, from)
  if (permit.isPermit) {
    next()
  } else {
    next(permit.path)
  }
}
const langFlag = store.getters['auth/serviceLang']

const routes = [
  {
    path: '/cargo-tracking',
    name: 'cargo-tracking',
    component: CargoTracking,
    meta: {
      breadcrumb: '화물추적',
      key: 'menu.MENU07.000'
    }
  },
  {
    path: '/common',
    redirect: '/common/notice',
    component: EmptyLayout,
    meta: {
      breadcrumb: '고객센터',
      key: 'menu.MENU09.000'
    },
    children: [
      {
        path: 'notice',
        name: 'notice',
        props: true,
        component: NoticeHome,
        meta: {
          breadcrumb: '공지사항',
          key: 'menu.MENU09.010'
        }
      },
      {
        path: 'network',
        name: 'network',
        component: Network,
        meta: {
          breadcrumb: 'Network',
          key: 'menu.MENU09.020'
        }
      },
      {
        path: 'pds',
        name: 'pds',
        props: true,
        component: Pds,
        meta: {
          breadcrumb: '자료실',
          key: 'menu.MENU09.030'
        }
      },
      {
        path: 'faq',
        name: 'faq',
        props: true,
        component: FaqHome,
        meta: {
          breadcrumb: 'FAQ',
          key: 'menu.MENU09.040'
        }
      },
      {
        path: 'voc',
        name: 'Voc',
        component: VocHome,
        meta: {
          breadcrumb: '고객의 소리',
          requiresAuth: true,
          key: 'menu.MENU09.122'
        }
      },
      {
        path: 'voc-reg',
        name: 'VocReg',
        component: VocReg,
        meta: {
          breadcrumb: '문의하기',
          sitemapHide: true,
          key: 'menu.MENU09.123'
        }
      },
      {
        path: 'voc-detail',
        name: 'VocDetail',
        component: VocDetail,
        props: true,
        meta: {
          breadcrumb: '고객의 소리 상세',
          sitemapHide: true
        }
      },
      {
        path: 'info',
        name: 'info',
        component: EmptyLayout,
        meta: {
          breadcrumb: '정보조회',
          key: 'menu.MENU09.050'
        },
        children: [
          {
            path: 'vessel-Info',
            name: 'vessel-Info',
            component: VesselInfo,
            meta: {
              breadcrumb: 'Vessel',
              key: 'menu.MENU09.060'
            }
          },
          {
            path: 'port-terminal-cy',
            name: 'port-terminal-cy',
            component: PortTerminalCy,
            meta: {
              breadcrumb: 'Port/Terminal/CY',
              key: 'menu.MENU09.070'
            }
          },
          {
            path: 'mrnmsn',
            name: 'mrnmsn',
            component: MrnMsn,
            props: true,
            meta: {
              breadcrumb: 'MRN/MSN/Call Sign',
              key: 'menu.MENU09.080'
            }
          },
          {
            path: 'local-charge',
            name: 'local-charge',
            component: LocalCharge,
            meta: {
              breadcrumb: 'Local Charge',
              key: 'menu.MENU09.090'
            }
          },
          {
            path: 'dem-det-tariff',
            name: 'dem-det-tariff',
            component: DemDetTariff,
            meta: {
              breadcrumb: 'DEM/DET Tariff',
              key: 'menu.MENU09.100'
            }
          },
          {
            path: 'exchange-rate',
            name: 'exchange-rate',
            component: ExchangeRate,
            meta: {
              breadcrumb: '환율',
              key: 'menu.MENU09.110'
            }
          },
          {
            path: 'intergis-cfs',
            name: 'intergis-cfs',
            component: IntergisCfs,
            meta: {
              breadcrumb: '인터지스 CFS 입고조회',
              key: 'menu.MENU09.120',
              requiresAuth: true,
              intergisLvs: ['1', '2', '3', '4']
            }
          },
          {
            path: 'intergis-cfs-image',
            name: 'intergis-cfs-image',
            component: IntergisCfsImage,
            meta: {
              breadcrumb: '인터지스 CFS 사진조회',
              key: 'menu.MENU09.121',
              requiresAuth: true,
              intergisLvs: ['1', '2', '3', '4']
            }
          }
        ]
      },
      {
        path: 'sample-popup-list',
        name: 'sample-popup-list',
        component: SamplePopUpList,
        meta: {
          breadcrumb: '팝업리스트(개발중)',
          sitemapHide: true
        }
      },
      {
        path: 'pds?messageIdSeq=CKR415&groupId=415',
        name: 'pds-form',
        component: Pds,
        meta: {
          breadcrumb: '각종 요청서 양식',
          sitemapHide: langFlag !== 'KOR'
        }
      }
    ]
  },
  {
    path: '/my-info',
    component: EmptyLayout,
    meta: {
      breadcrumb: '나의 정보',
      key: 'menu.MENU08.000'
    },
    // beforeEnter: beforeCheck(),
    children: [
      {
        path: 'login-approval',
        name: 'login-approval',
        component: LoginApproval,
        meta: {
          breadcrumb: '약관동의',
          sitemapHide: true
        }
      },
      {
        path: 'join-member-form',
        name: 'join-member-form',
        component: JoinMemberForm,
        props: true,
        meta: {
          breadcrumb: '회원정보',
          key: 'menu.MENU08.010',
          sitemapHide: true
        }
      },
      {
        path: 'searchdemurrage-detail-form',
        name: 'searchdemurrage-detail-form',
        component: SearchdemurrageDetailForm,
        meta: {
          breadcrumb: 'Freetime 요청',
          sitemapHide: true
        }
      },
      {
        path: 'check',
        name: 'check',
        component: Check,
        meta: {
          breadcrumb: 'temp',
          sitemapHide: true
        }
      },
      {
        path: 'member',
        name: 'member',
        // beforeEnter: beforeCheck(),
        component: MyInfoMember,
        meta: {
          breadcrumb: '회원정보',
          key: 'menu.MENU08.010',
          requiresAuth: true
        }
      },
      {
        path: 'report',
        component: ReportMain,
        meta: {
          breadcrumb: 'Report',
          key: 'menu.MENU08.020'
        },
        children: [
          {
            path: 'report-dashboard',
            name: 'report-dashboard',
            meta: {
              breadcrumb: 'Report Dashboard',
              key: 'menu.MENU08.110',
              requiresAuth: true
              // sitemapHide: true
            }
          },
          {
            path: 'report-list',
            name: 'report-list',
            meta: {
              breadcrumb: 'Report 조회/생성',
              key: 'menu.MENU08.090',
              requiresAuth: true
              // sitemapHide: true
            }
          },
          {
            path: 'report-info',
            name: 'report-info',
            meta: {
              breadcrumb: 'Report 구독정보',
              key: 'menu.MENU08.100',
              requiresAuth: true
              // sitemapHide: true
            }
          }
        ]
      },
      // {
      //   path: 'mileage',
      //   name: 'mileage',
      //   // beforeEnter: beforeCheck(),
      //   component: MileageInfo,
      //   meta: {
      //     breadcrumb: '마일리지',
      //     key: 'menu.MENU08.030',
      //     requiresAuth: true
      //   }
      // },
      {
        path: 'import-business-agency-information-main',
        name: 'import-business-agency-information-main',
        component: ImptBsnesAgencInfoMain,
        beforeEnter: beforeCheck(),
        meta: {
          breadcrumb: '업무 대행정보',
          key: 'menu.MENU08.040',
          serviceCtrCds: ['KR']
        }
      },
      {
        path: 'setting',
        name: 'setting',
        beforeEnter: beforeCheck(),
        meta: {
          breadcrumb: '설정',
          key: 'menu.MENU08.050'
        },
        component: Setting,
        children: [
          {
            path: 'profile',
            name: 'profile',
            meta: {
              breadcrumb: 'Profile',
              key: 'menu.MENU08.060'
            }
          },
          {
            path: 'sch-subs',
            name: 'sch-subs',
            meta: {
              breadcrumb: '스케줄구독',
              key: 'menu.MENU08.080'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/find-id-password',
    name: 'FindIdPassword',
    component: FindIdPassword,
    meta: {
      breadcrumb: 'ID/Password'
    }
  }
]

export default routes
