var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c(
        "div",
        { staticClass: "popup_cont" },
        [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.001"))),
          ]),
          _c(
            "div",
            { staticClass: "content_box" },
            [
              _c("div", { staticClass: "flex_box" }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(
                    _vm._s(_vm.vesselInfoDetail.vslNm) +
                      " " +
                      _vm._s(_vm.vesselInfoDetail.voyNoDisp)
                  ),
                ]),
                _c("span", { staticClass: "ml_auto cal_btn" }, [
                  _vm.vslDelayReasonBtnFlag
                    ? _c(
                        "a",
                        {
                          staticClass: "button sm",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopup("ChangeNoticePop")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.017")))]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("table", { staticClass: "tbl_row" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.002")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.vslType))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.003")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.imoNo))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.004")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.vslOwner))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.005")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.clsgNo))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.006")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeDatePattern(
                            _vm.vesselInfoDetail.dlyDt,
                            "."
                          )
                        )
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.007")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.vesselInfoDetail.ntltCtrCd
                            ? _vm.vesselInfoDetail.ntltCtrCd +
                                " (" +
                                _vm.vesselInfoDetail.ctrEnm +
                                ")"
                            : _vm.vesselInfoDetail.ctrEnm
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.008")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.clsfiNm))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.009")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.breadth))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.010")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.vesselInfoDetail.dwgtTons,
                            { isComma: true }
                          )
                        )
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.011")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.vesselInfoDetail.grsWt,
                            { isComma: true }
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.012")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.nmnlTeu))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.013")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.vesselInfoDetail.netWt,
                            { isComma: true }
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.014")))]),
                    _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.loaLen))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.015")))]),
                    _c("td", [
                      _vm.$ekmtcCommon.isNotEmpty(
                        _vm.vesselInfoDetail.vslCertiFileNm
                      )
                        ? _c(
                            "a",
                            {
                              staticClass: "button sm",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.certiFileDownload(
                                    _vm.vesselInfoDetail.atchFileRno
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.SCHD010P040.016")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
              _vm.parentInfo.vesselSeq === 1
                ? _c("table", { staticClass: "tbl_row mt10" }, [
                    _vm._m(1),
                    _vm.parentInfo.bound === "O"
                      ? _c("tbody", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.018"))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.vesselInfoDetail.mrnNo)),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.019"))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.vesselInfoDetail.lstMsnNo)),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.020"))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.vesselInfoDetail.polTrmlCd)),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.021"))),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.vesselInfoDetail.trmlVslCd) +
                                  " / " +
                                  _vm._s(_vm.vesselInfoDetail.trmlVoyNo)
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.parentInfo.bound === "I"
                      ? _c("tbody", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.018"))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.deadlineInfoDetail.mrnNo)),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.019"))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.deadlineInfoDetail.msnNo)),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONIM010P020.005"))),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeDatePattern(
                                    _vm.deadlineInfoDetail.anSendDt,
                                    "-"
                                  )
                                )
                              ),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONIM010P020.006"))),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeDatePattern(
                                    _vm.deadlineInfoDetail.mfSendDt,
                                    "-"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.parentInfo.vesselSeq === 1 && _vm.parentInfo.bound === "O"
                ? _c("close-info", {
                    ref: "closeInfo",
                    attrs: {
                      "parent-info": _vm.parentInfo,
                      "vessel-info": _vm.vesselInfoDetail,
                    },
                  })
                : _vm._e(),
              _c("table", { staticClass: "tbl_col font_sm mt10" }, [
                _vm._m(2),
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { rowspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.032"))),
                    ]),
                    _c("th", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.033"))),
                    ]),
                    _c("th", { attrs: { rowspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.034"))),
                    ]),
                    _c("th", { attrs: { rowspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.035"))),
                    ]),
                    _c("th", { attrs: { colspan: "3" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.036"))),
                    ]),
                    _c("th", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.037"))),
                    ]),
                    _c("th", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.038"))),
                    ]),
                    _c("th", { attrs: { rowspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.039"))),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "border_left" }, [
                      _vm._v(_vm._s(_vm.$t("msg.SCHD010P040.040"))),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.041")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.042")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.043")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.044")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.042")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.044")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.045")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.044")))]),
                  ]),
                ]),
                _vm.vesselScheduleInfo.length > 0
                  ? _c(
                      "tbody",
                      [
                        _vm._l(_vm.vesselScheduleInfo, function (item) {
                          return [
                            _c("tr", { key: item.prfmApoDt + item.prfmApoTm }, [
                              _c(
                                "td",
                                {
                                  attrs: { rowspan: "2" },
                                  on: {
                                    mouseover: _vm.showTooltip,
                                    mouseout: _vm.hideTooltip,
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(item.portCd) + " "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tooltip_wrap short position_absolute ts_tt",
                                      style: {
                                        display: "none",
                                        width: "200px",
                                        height: "50px",
                                        left: "200",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "cont",
                                          staticStyle: { padding: "15px" },
                                        },
                                        [
                                          _c("ul", [
                                            _c("li", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.portNm) +
                                                  " , " +
                                                  _vm._s(item.ctrCd) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _vm._v(" " + _vm._s(item.trmlCd) + " "),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _vm._v(_vm._s(item.trmlCd)),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _vm._v(_vm._s(item.apoBthNo)),
                              ]),
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _vm._v(_vm._s(item.skpYn)),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      item.prfmApoDt,
                                      "-"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.prfmApoDay))]),
                              _c("td", [_vm._v(_vm._s(item.prfmApoTm))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      item.rvsdApoDt,
                                      "-"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.rvsdApoTm))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      item.apoActlDt,
                                      "-"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.apoActlTm))]),
                              _c("td", [_vm._v(_vm._s(item.etaDiff))]),
                            ]),
                            _c("tr", { key: item.prfmDpoDt + item.prfmDpoTm }, [
                              _c("td", { staticClass: "border_left" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      item.prfmDpoDt,
                                      "-"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.prfmDpoDay))]),
                              _c("td", [_vm._v(_vm._s(item.prfmDpoTm))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      item.rvsdDpoDt,
                                      "-"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.rvsdDpoTm))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      item.dpoUbthDt,
                                      "-"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.dpoUbthTm))]),
                              _c("td", [_vm._v(_vm._s(item.etdDiff))]),
                            ]),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm.isVslLocation
            ? _c("div", { staticClass: "content_box" }, [
                _c(
                  "div",
                  { staticClass: "map_wrap", staticStyle: { height: "400px" } },
                  [
                    _c("location-info", {
                      attrs: { "parent-info": _vm.vslInfo },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("msg.SCHD010P040.046")) + " ")]
            ),
          ]),
          _c(
            "win-layer-pop",
            {
              staticClass: "pdf_download_pop",
              staticStyle: { "z-index": "99999" },
            },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.popupParams },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }