<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
  >
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <l-control>
      <img src="../../assets/images/schedule/kmtc_logo.gif" style="width: 150px;">
    </l-control>
    <l-control
      v-if="parentInfo.catCd === 'V'"
      :position="'bottomleft'"
    >
      <div>{{ $t('msg.SCHD010P040.047') }}:{{ vslUpdateTime }}</div><!-- 최근 업데이트 시간 -->
    </l-control>
    <v-rotated-marker
      :lat-lng="makerLatLng"
      :icon="icon"
      :rotation-angle="rotationAngle"
    >
      <l-tooltip v-if="parentInfo.catCd === 'V'">
        {{ parentInfo.vslNm }} / {{ parentInfo.voyNo }}
      </l-tooltip>
    </v-rotated-marker>
  </l-map>
</template>

<script>

import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LControl, LTooltip } from 'vue2-leaflet'
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'LocationInfo',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LTooltip,
    'v-rotated-marker': Vue2LeafletRotatedMarker
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          // 업무 구분 코드(V=선박위치, P=항구위치)
          catCd: 'V',
          // 선박위치 관련 데이터
          vslCd: '',
          voyNo: '',
          vslNm: '',
          // 항구위치 관련 데이터
          portCd: '',
          // 자체 위,경도 파라미터 사용시
          lttd: '',
          lngtd: ''
        }
      }
     }
  },
  data: function () {
    return {
      // 지도 확대정도
      zoom: this.parentInfo.catCd === 'V' ? 5 : 10,
      // 지도 중앙 위/경도(default - 서울)
      center: latLng(37.564214, 127.001699),
      // 지도 호출 url
      url: 'https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=DiDJ0YR81yQKSEioZPoD',
      // url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      // 우측 하단 표시
      attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
      // attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      // 아이콘 표시 위/경도(default - 서울)
      makerLatLng: latLng(37.564214, 127.001699),
      // 지도 옵션
      mapOptions: {
        zoomSnap: 0.5,
        minZoom: 3,
        maxZoom: this.parentInfo.catCd === 'V' ? 5 : 25
      },
      // 아이콘 옵션
      icon: icon({
        iconUrl: this.parentInfo.catCd === 'V' ? '/img/EKMTC_SHIP_2.png' : '/img/marker_red.gif',
        iconSize: this.parentInfo.catCd === 'V' ? [20, 20] : [30, 30],
        iconAnchor: [20, 15]
      }),
      // 선박 위치 수신일자
      vslUpdateTime: '',
      rotationAngle: 0
    }
  },
  created () {
    // 선박 실시간 위치 지도에 표시
    this.getLocationToMap(this.$props.parentInfo)
  },
  methods: {
    async getLocationToMap (data) {
      const catCd = data.catCd

      if (catCd === 'V') {
        // 선박 위치 API 호출
        await vslScheduleInfo.getVslLocationInfo(data)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              const resData = response.data

              this.center = latLng(resData.lttd, resData.lngtd)
              this.makerLatLng = latLng(resData.lttd, resData.lngtd)
              this.vslUpdateTime = this.$ekmtcCommon.changeDatePattern(resData.aisRecvDtm, '-')
              this.rotationAngle = this.$ekmtcCommon.isEmpty(resData.truHdg) || Number(resData.truHdg) === 511 ? 0 : Number(resData.truHdg)
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else if (catCd === 'P') {
        // 항구 위치 API 호출
        await vslScheduleInfo.getPortLocationInfo(data)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              const resData = response.data

              this.center = latLng(resData.lttd, resData.lngtd)
              this.makerLatLng = latLng(resData.lttd, resData.lngtd)
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else if (catCd === 'S') {
        let lttd = isNaN(Number(this.parentInfo.lttd)) ? 37.564214 : this.parentInfo.lttd
        let lngtd = isNaN(Number(this.parentInfo.lngtd)) ? 127.001699 : this.parentInfo.lngtd
        this.center = latLng(lttd, lngtd)
        this.makerLatLng = latLng(lttd, lngtd)
      }
    }
  }

}
</script>
