<template>
  <div class="popup_wrap" style="width:800px;">
    <!-- 팝업사이즈 참고 : 800*600  popup_wrap -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="frm_bldo_payment">
      <div class="popup_cont">
        <!-- popup_cont -->

        <h1 class="page_title">{{ $t('msg.ONIM050G010.001') }}</h1><!--D/O 발행 & B/L 현황 -->

        <win-layer-pop class="bldo_req_alarm_pop" style="z-index:99999;">
          <component
            v-if="customComponent"
            :is="customComponent"
            @closeAlarmPop="closePopup"
            :parent-info="popupParams"
          />
        </win-layer-pop>

        <div class="content_box">
          <!-- content_box -->
          <table class="tbl_search">
            <colgroup>
              <col width="100px">
              <col width="200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONIM050G010.002') }}</th><!-- B/L No. -->
                <td>
                  <!-- <input type="text" class="wid150" value="B/L No."> -->
                  <input
                    v-model="params.blNo"
                    id="ipt_blNo"
                    type="text"
                    @keyup.enter="getSurrenderDoDetail()"
                    @keyup="checkBlNo()"
                  >
                  <input type="text" id="_temp" style="display:none;" /><!-- Enter 클릭시 submit 방지용 -->
                </td>
                <td class="text_right">
                  <a
                    class="button blue sh"
                    href="#"
                    @click.prevent="getSurrenderDoDetail()"
                  >{{ $t('msg.ONIM050G010.003') }}</a><!-- 검색 -->
                </td>
              </tr>
            </tbody>
          </table>
        </div> <!-- content_box // -->

        <div class="content_box" v-if="auth.serviceLang === 'CHN'">
          <!-- content_box -->
          <table class="tbl_col">
            <colgroup>
              <col width="15%">
              <col width="15%">
              <col width="20%">
              <col width="20%">
              <col width="15%">
              <col width="15%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.ONIM050G010.004') }}</th><!-- B/L 상태 -->
                <th>{{ $t('msg.ONIM050G010.035') }}</th><!-- Onboard Date  -->
                <th>{{ $t('msg.ONIM050G010.005') }}</th><!-- 운임 납부 -->
                <th>{{ blDoPayment !== undefined ? (blDoPayment.ofcYn === 'Y' ? $t('msg.ONIM050G010.006') : $t('msg.ONIM050G010.007')) : $t('msg.ONIM050G010.007') }}</th><!-- OverFreetime Charge or Demurrage -->
                <th>{{ $t('msg.ONIM050G010.008') }}</th><!-- D/O 신청여부 -->
                <th>Agent</th><!-- Agent-->
              </tr>
            </thead>
            <tbody v-if="blDoPayment !== undefined && $ekmtcCommon.isNotEmpty(blDoPayment.blSts)">
              <tr>
                <td :style="{ color: blDoPayment.blStsNmRed ? 'red' : '' }">{{ blDoPayment.blStsNm }}</td>
                <td :style="{ color: blDoPayment.obrdDtRed ? 'red' : '' }">{{ blDoPayment.obrdDt }}</td>
                <td :style="{ color: blDoPayment.frStsNmRed ? 'red' : '' }">{{ blDoPayment.frStsNm }}</td>
                <td :style="{ color: blDoPayment.demStsNmRed ? 'red' : '' }">{{ blDoPayment.demStsNm }}</td>
                <td :style="{ color: blDoPayment.doStsNmRed ? 'red' : '' }">{{ blDoPayment.doStsNm }}</td>
                <td :style="{ color: blDoPayment.AgentNmRed ? 'red' : '' }">{{ blDoPayment.agent }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">{{ $t('msg.ONIM050G010.009') }}</td><!-- 등록된 내용이 없습니다. -->
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->
        <div class="content_box" v-else>
          <!-- content_box -->
          <table class="tbl_col">
            <colgroup>
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.ONIM050G010.004') }}</th><!-- B/L 상태 -->
                <th>{{ $t('msg.ONIM050G010.035') }}</th><!-- Onboard Date  -->
                <th>{{ $t('msg.ONIM050G010.005') }}</th><!-- 운임 납부 -->
                <th>{{ blDoPayment !== undefined ? (blDoPayment.ofcYn === 'Y' ? $t('msg.ONIM050G010.006') : $t('msg.ONIM050G010.007')) : $t('msg.ONIM050G010.007') }}</th><!-- OverFreetime Charge or Demurrage -->
                <th>{{ $t('msg.ONIM050G010.008') }}</th><!-- D/O 신청여부 -->
              </tr>
            </thead>
            <tbody v-if="blDoPayment !== undefined && $ekmtcCommon.isNotEmpty(blDoPayment.blSts)">
              <tr>
                <td :style="{ color: blDoPayment.blStsNmRed ? 'red' : '' }">{{ blDoPayment.blStsNm }}</td>
                <td :style="{ color: blDoPayment.obrdDtRed ? 'red' : '' }">{{ blDoPayment.obrdDt }}</td>
                <td :style="{ color: blDoPayment.frStsNmRed ? 'red' : '' }">{{ blDoPayment.frStsNm }}</td>
                <td :style="{ color: blDoPayment.demStsNmRed ? 'red' : '' }">{{ blDoPayment.demStsNm }}</td>
                <td :style="{ color: blDoPayment.doStsNmRed ? 'red' : '' }">{{ blDoPayment.doStsNm }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">{{ $t('msg.ONIM050G010.009') }}</td><!-- 등록된 내용이 없습니다. -->
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div v-show="doIssueCtrYn === 'Y'" class="flex_box mt15">
          <a class="button blue lg mr5"
             :class="{ disabled: doIssueYn === 'Y' }"
             style="margin-left: auto;"
             @click.prevent="goDoIssue()"
          >{{ $t('msg.MYIN050T010.084') }}</a>
        </div>
        <!-- <div
          v-if="blDoPayment !== undefined && (!auth.userId || memberDetail.userCtrCd === 'KR')"
          class="mt10 text_center"
        >
          <a
            class="button blue lg"
            href="#"
            @click.prevent=" reqAlarm()"
          >{{ $t('msg.ONIM050G010.010') }}</a>
        </div> -->
        <!-- 선적지 B/L 발급 문의 및 알림 -->
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>

import { rootComputed } from '@/store/helpers'
import blDoPayment from '@/api/rest/trans/blDoPayment'

export default {
  name: 'BlDoPaymentPop',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'bl-do-alarm-pop': () => import('@/pages/trans/popup/BlDoAlarmPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          isValidCheck: true
        }
      }
    }
  },
  computed: {
    ...rootComputed
  },
  data: function () {
    return {
      customComponent: null,
      params: {
        blNo: this.parentInfo.blNo
      },
      blDoPayment: undefined,
      // 팝업을 띄우기 위해 필요한 데이터
      popupParams: {},
      doIssueYn: 'N',
      doIssueCtrYn: 'N'
    }
  },
  created () {
    // console.log('this.auth')
    // console.log(this.auth)
    // console.log('this.memberDetail')
    // console.log(this.memberDetail)
    // console.log('this.selectedProfile')
    // console.log(this.selectedProfile)
  },
  mounted () {
    if (this.params.blNo !== undefined) {
      this.getSurrenderDoDetail()
    }
  },
  methods: {
    async getSurrenderDoDetail () {
      if (!this.checkBlNo()) return
      let blNo = this.params.blNo.replace('KMTC', '')
      await blDoPayment.surrenderDoDetail(blNo)
        .then((response) => {
          const resp = response.data
          const blDoPayment = resp.blDoPayment

          this.blDoPayment = undefined

          if (blDoPayment !== undefined) {
            this.blDoPayment = blDoPayment

            this.blDoPayment.blStsNmRed = false
            if (['A5', 'A6', 'A7', 'A8'].includes(this.blDoPayment.blSts)) this.blDoPayment.blStsNmRed = true
            this.blDoPayment.blStsNm = 'N/A'
            if (this.blDoPayment.blSts === 'A1') this.blDoPayment.blStsNm = this.$t('msg.ONIM050G010.011') // O.B/L 접수
            if (this.blDoPayment.blSts === 'A2') this.blDoPayment.blStsNm = this.$t('msg.ONIM010T010.016') // Bank L/G 접수
            if (this.blDoPayment.blSts === 'A3') this.blDoPayment.blStsNm = this.$t('msg.ONIM050G010.013') // SURENDER B/L
            if (this.blDoPayment.blSts === 'A4') this.blDoPayment.blStsNm = this.$t('msg.ONIM050G010.014') // WAYBILL
            if (this.blDoPayment.blSts === 'A5') this.blDoPayment.blStsNm = this.$t('msg.ONIM050G010.015') // O.B/L 미접수
            if (this.blDoPayment.blSts === 'A6') this.blDoPayment.blStsNm = this.$t('msg.ONIM050G010.015') // O.B/L 미접수
            if (this.blDoPayment.blSts === 'A7') this.blDoPayment.blStsNm = this.$t('msg.ONIM050G010.016') // WAY BILL 미발행
            if (this.blDoPayment.blSts === 'A8') this.blDoPayment.blStsNm = this.$t('msg.ONIM050G010.017') // O.B/L, SUR 미발행

            this.blDoPayment.frStsNmRed = false
            if (['01', '02', '03', '04', '05', '14', '15', '16'].includes(this.blDoPayment.frSts)) this.blDoPayment.frStsNmRed = true
            this.blDoPayment.frStsNm = 'N/A'
            if (this.blDoPayment.frSts === '01') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.018') // 미정산 (영수)
            if (this.blDoPayment.frSts === '02') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.019') // 미정산 (개별청구)
            if (this.blDoPayment.frSts === '03') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.020') // 미정산 (월말청구)
            if (this.blDoPayment.frSts === '04') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.021') // 외상한도 초과 (개별청구)
            if (this.blDoPayment.frSts === '05') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.022') // 외상한도 초과 (월말청구)
            if (this.blDoPayment.frSts === '11') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.023') // 정산완료 (영수)
            if (this.blDoPayment.frSts === '12') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.024') // 정산완료 (개별청구)
            if (this.blDoPayment.frSts === '13') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.025') // 정산완료 (월말청구)
            if (this.blDoPayment.frSts === '14') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.026') // 운임미확정(영수)
            if (this.blDoPayment.frSts === '15') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.027') // 운임미확정(개별청구)
            if (this.blDoPayment.frSts === '16') this.blDoPayment.frStsNm = this.$t('msg.ONIM050G010.028') // 운임미확정(월말청구)

            this.blDoPayment.demStsNmRed = false
            this.blDoPayment.demStsNm = this.$t('msg.ONIM050G010.029') // 미발생
            if (this.blDoPayment.demSts === 'Y') {
              this.blDoPayment.demStsNmRed = true
              this.blDoPayment.demStsNm = this.$t('msg.ONIM050G010.030', { ovrDds: this.blDoPayment.ovrDds }) // 발생 ({ovrDds}일 경과)
            }

            this.blDoPayment.doStsNmRed = false
            this.blDoPayment.doStsNm = 'N/A'
            if (['4', '5', '6', '7', '8'].includes(this.blDoPayment.doStsCd)) {
              this.blDoPayment.doStsNmRed = true
              this.blDoPayment.doStsNm = this.$t('msg.ONIM050G010.037') // D/O 미발행
            }
            if (['3', '9'].includes(this.blDoPayment.doStsCd)) this.blDoPayment.doStsNm = this.$t('msg.ONIM050G010.032') // D/O 발행요청
            if (['1', '2'].includes(this.blDoPayment.doStsCd)) this.blDoPayment.doStsNm = this.$t('msg.ONIM050G010.033') // D/O 발행

            this.blDoPayment.agentNmRed = false
            this.blDoPayment.obrdDtRed = false

            // D/O 발행 이동 상태 체크
            if (['1', '2', '3', '9'].includes(this.blDoPayment.doStsCd)) {
              this.doIssueYn = 'Y'
            }

            // D/O 발행 버튼 국가 체크
            // if (['ID', 'JP', 'VN', 'TH', 'MY'].indexOf(this.blDoPayment.podCtrCd) > -1) {
            //   this.doIssueCtrYn = 'Y'
            // } else {
            //   this.doIssueCtrYn = 'N'
            // }

            //D/O 발행 버튼 전지역 확대 240814
            this.doIssueCtrYn = 'Y'
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // 'B/L No.' check
    checkBlNo (frm) {
      let isValidCheck = this.parentInfo.isValidCheck !== false
      this.parentInfo.isValidCheck = true
      if (frm === undefined) {
        frm = document.querySelector('#frm_bldo_payment')
      }

      const elemBlNo = frm.querySelector('#ipt_blNo')
      const valBlNo = this.params.blNo
      let isOk = true
      if (valBlNo === '' && isValidCheck) {
        let msg = this.$t('msg.ONIM050G010.034') // B/L No.를 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elemBlNo, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemBlNo)
      }

      return isOk
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.bldo_req_alarm_pop')
      }
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.bldo_req_alarm_pop')
    },
    closePopup (obj) {
      if (obj !== undefined && obj.surremailyn === 'Y') {
        this.blDoPayment.surremailyn = obj.surremailyn
      }

      this.customComponent = null
      this.$ekmtcCommon.layerClose('.bldo_req_alarm_pop')
    },
    reqAlarm () {
      this.popupParams = {
        blNo: this.params.blNo,
        blDoPayment: this.blDoPayment
      }

      this.openPopup('bl-do-alarm-pop')
    },
    async goDoIssue () {
      // 정산/발행(D/O 발행) 으로 이동할 때, 로그인 창과 z-index 비교해서 값 세팅
      $('.main_inquiry_pop').css('z-index', '999')
      $('.quick_section').css('z-index', '111')

      const quickMain = {
        from: 'quickMainDO',
        blNo: this.params.blNo
      }

      sessionStorage.setItem('quickMain', JSON.stringify(quickMain))

      // await this.$store.commit('settle/updateQuickMain', { from: 'quickMainDO', blNo: this.params.blNo })

      // D/O 발행화면으로 이동
      await this.$router.push({
        name: 'calcIssueMain',
        params: { quickMain }
      }).catch(() => {})
    }
  }
}
</script>
