import Send from '../../user.client.js'
const mode = process.env.VUE_APP_MODE
const addUrl = '/user'
export default {
  getManagerInfo () {
    return Send({
      url: addUrl + '/manager',
      method: 'get',
      params: {}
    })
  },
  getSpecialContainerManagerInfo () {
    return Send({
      url: addUrl + '/manager/special-sales',
      method: 'get',
      params: {}
    })
  }
}
