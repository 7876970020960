var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "per_section" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.025")))]),
      _vm._v(" "),
      _c("p", { staticClass: "schedule_btn" }, [
        _c(
          "button",
          {
            staticClass: "button sm",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.openPopup("ScheduleComparePopType01")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.026")))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "win-layer-pop",
        { staticClass: "sample_popup_list" },
        [
          _vm.custComponent
            ? _c(_vm.custComponent, {
                tag: "component",
                attrs: { "select-data": _vm.selectData },
                on: {
                  "update:selectData": function ($event) {
                    _vm.selectData = $event
                  },
                  "update:select-data": function ($event) {
                    _vm.selectData = $event
                  },
                  close: _vm.closePopup,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._l(_vm.items, function (item, index) {
        return _c("dl", { key: index, staticClass: "schedule" }, [
          _c("dt", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: item.chk,
                  expression: "item.chk",
                },
              ],
              attrs: {
                type: "checkbox",
                name: "schedule-chk",
                id: `schedule-chk-${item.logRno}`,
              },
              domProps: {
                checked: Array.isArray(item.chk)
                  ? _vm._i(item.chk, null) > -1
                  : item.chk,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = item.chk,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(item, "chk", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            item,
                            "chk",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(item, "chk", $$c)
                    }
                  },
                  _vm.test,
                ],
              },
            }),
            _c("label", { attrs: { for: `schedule-chk-${item.logRno}` } }, [
              _c("span", { staticClass: "offscreen" }, [
                _vm._v(_vm._s(item.logRno)),
              ]),
            ]),
            _vm._v(" " + _vm._s(item.vslNm) + " " + _vm._s(item.voyNo) + " "),
          ]),
          _c("dd", [
            _vm._v(_vm._s(item.polPortNm)),
            _c("span", { staticClass: "arr" }, [_vm._v(">")]),
            _vm._v(_vm._s(item.polCtrNm)),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }