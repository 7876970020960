var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      style:
        _vm.scheduleData.length === 3
          ? "width:1200px; height: 600px;"
          : "width:800px; height: 600px;",
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("child", { type: "close" })
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.PERS010L020.026"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm.scheduleData.length === 3
              ? _c("colgroup", [
                  _c("col", { attrs: { width: "150px" } }),
                  _c("col", { attrs: { width: "350px" } }),
                  _c("col", { attrs: { width: "350px" } }),
                  _c("col", { attrs: { width: "350px" } }),
                ])
              : _c("colgroup", [
                  _c("col", { attrs: { width: "200px" } }),
                  _c("col", { attrs: { width: "350px" } }),
                  _c("col", { attrs: { width: "350px" } }),
                ]),
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONGO080G010.011")))]),
                  _vm._v(" "),
                  _vm.infoData.titleInfo.length > 0
                    ? _vm._l(_vm.infoData.titleInfo, function (item, index) {
                        return _c("th", { key: index }, [
                          _vm._v(_vm._s(item.vslNm) + " " + _vm._s(item.voyNo)),
                        ])
                      })
                    : _vm._l(_vm.scheduleData, function (item) {
                        return _c("th", { key: item.logRno })
                      }),
                ],
                2
              ),
            ]),
            _c("tbody", [
              _c(
                "tr",
                [
                  _c("td", { staticClass: "text_left valign_top" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.056")))]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("msg.VOSD100_M4.011"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("VGM " + _vm._s(_vm.$t("msg.CSBL100_M1.017"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("CFS " + _vm._s(_vm.$t("msg.VOSD100_M4.010"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "EDI " + _vm._s(_vm.$t("msg.ADD20220221.020")) + " "
                      ),
                    ]),
                  ]),
                  _vm.infoData.deadLineInfo.length > 0
                    ? _vm._l(_vm.infoData.deadLineInfo, function (v, index) {
                        return _c(
                          "td",
                          { key: index, staticClass: "text_left valign_top" },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: v.bound === "O",
                                    expression: "v.bound === 'O'",
                                  },
                                ],
                              },
                              [
                                _c("p", [_vm._v(_vm._s(v.bkgDocCls))]),
                                _c("p", [_vm._v(_vm._s(v.bkgCgoCls))]),
                                _c("p", [_vm._v(_vm._s(v.bkgDocCls))]),
                                _c("p", [_vm._v(_vm._s(v.cfsCls))]),
                                _c("p", [_vm._v(_vm._s(v.bkgMfCls))]),
                              ]
                            ),
                          ]
                        )
                      })
                    : _vm._l(_vm.scheduleData, function (item) {
                        return _c("td", { key: item.logRno })
                      }),
                ],
                2
              ),
              _c(
                "tr",
                [
                  _c(
                    "td",
                    { staticClass: "text_left valign_top" },
                    [
                      _c("P", { staticClass: "font_medium" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBL100_P1.005")) + " "),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("- ETA")]),
                      _c("p", [_vm._v("- ETD")]),
                      _c("p", [_vm._v("- Terminal")]),
                    ],
                    1
                  ),
                  _vm.infoData.startInfo.length > 0
                    ? _vm._l(_vm.infoData.startInfo, function (v, index) {
                        return _c(
                          "td",
                          { key: index, staticClass: "text_left valign_top" },
                          [
                            _c("P", { staticClass: "font_medium" }, [
                              _vm._v(_vm._s(v.polNm)),
                            ]),
                            _c("p", [_vm._v(_vm._s(v.polEtbDT))]),
                            _c("p", [_vm._v(_vm._s(v.etdDT))]),
                            _c("p", [_vm._v(_vm._s(v.polTml))]),
                          ],
                          1
                        )
                      })
                    : _vm._l(_vm.scheduleData, function (item) {
                        return _c("td", { key: item.logRno }, [_vm._v("-")])
                      }),
                ],
                2
              ),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.maxDegree > 1,
                      expression: "maxDegree > 1",
                    },
                  ],
                },
                [
                  _c(
                    "td",
                    { staticClass: "text_left valign_top" },
                    [
                      _c("P", [
                        _vm._v("T/S (1"),
                        _c("sup", [_vm._v("ST")]),
                        _vm._v(")"),
                      ]),
                      _c("p", [_vm._v("- ETA")]),
                      _vm._v(" "),
                      _c("p", [_vm._v("- ETD")]),
                      _vm._v(" "),
                      _c("p", [_vm._v("- Terminal")]),
                    ],
                    1
                  ),
                  _vm._l(_vm.infoData.ts01Info, function (v, index) {
                    return [
                      _c(
                        "td",
                        {
                          key: index,
                          class:
                            _vm.strTrim(v.podNm) !== ""
                              ? "text_left valign_top"
                              : "",
                        },
                        [
                          _vm.strTrim(v.podNm) !== ""
                            ? [
                                _c("P", {}, [_vm._v(_vm._s(v.podNm))]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.strTrim(v.polEtaDT) === ""
                                        ? "-"
                                        : v.polEtaDT
                                    )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.strTrim(v.polEtbDT) === ""
                                        ? "-"
                                        : v.polEtbDT
                                    )
                                  ),
                                ]),
                                _c("p", [_vm._v(_vm._s(v.polTml))]),
                              ]
                            : [_vm._v("-")],
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.maxDegree > 2,
                      expression: "maxDegree > 2",
                    },
                  ],
                },
                [
                  _c(
                    "td",
                    { staticClass: "text_left valign_top" },
                    [
                      _c("P", [
                        _vm._v("T/S (2"),
                        _c("sup", [_vm._v("ND")]),
                        _vm._v(")"),
                      ]),
                      _c("p", [_vm._v("- ETA")]),
                      _c("p", [_vm._v("- ETD")]),
                      _c("p", [_vm._v("- Terminal")]),
                    ],
                    1
                  ),
                  _vm._l(_vm.infoData.ts02Info, function (v, index) {
                    return [
                      _c(
                        "td",
                        {
                          key: index,
                          class:
                            _vm.strTrim(v.podNm) !== ""
                              ? "text_left valign_top"
                              : "",
                        },
                        [
                          _vm.strTrim(v.podNm) !== ""
                            ? [
                                _c("P", [_vm._v(_vm._s(v.podNm))]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.strTrim(v.polEtaDT) === ""
                                        ? "-"
                                        : v.polEtaDT
                                    )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.strTrim(v.polEtbDT) === ""
                                        ? "-"
                                        : v.polEtbDT
                                    )
                                  ),
                                ]),
                                _c("p", [_vm._v(_vm._s(v.polTml))]),
                              ]
                            : [_vm._v("-")],
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.maxDegree > 3,
                      expression: "maxDegree > 3",
                    },
                  ],
                },
                [
                  _c(
                    "td",
                    { staticClass: "text_left valign_top" },
                    [
                      _c("P", [
                        _vm._v("T/S (3"),
                        _c("sup", [_vm._v("RD")]),
                        _vm._v(")"),
                      ]),
                      _c("p", [_vm._v("- ETA")]),
                      _c("p", [_vm._v("- ETD")]),
                      _c("p", [_vm._v("- Terminal")]),
                    ],
                    1
                  ),
                  _vm._l(_vm.infoData.ts03Info, function (v, index) {
                    return [
                      _c(
                        "td",
                        {
                          key: index,
                          class:
                            _vm.strTrim(v.podNm) !== ""
                              ? "text_left valign_top"
                              : "",
                        },
                        [
                          _vm.strTrim(v.podNm) !== ""
                            ? [
                                _c("P", [_vm._v(_vm._s(v.podNm))]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.strTrim(v.polEtaDT) === ""
                                        ? "-"
                                        : v.polEtaDT
                                    )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.strTrim(v.polEtbDT) === ""
                                        ? "-"
                                        : v.polEtbDT
                                    )
                                  ),
                                ]),
                                _c("p", [_vm._v(_vm._s(v.polTml))]),
                              ]
                            : [_vm._v("-")],
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "tr",
                [
                  _c(
                    "td",
                    { staticClass: "text_left valign_top" },
                    [
                      _c("P", { staticClass: "font_medium" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBL100_P1.006"))),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("- ETA")]),
                      _c("p", [_vm._v("- Terminal")]),
                    ],
                    1
                  ),
                  _vm.infoData.arrivalInfo.length > 0
                    ? _vm._l(_vm.infoData.arrivalInfo, function (v, index) {
                        return _c(
                          "td",
                          { key: index, staticClass: "text_left valign_top" },
                          [
                            _c("P", { staticClass: "font_medium" }, [
                              _vm._v(_vm._s(v.podNm)),
                            ]),
                            _c("p", [_vm._v(_vm._s(v.polEtaDT))]),
                            _c("p", [_vm._v(_vm._s(v.podTml))]),
                          ],
                          1
                        )
                      })
                    : _vm._l(_vm.scheduleData, function (item) {
                        return _c("td", { key: item.logRno }, [_vm._v("-")])
                      }),
                ],
                2
              ),
              _c(
                "tr",
                [
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(" Transit Time "),
                  ]),
                  _vm.infoData.etcInfo.length > 0
                    ? _vm._l(_vm.infoData.etcInfo, function (v, index) {
                        return _c(
                          "td",
                          { key: index, staticClass: "text_left valign_top" },
                          [_vm._v(" " + _vm._s(v.transitTime) + " ")]
                        )
                      })
                    : _vm._l(_vm.scheduleData, function (item) {
                        return _c("td", { key: item.logRno }, [_vm._v("-")])
                      }),
                ],
                2
              ),
              _c(
                "tr",
                [
                  _c("td", { staticClass: "text_left valign_top" }, [
                    _c("p", [_vm._v("Call sign ")]),
                    _c("p", [_vm._v("MRN No.")]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("btn.CMBTK044")))]),
                  ]),
                  _vm.infoData.etcInfo.length > 0
                    ? _vm._l(_vm.infoData.etcInfo, function (v, index) {
                        return _c(
                          "td",
                          { key: index, staticClass: "text_left valign_top" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.strTrim(v.callSign) === ""
                                    ? "-"
                                    : v.callSign
                                )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.strTrim(v.mrnNo) === "" ? "-" : v.mrnNo
                                )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.strTrim(v.jrstCstm) === ""
                                    ? "-"
                                    : v.jrstCstm
                                )
                              ),
                            ]),
                          ]
                        )
                      })
                    : _vm._l(_vm.scheduleData, function (item) {
                        return _c("td", { key: item.logRno }, [_vm._v("-")])
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }