// 화물추적, 나의정보, 고객센터
import { checkPermission } from './permission'
const EmptyLayout = () => import(/* webpackChunkName: "topmenu_cp" */'@/layout/EmptyLayout')
const VesselInfo = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/common/Vessel')
const FaqHome = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/common/faq/FaqHome')
const NoticeHome = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/common/NoticeHome')
const Pds = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/common/Pds')
const CpScenarioList = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/CpScenarioList')
const ScenarioTargetSet = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/ScenarioTargetSet')
const ScenarioAction = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/ScenarioAction')
const CpCommCodeMng = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/CommonCodeMng')
const FactorCodeMng = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/FactorCodeMng')
const ActionListMng = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/ActionListMng')
const ScenarioResultList = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/ScenarioResultList')
const HandWriting = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/cp/HandWriting')
const VesselLocationPresentCondition = () => import(/* webpackChunkName: "topmenu_cp" */'@/pages/schedule/VesselLocationPresentCondition')
const sendNewYearCard = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/sendNewYearCard')
const sendNewYearCardLastStep = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/sendNewYearCardLastStep')
const sendNewYearCardNextStep = () => import(/* webpackChunkName: "topmenu07_09" */'@/pages/common/sendNewYearCardNextStep')

const beforeCheck = () => (to, from, next) => {
  let permit = checkPermission(to, from)
  if (permit.isPermit) {
    next()
  } else {
    next(permit.path)
  }
}

const routes = [
  {
    path: '/cp',
    redirect: '/cp/cp-scenario-list',
    component: EmptyLayout,
    meta: {
      breadcrumb: 'Control Panel'//,
      //key: 'menu.MENUCP.000'
    },
    children: [
      {
        path: 'cp-scenario-list',
        name: 'cp-scenario-list',
        component: CpScenarioList,
        meta: {
          breadcrumb: '시나리오 리스트',
          key: 'cp.CTRL020G060.001'
        }
      },
      {
        path: 'scenario-target-set/:scenarioCd',
        name: 'scenario-target-set',
        component: ScenarioTargetSet,
        meta: {
          breadcrumb: '시나리오 타겟',
          key: 'cp.CTRL020G070.001',
          sitemapHide: true
        }
      },
      {
        path: 'scenario-action/:scenarioCd',
        name: 'scenario-action',
        component: ScenarioAction,
        meta: {
          breadcrumb: '시나리오 액션',
          key: 'cp.CTRL020G080.001',
          sitemapHide: true
        }
      },
      {
        path: 'scenario-result',
        name: 'scenario-result',
        component: ScenarioResultList,
        meta: {
          breadcrumb: '시나리오 결과 조회',
          key: 'menu.MENUCP.030'
        }
      },
      /*{
        path: 'faq',
        name: 'faq',
        meta: {
          breadcrumb: '통합화주정보DB 조회',
          key: 'menu.MENUCP.040'
        }
      },*/
      {
        path: 'mng',
        name: 'mng',
        redirect: '/cp/mng/common-code',
        component: EmptyLayout,
        meta: {
          breadcrumb: '공통 관리',
          key: 'menu.MENUCP.050'
        },
        children: [
          {
            path: 'common-code',
            name: 'common-code',
            component: CpCommCodeMng,
            meta: {
              breadcrumb: '공통코드 관리',
              key: 'menu.MENUCP.051'
            }
          },
          {
            path: 'action-code',
            name: 'action-code',
            component: ActionListMng,
            meta: {
              breadcrumb: '액션코드 관리',
              key: 'menu.MENUCP.052'
            }
          },
          {
            path: 'factor-code',
            name: 'factor-code',
            component: FactorCodeMng,
            meta: {
              breadcrumb: '팩터코드 관리',
              key: 'menu.MENUCP.053'
            }
          },
          /*{
            path: 'vessel-Info',
            name: 'vessel-Info',
            meta: {
              breadcrumb: 'API관리',
              key: 'menu.MENUCP.054'
            }
          },*/
          {
            path: 'hand-writing',
            name: 'hand-writing',
            component: HandWriting,
            meta: {
              breadcrumb: '통합화주정보DB 수기입력',
              key: 'menu.MENUCP.055'
            }
          }
        ]
      },
      {
        path: 'vessel-location-present-condition',
        name: 'vessel-location-present-condition',
        component: VesselLocationPresentCondition,
        meta: {
          breadcrumb: 'KMTC OBS'
        }
      }
    ]
  },
  {
    path: '/send-new-year-card',
    name: 'sendNewYearCard',
    component: sendNewYearCard,
    meta: {
      breadcrumb: '전자연하장',
      key: 'menu.MENU09.141'
    },
    props: true
  },
  {
    path: '/send-new-year-card-next-step',
    name: 'sendNewYearCardNextStep',
    component: sendNewYearCardNextStep,
    meta: {
      breadcrumb: '전자연하장',
      key: 'menu.MENU09.141'
    },
    props: true
  },
  {
    path: '/send-new-year-card-last-step',
    name: 'sendNewYearCardLastStep',
    component: sendNewYearCardLastStep,
    meta: {
      breadcrumb: '전자연하장',
      key: 'menu.MENU09.141'
    },
    props: true
  }
]

export default routes
