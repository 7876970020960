import Send from '../../trans.client.js'
export default {
    getMyRequestList (params) {
      return Send({
        url: '/trans/my-request',
        method: 'get',
        params: params
      })
    },
    getDataSimple (params) {
      return Send({
        url: '/trans/my-request/data-simple',
        method: 'get',
        params: params
      })
    },
    getRsnCont (params) {
      return Send({
        url: '/trans/my-request/rsn-cont',
        method: 'get',
        params: params
      })
    },
    getIssDtm (params) {
      return Send({
        url: '/trans/my-request/iss-dtm',
        method: 'get',
        params: params
      })
    },
    getMyRequestFile (params) {
      return Send({
        url: '/trans/my-request/file',
        method: 'get',
        params: params
      })
    },
    getSimpleMileage (params) {
      return Send({
        url: '/trans/my-request/mileage',
        method: 'get',
        params: params
      })
    },
    getFreightSimple (params) {
      return Send({
        url: '/trans/my-request/freight-simple',
        method: 'get',
        params: params
      })
    },
    putMyRequest (params) {
      return Send({
        url: '/trans/my-request/cancel',
        method: 'put',
        params: params
      })
    },
    getUserInfo (params) {
      return Send({
        url: '/trans/my-request/user-info',
        method: 'get',
        spinner: false,
        params: params
      })
    },
    getUserSalInfo (params) {
      return Send({
        url: '/trans/my-request/user-sal-info',
        method: 'get',
        params: params
      })
    },
    getUserIngInfo (params) {
      return Send({
        url: '/trans/my-request/user-ing-info',
        method: 'get',
        params: params
      })
    },
    postMyRequestLog (params) {
      return Send({
        url: '/trans/my-request/save-log',
        method: 'post',
        data: params
      })
    },
    findRequestDetail (reqRno) {
      return Send({
        url: '/trans/my-request/' + reqRno,
        method: 'get'
      })
    },
    findblYn (blNo) {
      return Send({
        url: '/trans/my-request/bl-yn/' + blNo,
        method: 'get'
      })
    }
  }
