var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "620px" },
      attrs: { id: "profile_pop" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.MAIN.001"))),
        ]),
        _c("div", { staticClass: "clearfix" }, [
          _c("span", { staticClass: "tit_label" }, [_vm._v("Profile Search")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.txtFilter,
                expression: "txtFilter",
              },
            ],
            staticClass: "wid200",
            attrs: { type: "text", id: "filter", autocomplete: "off" },
            domProps: { value: _vm.txtFilter },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.txtFilter = $event.target.value
              },
            },
          }),
          _c(
            "a",
            {
              staticClass: "button blue sh",
              on: {
                click: function ($event) {
                  return _vm.changeFilter()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00120")))]
          ),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c(
            "ul",
            { staticClass: "profile_list choice hscroll" },
            [
              _c(
                "li",
                {
                  staticClass: "more",
                  on: {
                    click: function ($event) {
                      return _vm.addProfilePage()
                    },
                  },
                },
                [_vm._m(0)]
              ),
              _vm._l(_vm.filterList, function (p, i) {
                return [
                  p.useYn === "Y"
                    ? _c(
                        "li",
                        {
                          key: "profile-" + i,
                          class: p.isMe ? "my" : "",
                          staticStyle: { position: "relative" },
                          on: {
                            click: function ($event) {
                              return _vm.select(i)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tooltip_wrap short position_absolute",
                              style:
                                i % 5 === 3
                                  ? "display: none; right: 80px;"
                                  : "display: none; right: -80px; color: black !important;",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "cont",
                                  staticStyle: { padding: "0px" },
                                },
                                [
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(" " + _vm._s(p.preUserId) + " "),
                                      _c("br"),
                                      _vm._v(" " + _vm._s(p.userCtrCd) + " "),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              class: p.useYn == "N" ? "no-use" : "img",
                              on: {
                                mouseover: _vm.showTooltip,
                                mouseout: _vm.hideTooltip,
                              },
                            },
                            [
                              !_vm.isEmpty(p.picPotoString)
                                ? _c("img", {
                                    attrs: { src: p.picPotoString, alt: "" },
                                  })
                                : _vm.isEmpty(p.picPotoString) &&
                                  !_vm.isEmpty(p.fileNm) &&
                                  _vm.getFilePath(p.fileNm) != null
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.getFilePath(p.fileNm),
                                      alt: "",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/pers/profile_noimg.png"),
                                      alt: "",
                                    },
                                  }),
                              p.useYn == "N"
                                ? _c("span", [_vm._v("NO USE")])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              staticStyle: { color: "#000" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isEmpty(p.picAlsNm)
                                      ? p.picNm
                                      : p.picAlsNm
                                  )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }