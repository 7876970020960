<template>
  <div class="container_ekmtc"><!--   ##### container  #####-->
    <router-view></router-view>
    <Quick></Quick>
  </div>
</template>
<script>
import Quick from '@/layout/Quick'

  export default {
    components: {
      Quick
    }
  }
</script>
<style>

</style>
