<template>
  <e-auto-complete class="auto-input"
                   v-model="text"
                   :values="dynamicItems"
                   @update="dynamicSearchPlace"
                   :read-only="readOnly"
                   @input="inputEvt"
                   :is-char="isChar"
                   :plaholder="placeholder"
                   :is-char-alert="isCharAlert"
                   :list-group-width="listGroupWidth"
                   :disabled="disabled"
                   :auto-focus="autoFocus"
  >
  </e-auto-complete>
</template>

<script>
import apiCommons from '@/api/services/commons'
import EAutoComplete from '@/components/common/EAutoComplete'

export default {
  name: 'EAutoCompletePlace',
  components: {
    EAutoComplete
  },
  props: {
    ctrCd: { type: String, default: '', required: false },
    plcCd: { type: String, default: '', required: false },
    readOnly: { type: Boolean, default: false, required: false },
    isChar: { type: Boolean, default: false, required: false },
    plaholder: { type: String, default: '', required: false },
    isCharAlert: { type: Boolean, default: false, required: false },
    listGroupWidth: { type: String, default: '', required: false },
    disabled: { type: Boolean, default: false, required: false },
    autoFocus: { type: Boolean, default: false, required: false }
  },
  data: function () {
    return {
      text: '',
      rtnVo: {
        ctrCd: '',
        ctrEnm: '',
        plcCd: '',
        plcEnm: '',
        plcNm: '',
        plcCatCd: ''
      },
      items: [],
      dynamicItems: [],
      placeholder: this.$t('msg.MAIN010G030.035')
    }
  },
  computed: {
    changeData () {
      const { ctrCd, plcCd } = this.$props
      return { ctrCd, plcCd }
    }
  },
  watch: {
    changeData (newVal, oldVal) {
      if (oldVal.ctrCd !== newVal.ctrCd || oldVal.plcCd !== newVal.plcCd) {
        this.initData(newVal.ctrCd, newVal.plcCd)
      }
    }
  },
  created () {
    if (this.ctrCd !== '' && this.plcCd !== '') {
      this.initData(this.$props.ctrCd, this.$props.plcCd)
    }
  },
  methods: {
    initData (ctrCd, plcCd) {
      if (ctrCd === '' && plcCd === '') {
        this.rtnVo = {
          ctrCd: '',
          ctrEnm: '',
          plcCd: '',
          plcEnm: '',
          plcNm: '',
          plcCatCd: ''
        }
        //this.text = ''
        return
      }

      apiCommons.getCommonsPlacesInfo(ctrCd, plcCd).then((data) => {
        if (data.data !== undefined) {
          this.text = data.data.plcEnm
        } else {
          this.text = ''
        }
      })
    },
    dynamicSearchPlace: function (e) {
      const THIS = this
      const keyword = e
      if (keyword === '') {
        this.items = []
        THIS.dynamicItems = []
        return
      }
      apiCommons.getCommonsPlaces(keyword).then((data) => {
        this.items = data.data
        if (this.items === undefined) {
          THIS.dynamicItems = []
        } else {
          THIS.dynamicItems = this.items.map(vo => vo.plcEnm)
        }
      })
    },
    inputEvt (val) {
      if (this.items === undefined || this.items === null || this.items.length === 0) {
        this.rtnVo = {
          ctrCd: '',
          ctrEnm: '',
          plcCd: '',
          plcEnm: '',
          plcNm: '',
          plcCatCd: ''
        }
        this.$emit('change', this.rtnVo)
        return
      }
      const tvo = this.items.find(vo => vo.plcEnm === val)

      if (tvo !== undefined) {
        this.rtnVo = {
          ctrCd: tvo.ctrCd,
          ctrEnm: tvo.ctrEnm,
          plcCd: tvo.plcCd,
          plcEnm: tvo.plcEnm,
          plcNm: tvo.plcNm,
          plcCatCd: tvo.plcCatCd
        }
      } else {
        this.rtnVo = {
          ctrCd: '',
          ctrEnm: '',
          plcCd: '',
          plcEnm: '',
          plcNm: '',
          plcCatCd: ''
        }
      }
      this.$emit('change', this.rtnVo)
    }
  }
}
</script>
