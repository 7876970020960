import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
    //Oog - Oog > OOG 화물리스트 조회 - GET /trans/oog/list
    getOogList (param) {
        return Send({
            //url: 'http://localhost:9006/trans/oog/list',
            url: '/trans/oog/list',
            method: 'get',
            params: {
                asFromDt: param.asFromDt,
                asToDt: param.asToDt,
                asDtKnd: param.asDtKnd,
                asReqNo1: param.asReqNo1,
                asReqNo2: param.asReqNo2,
                asReqNo3: param.asReqNo3,
                polPlcCd: param.asPolPlcCd,
                podPlcCd: param.asPodPlcCd,

                bkgShprCstNo: param.bkgShprCstNo,
                status: param.apvStsCd.join(',')

            }
        })
    },
    //oogDetail - oog 상세 조회
    getOogDetail (reqNo) {
        return Send({
            url: '/trans/oog/info',
            method: 'get',
            params: {
                reqNo: reqNo
            }
        })
    },
    // OOG 선적가부문의 - PORT 조회
    getOogPlc (ctrCd) {
        return new Promise((resolve, reject) => {
            Send({
                url: '/trans/oog/plc',
                method: 'get',
                params: {
                    ctrCd: ctrCd
                }
            }).then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    // OOG 선적가부문의 - Trml 조회
    getOogTrml (plcCd) {
        return new Promise((resolve, reject) => {
            Send({
                url: '/trans/oog/trml',
                method: 'get',
                spinner: false,
                params: {
                    plcCd: plcCd
                }
            }).then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    // OOG 선적가부문의 - commodity DESC 조회
    getOogCmdtDsc (cmdtNm) {
        return new Promise((resolve, reject) => {
            Send({
                // url: 'http://172.17.213.75/user/adminMod/info' + (tableId === 'C' ? 'pds/' : 'faq/') + messageIdSeq,
                url: '/trans/oog/cmdtDsc',
                method: 'get',
                params: {
                    cmdtNm: cmdtNm
                }
            }).then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    //oogDetail - oog 추가 공통 코드 조회
    getOogForm () {
        return Send({
            url: '/trans/oog/form',
            method: 'get'

        })
    },
    //oog 상세 - commidity 파일 다운
    oogFileDown (cmdtCd) {
        return new Promise((resolve, reject) => {
            Send({
              url: '/trans/oog/down',
              responseType: 'blob',
              method: 'get',
              params: {
                  cmdtCd: cmdtCd
              }
            }).then(res => {
              resolve(res)
            //   if (res.data) {
            //     const header_dis = res.headers['content-disposition']
            //     if (header_dis) {
            //       const name = header_dis.split('filename=')[1]
            //       const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
            //       const link = document.createElement('a')
            //       link.href = url
            //       link.setAttribute('download', name)
            //       document.body.appendChild(link)
            //       link.click()
            //       link.remove()
            //     }
            //   }
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    //oog 선적 가부 문의 등록
    oogInsert (data) {
        return new Promise((resolve, reject) => {
            Send({
                //url: 'http://localhost:9006/trans/oog',
                url: '/trans/oog',
                method: 'PUT',
                data: data
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    // 진행중인 업무 개인화 데이터 조회
    getWorkingCnt (param) {
        return Send({
        //   url: 'http://localhost:9006/trans/oog/working-cnt',
          url: '/trans/oog/working-cnt',
          method: 'get',
          params: {
            profileYn: param
          }
        })
    }
}
