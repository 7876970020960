<template>
  <div class="per_section">
    <h2>{{ $t('msg.PERS010L020.025') }}</h2> <!-- 최근 조회 스케줄 -->
    <p class="schedule_btn"><button type="button" class="button sm" @click="openPopup('ScheduleComparePopType01')">{{ $t('msg.PERS010L020.026') }}</button></p> <!-- 스케줄 비교 -->
    <!-- 팝업  -->
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="custComponent"
        :is="custComponent"
        :select-data.sync="selectData"
        @close="closePopup"
      />
    </win-layer-pop>
    <dl v-for="(item, index) in items" :key="index" class="schedule">
      <dt>
        <input type="checkbox" name="schedule-chk" :id="`schedule-chk-${item.logRno}`" v-model="item.chk" @change="test">
        <label :for="`schedule-chk-${item.logRno}`"><span class="offscreen">{{ item.logRno }}</span></label>
        {{ item.vslNm }} {{ item.voyNo }}
      </dt>
      <dd>{{ item.polPortNm }}<span class="arr">&gt;</span>{{ item.polCtrNm }}</dd>
    </dl>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import apiCompare from '@/api/rest/schedule/compare'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ScheduleComparePop from '@/components/schedule/ScheduleComparePop'
import ScheduleComparePopType01 from '@/components/schedule/ScheduleComparePopType01'

export default {
  name: 'ScheduleCompare',
  components: {
    WinLayerPop,
    ScheduleComparePop,
    ScheduleComparePopType01
  },
  props: {
    lineCount: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      custComponent: null,
      selectData: [],
      rowsCount: 0,
      items: [],
      fields: [
        { key: 'chk', label: 'chk', text: 'chk', sortable: false },
        { key: 'logRno', label: 'logRno', text: 'logRno', sortable: false },
        { key: 'logCatCd', label: 'logCatCd', text: 'logCatCd', sortable: false },
        { key: 'pgmCatCd', label: 'pgmCatCd', text: 'pgmCatCd', sortable: false },
        { key: 'logDt', label: 'logDt', text: 'logDt', sortable: false },
        { key: 'logTm', label: 'logTm', text: 'logTm', sortable: false },
        { key: 'cstId', label: 'cstId', text: 'cstId', sortable: false },
        { key: 'polCtrCd', label: 'polCtrCd', text: 'polCtrCd', sortable: false },
        { key: 'vslCd', label: 'vslCd', text: 'vslCd', sortable: false },
        { key: 'vslNm', label: 'vslNm', text: 'vslNm', sortable: false },
        { key: 'voyNo', label: 'voyNo', text: 'voyNo', sortable: false },
        { key: 'polCtrNm', label: 'polCtrNm', text: 'polCtrNm', sortable: false },
        { key: 'polPortCd', label: 'polPortCd', text: 'polPortCd', sortable: false },
        { key: 'polPortNm', label: 'polPortNm', text: 'polPortNm', sortable: false },
        { key: 'podCtrCd', label: 'podCtrCd', text: 'podCtrCd', sortable: false },
        { key: 'podCtrNm', label: 'podCtrNm', text: 'podCtrNm', sortable: false },
        { key: 'podPortCd', label: 'podPortCd', text: 'podPortCd', sortable: false },
        { key: 'podPortNm', label: 'podPortNm', text: 'podPortNm', sortable: false },
        { key: 'dpoDt', label: 'dpoDt', text: 'dpoDt', sortable: false },
        { key: 'dpoTm', label: 'dpoTm', text: 'dpoTm', sortable: false },
        { key: 'apoDt', label: 'apoDt', text: 'apoDt', sortable: false },
        { key: 'apoTm', label: 'apoTm', text: 'apoTm', sortable: false },
        { key: 'eiCatCd', label: 'eiCatCd', text: 'eiCatCd', sortable: false },
        { key: 'schInqYm', label: 'schInqYm', text: 'schInqYm', sortable: false },
        { key: 'closYn', label: 'closYn', text: 'closYn', sortable: false },
        { key: 'fstEntUno', label: 'fstEntUno', text: 'fstEntUno', sortable: false },
        { key: 'fstEntDtm', label: 'fstEntDtm', text: 'fstEntDtm', sortable: false }
      ]
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    lineCount (nv, ov) {
      this.rowsCount = nv
      this.init()
    }
  },
  mounted () {
    this.rowsCount = this.lineCount
    this.init()
  },
  methods: {
    init () {
      this.items = []
      const params = {
        cnt: this.rowsCount
      }
      apiCompare.getMyHistory(params)
        .then(response => {
          if (response.data) {
            const tmp = response.data.map(d => {
              return {
                ...d,
                chk: false
              }
            })
            this.items = tmp
          }
        })
    },
    openPopup (compNm) {
      this.selectData = this.items.filter(v => { return v.chk === true })

      if (this.selectData.length < 2) {
        this.openAlert(this.$t('msg.PERS010L020.027')) // 비교할 데이터를 2개 이상 선택해주세요.
        return
      }
      if (this.selectData.length >= 4) {
        this.openAlert(this.$t('msg.PERS010L020.028')) // 비교할 수 있는 최대 갯수는 3개 입니다.
        return
      }
      if (this.custComponent !== null) {
        this.custComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.custComponent = compNm
      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },

    closePopup (obj) {
      this.custComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    },

    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    test (v1) {
      console.log(v1)
      console.log(this.items)
    }

  }
}
</script>
