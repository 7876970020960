<template id="e-auto-complete">
  <div class="autocomplete-form"
       @keydown.down="onKeydown($event)"
       @keydown.up="onKeyup($event)"
       @keydown.enter.stop.prevent="onEnterClicked"
       @keyup.tab="onKeyTab($event)"
       @focusin="visible=true"
       @focusout="onFocusout($event)"
  >
    <div class="autocomplete-form-input-elements">
      <input id="autocomplete-form-input"
             autocomplete="off"
             class="w-100"
             @input="onInputHandler"
             v-model="text"
             :readonly="readOnly"
             :disabled="disabled"
             :placeholder="placeholder"
             type="text"
             ref="handleInput"
      >
    </div>
    <div :id="elemId" class="list-group list-group-flush autocomplete-form-items"
         :class="{hide: (disabled && visible)|| !visible}" v-show="valuesShowing.length > 0"
         :style="{ 'width': $ekmtcCommon.isNotEmpty($props.listGroupWidth) ? $props.listGroupWidth : '' }"
    >
      <template v-for="(val,index) in valuesShowing">
        <button v-if="isSafari"
                :key="'btn_' + index"
                class="list-group-item list-group-item-action"
                :class="{active:selectedIndex == index}"
                @mousedown="onClickHandler($event, 'mousedown')"
                @mouseover="selectedIndex = index"
                type="button"
                v-html="val"
        >
        </button>
        <button v-else
                :key="'btn_' + index"
                class="list-group-item list-group-item-action"
                :class="{active:selectedIndex == index}"
                @click="onClickHandler($event, 'click')"
                @mouseover="selectedIndex = index"
                type="button"
                v-html="val"
        >
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EAutoComplete',
  props: {
    values: { type: Array, required: true },
    value: { type: String, default: '', required: false },
    readOnly: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
    isChar: { type: Boolean, default: false, required: false },
    isFilter: { type: Boolean, default: true, required: false },
    isDebug: Boolean,
    placeholder: { type: String, default: '', required: false },
    isCharAlert: { type: Boolean, default: false, required: false },
    listGroupWidth: { type: String, default: '', required: false },
    autoFocus: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      text: '',
      valuesShowing: [],
      visible: false,
      selectedIndex: 0,
      isSafari: this.$ekmtcCommon.isSafari()
    }
  },
  watch: {
    value () {
      // if (this.text !== this.$props.value) {
        this.text = this.$props.value
      // }
    },
    values: function () {
      const tx = this.text.toLowerCase()
      let valuesList

      if (this.$props.isFilter) {
        valuesList = this.$props.values.filter(a => a.toLowerCase().includes(tx))
          .sort((a, b) => {
            const a1 = a.toLowerCase()
            const b1 = b.toLowerCase()
            if (a1.startsWith(tx) && b1.startsWith(tx)) {
              if (a < b) return -1
              else if (a > b) return 1
              else return 0
            } else if (a1.startsWith(tx)) {
              return -1
            } else if (b1.startsWith(tx)) {
              return 1
            } else {
              if (a < b) return -1
              else if (a > b) return 1
              else return 0
            }
          })
          .map(obj => {
            if (this.text) {
              return this.changeString(obj)
            } else {
              return obj
            }
          })
      } else {
        valuesList = this.$props.values
          .map(obj => {
            if (this.text) {
              return this.changeString(obj)
            } else {
              return obj
            }
          })
      }

      this.$data.valuesShowing = valuesList
      this.selectedIndex = 0
      this.setBoxScrollTop(0)
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      if (this.autoFocus) {
        this.$refs.handleInput && this.$refs.handleInput.focus()
      }
    })
  },
  created () {
    this.elemId = 'autocomplete_' + (new Date().getTime()) + '_' + Math.floor(Math.random() * 10000)
    if (this.text !== this.$props.value) {
      this.text = this.$props.value
    }
  },
  methods: {
    changeString (str) {
      if (str && this.text) {
        let chgStr = []
        let isWhile = true
        while (isWhile) {
          const obj = str.toLowerCase().match(this.text.toLowerCase())
          if (!obj) {
            chgStr.push(str)
            isWhile = false
          } else {
            const txt = '<b>' + str.substring(obj.index, obj.index + this.text.length) + '</b>'
            chgStr.push(str.substring(0, obj.index) + txt)
            str = str.substring((obj.index + this.text.length))
          }
        }
        return chgStr.join('')
      } else {
        return ''
      }
    },
    onKeydown () {
      if (this.valuesShowing.length === 0) {
        return
      }
      this.selectedIndex = this.selectedIndex < this.valuesShowing.length - 1 ? this.selectedIndex + 1 : this.selectedIndex
      // 스크롤 처리
      const elem = document.querySelector('#' + this.elemId)
      const btn = elem.querySelectorAll('.list-group-item')[this.selectedIndex]
      const bb = btn.offsetTop + btn.offsetHeight
      const h = elem.offsetHeight

      if (this.selectedIndex === 0) {
        elem.scrollTop = 0
      } else if (h < bb) {
        elem.scrollTop = bb - h
      }
    },
    onKeyup () {
      if (this.valuesShowing.length === 0) {
        return
      }
      this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.selectedIndex
      // 스크롤 처리
      const elem = document.querySelector('#' + this.elemId)
      const btn = elem.querySelectorAll('.list-group-item')[this.selectedIndex]
      const bt = btn.offsetTop

      if (bt < elem.scrollTop) {
        elem.scrollTop = bt
      }
    },
    // Tab 이동시 선택처리
    onKeyTab (e) {
      const elem = $('#' + this.elemId)
      const btn = $(e.target)

      if (!btn.hasClass('list-group-item')) {
        return
      }
      this.selectedIndex = elem.find('.list-group-item').index(btn)
    },
    setBoxScrollTop (y) {
      const elem = document.querySelector('#' + this.elemId)
      elem.scrollTop = y
    },
    onFocusout (e) {
      if (e.relatedTarget === null || e.relatedTarget === undefined) {
        this.visible = false
        this.selectedIndex = 0
        this.setBoxScrollTop(0)
        return
      }

      try {
        const cls = e.relatedTarget.getAttribute('class')
        if (cls.indexOf('list-group-item-action') === -1) {
          this.visible = false
          this.selectedIndex = 0
          this.setBoxScrollTop(0)
        }
      } catch (e) {
        this.visible = false
        this.selectedIndex = 0
        this.setBoxScrollTop(0)
      }
    },
    onInputHandler (e) {
      let isOk = true
      if (this.$props.isChar) {
        //if (this.$props.isCharAlert && /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g.test(e.target.value)) {
        //  this.$ekmtcCommon.alertCallback(this.$t('msg.MAIN010G030.035'), () => {
        //    $(e.target).focus().click()
        //  }, true)
        //  isOk = false
        //}
        let val = this.$ekmtcCommon.removeHangle(e.target.value)
        const arr = []
        let str
        let c
        for (let i = 0; i < val.length; i++) {
          str = val.charAt(i)
          c = val.charCodeAt(i)
          if ('#?~`$_|{}[]!^\\"-\\+='.indexOf(str) < 0 && ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))) {
            arr.push('' + str)
          }
        }

        if (!isOk) {
          val = arr.join('')
          e.target.value = val
          this.$data.text = val
        }
      }

      this.$emit('input', this.$data.text)
      this.$emit('update', e.target.value)
      // console.log('input 1 ->', this.$data.text)
    },
    onClickHandler: function (e, ty) {
      let el
      if (e.target.tagName === 'B') {
        el = $(e.target).parent()
      } else {
        el = $(e.target)
      }

      const arrBtn = $('#' + this.elemId).find('button.list-group-item')

      this.$data.text = el.text()
      this.selectedIndex = arrBtn.index(el)
      this.$emit('input', this.$data.text)
      // console.log('input 2 ->', this.$data.text)
      el.blur()
      // this.$data.text = e.target.innerHTML.trim().replace(/<b>/gi, '').replace(/<\/b>/gi, '')
      // this.selectedIndex = e.target.dataset.id
      // this.$emit('input', this.$data.text)
      // e.target.blur()
    },
    onEnterClicked: function (e) {
      if (this.valuesShowing.length > 0) {
        const selectedIndex = this.$data.selectedIndex // e.target.blur() 시 초기화 되기 때문에 미리 선언
        e.target.blur()
        this.$data.text = this.$data.valuesShowing[selectedIndex].trim().replace(/<b>/gi, '').replace(/<\/b>/gi, '')
        this.$emit('input', this.$data.text)
        // console.log('input 3 ->', this.$data.text, selectedIndex)
      }
    }
  }
}
</script>

<style scoped>
.hide {
  display: none;
}
.list-group {
  position: absolute;
  min-width: 260px;
  max-height: 300px;
  border: 1px solid #d8d8d8;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
}

.input_error #autocomplete-form-input{
  border: 1px solid #f2084f !important;
}

</style>
