import 'babel-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './app.scss'
import './mixins'
import i18n from './i18n'
import VueI18n from 'vue-i18n'
import LoadScript from 'vue-plugin-load-script'
import commonUtils from '@/plugins/commonUtils'
import './assets/css/ekmtc.css'
import './assets/css/realgrid-style-ekmtc.css'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import VueMask from 'v-mask'
import api from '@/api/services/auth'
import { Simplert } from 'vue2-simplert-plugin'
import _ from 'lodash'
//import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as VueBaiduMap from 'vue-baidu-map'
import UUID from 'vue-uuid'
import VueGtag from 'vue-gtag'
import VueGtm from '@gtm-support/vue2-gtm'
//import VueRouter from 'vue-router'
//const router = new VueRouter({ routes, mode, linkActiveClass })
// import { datadogRum } from '@datadog/browser-rum'

// datadogRum.init({
//     applicationId: 'e621e65c-b3bf-478b-96fc-d726c30a86e0',
//     clientToken: 'pube7fb02aa37d7f06fbe5e5e97b20ab34f',
//     site: 'datadoghq.com',
//     service: 'kmtc',
//     env: process.env.VUE_APP_MODE.toLowerCase(),
//     // 버전별 분석이 필요하신경우 입력
//     // version: '1.0.0',
//     sampleRate: 100,
//     trackInteractions: true,
//     defaultPrivacyLevel: 'mask-user-input',
//     allowedTracingOrigins: ['https://www.ekmtc.com', /https?:\/\/.*\.ekmtc\.com/]
// })

// datadogRum.startSessionReplayRecording()

document.title = process.env.VUE_APP_NAME //환경별로바꿔야함 minin으로 세팅?

Vue.config.devtools = process.env.VUE_APP_MODE !== 'PRD'
Vue.config.productionTip = false
Vue.use(VueBreadcrumbs)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)
Vue.use(LoadScript)
Vue.use(VueMask)
Vue.use(UUID)
Vue.use(Simplert, { disableOverlayClick: true })
//Vue.use(Simplert)
Vue.prototype.$ekmtcCommon = commonUtils
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyDpITklcZAXxUv6NgmmXDfQa341RzclH6A',
    key: 'AIzaSyAHX0JxHdaVdJZ2Cu4PkWjUIoyv21b7mAU',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    //// If you want to set the version, you can do so:
    // v: '3.26',
  }
})
Vue.use(VueBaiduMap.default, {
  ak: '927cb23887926d2b345b0c762045feb3'
})
const vuex = JSON.parse(sessionStorage.getItem(['vuex']))
/*
Vue.use(VueGtag, {
  config: {
    id: 'G-QCG4TW4FZD',
    params: {
      user_id: vuex.auth.auth.userId
    }
   }
}, router)

Vue.use(VueGtm, {
  id: 'GTM-WKHLNBB',
  // queryParams: {
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  defer: true,
  compatibility: false,
  nonce: '2726c7f26c',
  enabled: true,
  debug: true,
  //loadScript: true,
  //vueRouter: router,
  //ignoredViews: ['homepage'],
  trackOnNextTick: true
})
*/
Object.defineProperty(Vue.prototype, '$_', { value: _ })

  const vm = new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
  }).$mount('#app')

window.app = vm

store.dispatch('auth/init', router)

let content = document.querySelector('.simplert__content')
let parent = content.parentNode
let html = '<div id="e-alert" class="alert_wrap" style="display: none">\n' +
  '\n' +
  '    <div id="e-alert-message" class="message">\n' +
  '    </div>\n' +
  '    <div id="e-alert-set1" class="btn_set full">\n' +
  '      <ul>\n' +
  '        <li><a id="e-alert-set1-btn1" class="button blue lg">확인1</a></li>\n' +
  '      </ul>\n' +
  '    </div>\n' +
  '    <div id="e-alert-set2" class="btn_set dv2" style="display: none">\n' +
  '      <ul>\n' +
  '        <li><a id="e-alert-set2-btn1" class="button blue lg">확인</a></li>\n' +
  '        <li><a id="e-alert-set2-btn2" class="button gray lg">닫기</a></li>\n' +
  '      </ul>\n' +
  '    </div>\n' +
  '    <div id="e-alert-set3" class="btn_set dv3" style="display: none">\n' +
  '      <ul>\n' +
  '        <li><a id="e-alert-set3-btn1" class="button blue lg">확인</a></li>\n' +
  '        <li><a id="e-alert-set3-btn2" class="button gray lg">닫기</a></li>\n' +
  '        <li><a id="e-alert-set3-btn3" class="button gray lg">기타1</a></li>\n' +
  '      </ul>\n' +
  '    </div>\n' +
  '  </div>'
parent.removeChild(content)

parent.innerHTML = html
parent.appendChild(content)

router.beforeEach(async (to, from, next) => {
  const profile = store.getters['auth/selectedProfile']

  if (to.path !== '/calcIssue/freetime-request') {
    sessionStorage.removeItem('freetimeParamsForLogin')
    sessionStorage.removeItem('freetimeDataForLogin')
  }

  if (profile === null) {
    if (to.path === '/my-info/setting/profile') {
      next()
    } else if (to.path === '/my-info/login-approval') {
      next()
    } else {
      let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: i18n.t('msg.MYIN050T010.056'),
        useIcon: false,
        customClass: 'simple_alert',
        message: i18n.t('msg.MYIN050T010.072'),
        type: 'info'
      }
      commonUtils.alert(obj)
      store.commit('endSpinner')
      next(false)
    }
  } else {
    next()
  }
})
