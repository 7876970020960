//BSA
const EmptyLayout = () => import(/* webpackChunkName: "topmenu_bsa" */'@/layout/EmptyLayout')
const BsaPerformance = () => import(/* webpackChunkName: "topmenu_bsa" */'@/pages/bsa/BsaPerformanceData')
const BsaTotalData = () => import(/* webpackChunkName: "topmenu_bsa" */'@/pages/bsa/BsaTotalData')
const BsaExternalVariables = () => import(/* webpackChunkName: "topmenu_bsa" */'@/pages/bsa/BsaExternalVariables')
const BsaAdjRvnTotal = () => import(/* webpackChunkName: "topmenu_bsa" */'@/pages/bsa/BsaAdjRvnTotal')

const routes = [
  {
    path: '/bsa',
    redirect: '/bsa/bsa-Performance',
    component: EmptyLayout,
    meta: {
      breadcrumb: 'BSA'//,
      //key: 'menu.MENUBSA.000'
    },
    children: [
      {
        path: 'bsa-Performance',
        name: 'bsa-Performance',
        component: BsaPerformance,
        meta: {
          breadcrumb: 'BSA 실적데이터',
          key: 'menu.MENUBSA.000',
          requiresAuth: true
        }
      },
      {
        path: 'bsa-variables',
        name: 'bsa-variables',
        component: BsaExternalVariables,
        meta: {
          breadcrumb: 'BSA 외부변수 집계',
          key: 'menu.MENUBSA.030',
          requiresAuth: true
        }
      },
      {
        path: 'bsa-totalData',
        name: 'bsa-totalData',
        component: BsaTotalData,
        meta: {
          breadcrumb: 'BSA 집계데이터',
          key: 'menu.MENUBSA.010',
          requiresAuth: true
        }
      },
      {
        path: 'bsa-adjRvnTotal',
        name: 'bsa-adjRvnTotal',
        component: BsaAdjRvnTotal,
        meta: {
          breadcrumb: 'BSA 항차별 조정/항로수익집계',
          key: 'menu.MENUBSA.020',
          requiresAuth: true
        }
      }
    ]
  }
]

export default routes
