var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c(
        "win-layer-pop",
        { staticClass: "footer-pop" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "inner_footer" }, [
        _c("div", { staticClass: "wrap_address" }, [
          _c("span", { staticClass: "logo_foot" }),
          _c("p", { staticClass: "address" }, [
            _vm._v(_vm._s(_vm.$t("footer.addr")) + " "),
            _c("span", { staticClass: "tel" }, [
              _vm._v(_vm._s(_vm.$t("footer.tel"))),
            ]),
          ]),
          _c("p", { staticClass: "copyright" }, [
            _vm._v("2022 KOREA MARINE TRANSPORT CO. LTD"),
          ]),
        ]),
        _vm.serviceLang == "CHN"
          ? _c("div", { staticClass: "wrap_address" }, [
              _c(
                "a",
                {
                  staticStyle: {
                    color: "#fff",
                    opacity: "0.6",
                    "font-size": "11px",
                  },
                  attrs: {
                    href: "https://beian.miit.gov.cn",
                    target: "_blank;",
                  },
                },
                [_vm._v("沪ICP备12037401号-2")]
              ),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._m(0),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902002679",
          target: "_blank;",
        },
      },
      [_c("img", { attrs: { src: "/img/ICP.png" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }