<template>
  <li v-if="menu != undefined">
    <template v-if="menu.children != undefined">
      <div class="tit">{{ menu.meta.key !== undefined ? $t(menu.meta.key) : menu.meta.breadcrumb }}</div>
      <ul>
        <template v-for="(vo, idx) in menu.children">
          <li v-if="vo.meta.sitemapHide ? false :
            (vo.meta.serviceLangs ? vo.meta.serviceLangs.includes(serviceLang) : true
              && vo.meta.serviceCtrCds ? vo.meta.serviceCtrCds.includes(memberDetail.userCtrCd) : true
                && vo.meta.intergisLvs ? vo.meta.intergisLvs.includes(intergisLv) : true
            )" :key="'menu_' + idx" :class="{ 'site_depth2' : vo.children != undefined }"
          >
            <a v-if="vo.children == undefined" :href="`#${menu.path}`" @click.prevent="go(menu.path + '/' + vo.path, vo)" :class="vo.children != undefined ? 'sub_list' : ''">{{ vo.meta.key !== undefined ? $t(vo.meta.key) : vo.meta.breadcrumb }}</a>
            <span v-if="vo.children != undefined" @click="openSubmenu">{{ vo.meta.key !== undefined ? $t(vo.meta.key) : vo.meta.breadcrumb }}</span>
            <ul v-if="vo.children != undefined" class="site_depth2_child">
              <template v-for="(svo, sidx) in vo.children">
                <li v-if="svo.meta.sitemapHide ? false : (svo.meta.serviceLangs ? svo.meta.serviceLangs.includes(serviceLang) : true && svo.meta.intergisLvs ? svo.meta.intergisLvs.includes(intergisLv) : true)" :key="'sub_menu_' + sidx">
                  <a :href="`#${menu.path}`" @click.prevent="$router.push(menu.path + '/' + vo.path + '/' + svo.path).catch(()=>{}); $emit('click')">{{ svo.meta.key !== undefined ? $t(svo.meta.key) : svo.meta.breadcrumb }}</a>
                </li>
              </template>
            </ul>
          </li>
          <template>
            <li v-show="menu.path === '/my-info' && menu.children.length -1 === idx" :key="'menu_1' + idx">
              <a :href="`#${menu.path}`" @click.prevent="goManual">{{ $t('menu.MENU09.140') }}</a>
            </li>
          </template>
        </template>
      </ul>
    </template>
    <template v-else>
      <div class="tit">{{ menu.meta.key !== undefined ? $t(menu.meta.key) : menu.meta.breadcrumb }}</div>
      <ul>
        <li>
          <a :href="`#${menu.path}`" @click.prevent="$router.push(menu.path).catch(()=>{}); $emit('click')">{{ menu.meta.key !== undefined ? $t(menu.meta.key) : menu.meta.breadcrumb }}</a>
        </li>
      </ul>
    </template>
  </li>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'

export default {
  name: 'ESitemapMenu',
  props: {
    menu: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data () {
    return {
      intergisLv: ''
    }
  },

  computed: {
    ...rootComputed
  },

  mounted () {
    const memberDetail = this.$store.getters['auth/memberDetail']
    this.intergisLv = memberDetail?.intergisLv ?? ''
  },

  methods: {
    openSubmenu (e) {
      const cl = e.target.classList
      const cn = 'on'
      if (cl.contains(cn)) cl.remove(cn)
      else cl.add(cn)
    },
    go (path, vo) {
      if (vo.meta.breadcrumb && vo.meta.breadcrumb === '각종 요청서 양식') {
        window.location.href = `/${window.location.hostname === 'localhost' ? '' : 'index.html'}#/common/pds?messageIdSeq=CKR415&groupId=415`
        window.location.reload()
        this.$emit('click')
      } else {
        this.$router.push(path).catch(() => {})
        this.$emit('click')
      }
    },
    goManual () {
      let _serviceCtrCd = ''
      let _groupId = ''
      console.log('goManual')
      if (this.auth.login) {
        //
        switch (this.auth.userCtrCd) {
          case 'KR':
            _serviceCtrCd = 'KR'
            _groupId = '442'
            break
          case 'AE':
            _serviceCtrCd = 'AE'
            _groupId = '1'
            break
          case 'CN':
            _serviceCtrCd = 'ZH'
            _groupId = '2'
            break
          case 'HK':
            _serviceCtrCd = 'HK'
            _groupId = '1'
            break
          case 'ID':
            _serviceCtrCd = 'ID'
            _groupId = '1'
            break
          case 'IN':
            _serviceCtrCd = 'IN'
            _groupId = '1'
            break
          case 'JP':
            _serviceCtrCd = 'JP'
            _groupId = '18'
            break
          case 'MY':
            _serviceCtrCd = 'MY'
            _groupId = '1'
            break
          case 'PH':
            _serviceCtrCd = 'PH'
            _groupId = '1'
            break
          case 'SG':
            _serviceCtrCd = 'SG'
            _groupId = '1'
            break
          case 'TH':
            _serviceCtrCd = 'TH'
            _groupId = '1'
            break
          case 'VN':
            _serviceCtrCd = 'VN'
            _groupId = '1'
            break
          case 'RU':
            _serviceCtrCd = 'RU'
            _groupId = '1'
            break
          case 'TW':
            _serviceCtrCd = 'TW'
            _groupId = '1'
            break
          case 'KH':
            _serviceCtrCd = 'KH'
            _groupId = '1'
            break
          case 'MM':
            _serviceCtrCd = 'MM'
            _groupId = '1'
            break
          case 'LK':
            _serviceCtrCd = 'LK'
            _groupId = '1'
            break
          case 'PK':
            _serviceCtrCd = 'PK'
            _groupId = '1'
            break
        }

        window.location.href = `/${window.location.hostname === 'localhost' ? '' : 'index.html'}#/common/pds?messageIdSeq=C${_serviceCtrCd === 'JP' ? 'JA' : _serviceCtrCd}${_groupId}&groupId=${_groupId}`
        window.location.reload()
      } else {
        //
        switch (this.serviceLang) {
          case 'KOR':
            _serviceCtrCd = 'KR'
            _groupId = '442'
            break
          case 'ENG':
            _serviceCtrCd = 'AE'
            _groupId = '1'
            break
          case 'JPN':
            _serviceCtrCd = 'JP'
            _groupId = '18'
            break
          case 'CHN':
            _serviceCtrCd = 'ZH'
            _groupId = '2'
            break
        }

        window.location.href = `/${window.location.hostname === 'localhost' ? '' : 'index.html'}#/common/pds?messageIdSeq=C${_serviceCtrCd === 'JP' ? 'JA' : _serviceCtrCd}${_groupId}&groupId=${_groupId}`
        window.location.reload()
      }
    }
  }
}

</script>
