<template>
  <div class="per_section">
    <h2>{{ $t('msg.PERS010L020.025') }}</h2>
    <p class="schedule_btn"><button type="button" class="button sm" @click="openPopup('ScheduleComparePopType01')">{{ $t('msg.PERS010L020.026') }}</button></p>
    <table class="tbl_search">
      <colgroup>
        <col width="60px"><col>
      </colgroup>
      <tbody>
        <tr>
          <th style="padding: 4px 5px 4px 0px">{{ $t('msg.VOSD100.002') }}</th>
          <td>
            <e-auto-complete-place
              :auto-focus="true"
              id="polPol"
              @change="changePol"
              :ctr-cd="pol.ctrCd"
              :plc-cd="pol.plcCd"
              :is-char="true"
              :is-char-alert="true"
            />
          </td>
        </tr>
        <tr>
          <th style="padding: 4px 5px 4px 0px">{{ $t('msg.VOSD100.003') }}</th>
          <td>
            <e-auto-complete-place
              id="podPod"
              @change="changePod"
              :ctr-cd="pod.ctrCd"
              :plc-cd="pod.plcCd"
              :is-char="true"
              :is-char-alert="true"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- 팝업  -->
    <dl v-for="(item, index) in items" :key="index" class="schedule">
      <dt>
        <input type="checkbox" name="schedule-chk" :id="`schedule-chk-${item.logRno}`" v-model="item.chk" @change="test">
        <label :for="`schedule-chk-${item.logRno}`"><span class="offscreen">{{ item.logRno }}</span></label>
        {{ item.vslNm }} {{ item.voyNo }}
      </dt>
      <dd>
        {{ item.polPortNm }}
        <span v-if="item.tsYn === 'Y'" class="arr">&gt;</span>
        {{ item.tsYn === 'Y' ? '(T/S) '+item.pod1PortNm : '' }}
        <span class="arr">&gt;</span>
        {{ item.podPortNm }}
        <!--        {{ item.tsYn !== 'Y' ? 'DIRECT ' : '' }}{{ item.podPortNm }}-->
      </dd>
    </dl>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import apiCompare from '@/api/rest/schedule/compare'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ScheduleComparePopType01 from '@/components/schedule/ScheduleComparePopType01'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace.vue'

export default {
  name: 'RecentSchedule',
  components: {
    EAutoCompletePlace,
    WinLayerPop,
    ScheduleComparePopType01
  },
  props: {
    lineCount: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      custComponent: null,
      selectData: [],
      rowsCount: 0,
      items: [],
      fields: [
        { key: 'chk', label: 'chk', text: 'chk', sortable: false },
        { key: 'logRno', label: 'logRno', text: 'logRno', sortable: false },
        { key: 'logCatCd', label: 'logCatCd', text: 'logCatCd', sortable: false },
        { key: 'pgmCatCd', label: 'pgmCatCd', text: 'pgmCatCd', sortable: false },
        { key: 'logDt', label: 'logDt', text: 'logDt', sortable: false },
        { key: 'logTm', label: 'logTm', text: 'logTm', sortable: false },
        { key: 'cstId', label: 'cstId', text: 'cstId', sortable: false },
        { key: 'polCtrCd', label: 'polCtrCd', text: 'polCtrCd', sortable: false },
        { key: 'vslCd', label: 'vslCd', text: 'vslCd', sortable: false },
        { key: 'vslNm', label: 'vslNm', text: 'vslNm', sortable: false },
        { key: 'voyNo', label: 'voyNo', text: 'voyNo', sortable: false },
        { key: 'polCtrNm', label: 'polCtrNm', text: 'polCtrNm', sortable: false },
        { key: 'polPortCd', label: 'polPortCd', text: 'polPortCd', sortable: false },
        { key: 'polPortNm', label: 'polPortNm', text: 'polPortNm', sortable: false },
        { key: 'podCtrCd', label: 'podCtrCd', text: 'podCtrCd', sortable: false },
        { key: 'podCtrNm', label: 'podCtrNm', text: 'podCtrNm', sortable: false },
        { key: 'podPortCd', label: 'podPortCd', text: 'podPortCd', sortable: false },
        { key: 'podPortNm', label: 'podPortNm', text: 'podPortNm', sortable: false },
        { key: 'dpoDt', label: 'dpoDt', text: 'dpoDt', sortable: false },
        { key: 'dpoTm', label: 'dpoTm', text: 'dpoTm', sortable: false },
        { key: 'apoDt', label: 'apoDt', text: 'apoDt', sortable: false },
        { key: 'apoTm', label: 'apoTm', text: 'apoTm', sortable: false },
        { key: 'eiCatCd', label: 'eiCatCd', text: 'eiCatCd', sortable: false },
        { key: 'schInqYm', label: 'schInqYm', text: 'schInqYm', sortable: false },
        { key: 'closYn', label: 'closYn', text: 'closYn', sortable: false },
        { key: 'fstEntUno', label: 'fstEntUno', text: 'fstEntUno', sortable: false },
        { key: 'fstEntDtm', label: 'fstEntDtm', text: 'fstEntDtm', sortable: false }
      ],
      pol: '',
      pod: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    lineCount (nv, ov) {
      this.rowsCount = nv
      this.init()
    }
  },
  mounted () {
    this.rowsCount = this.lineCount
    //this.init()
  },
  methods: {
    init () {
      this.items = []
      this.getMyHistory()
    },
    openPopup (compNm) {
      this.selectData = this.items.filter(v => { return v.chk === true })
      console.log('부모', this.selectData)

      if (this.selectData.length < 2) {
        this.openAlert(this.$i18n.t('msg.PERS010L020.027'))
        return
      }
      if (this.selectData.length >= 4) {
        this.openAlert(this.$i18n.t('msg.PERS010L020.028'))
        return
      }
      if (this.custComponent !== null) {
        this.custComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.$emit('child', { type: 'openPop', payload: { compNm: compNm, selectData: this.selectData } })
      // this.custComponent = compNm
      // this.$ekmtcCommon.layerOpen('')
    },

    closePopup (obj) {
      this.custComponent = null
      this.$ekmtcCommon.layerClose('.popup_dim')
    },

    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    test (v1) {
      console.log(v1)
      console.log(this.items)
    },
    changePol (vo) {
      this.pol = vo.plcCd
      this.getMyHistory()
    },
    changePod (vo) {
      this.pod = vo.plcCd
      this.getMyHistory()
    },
    getMyHistory () {
      const params = {
        cnt: this.rowsCount,
        pol: this.pol,
        pod: this.pod
      }
      apiCompare.getMyHistory(params)
        .then(response => {
          if (response.data) {
            const tmp = response.data.map(d => {
              return {
                ...d,
                chk: false
              }
            })
            this.items = tmp
          }
        })
    }
  }
}
</script>
