import Send from '../common.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //notices - 기타정보 > 공지사항 리스트 - GET /common/notices
  getNoticesList (formData) {
    return Send({
      url: '/common/notices',
      method: 'get',
      params: {
        tableId: formData.tableId || 'A',
        title: formData.title,
        content: formData.contents,
        curPage: formData.curPage,
        pageSize: formData.pageSize,
        select: formData.select,
        catCd: formData.catCd,
        mainCall: formData.mainCall,
        cdId: formData.cdId,
        serviceCtrCd: formData.serviceCtrCd
      }
    })
  },
  getPopupList (formData) {
    return Send({
      url: '/common/notices/popups',
      method: 'get',
      params: {
        tableId: formData.tableId || 'A',
        serviceCtrCd: formData.serviceCtrCd
      }
    })
  },
  getPopupDetailInfo (messageNum) {
    return Send({
      url: '/common/notices/popups/' + messageNum,
      method: 'get'
    })
  },
  //noticesDetail - 기타정보 > 공지사항 상세 - GET /common/notices/{noticeId}
  getNoticesDetail (noticeId, tableId, serviceCtrCd) {
    return Send({
      url: '/common/notices/' + noticeId,
      method: 'get',
      params: {
        tableId: tableId || 'A',
        serviceCtrCd: serviceCtrCd
      }
    })
  },
  //findCommonCode - 공통코드 가져오기 - GET /commons/codes/common
  getCode (formData) {
    return Send({
      url: '/commons/codes/common',
      method: 'get',
      params: {
        cdId: formData.cdId,
        arrCdId: formData.arrCdId || ''
      }
    })
  },
  //fileDownload - 파일 다운로드 - GET /commons/notices/{fileId}/file-download
  fileDownload (formData) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/common/notices/' + formData.noticeId + '/file-download',
        method: 'get',
        responseType: 'blob',
        params: {
          noticeId: formData.noticeId,
          langCd: formData.lang,
          lang: formData.lang,
          seq: formData.seq
        }
      }).then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
  }
}
