var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isSlick,
          expression: "isSlick",
        },
      ],
      staticClass: "per_recom",
    },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.024")))]),
      _c(
        "div",
        { staticClass: "recom_sch_area" },
        [
          _c("div", { staticClass: "indicator" }, [
            _c(
              "ul",
              [
                _vm._l(_vm.scheduleList, function (li, i) {
                  return [
                    _c("li", { key: "schedule-indicator-" + i }, [
                      _c("a", {
                        class: parseInt(_vm.currentIdx) == i ? "on" : "",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clickIndicator(i)
                          },
                        },
                      }),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm.isSlick
            ? _c(
                "Slick",
                {
                  ref: "slick",
                  attrs: { options: _vm.slickOptions },
                  on: { afterChange: _vm.handleAfterChange },
                },
                _vm._l(_vm.scheduleList, function (schedule, i) {
                  return _c(
                    "div",
                    {
                      key: "schedule-slick-" + i,
                      staticClass: "recom_sch",
                      on: {
                        mousedown: _vm.mouseDown,
                        mouseup: _vm.mouseUp,
                        click: function ($event) {
                          return _vm.moveSchedulePage(i)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "port" }, [
                        _c("span", [_vm._v(_vm._s(schedule.polPortCd))]),
                        _c("span", [_vm._v(_vm._s(schedule.podPortCd))]),
                      ]),
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(schedule.info)),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }