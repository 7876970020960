import Send from '../../cp.client.js'

export default {
    cpCommonCodeList (param) {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/cp/common/code',
                method: 'get',
                params: {
                    groupCd: param
                }
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    countryList () {
        return Send({
            url: '/cp/common/countryList',
            method: 'get'
        })
    },
    countryListForEntry () {
        return Send({
            url: '/cp/common/countryListForEntry',
            method: 'get'
        })
    },
    harborList (param) {
        return Send({
            url: '/cp/common/harborList',
            method: 'get',
            params: {
                ctrCd: param
            }
        })
    },
    harborListForEntry (param) {
        return Send({
            url: '/cp/common/harborListForEntry',
            method: 'get',
            params: {
                ctrCd: param
            }
        })
    },
    cstCatList (param) {
        return Send({
            url: '/cp/common/cstCatList',
            method: 'get'
        })
    },
    cstSearchList (param) {
        return Send({
            url: '/cp/common/cstList',
            method: 'get',
            params: {
                cstCatCd: param.cstCatCd,
                cstNo: param.cstNo,
                cstEnm: param.cstEnm,
                cstNm: param.cstNm,
                ctrNm: param.ctrNm,
                salUno: param.salUno
            }
        })
    },
    actionList () {
        return Send({
            url: '/cp/common/actionList',
            method: 'get'
        })
    },
    cmdtGrpList () {
        return Send({
            url: '/cp/common/cmdtGrpList',
            method: 'get'
        })
    },
    cmdtItemList (param) {
        return Send({
            url: '/cp/common/cmdtItemList',
            method: 'get',
            params: {
                cmdtGrpCd: param
            }
        })
    },
    cntrSzTypList () {
        return Send({
            url: '/cp/common/cntrSzTypList',
            method: 'get'
        })
    },
    profileList (param) {
        return Send({
            url: '/cp/common/profileList',
            method: 'get',
            params: {
                cstNos: param
            }
        })
    },
    cstChooseList (param) {
        return Send({
            url: '/cp/common/cstChooseList',
            method: 'get',
            params: {
                cstCatCd: param.cstCatCd,
                ships: param.ships
            }
        })
    },
    terminalList (countryCd, harborCd) {
        return Send({
            url: '/cp/common/terminalList',
            method: 'get',
            params: {
                porCtr: countryCd,
                porPlc: harborCd
            }
        })
    },
    fairwayList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/cp/common/fairwayList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    shipNmList () {
        return new Promise((resolve, reject) => {
            return Send({
                url: '/cp/common/shipNmList',
                method: 'get'
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    cpRole () {
      return Send({
        url: '/cp/common/cpRole',
        method: 'get'
      })
    }
}
