<template>
  <div class="footer">
    <!-- footer -->
    <win-layer-pop class="footer-pop">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
      />
    </win-layer-pop>
    <div class="inner_footer">
      <div class="wrap_address">
        <span class="logo_foot"></span>
        <p class="address">{{ $t('footer.addr') }} <span class="tel">{{ $t('footer.tel') }}</span></p>
        <p class="copyright">2022 KOREA MARINE TRANSPORT CO. LTD</p>
      </div>

      <div class="wrap_address" v-if="serviceLang == 'CHN'">
        <a style="color:#fff; opacity: 0.6;  font-size:11px;" href="https://beian.miit.gov.cn" target="_blank;">沪ICP备12037401号-2</a> <br><br>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902002679" target="_blank;">
          <img src="/img/ICP.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'

export default {
  name: 'LayoutFooter',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'email-collect-refuse-pop': () => import('@/pages/common/popup/EmailCollectRefusePop'),
    'inform-handle-policy-pop': () => import('@/pages/common/popup/InformHandlePolicyPop')
  },
  data: function () {
    return {
      customComponent: null
    }
  },
  computed: {
    ...rootComputed
  },
  methods: {
    goContactUs () {
      this.$router.push({ name: 'network', query: {} }).catch(() => {})
    },
    // 팝업 열기
    openPopup (cmpNm) {
      this.customComponent = cmpNm
      this.$ekmtcCommon.layerOpen('.footer-pop')
    },
    // 팝업 닫기
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.footer-pop')
    }
  }
}
</script>
