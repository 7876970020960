var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pers_profile logout" }, [
    _c("p", { staticClass: "photo" }, [
      _c("img", {
        staticClass: "cs-pointer",
        attrs: { src: _vm.getProfileImg() },
        on: {
          click: function ($event) {
            return _vm.movePage("/my-info/setting/profile")
          },
        },
      }),
    ]),
    !_vm.isEmpty(_vm.jwt) && !_vm.isEkmtc
      ? _c(
          "span",
          {
            staticClass: "change-profile",
            on: {
              click: function ($event) {
                return _vm.updateProfile()
              },
            },
          },
          [_c("button", { staticClass: "tbl_icon refresh" })]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c("p", { staticClass: "name" }, [
          _vm.isEmpty(_vm.profile)
            ? _c("span", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.000")))])
            : _c(
                "span",
                {
                  staticClass: "cs-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.movePage("/my-info/setting/profile")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.profile.picAlsNm))]
              ),
          _vm._v(
            " " +
              _vm._s(_vm.$t("msg.PERS010L020.001")) +
              " " +
              _vm._s(_vm.profile.preUserId) +
              "(" +
              _vm._s(_vm.profile.userCtrCd) +
              ") "
          ),
        ]),
        _vm.isEmpty(_vm.jwt)
          ? [
              _c("p", { staticClass: "nts" }, [
                _vm._v(_vm._s(_vm.$t("msg.PERS010L020.002"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("msg.PERS010L020.003"))),
              ]),
              _c("p", { staticClass: "login_btn" }, [
                _c(
                  "a",
                  {
                    staticClass: "button sm",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openLoginForm()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.004")))]
                ),
              ]),
            ]
          : [
              _c("p", { staticClass: "date" }, [
                _vm._v(_vm._s(_vm.$t("msg.PERS010L020.005")) + " : "),
                _c("span", [_vm._v(_vm._s(_vm.loginDtm))]),
              ]),
              _c("dl", [
                _c("dt", [_vm._v(_vm._s(_vm.$t("msg.PERS010L020.006")))]),
                _c("dd", [_vm._v(_vm._s(_vm.compName))]),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }