var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$breadcrumbs.length
    ? _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
        _c(
          "ul",
          { staticClass: "location" },
          [
            _vm._m(0),
            _vm._l(this.$breadcrumbs, function (crumb, key) {
              return _c(
                "li",
                { key: key, attrs: { "aria-current": "page" } },
                [
                  crumb.meta.breadcrumb
                    ? _c(
                        "router-link",
                        { attrs: { to: { path: _vm.getPath(crumb) } } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getBreadcrumb(
                                  crumb.meta.breadcrumb,
                                  crumb.meta.key
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("a", { staticClass: "home" }, [_vm._v("home")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }