var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "per_section brdbtm_none" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.001")))]),
    _c("ul", { staticClass: "per_quick" }, [
      _c("li", [
        _vm.serviceLang == "KOR"
          ? _c("a", {
              staticClass: "per_qicon ico1",
              attrs: { href: "javascript:void(0)" },
              domProps: { innerHTML: _vm._s(_vm.$t("msg.MAIN010G030.002")) },
              on: {
                click: function ($event) {
                  return _vm.movePage("/pickup-detail")
                },
              },
            })
          : _vm._e(),
      ]),
      _c("li", [
        _c("a", {
          staticClass: "per_qicon ico2",
          attrs: { href: "javascript:void(0)" },
          domProps: { innerHTML: _vm._s(_vm.$t("msg.MAIN010G030.006")) },
          on: {
            click: function ($event) {
              return _vm.movePage("/calcIssue/freetime-request")
            },
          },
        }),
      ]),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "per_qicon ico4",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.openPopup("BlDoPaymentPop")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.ONIM050G010.001")))]
        ),
      ]),
      _c("li", [
        _vm.serviceLang == "KOR"
          ? _c(
              "a",
              {
                staticClass: "per_qicon ico3",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.movePage("/container-return")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MAIN010G030.014")))]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }