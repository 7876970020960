import OpenPopupLayout from '@/layout/OpenPopupLayout'
import OpenPopupAddressResult from '@/pages/open_popup/AddressResult'
const routes = [
  {
    path: '/open-popup',
    component: OpenPopupLayout,
    children: [
      {
        path: 'address-result',
        component: OpenPopupAddressResult
      },
      {
        path: 'voc-pop',
        component: () => import('@/pages/trans/popup/BookingVOCPop')
      },
      {
        path: 'print-pop',
        component: () => import('@/pages/trans/popup/ContentPrintPop'),
        props: true
      },
      {
        path: 'notice-pop',
        component: () => import('@/pages/trans/popup/NoticePop'),
        props: true
      }
    ]
  }
]

export default routes
