var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("body", { staticClass: "bsaMain" }, [
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "container_main" }, [
          _c("div", { staticClass: "content_main" }, [
            _c("div", { staticClass: "visual_area" }, [
              _c("form", { attrs: { id: "frm_main" } }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }