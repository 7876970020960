<template>
  <div class="popup_wrap" id="profile_pop" style="width:620px;">
    <!--  popup_wrap  width:520px; height:auto; -->
    <button class="layer_close" @click="close()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.MAIN.001') }}</h1>
      <div class="clearfix">
        <span class="tit_label">Profile Search</span>
        <input type="text" id="filter" class="wid200" v-model="txtFilter" autocomplete="off">
        <a
          class="button blue sh"
          @click="changeFilter()"
        >{{ $t('msg.CMBA100.00120') }}</a> <!-- 검색 -->
      </div>

      <div class="content_box">
        <ul class="profile_list choice hscroll">
          <li class="more" @click="addProfilePage()">
            <a href="javascript:void(0)"><span class="offscreen"></span></a>
          </li>
          <template v-for="(p, i) in filterList">
            <li v-if="p.useYn === 'Y'" :class="p.isMe ? 'my' : ''" :key="'profile-' + i" @click="select(i)" style="position: relative;">
              <div class="tooltip_wrap short position_absolute" :style="i % 5 === 3 ? 'display: none; right: 80px;' :'display: none; right: -80px; color: black !important;'">
                <div class="cont" style="padding: 0px;">
                  <ul>
                    <li>
                      {{ p.preUserId }} <br> {{ p.userCtrCd }}
                    </li>
                  </ul>
                </div><!-- cont -->
              </div><!-- tooltip_wrap // -->
              <p :class="p.useYn == 'N' ? 'no-use' : 'img'" @mouseover="showTooltip" @mouseout="hideTooltip">
                <img v-if="!isEmpty(p.picPotoString)" :src="p.picPotoString" alt="">
                <img v-else-if="isEmpty(p.picPotoString) && !isEmpty(p.fileNm) && getFilePath(p.fileNm) != null" :src="getFilePath(p.fileNm)" alt="">
                <img v-else :src="require('@/assets/images/pers/profile_noimg.png')" alt="">
                <span v-if="p.useYn == 'N'">NO USE</span>
              </p>
              <p class="name" style="color: #000;"> {{ isEmpty(p.picAlsNm) ? p.picNm : p.picAlsNm }}</p>
            </li>
          </template>
        </ul>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>
<script>
export default {
  name: 'SelectProfile',
  props: [
    'profileList'
  ],
  data: function () {
    return {
      txtFilter: '',
      filterList: []

    }
  },
  computed: {
    isEkmtc () {
      return sessionStorage.getItem('loginId').indexOf('EKMTC_') !== -1
    }
  },
  watch: {
  },
  created () {
  },
  mounted: function () {
    this.filterList = this.profileList
  },
  methods: {
    select (idx) {
      if (this.isEkmtc) {
        let id = sessionStorage.getItem('loginId').replace('EKMTC_', '')
        if (this.filterList[idx].userId !== id) {
           let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$i18n.t('msg.SETT060G010.018'),
            useIcon: false,
            customClass: 'simple_alert',
            message: '본인의 프로필만 사용할 수 있습니다.',
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
          return
        }
      }

      let profileIdx = 0
      for (let i = 0; i < this.profileList.length; i++) {
        if (this.profileList[i].userId === this.filterList[idx].userId) {
          profileIdx = i
          break
        }
      }
      this.$emit('child', { type: 'select', payload: { idx: profileIdx } })
    },
    close () {
      this.$emit('child', { type: 'close', payload: null })
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    addProfilePage () {
      this.$emit('child', { type: 'addProfilePage', payload: this.profileList })
    },
    getFilePath (fileNm) {
      try {
        let path = require('@/assets/images/myin/' + fileNm)
        return path
      } catch (error) {
        return null
      }
    },
    showTooltip (e) {
      const el = e.target
      const parentEl = el.parentElement.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'inline-block'
    },
    hideTooltip (e) {
      const el = e.target
      const parentEl = el.parentElement.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'none'
    },
    changeFilter () {
      if (this.txtFilter === '') {
          this.filterList = this.profileList.filter(vo => {
            return true
          })
      } else {
        this.filterList = this.profileList.filter(vo => {
          let picNm = this.isEmpty(vo.picAlsNm) ? vo.picNm : vo.picAlsNm
          picNm = picNm.toLowerCase()
          let txtFilter = this.txtFilter.toLowerCase()
          if (picNm.indexOf(txtFilter) !== -1) {
            return true
          } else {
            return false
          }
        })
      }
    }
  }
}
</script>
<style scoped>
  .no-use {
    position: relative;
    display: flex;
    opacity: 0.6;
    padding-left: 14px;
    padding-top: 10px;
    width: 72px;
  }
  .no-use > span {
    font-weight: bold;
    position: absolute;
    width: inherit;
    text-align: center;
    padding-top: 25px;
  }
  .my > .no-use > img {
  /*   width: 66px !important;
    padding-left: 2px !important; */
  }

</style>
