import store from '@/store/index'

export function checkPermission (to, from) {
    let res = {
        isPermit: true,
        path: ''
    }
    let jwt = store.state.auth.jwt
    let serviceLang = store.state.auth.serviceLang
    /*
      임시로 login여부만 판단.
      추가 조건이 필요하다면 파라메터 받아서 각 페이지마다 조건 설정
    */
    if (jwt === '') {
        if (to.path === '/calcIssue/freetime-request') {
            // if (serviceLang !== 'KOR') {
            //     res.isPermit = false
            //     res.path = '/main'
            // }
        } else {
            store.commit('auth/requireLogin', true)
            res.isPermit = false
            res.path = '/main'
        }
    }
    store.commit('endSpinner')
    return res
}
