import Vue from 'vue'
import VueRouter from 'vue-router'

import sample from './sample'
import open_popup from './open_popup'
import topmenu01_03 from './topmenu01_03'
import topmenu04_06 from './topmenu04_06'
import topmenu07_09 from './topmenu07_09'
import topmenu_cp from './topmenu_cp'
import topmenu_bsa from './topmenu_bsa'
import personal from './personal'
import topmenu99 from './topmenu99'

import HomeLayout from '@/layout/HomeLayout'
import BsaHomeLayout from '@/layout/bsa/BsaHomeLayout'
import NotFound from '@/pages/sample/NotFound'
import Main from '@/pages/Main'
import BsaMain from '@/pages/BsaMain'
import IntegratedSearchResult from '@/pages/IntegratedSearchResult'
import store from '../store/index'

const staffFlag = store.getters['auth/isStaff']

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomeLayout,
    redirect: '/main',
    meta: {
      breadcrumb: 'Home'
    },
    children: [
      {
        path: '/main',
        component: Main,
        meta: {
          breadcrumb: 'Main'
        }
      },
      ...topmenu01_03,
      ...topmenu04_06,
      ...topmenu07_09,
      ...(staffFlag ? topmenu_cp : []), // CP 메뉴 우선 활성화 하고 필요시 후단에서 막아야함.
      ...personal,
      ...topmenu99,
      {
        path: 'integrated-search',
        name: 'integrated-search',
        component: IntegratedSearchResult,
        props: true,
        meta: {
          breadcrumb: '통합검색'
        }
      }//,
      //{
      //  path: '*',
      //  redirect: '/main'
      //}
      // ...topmenu99
    ]
  },
  {
    path: '/bsa',
    component: BsaHomeLayout,
    redirect: '/bsaMain',
    meta: {
      breadcrumb: 'Home'
    },
    children: [
      {
        path: '/bsaMain',
        component: BsaMain,
        meta: {
          breadcrumb: 'BsaMain'
        }
      },
      ...topmenu_bsa
    ]
  }
]

const allRoutes = [
  ...routes,
  ...sample,
  ...open_popup,
  ...[
    { path: '/404', component: NotFound },
    {
      path: '*',
      //component: NotFound
      //component: Main
      redirect: '/main'
    }
  ]
]

const router = new VueRouter({
  routes: allRoutes
})

let directLoginAccess = false

router.beforeEach((to, from, next) => {
  // 1번 해결 로컬스토리지 체크
  const loggedIn = sessionStorage.getItem('auth')
  // console.log('route', loggedIn)
  // 2번 해결 requiresAuth 체크
  const dsmYn = true //process.env.VUE_APP_MODE !== 'PRD' // JamSin DSM yn LOCAL, DEV, STG 에서만 DSM 처리
  //const hisYn = process.env.VUE_APP_MODE !== 'PRD' // JamSin his 진행중업무 히스토리 백 데이터 백업
  const lock = store.getters['auth/lock']

  if (lock) {
    const app = router.app
    let msg = ''

    try {
      msg = app.$t('msg.UTIL010G010.022', { minutes: process.env.VUE_APP_AUTH_TIMEOUT })
    } catch (e) {
      //
    }

    if (msg) {
      (async () => {
        if (await app.$ekmtcCommon.asyncAlertMessage({ message: app.$t('msg.UTIL010G010.022', { minutes: process.env.VUE_APP_AUTH_TIMEOUT }), useConfirmBtn: true })) {
          store.commit('auth/requireLoginRtnPath', '##unlock##')
          store.commit('auth/requireLogin', true)
          store.commit('auth/requireLoginRtnPath', to.fullPath)
        } else {
          store.dispatch('auth/delLogin').then(resolve => {
            location.replace('/')
          })
        }
      })()

      return
    }
  }

  // JamSin his 부킹, BL에서 진행중업무로 히스토리 빽을 했을 경우 구분한다.
  //if (hisYn) {
  //  if (from.path.indexOf('/working/detail') > -1) {
  //    if (to.path === '/working/progress') {
  //      localStorage.setItem('workingHistory', true)
  //    }
  //  } else {
  //    localStorage.setItem('workingHistory', false)
  //  }
  //}

  if (to.matched.some((route) => route.meta.requiresAuth)) {
  // if (to.matched.some(record => record.meta.requireAuth)) {
    // 로그인 상태가 아니면 '/' 여기로 보내버린다.
    // console.log('로그인이 필요한데 로그인 아님')

    if (!loggedIn) {
      store.commit('auth/requireLogin', true)
      store.commit('auth/requireLoginRtnPath', to.fullPath)

      if (from.path === '/') {
        directLoginAccess = true
        next('/')
      }

      return
    }
  } else {
    if (to.path === '/schedule/leg' && dsmYn) { // JamSin DSM 선호항로, 선복보장 url 로 연결된 스케줄은 로그인으로 연결
      if (!loggedIn) {
        if (Object.keys(to.query).length > 0 && Object.keys(to.query).indexOf('loginChk') < 0) {
          store.commit('auth/requireLoginRtnPath', '##unlock##')
          store.commit('auth/requireLogin', true)
          store.commit('auth/requireLoginRtnPath', to.fullPath)
          if (from.path === '/') {
            directLoginAccess = true
            next('/')
          }

          return
        }
      }
    }
  }

  if (directLoginAccess && store.getters['auth/requireLoginRtnPath']) {
    directLoginAccess = false
    store.commit('auth/requireLogin', true)
  } else {
    store.commit('auth/requireLogin', false)
  }

  store.commit('startSpinner')

  // requiresAuth가 false일때 즉, 권한이 필요 없는 페이지 일때
  setTimeout(() => {
    next()
  }, 1)
})
router.afterEach((to, from) => {
  store.commit('endSpinner')
})
export default router
