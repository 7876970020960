<template>
  <div class="content"><!-- content : 화면사이즈 900으로 고정 widfix 추가 -->
    <e-breadcrumbs />
    <win-layer-pop class="no-search-result-pop2">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="popupParams"
      />
    </win-layer-pop>

    <h1 class="page_title">{{ $t('msg.UTIL010G010.006') }}</h1><!-- 통합검색 -->

    <div class="content_box mt10"><!-- content_box -->

      <div class="schbox">
        <span class="inputClear">
          <input
            v-model="searchKeyword"
            type="text"
            placeholder="B/L No. / Cntr No. / Booking No. / Keyword"
            @keyup.prevent="preventFirstInputWhiteSpace()"
            @keyup.enter.prevent="integratedSearch()"
          />
          <span
            :class="['icon_clear', !keywordClaerBtn ? 'hidden' : '']"
            @click.prevent="clearKeyword()"
          ></span>
        </span>
        <a
          class="button"
          href="#"
          @click.prevent="integratedSearch()"
        >{{ $t('msg.UTIL010G010.007') }}</a><!-- 검색 -->
      </div>

      <div class="sch_section fir"><!-- sch_section-->
        <p class="title">
          <span>{{ $t('msg.UTIL010G010.008') }}</span><!-- 메뉴 --> {{ $t('msg.UTIL010G010.009') }}<!-- 검색 결과 --> :
          <span class="font_light">
            {{ $t('msg.UTIL010G010.015') }}<!-- 총 --> <span class="num">{{ integratedSearchResult.menuList !== undefined && integratedSearchResult.menuList.length > 0 ? integratedSearchResult.menuList.length : 0 }}</span>{{ $t('msg.UTIL010G010.010') }}<!-- 건 -->
          </span>
        </p>

        <div v-if="integratedSearchResult.menuList !== undefined && integratedSearchResult.menuList.length > 0">
          <dl
            v-for="menu in integratedSearchResult.menuList"
            :key="menu.routerName"
          >
            <dt>
              <a
                href="#"
                @click.prevent="$router.push({ name: menu.routerName })"
              >
                <p v-html="menu.menuName">
                </p>
              </a>
            </dt>
            <dd>
              {{ menu.menuPath }}
            </dd>
          </dl>
        </div>
        <div v-else class="nodata">{{ $t('msg.UTIL010G010.011') }}</div><!-- 검색결과가 없습니다. -->

      </div><!-- sch_section // -->

      <div class="sch_section"><!-- sch_section-->
        <p class="title">
          <span>{{ $t('msg.UTIL010G010.012') }}</span><!-- FAQ --> {{ $t('msg.UTIL010G010.009') }}<!-- 검색 결과 --> :
          <span class="font_light">
            {{ $t('msg.UTIL010G010.015') }}<!-- 총 --> <span class="num">{{ integratedSearchResult.boardList.faqList !== undefined && integratedSearchResult.boardList.faqList.length > 0 ? integratedSearchResult.boardList.faqList.length : 0 }}</span>{{ $t('msg.UTIL010G010.010') }}<!-- 건 -->
          </span>
        </p>

        <div v-if="integratedSearchResult.boardList.faqList !== undefined && integratedSearchResult.boardList.faqList.length > 0">
          <dl
            v-for="faq in integratedSearchResult.boardList.faqList"
            :key="faq.messageIdSeq"
          >
            <dt>
              <!-- <a href="#" @click.prevent="$router.push({ name: 'faq', params: { integratedSearchKeyword: searchKeyword, integratedSearchVo: faq } })"><p v-html="faq.title"></p></a> <span class="date">{{ $ekmtcCommon.changeDatePattern(faq.createDate, '-') }}</span> -->
              <a href="#" @click.prevent="moveDetail('faq', searchKeyword, faq)"><p v-html="faq.title"></p></a> <span class="date">{{ $ekmtcCommon.changeDatePattern(faq.createDate, '-') }}</span>
            </dt>
            <dd class="text_overflow" v-html="faq.contents"></dd>
          </dl>
        </div>
        <div v-else class="nodata">{{ $t('msg.UTIL010G010.011') }}</div><!-- 검색결과가 없습니다. -->

      </div><!-- sch_section // -->

      <div class="sch_section"><!-- sch_section-->
        <p class="title">
          <span>{{ $t('msg.UTIL010G010.013') }}</span><!-- 자료실 --> {{ $t('msg.UTIL010G010.009') }}<!-- 검색 결과 --> :
          <span class="font_light">
            {{ $t('msg.UTIL010G010.015') }}<!-- 총 --> <span class="num">{{ integratedSearchResult.boardList.pdsList !== undefined && integratedSearchResult.boardList.pdsList.length > 0 ? integratedSearchResult.boardList.pdsList.length : 0 }}</span>{{ $t('msg.UTIL010G010.010') }}<!-- 건 -->
          </span>
        </p>

        <div v-if="integratedSearchResult.boardList.pdsList !== undefined && integratedSearchResult.boardList.pdsList.length > 0">
          <dl
            v-for="pds in integratedSearchResult.boardList.pdsList"
            :key="pds.messageIdSeq"
          >
            <dt>
              <!-- <a href="#" @click.prevent="$router.push({ name: 'pds', params: { integratedSearchKeyword: searchKeyword, integratedSearchVo: pds } })"><p v-html="pds.title"></p></a> <span class="date">{{ $ekmtcCommon.changeDatePattern(pds.createDate, '-') }}</span> -->
              <a href="#" @click.prevent="moveDetail('pds', searchKeyword, pds)"><p v-html="pds.title"></p></a> <span class="date">{{ $ekmtcCommon.changeDatePattern(pds.createDate, '-') }}</span>
            </dt>
            <dd class="text_overflow" v-html="pds.contents"></dd>
          </dl>
        </div>
        <div v-else class="nodata">{{ $t('msg.UTIL010G010.011') }}</div><!-- 검색결과가 없습니다. -->

      </div><!-- sch_section // -->

      <div class="sch_section"><!-- sch_section-->
        <p class="title">
          <span>{{ $t('msg.UTIL010G010.014') }}</span><!-- 공지사항 --> {{ $t('msg.UTIL010G010.009') }}<!-- 검색 결과 --> :
          <span class="font_light">
            {{ $t('msg.UTIL010G010.015') }}<!-- 총 --> <span class="num">{{ integratedSearchResult.boardList.noticeList !== undefined && integratedSearchResult.boardList.noticeList.length > 0 ? integratedSearchResult.boardList.noticeList.length : 0 }}</span>{{ $t('msg.UTIL010G010.010') }}<!-- 건 -->
          </span>
        </p>

        <div v-if="integratedSearchResult.boardList.noticeList !== undefined && integratedSearchResult.boardList.noticeList.length > 0">
          <dl
            v-for="notice in integratedSearchResult.boardList.noticeList"
            :key="notice.tableId + '_' + notice.noticeId"
          >
            <dt>
              <!-- <a href="#" @click.prevent="$router.push({ name: 'notice', params: { integratedSearchKeyword: searchKeyword, integratedSearchVo: notice } })"><p v-html="notice.title"></p></a> <span class="date">{{ $ekmtcCommon.changeDatePattern(notice.createDate, '-') }}</span> -->
              <a href="#" @click.prevent="moveDetail('notice', searchKeyword, notice)"><p v-html="notice.title"></p></a> <span class="date">{{ $ekmtcCommon.changeDatePattern(notice.createDate, '-') }}</span>
            </dt>
            <dd class="text_overflow" v-html="notice.contents"></dd>
          </dl>
        </div>
        <div v-else class="nodata">{{ $t('msg.UTIL010G010.011') }}</div><!-- 검색결과가 없습니다. -->

      </div><!-- sch_section // -->

    </div><!-- content_box // -->

    <!-- 하단 버튼 <div class="flex_box mt10">
      <a class="button blue lg ml_auto mr5" href="#">확인</a>
      <a class="button gray lg" href="#">취소</a>
    </div> -->

  </div><!-- content // -->
</template>

<script>
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import boards from '@/api/services/boards'
import trans from '@/api/rest/trans/trans'
import { keywords } from '@/api/search-menu'

export default {
  name: 'IntegratedSearchResult',
  components: {
    EBreadcrumbs,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'no-search-result-pop': () => import('@/components/layout/NoSearchResultPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          // $eHeaderUtil: '',
          // 메뉴 리스트(EHeaderUtil에서 받아옴)
          // $keywords: {},
          keyword: '',
          menuList: [],
          boardList: {}
        }
      }
    }
  },
  data () {
    return {
      customComponent: null,
      // 통합검색 키워드
      // test
      searchKeyword: '',
      // searchKeyword: 'KR03129296',
      // searchKeyword: 'PUSB267259',
      // searchKeyword: 'KMTU5904740',
      // 통합검색 clear 버튼 표시유무
      keywordClaerBtn: false,
      // 통합검색 결과 데이터
      integratedSearchResult: {
        //  메뉴 리스트(메뉴 검색 결과)
        menuList: [],
        // FAQ/자료실/공지사항 리스트
        boardList: {}
      },
      // 메뉴 선택 팝업에 넘겨줄 데이터(Booking No & B/L No & Container No)
      popupParams: {}
      // keywords: {},
      // eHeaderUtil: ''
    }
  },
  watch: {
    searchKeyword: function (val) {
      if (val !== '') {
        this.keywordClaerBtn = true
      } else {
        this.keywordClaerBtn = false
      }
    }
  },
  created () {
    const tempIntegratedSearchInfo = sessionStorage.getItem('integrated-search-info')
    let integratedSearchInfo = tempIntegratedSearchInfo === null || tempIntegratedSearchInfo === undefined ? undefined : JSON.parse(tempIntegratedSearchInfo)

    if (integratedSearchInfo !== undefined && integratedSearchInfo.moveFlag === 'Y') {
      // console.log('통합검색에서 왔을 경우')
      this.searchKeyword = this.parentInfo.keyword
      this.integratedSearchResult.menuList = this.parentInfo.menuList
      this.integratedSearchResult.boardList = this.parentInfo.boardList

      // 검색결과 하이라이트 처리
      this.setResultHighlight()

      // moveFlag 설명
      // |_ Y - 통합검색(전체메뉴 우상단 돋보기 클릭 시 검색 할 수 있는 검색팝업을 통해 온 경우, 데이터를 가져오기 때문에 결과 페이지에서 재검색하지 않도록 해야 함.)
      // |_ N - 통합검색 外 에서 온 경우(이전 데이터가 없기 때문에, 검색해야 함.)
      integratedSearchInfo = {
        keyword: this.parentInfo.keyword,
        moveFlag: 'N'
      }

      sessionStorage.setItem('integrated-search-info', JSON.stringify(integratedSearchInfo))
    } else {
      // console.log('통합검색 外 에서 왔을 경우')
      this.searchKeyword = integratedSearchInfo.keyword

      this.integratedSearch()
    }
  },
  methods: {
    clearKeyword () {
      this.searchKeyword = ''
      this.keywordClaerBtn = false
    },
    async integratedSearch () {
      // [TODO]
      // ============================== 통합검색 흐름 ===============================
      // 1. [Booking No, B/L No, Cntr No]가 있는 지 유효성 검사
      // => 매칭되는 No가 있으면 해당 No로 검색하는 메뉴 선택 팝업을 띄움 (2, 3번 실행하지 않음)
      // 2. 상기 정의해놓은 메뉴의 키워드와 비교하여 비교 기준에 일치하면 menuList에 추가
      // 3. [자료실, FAQ, 공지사항]의 게시글의 제목+내용으로 키워드와 비교하여 가져옴.
      // ===========================================================================
      if (this.searchKeyword === '' || (this.searchKeyword !== '' && this.searchKeyword.length < 2)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.UTIL010G010.002')) // 검색어를 2글자 이상 입력해 주세요.
        return
      }

      const isExistNo = await this.searchExistNoByKeyword()
      // 1.
      if (!isExistNo) {
        // 2.
        const searchMenu = this.searchMenuByKeyword()
        // 3.
        const searchBoard = await this.searchBoardList()

        if (!searchMenu && !searchBoard) {
          // this.$ekmtcCommon.alertDefault('해당 번호는 존재하지 않습니다.')
          // this.$ekmtcCommon.alertDefault(this.$t('msg.UTIL010G010.003')) // 검색 결과가 존재하지 않습니다.
          this.$ekmtcCommon.alertDefault(this.$t('msg.UTIL010G010.016')) // 해당 번호는 존재하지 않습니다.
        } else {
          // moveFlag 설명
          // |_ Y - 통합검색(전체메뉴 우상단 돋보기 클릭 시 검색 할 수 있는 검색팝업을 통해 온 경우, 데이터를 가져오기 때문에 결과 페이지에서 재검색하지 않도록 해야 함.)
          // |_ N - 통합검색 外 에서 온 경우(이전 데이터가 없기 때문에, 검색해야 함.)
          const integratedSearchInfo = {
            keyword: this.searchKeyword,
            moveFlag: 'N'
          }

          sessionStorage.setItem('integrated-search-info', JSON.stringify(integratedSearchInfo))

          this.setResultHighlight()
        }
      }
    },
    async searchExistNoByKeyword () {
      let isExistNo = false

      const encodeSearchKeyword = encodeURIComponent(this.searchKeyword)
      const params = {
        keyword: encodeSearchKeyword
      }

      await trans.integratedSearch(params)
        .then(response => {
          const result = response.data.noSearchResult
          if (result !== undefined) {
            const searchType = result.searchType
            const searchCode = result.searchCode
            let menuList = []

            isExistNo = true

            if (searchType === 'BOOKING') {
              menuList = [
                keywords.progress,
                keywords['cargo-tracking']
              ]
            }
            if (searchType === 'BL') {
              menuList = [
                keywords.progress,
                keywords.calcIssueMain,
                keywords.FreeTimeRequest,
                keywords['cargo-tracking']
              ]
            }
            if (searchType === 'CONTAINER') {
              menuList = [
                keywords.FreeTimeRequest,
                keywords['cargo-tracking']
              ]
            }

            this.popupParams = {
              searchType: searchType,
              keyword: searchCode,
              eiCatCd: result.eiCatCd,
              menuList: menuList
            }

            this.openPopup('no-search-result-pop')
          }
        })

      return isExistNo
    },
    searchMenuByKeyword () {
      this.integratedSearchResult.menuList = []

      // 한글 정규식
      const regKor = /^[가-힣/]+$/
      // 영문 정규식
      const regEng = /^[a-zA-Z/]+$/

      // 유저 입력값
      const usrKeyword = this.searchKeyword.trim().replace(/ /gi, '').toUpperCase()
      // 유저 입력값이 한글일 때, 한 글자씩 비교해야 하기 때문에, 배열 선언
      // let spltKeyword = []
      // let cntMatchKorKeyword = 0
      // 메뉴 조회 데이터 유무
      let isSearchMenu = false

      const matchKor = regKor.test(usrKeyword)
      const matchEng = regEng.test(usrKeyword)

      // if (matchKor) {
      //   // 검색어 한글인 경우, 문자열을 낱개로 자른다.
      //   spltKeyword = usrKeyword.split('')
      // }

      const keys = Object.keys(keywords)

      if (matchKor || matchEng) {
        keys.forEach((key, idx1) => {
          if (matchKor) {
            // 검색어 - 한글일 때 =================================================
            // keywords[key].keyword.KR.forEach((keyword) => {
            for (const keyword of keywords[key].keyword.KR) {
              const delWhiteSpaceKeyword = keyword.replace(/ /gi, '')
              // cntMatchKorKeyword = 0
              // spltKeyword.forEach((spltUsrWord) => {
              //   if (delWhiteSpaceKeyword.indexOf(spltUsrWord) > -1) {
              //     cntMatchKorKeyword++
              //   }
              // })
              // if (cntMatchKorKeyword >= 2) {
              //   // 국문 2글자 이상 일치한 경우, 검색 결과에 포함시킴(menuList에 추가)
              //   this.integratedSearchResult.menuList.push(keywords[key])
              //   // return false
              //   break
              // }
              if (delWhiteSpaceKeyword.indexOf(usrKeyword) > -1) {
                // 국문 검색어가 키워드에 포함되는 경우, 검색 결과에 포함시킴(menuList에 추가)
                this.integratedSearchResult.menuList.push(JSON.parse(JSON.stringify(keywords[key])))
                // return false
                break
              }
            }
          } else {
            // 검색어 - 영문일 때 =================================================
            // keywords[key].keyword.EN.forEach((keyword) => {
            for (const keyword of keywords[key].keyword.EN) {
              if (keyword.replace(/ /gi, '').toUpperCase().indexOf(usrKeyword) > -1) {
                // 영문 검색어가 키워드에 포함되는 경우, 검색 결과에 포함시킴(menuList에 추가)
                this.integratedSearchResult.menuList.push(JSON.parse(JSON.stringify(keywords[key])))
                // return false
                break
              }
            }
          }
        })

        if (this.integratedSearchResult.menuList.length > 0) {
          isSearchMenu = true
        }
      }

      return isSearchMenu
    },
    async searchBoardList () {
      this.integratedSearchResult.boardList = {}

      let isSearchBoard = false

      const encodeSearchKeyword = encodeURIComponent(this.searchKeyword)

      await boards.boardsIntegratedSearch(encodeSearchKeyword, localStorage.getItem('service_ctrcd'))
        .then(response => {
          const boardList = response.data
          if (boardList !== undefined && (boardList.faqList.length > 0 || boardList.pdsList.length > 0 || boardList.noticeList.length > 0)) {
            isSearchBoard = true
            this.integratedSearchResult.boardList = boardList
          }
        })

      return isSearchBoard
    },
    // 팝업 열기
    openPopup (cmpNm) {
      this.customComponent = cmpNm
      this.$ekmtcCommon.layerOpen('.no-search-result-pop2')
    },
    // 팝업 닫기
    async closePopup (vo) {
      // if (this.eHeaderUtil.showLogin !== null) {
      if (sessionStorage.getItem('auth') !== null) {
        // 로그인 상태
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.no-search-result-pop2')
        if (vo !== undefined) {
          let query = {
            searchType: vo.searchType,
            keyword: vo.keyword,
            eiCatCd: vo.eiCatCd
          }
          if (vo.menu.routerName === 'progress') {
            query = {
              ...query,
              ...{
                workType: (vo.eiCatCd !== 'I' ? 'E' : 'I'),
                kind: '02'
              }
            }
          } else if (vo.menu.routerName === 'calcIssueMain') {
            query = {
              ...query,
              ...{
                dtKnd: 'BL',
                tab: '1'
              }
            }
          } else if (vo.menu.routerName === 'FreeTimeRequest') {
            query = {
              ...query,
              ...{
                dtKnd: (vo.searchType !== 'CONTAINER' ? 'BL' : 'CN'),
                num: vo.keyword
              }
            }
          }

          this.$router.push({ name: vo.menu.routerName, query: query })
        }
      } else {
        // 비로그인 상태
        if (vo !== undefined) {
          const chkLoginPage = ['progress', 'calcIssueMain']

          let query = {
            searchType: vo.searchType,
            keyword: vo.keyword,
            eiCatCd: vo.eiCatCd
          }

          // 메뉴를 클릭했을 때
          // 로그인 후 검색결과 화면으로 이동합니다.
          // this.$ekmtcCommon.alertCallback(this.$t('msg.UTIL010G010.004'), () => {
          //   this.customComponent = null
          //   this.$ekmtcCommon.layerClose('.no-search-result-pop2')

          //   if (vo.menu.routerName === 'progress') {
          //     query = {
          //       ...query,
          //       ...{
          //         workType: 'E',
          //         kind: '02'
          //       }
          //     }
          //   }

          //   this.$router.push({ name: vo.menu.routerName, query: query })
          // })

          if (chkLoginPage.includes(vo.menu.routerName)) {
            // 로그인 후 검색결과 화면으로 이동합니다.
            await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.UTIL010G010.004') })
          }

          this.customComponent = null
          this.$ekmtcCommon.layerClose('.no-search-result-pop2')

          if (vo.menu.routerName === 'progress') {
            query = {
              ...query,
              ...{
                workType: (vo.eiCatCd !== 'I' ? 'E' : 'I'),
                kind: '02'
              }
            }
          } else if (vo.menu.routerName === 'calcIssueMain') {
            query = {
              ...query,
              ...{
                dtKnd: 'BL',
                tab: '1'
              }
            }
          } else if (vo.menu.routerName === 'FreeTimeRequest') {
            query = {
              ...query,
              ...{
                dtKnd: (vo.searchType !== 'CONTAINER' ? 'BL' : 'CN'),
                num: vo.keyword
              }
            }
          }

          this.$router.push({ name: vo.menu.routerName, query: query })
        } else {
          // 닫기버튼을 클릭했을 때
          this.customComponent = null
          this.$ekmtcCommon.layerClose('.no-search-result-pop2')
        }
      }
    },
    // 검색결과 하이라이트 처리
    setResultHighlight () {
      const keyword = this.searchKeyword
      let idx = 0

      if (this.integratedSearchResult.menuList !== undefined && this.integratedSearchResult.menuList.length > 0) {
        // 메뉴 검색결과 하이라이트 처리
        this.integratedSearchResult.menuList.forEach(menu => {
          idx = menu.menuName.indexOf(keyword)
          if (idx > -1) {
            const regexStr = keyword
            const regexp = new RegExp(regexStr, 'gi')

            // menu.menuName = menu.menuName.replaceAll(keyword, `<span class="schword">${keyword}</span>`)
            menu.menuName = menu.menuName.replace(regexp, `<span class="schword">${keyword}</span>`)
          }
        })
      }

      const keys = Object.keys(this.integratedSearchResult.boardList)

      if (keys.length > 0) {
        // [FAQ, 자료실, 공지사항] 검색결과 하이라이트 처리
        keys.forEach(key => {
          if (this.integratedSearchResult.boardList[key] !== undefined && this.integratedSearchResult.boardList[key].length > 0) {
            this.integratedSearchResult.boardList[key].forEach(board => {
              const regexStr = keyword
              const regexp = new RegExp(regexStr, 'gi')

              idx = board.title.indexOf(keyword)
              if (idx > -1) {
                // board.title = board.title.replaceAll(keyword, `<span class="schword">${keyword}</span>`)
                board.title = board.title.replace(regexp, `<span class="schword">${keyword}</span>`)
              }
              board.title = `[${board.catNm}] ${board.title} `

              idx = board.contents.indexOf(keyword)
              if (idx > -1) {
                // board.contents = board.contents.replaceAll(keyword, `<span class="schword">${keyword}</span>`)
                board.contents = board.contents.replace(regexp, `<span class="schword">${keyword}</span>`)
              }
            })
          }
        })
      }
    },
    preventFirstInputWhiteSpace () {
      this.searchKeyword = this.searchKeyword.replace(/(^\s*)/g, '')
    },
    moveDetail (movePage, keyword, vo) {
      vo.searchInput = keyword
      this.$router.push({ name: movePage, query: vo })
    }
  }
}
</script>

<style scoped>
/*  inputClear  설정   */
::-ms-clear {  display: none; }
.inputClear { position: relative; }
.icon_clear { position:absolute; top:5px; right:10px; cursor: pointer; display:inline-block; width: 14px;height: 14px; background:url(../assets/images/common/icon_clear.png) 0 0 no-repeat; }
.text_overflow {
  height: 42px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
