import Send from '../../trans.client.js'

export default {
  // 수출담당자 팝업에서 쓰이는 수입담당자 정보
  getImportManagerInfo (params) {
    return Send({
      url: '/trans/arrival-notice/import-manager',
      method: 'get',
      params: params
    })
  },
  // 수출담당자 팝업에서 쓰이는 수입담당자 정보
  getImportManagerInfoV2 (params) {
    return Send({
      url: '/trans/arrival-notice/import-manager-info',
      method: 'get',
      params: params
    })
  },
  // 수입업무 담당자 팝업 정보
  findImportManagerDeadlineInfo (blNo) {
    return Send({
      url: '/trans/arrival-notice/' + blNo + '/import-manager-deadline-info',
      method: 'get'
    })
  },
  // 로그 테이블 저장
  insertArrivalNoticeLog (data) {
    return Send({
      url: '/trans/arrival-notice/pdf-log-insert',
      method: 'post',
      data: data
    })
  }
}
