import Send from '../../settle.client.js'

export default {
    getVtlAccPopInfo (param) {
        return Send({
            // url: 'http://localhost:9008/settle/vtlAcc/step',
            url: '/settle/vtlAcc/step',
            method: 'put',
            data: param
        })
    },
    getRemarkList (param) {
        return Send({
            //url: 'http://localhost:9008/settle/invoice/rmk',
            url: '/settle/invoice/rmk',
            method: 'get',
            params: param
        })
    },
    getPickupInfo (param) {
        return Send({
            //url: 'http://localhost:9008/settle/invoice/pickup',
            url: '/settle/invoice/pickup',
            method: 'get',
            params: param
        })
    },
    getFreightInfo (param) {
        return Send({
            //url: 'http://localhost:9008/settle/invoice/reqpop',
            url: '/settle/invoice/reqpop',
            method: 'get',
            params: param
        })
    },
    insertReq (data) {
        return new Promise((resolve, reject) => {
            Send({
                //url: 'http://localhost:9008/settle/invoice/reqsave',
                url: 'settle/invoice/reqsave',
                method: 'POST',
                data: data
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    getExrtInfo (param) {
        return Send({
            //url: 'http://localhost:9008/settle/invoice/exrt',
            url: '/settle/invoice/exrt',
            method: 'get',
            params: param
        })
    },
    getLimitInfo (param) {
        return Send({
            //url: 'http://localhost:9008/settle/invoice-limit',
            url: '/settle/invoice-limit',
            method: 'get',
            params: param
        })
    },
    getPaymentList (param) {
        return Send({
            //url: 'http://localhost:9008/settle/invoice-inquiry/payment',
            url: '/settle/invoice-inquiry/payment',
            method: 'get',
            params: param
        })
    },
    getEmailList () {
        return Send({
            //url: 'http://localhost:9008/settle/invoice-inquiry/email',
            url: '/settle/invoice-inquiry/email',
            method: 'get'
        })
    },
    sendEmail (data) {
        return new Promise((resolve, reject) => {
            Send({
                //url: 'http://localhost:9008/settle/invoice-inquiry/send',
                url: 'settle/invoice-inquiry/send',
                method: 'POST',
                data: data
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    getDetailList (param) {
        return Send({
            //url: 'http://localhost:9008/settle/invoice-inquiry/detail',
            url: '/settle/invoice-inquiry/detail',
            method: 'get',
            params: param
        })
    },
    getTaxTemplateList () {
        return Send({
            url: '/settle/invoice/tax/template',
            method: 'get',
            params: {}
        })
    },
    insertTaxTemplate (data) {
        return Send({
            url: '/settle/invoice/tax/template',
            method: 'post',
            data: data
        })
    },
    updateTaxTemplate (data) {
        return Send({
            url: '/settle/invoice/tax/template',
            method: 'put',
            data: data
        })
    },
    deleteTaxTemplate (data) {
        return Send({
            url: '/settle/invoice/tax/template',
            method: 'delete',
            data: data
        })
    },
    getVrAccount () {
        return Send({
            url: '/settle/invoice/vr-account',
            method: 'get',
            params: {}
        })
    },
    getPayCur () {
        return Send({
            url: '/settle/invoice/paycur',
            method: 'get',
            params: {}
        })
    },
    getBank (params) {
        return Send({
            url: '/settle/invoice/bank',
            method: 'get',
            params: params
        })
    },
    getAccNo (params) {
        return Send({
            url: '/settle/invoice/accno',
            method: 'get',
            params: params
        })
    },
    accPay (data) {
        return Send({
            url: '/settle/invoice/acc-pay',
            method: 'post',
            data: data
        })
    },
    erpPay (data) {
        return Send({
            url: '/settle/invoice/erp-pay',
            method: 'post',
            data: data
        })
    },
    bankingInfoAndTax (data) {
        return Send({
            url: '/settle/invoice/banking-info-and-tax',
            method: 'post',
            data: data
        })
    },
    tenUnderTransfer (data) {
        return Send({
            url: '/settle/invoice/ten-under-transfer',
            method: 'post',
            data: data
        })
    },
    getBankLoginInfo (loginId) {
        return Send({
            url: '/settle/invoice/bank-login-info?loginId=' + loginId,
            method: 'get',
            params: {}
        })
    },
    cancelPay (params) {
        return Send({
            url: '/settle/invoice/cancel-pay',
            method: 'put',
            params: params
        })
    },
    tenUnderSel (data) {
        return Send({
            url: '/settle/invoice/ten-under-sel',
            method: 'post',
            data: data
        })
    },
    createDemDetInvoice (data) {
        return Send({
            url: '/settle/create-invoice',
            method: 'post',
            data: data
        })
    },
    invoicePublish (data) {
        //Invoice 발행
        return Send({
            url: '/settle/invoice/bl',
            method: 'post',
            data: data
        })
    }
}
