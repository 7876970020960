<template>
  <div>
    <div @mouseout="mouseout" class="w-100" style="height: 5px;"></div>
    <div class="pers_link">
      <ul class="link_info">
        <li :style="liWidth.report">
          <a
            href="javascript:void(0)"
            @click="movePage('/my-info/report/report-info')"
            @mouseover="mouseover('report')"
            @mouseout="mouseout"
            :class="activeMenu == 'report' ? 'on' : ''"
          >Report</a>
        </li>
        <li :style="liWidth.shipping">
          <a
            href="javascript:void(0)"
            @click="movePage('/shipping-cost/my')"
            @mouseover="mouseover('shipping')"
            @mouseout="mouseout"
            :class="activeMenu == 'shipping' ? 'on' : ''"
          >{{ $t('msg.PERS010L020.009') }}</a>
        </li>
        <!-- <li :style="liWidth.calcIssue"
            v-show="memberDetail.userCtrCd === 'KR' && (userDmdInfo.etDmdCatCd !== 'R' || userDmdInfo.imDmdCatCd !== 'R') && auth.login"
        >
          <a
            href="javascript:void(0)"
            @click="movePage('/calcIssue/calcIssueMain')"
            @mouseover="mouseover('calcIssue')"
            @mouseout="mouseout"
            :class="activeMenu == 'calcIssue' ? 'on' : ''"
          >{{ $t('msg.PERS010L020.010') }}</a>
        </li> -->
        <!-- TODO 신용한도조회 탭 클릭 -->
        <!-- <li :style="liWidth.news">
          <a
            href="javascript:void(0)"
            @click="movePage()"
            @mouseover="mouseover('news')"
            :class="activeMenu == 'news' ? 'on' : ''"
          >{{ $t('msg.PERS010L020.011') }}<span v-if="isNewUnsolved || isNewReq || isNewChange" class="ico"></span></a>
        </li> -->
        <li :style="liWidth.news">
          <a
            @mouseover="mouseover('news')"
            :class="activeMenu == 'news' ? 'on' : ''"
          >{{ $t('msg.PERS010L020.011') }}<span v-if="isNewUnsolved || isNewReq || isNewChange" class="ico"></span></a>
        </li>
        <li v-if="memberDetail.userCtrCd === 'KR'" :style="liWidth.import">
          <a
            href="javascript:void(0)"
            @click="movePage('/my-info/import-business-agency-information-main')"
            @mouseover="mouseover('import')"
            @mouseout="mouseout"
            :class="activeMenu == 'import' ? 'on' : ''"
          >{{ $t('msg.PERS010L020.012') }}</a>
        </li>
      </ul>
    </div>
    <div class="pers_noti" v-if="activeMenu == 'news'" @mouseover="mouseover('news')">
      <ul>
        <li>
          <a href="javascript:void(0)" @click="openPopup('ScheduleChangeInfo')">
            {{ $t('msg.PERS010L020.013') }}<template v-if="isNewChange"><span class="ico"></span></template>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="movePage('/working/unsolved')">
            {{ $t('msg.PERS010L020.014') }}<template v-if="isNewUnsolved"><span class="ico"></span></template>
            <!--
            {{ $t('msg.PERS010L020.014') }}<template v-if="unsolvedCnt > 0">(<span>{{ unsolvedCnt }}</span>)</template><template v-if="isNewUnsolved"><span class="ico"></span></template>
            -->
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="movePage('/working/my-request')">
            {{ $t('msg.PERS010L020.015') }}<template v-if="isNewReq "><span class="ico"></span></template>
          </a>
        </li>
      </ul>
    </div>
    <div @mouseout="mouseout" class="w-100" style="height: 5px;"></div>

    <div id="winLayerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          :parent-info="parentInfo"
          @close="closePoup"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import WinLayerPopWrap from '@/pages/trans/popup/WinLayerPopWrap'
import { rootComputed } from '@/store/helpers'
import ScheduleChangeInfo from '@/components/schedule/ScheduleChangeInfo'
import myRequestApi from '@/api/rest/trans/myRequest'
import exportSvcApi from '@/api/rest/trans/exportSvc'
import importSvcApi from '@/api/rest/trans/importSvc'
import unsolvedApi from '@/api/rest/trans/unsolved'
import scheduleChangeInfoApi from '@/api/rest/schedule/changeInfo'
export default {
  name: 'QuickLink',
  components: {
    WinLayerPop,
    WinLayerPopWrap,
    ScheduleChangeInfo
  },
  props: [
    'isLogin',
    'userDmdInfo'
  ],
  data: function () {
    return {
      isShowNews: false,
      activeMenu: '',
      parentInfo: '',
      customComponent: null,
      reqCnt: 0,
      isNewReq: false,
      unsolvedCnt: 0,
      isNewUnsolved: false,
      chnageScheduleCnt: 0,
      isNewChange: false,
      totalCnt: 0,
      isCallAble: true,
      liWidth: {
        report: {
          width: '17%',
          textAlign: 'center'
        },
        shipping: {
          width: this.serviceLang === 'KOR' ? '21%' : '19%',
          textAlign: 'center'
        },
        calcIssue: {
          width: this.serviceLang === 'KOR' ? '21%' : '19%',
          textAlign: 'center'
        },
        news: {
          width: '17%',
          textAlign: 'center'
        },
        import: {
          width: '19%',
          textAlign: 'center'
        }
      }
    }
  },
  computed: {
    ...rootComputed,
    serviceLang () {
      return this.$store.getters['auth/serviceLang']
    }
  },
  watch: {
    userDmdInfo: {
      deep: true,
      handler () {
        if (this.userDmdInfo.etDmdCatCd !== 'R' || this.userDmdInfo.imDmdCatCd !== 'R') {
          this.liWidth = {
            report: {
              width: '25%',
              textAlign: 'center'
            },
            shipping: {
              width: this.auth.serviceLang === 'KOR' ? '23.5%' : '22%',
              textAlign: 'center'
            },
            calcIssue: {
              width: this.auth.serviceLang === 'KOR' ? '20.5%' : '19%',
              textAlign: 'center'
            },
            news: {
              width: '23%',
              textAlign: 'center'
            },
            import: {
              width: '24%',
              textAlign: 'center'
            }
          }
        } else {
          this.liWidth = {
            report: {
              width: '23%',
              textAlign: 'center'
            },
            shipping: {
              width: '28%',
              textAlign: 'center'
            },
            news: {
              width: '22%',
              textAlign: 'center'
            },
            import: {
              width: '23%',
              textAlign: 'center'
            }
          }
        }
      }
    }
  },
  created () {

  },
  mounted: function () {
    this.$nextTick(function () {
      this.liWidth = {
        ...this.liWidth,
        shipping: {
          width: this.serviceLang === 'KOR' ? '20.5%' : '19%',
          textAlign: 'center'
        },
        calcIssue: {
          width: this.auth.serviceLang === 'KOR' ? '20.5%' : '19%',
          textAlign: 'center'
        }
      }
    })
  },
  methods: {
    getScheduleChangeInfo () {
      // setTimeout(() => {
      //   this.$store.commit('endSpinner')
      // }, 100)
      // scheduleChangeInfoApi.getScheduleChangeInfo({ cstNo: this.memberDetail.cstCd }).then(res => {
      //   if (res.data) {
      //     this.chnageScheduleCnt = res.data.etdEtaList.length + res.data.vslVoyList.length
      //     this.isNewChange = res.data.newYn === 'Y'
      //     this.getTotalCnt()
      //   }
      // })
    },
    getMyRequestList () {
      let dt = this.getLastWeek()
      setTimeout(() => {
        this.$store.commit('endSpinner')
      }, 100)
      myRequestApi.getMyRequestList({ kind: 'RD', fromDt: dt.fromDt, toDt: dt.toDt, profileYn: 'Y' }).then(res => {
        console.log(res.data.myRequestList)
        if (!this.isEmpty(res.data.myRequestList)) {
          this.reqCnt = res.data.myRequestList.length
          this.isNewReq = res.data.newYn === 'Y'
        } else {
          this.reqCnt = 0
          this.isNewReq = false
        }
        this.getTotalCnt()
      }).catch(e => {
        console.log(e)
      })
    },
    getNewUnsolvedList () {
      if (this.isCallAble) {
        this.isCallAble = false

        setTimeout(() => {
          this.$store.commit('endSpinner')
        }, 100)
        unsolvedApi.getUnsolvedCheckList({ fromDt: '', toDt: '' }).then(res => {
          //console.log('getUnsolvedCheckList==============', res.data)
          this.unsolvedCnt = res.data.unsolvedCnt
          this.isNewUnsolved = res.data.newYn === 'Y'
          //localStorage.setItem('service_lang', newState.auth.serviceLang)
          this.getTotalCnt()
        }).catch(e => {
          console.log(e)
        }).finally(() => {
           setTimeout(() => {
              this.isCallAble = true
            }, 10000)
        })
      }
    },
    getTotalCnt () {
      this.totalCnt = this.reqCnt + this.unsolvedCnt + this.chnageScheduleCnt
    },
    getLastWeek () {
      let res = {
        toDt: '',
        fromDt: ''
      }
      let d = new Date()
      res.toDt = this.getDateStr(d)
      let dayOfMonth = d.getDate()
      d.setDate(dayOfMonth - 7)
      res.fromDt = this.getDateStr(d)

      return res
    },
    getDateStr (myDate) {
      let year = myDate.getFullYear()
      let month = (myDate.getMonth() + 1)
      let day = myDate.getDate()
      month = (month < 10) ? '0' + String(month) : month
      day = (day < 10) ? '0' + String(day) : day

      return year.toString() + month.toString() + day.toString()
    },
    movePage (path) {
        if (path === '/calcIssue/calcIssueMain') {
          this.$store.commit('settle/updateTabIndex', '6')
        } else if (path === '/working/unsolved') {
          this.isNewUnsolved = false
        }

        this.$router.push(path)
    },
    mouseover (type) {
      this.activeMenu = type
    },
    mouseout () {
       this.activeMenu = ''
    },
    openPopup (compNm) {
      if (this.isLogin) {
         this.customComponent = compNm

        if (compNm === 'ScheduleChangeInfo') { // 버튼 클릭 상세
          console.log('ScheduleChangeInfo')
        }

        this.$ekmtcCommon.layerOpen('#winLayerPop > .popup_dim')
      } else {
        this.$store.commit('auth/requireLogin', true)
      }
    },
    closePoup (e) {
      this.customComponent = null

      this.$ekmtcCommon.layerClose('#winLayerPop > .popup_dim')
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>
