<template>
  <div class="per_section brdbtm_none">
    <h2>{{ $t("msg.MAIN010G030.001") }}</h2>
    <ul class="per_quick">
      <li><a href="javascript:void(0)" v-if="serviceLang == 'KOR'" class="per_qicon ico1" @click="movePage('/pickup-detail')" v-html="$t('msg.MAIN010G030.002')"></a></li>
      <li><a href="javascript:void(0)" class="per_qicon ico2" @click="movePage('/calcIssue/freetime-request')" v-html="$t('msg.MAIN010G030.006')"></a></li>
      <!--      <li><a href="javascript:void(0)" class="per_qicon ico4" @click="openPopup('BlDoPaymentPop')">D/O<br>{{ $t('msg.PERS010L020.019') }}</a></li>-->
      <li><a href="javascript:void(0)" class="per_qicon ico4" @click="openPopup('BlDoPaymentPop')">{{ $t('msg.ONIM050G010.001') }}</a></li>
      <li><a href="javascript:void(0)" v-if="serviceLang == 'KOR'" class="per_qicon ico3" @click="movePage('/container-return')">{{ $t('msg.MAIN010G030.014') }}</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'QuickMenu',
  components: {

  },
  props: [
    'countryDigitCode'
  ],
  data: function () {
    return {
        sample: ''
    }
  },
  computed: {
    serviceLang () {
      return this.$store.state.auth.serviceLang
    }
  },
  watch: {
  },
  created () {},
  mounted: function () {

  },
  methods: {
    openPopup (compNm) {
      this.$emit('child', { type: 'openPop', payload: { compNm: compNm, parentInfo: { blNo: '', isValidCheck: false } } })
    },
     movePage (path) {
        this.$router.push(path)
    }
  }
}
</script>
