<template>
  <!-- popup_dim -->
  <div class="popup_dim2">
    <slot />
  </div><!-- popup_dim // -->
</template>

<script>
export default {
  name: 'WinLayerPopWrap'
}
</script>
