/* 갈곳 없는 메뉴들 - 추후 정해지면 해당 파일 삭제 예정 */

const ContainerReturn = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/ContainerReturn')
const PickUpInfo = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/trans/PickUpInfo')
const ScheduleKmtcSpot = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/schedule/ScheduleKmtcSpot')
const scheduleKmtcPremium = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/schedule/scheduleKmtcPremium')
const KmtcCoupon = () => import(/* webpackChunkName: "topmenu01_03" */'@/pages/schedule/KmtcCoupon')

const routes = [
  {
    path: 'container-return',
    name: 'container-return',
    component: ContainerReturn,
    meta: {
      breadcrumb: '반납',
      key: 'men.CSDBL100.010',
      // requiresAuth: true,
      sitemapHide: true
    }
  },
  {
    path: 'pickup-detail',
    name: 'pickup-detail',
    component: PickUpInfo,
    props: true,
    meta: {
      breadcrumb: 'Pick Up 정보 변경',
      key: 'men.CSBK110.010',
      // requiresAuth: true,
      sitemapHide: true
    }
  },
  {
    path: 'kmtc-premium',
    name: 'kmtc-premium',
    component: scheduleKmtcPremium,
    props: true,
    meta: {
      breadcrumb: 'KMTC PREMIUM',
      key: 'men.KMTCON100.001',
      // requiresAuth: true,
      sitemapHide: true
    }
  },
  {
    path: 'kmtc-coupon',
    name: 'kmtc-coupon',
    component: KmtcCoupon,
    props: true,
    meta: {
      breadcrumb: 'KMTC COUPON',
      key: 'men.KMTCON100.002',
      // requiresAuth: true,
      sitemapHide: true
    }
  }
]

export default routes
