<template>
  <div class="popup_wrap" style="width:800px;height:600px;">
    <button class="layer_close" @click="closeNmove()">close</button>
    <div class="popup_cont capture_area">
      <h1 class="page_title">{{ $t('msg.VOSD100_M5.005') }}</h1><!-- 운임확인 -->
      <div class="content_box capture_sub_area">
        <!-- content_box -->
        <p class="txt_desc none">
          {{ $t('msg.VOSD100.002') }} : {{ polNm }} ~ {{ $t('msg.VOSD100.003') }} : {{ podNm }}<br />
          <span v-show="parentInfo.promotionChk === 'Y'"> <!-- 프로모션 배너 유입 여부 -->
            <span v-if="legMenuChk === 'Y'">
              {{ $t('msg.VOSD100_M5.006') }} : {{ parentInfo.rteCd }} / {{ parentInfo.vslNm }} / {{ parentInfo.voyNo }}<br />
            </span>
            <!-- SC No. : {{ frtAppNo }} -->
            SC No. :
            <select class="wid150" :disabled="true" @change="changeFrtAppNo">
              <!-- disabled-->
              <option v-for="(vo, idx) in freAppNoList" :key="'selFrtApp_' + idx" :value="vo.frtAppNo" :selected="vo.frtAppNo === parentInfo.frtAppNo">{{ vo.frtAppNo }}</option>
            </select>
            <br />
            {{ $t('msg.CSBL250.050') }} : <!--{{ containerType }}<br/> Dry (GP or HC) -->
            <select v-model="containerTypeCode" class="wid150" :disabled="true" @change="changeCntnType2">
              <option v-for="vo in containerType" :key="'selCntr_' + vo.cd" :value="vo.cd">{{ vo.cdNm }}</option>
            </select>
            <br />
          </span>
        </p>
        <span v-if="parentInfo.promotionChk !== 'Y'"><!-- 메뉴 진입인 경우 -->
          <p class="txt_desc none">
            <span v-if="legMenuChk === 'Y'">
              {{ $t('msg.VOSD100_M5.006') }} : {{ parentInfo.rteCd }} / {{ parentInfo.vslNm }} / {{ parentInfo.voyNo }}<br />
            </span>
            {{ $t('msg.CSBL250.050') }} : <!--{{ containerType }}<br/> Dry (GP or HC) -->
            <select v-model="containerTypeCode" class="wid150" :disabled="containerType.length === 1" @change="changeCntnType2">
              <option v-for="vo in containerType" :key="'selCntr_' + vo.cd" :value="vo.cd">{{ vo.cdNm }}</option>
            </select>
            <br />
            <span v-if="parentInfo.hotDealYn !== 'Y'">
              {{ $t('msg.CSBL250.047') }} : {{ startDate }} ~ {{ endDate }}<br /><!-- 운임 유효기간 -->
            </span>
            <!-- SC No. : {{ frtAppNo }} -->
            <span v-show="raOrRm === 'RA'">SC No. : </span>
            <select class="wid150" :disabled="true" @change="changeFrtAppNo" v-show="raOrRm === 'RA'">
              <!-- disabled-->
              <option v-for="(vo, idx) in freAppNoList" :key="'selFrtApp_' + idx" :value="vo.frtAppNo" :selected="vo.frtAppNo === parentInfo.frtAppNo">{{ vo.frtAppNo }}</option>
            </select>
            <br />
            <span v-if="parentInfo.hotDealYn === 'Y'" class="txt_desc asterisk" v-html="$t('msg.CSBL250.052')"></span><!-- 확정된 프로모션 운임은 <b>해당 선명항차에만</b> 적용됩니다. -->
          </p>
        </span>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="30%">
            <col width="12%">
            <col width="10%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
          </colgroup>
          <thead>
            <tr>
              <th>Freight Charges</th>
              <th>Currency</th>
              <th>Type</th>
              <th>Cargo</th>
              <th>20'</th>
              <th>40'</th>
              <th>HC</th>
            </tr>
          </thead>
          <tbody v-if="parentInfo.promotionChk !== 'Y'">
            <!-- 메뉴 유입시 (일반) -->
            <tr v-for="(row, idx) in surChargeList2Show" :key="idx">
              <td class="text_left" v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <span v-if="parentInfo.kmtcSpotYn === 'Y'"><!-- 프리미엄 구간이고 일반 운임인 경우 레디오타입 표시 -->
                  <input type="radio" :id="'kmtcOnN-' + idx" v-model="kmtcPremiumFrtChk" value="R" :disabled="parentInfo.detailResp2.bkgClose === 'Y'" /><!-- name 프로퍼티 추가시 더블클릭 해야 선택되는 증상이 있어 제외함 -->
                  <label :for="'kmtcOnN-' + idx" class="mr10">{{ row.frtCdNm }}</label>
                </span>
                <span v-if="parentInfo.kmtcSpotYn !== 'Y'">{{ row.frtCdNm }}</span>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="parentInfo.hotDealYn !== 'Y'">
                  <!-- 20 일반 유입 and RA 운임인 경우 -->
                  <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(row.rate20 + of20Rate, { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
                </div>
                <div v-else>
                  <!-- 20 일반 유입 and 핫딜 운임(프로모션 확정 운임)인 경우 -->
                  <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined || row.cgoTypCd === '01'">
                    <del>{{ bfHz20Rate }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ $ekmtcCommon.changeNumberFormat(row.rate20 + of20Rate, { isComma: true }) }}
                  </div>
                  <div v-else>
                    <del>{{ bfGp20Rate }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
                  </div>
                </div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="parentInfo.hotDealYn !== 'Y'">
                  <!-- 40 일반 유입 and RA 운임인 경우 -->
                  <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(row.rate40 + of40Rate, { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
                </div>
                <div v-else>
                  <!-- 40 일반 유입 and 핫딜 운임(프로모션 확정 운임)인 경우 -->
                  <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined || row.cgoTypCd === '01'">
                    <del>{{ bfHz40Rate }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ $ekmtcCommon.changeNumberFormat(row.rate40 + of40Rate, { isComma: true }) }}
                  </div>
                  <div v-else>
                    <del>{{ bfGp40Rate }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
                  </div>
                </div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="parentInfo.hotDealYn !== 'Y'">
                  <!-- HC 일반 유입 and RA 운임인 경우 -->
                  <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(row.rateHc + of40Rate, { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
                </div>
                <div v-else>
                  <!-- HC 일반 유입 and 핫딜 운임(프로모션 확정 운임)인 경우 -->
                  <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined || row.cgoTypCd === '01'">
                    <del>{{ bfHz40Rate }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ $ekmtcCommon.changeNumberFormat(row.rateHc + of40Rate, { isComma: true }) }}
                  </div>
                  <div v-else>
                    <del>{{ bfGp40Rate }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
                  </div>
                </div>
              </td>
            </tr>
            <!-- KMTC ON인 경우 운임 노출 -->
            <tr v-show="parentInfo.kmtcSpotYn === 'Y' && containerTypeCode === 'GP'" v-for="(row, idx) in bfSurChargeList" :key="idx + 'Y'">
              <td class="text_left" v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <input type="radio" :id="'kmtcOnY-' + idx" v-model="kmtcPremiumFrtChk" value="P" /><!-- name 프로퍼티 추가시 더블클릭 해야 선택되는 증상이 있어 제외함 -->
                <label :for="'kmtcOnY-' + idx" class="mr10"><span></span>{{ row.frtCdNm }}</label>
                <img src="../../../assets/images/common/icon_premium_text_only.png">
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <!-- 20 일반 유입 and RA 운임인 경우 -->
                <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(row.rate20 + of20Rate, { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <!-- 40 일반 유입 and RA 운임인 경우 -->
                <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(row.rate40 + of40Rate, { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <!-- HC 일반 유입 and RA 운임인 경우 -->
                <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(row.rateHc + of40Rate, { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="parentInfo.promotionChk === 'Y'">
            <!-- 프로모션 대상은 GP 운임만 대상으로 함. -->
            <tr v-for="(row,idx) in surChargeList2Show" :key="idx">
              <td class="text_left" v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.cntrTypCd === 'GP'">
                  <div v-if="parentInfo.hotDealYn !== 'Y'">
                    <!-- 프로모션 유입 and RA 운임인 경우 할인 처리 -->
                    <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                      <del>{{ $ekmtcCommon.changeNumberFormat(row.rate20 + of20Rate, { isComma: true }) }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ calcPromotionDC('20', row.rate20 + of20Rate, row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                    </div>
                    <div v-else>
                      <del>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ calcPromotionDC('20', row.rate20, row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                    </div>
                  </div>
                  <div v-else>
                    <!-- 프로모션 유입 and 프로모션 운임인 경우 이전 운임과 할인 운임 보여줌 -->
                    <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined || row.cgoTypCd === '01'">
                      <del>{{ bfHz20Rate }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ $ekmtcCommon.changeNumberFormat(row.rate20 + of20Rate, { isComma: true }) }}
                    </div>
                    <div v-else>
                      <del>{{ bfGp20Rate }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(row.rate20 + of20Rate, { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
                </div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.cntrTypCd === 'GP'">
                  <div v-if="parentInfo.hotDealYn !== 'Y'">
                    <!-- 프로모션 유입 and RA 운임인 경우 할인 처리 -->
                    <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                      <del>{{ $ekmtcCommon.changeNumberFormat(row.rate40 + of40Rate, { isComma: true }) }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ calcPromotionDC('40', row.rate40 + of40Rate, row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                    </div>
                    <div v-else>
                      <del>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ calcPromotionDC('40', row.rate40, row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                    </div>
                  </div>
                  <div v-else>
                    <!-- 프로모션 유입 and 프로모션 운임인 경우 이전 운임과 할인 운임 보여줌 -->
                    <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined || row.cgoTypCd === '01'">
                      <del>{{ bfHz40Rate }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ $ekmtcCommon.changeNumberFormat(row.rate40 + of40Rate, { isComma: true }) }}
                    </div>
                    <div v-else>
                      <del>{{ bfGp40Rate }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(row.rate40 + of40Rate, { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
                </div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.cntrTypCd === 'GP'">
                  <div v-if="parentInfo.hotDealYn !== 'Y'">
                    <!-- 프로모션 유입 and RA 운임인 경우 할인 처리 -->
                    <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                      <del>{{ $ekmtcCommon.changeNumberFormat(row.rateHc + of40Rate, { isComma: true }) }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ calcPromotionDC('40', row.rateHc + of40Rate, row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                    </div>
                    <div v-else>
                      <del>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ calcPromotionDC('40', row.rateHc, row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                    </div>
                  </div>
                  <div v-else>
                    <!-- 프로모션 유입 and 프로모션 운임인 경우 이전 운임과 할인 운임 보여줌 -->
                    <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined || row.cgoTypCd === '01'">
                      <del>{{ bfHz40Rate }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ $ekmtcCommon.changeNumberFormat(row.rateHc + of40Rate, { isComma: true }) }}
                    </div>
                    <div v-else>
                      <del>{{ bfGp40Rate }}</del>&nbsp;&nbsp;
                      <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                      {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(row.rateHc + of40Rate, { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <span v-show="parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && containerTypeCode === 'GP'">KMTC ON PREMIUM 운임으로 부킹하시면 특별 선복보장 서비스를 제공합니다.</span><br v-if="parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && containerTypeCode === 'GP'">
        <span v-show="parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && containerTypeCode === 'GP'" style="font-weight: bolder;">(출항일 포함 7일 이내 PREMIUM 부킹 캔슬시 캔슬비용 20'/40' KRW 50,000/100,000 발생됩니다.)</span>
        <span v-if="parentInfo.promotionChk !== 'Y'">
          <table class="tbl_col mt10">
            <colgroup>
              <col width="30%">
              <col width="12%">
              <col width="10%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
            </colgroup>
            <thead>
              <tr>
                <th>Export Charges</th>
                <th>Currency</th>
                <th>Type</th>
                <th>Cargo</th>
                <th>20'</th>
                <th>40'</th>
                <th>HC</th>
              </tr>
            </thead>
            <!-- 일반운임 선택시 -->
            <tr v-for="(row,idx) in surChargeList2Show" :key="'a' + idx" v-show="kmtcPremiumFrtChk !== 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
            <!-- KMTC ON 운임 선택시 -->
            <tr v-for="(row,idx) in bfSurChargeList" :key="'b' + idx" v-show="kmtcPremiumFrtChk === 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
          </table>
          <!-- Import Charge -->
          <table class="tbl_col mt10">
            <colgroup>
              <col width="30%">
              <col width="12%">
              <col width="10%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
            </colgroup>
            <thead>
              <tr>
                <th>Import Charges</th>
                <th>Currency</th>
                <th>Type</th>
                <th>Cargo</th>
                <th>20'</th>
                <th>40'</th>
                <th>HC</th>
              </tr>
            </thead>
            <!-- 일반운임 선택시 -->
            <tr v-for="(row,idx) in surChargeList2Show" :key="'a' + idx" v-show="kmtcPremiumFrtChk !== 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
            <!-- KMTC ON 운임 선택시 -->
            <tr v-for="(row,idx) in bfSurChargeList" :key="'b' + idx" v-show="kmtcPremiumFrtChk === 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
          </table>
          <!-- Import Charge -->
          <!-- Notes -->
          <div v-show="surChargeNoteList.length !== 0">
            <p class="txt_desc asterisk">Notes<br /></p>
            <div v-for="(row,idx) in surChargeNoteList" :key="idx">
              <div v-show="row.frtCd === 'DCF' || row.frtCd === 'SRD' || row.frtCd === 'AFS'">
                <p class="txt_desc2">{{ idx+1 }}. Subject to {{ row.frtCdNm }} : {{ row.curCd }} {{ $ekmtcCommon.changeNumberFormat(row.blRate, { isComma: true }) }} per Bill of Lading.<br /></p>
              </div>
              <div v-show="idx === (surChargeNoteList.length-1)">
                <p class="txt_desc2">{{ idx+2 }}. Subject to SPECIAL DG HANDLING SURCHARGE : USD 600/20', USD 700/40' per container (※For only DG CLASS 2 by BUSAN T/S).<br /></p>
                <p v-show="parentInfo.porCtrCd === 'JP'" class="txt_desc2">{{ idx+3 }}. 3ヶ月更新のLSS(Low Sulphur Surcharge)は、お見積もりのタイミングによって表示されない場合がございます。
                  料率が決まり次第表示されますが、ご不明な点がございましたら弊社営業部までお問い合わせください。<br /></p>
              </div>
            </div>
          </div>
          <!-- Notes -->
          <!-- Destination Freetime -->
          <table class="tbl_col mt10">
            <colgroup>
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
            </colgroup>
            <thead>
              <tr>
                <th rowspan="2">KIND</th>
                <th colspan="2">Loading</th>
                <th colspan="2">Discharging</th>
              </tr>
              <tr v-show="containerTypeCode === 'GP'">
                <th style="border-left:1px solid #e1e3eb;">GP</th>
                <th>HC</th>
                <th>GP</th>
                <th>HC</th>
              </tr>
              <tr v-show="containerTypeCode === 'RF'">
                <th style="border-left:1px solid #e1e3eb;">RF</th>
                <th>RH</th>
                <th>RF</th>
                <th>RH</th>
              </tr>
              <tr v-show="containerTypeCode === 'FR'">
                <th style="border-left:1px solid #e1e3eb;">FR</th>
                <th></th>
                <th>FR</th>
                <th></th>
              </tr>
              <tr v-show="containerTypeCode === 'OT'">
                <th style="border-left:1px solid #e1e3eb;">OT</th>
                <th></th>
                <th>OT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row,idx) in rateFreeDdsList" :key="idx">
                <td class="text_left">{{ row.demDetCatNm }}</td>
                <td>{{ row.ldGpFreeDds }}</td>
                <td>{{ row.ldHcFreeDds }}</td>
                <td>{{ row.disGpFreeDds }}</td>
                <td>{{ row.disHcFreeDds }}</td>
              </tr>
            </tbody>
          </table>
        </span>
        <div v-if="parentInfo.promotionChk !== 'Y'">
          <!-- 프로모션 아닌 일반 -->
          <!-- Destination Freetime -->
          <p class="txt_desc asterisk" v-show="raOrRm === 'RM'">{{ $t('msg.CSBL250.047') }} : {{ startDateRM }} ~ {{ endDateRM }}<br /></p><!-- 운임 유효기간 -->
          <span v-if="auth.userCtrCd === 'KR' && raOrRm === 'RM' && checkCharge === 'N' && (containerTypeCode === 'OT' || containerTypeCode === 'FR')">
            <span class="txt_desc asterisk" style="top:4px;"><a ref="#" @click="clickOpen('oog-notice')" style="color:blue;cursor:pointer; font-weight: bold;">[상세내용]</a> 버튼으로 특수화물 선적 및 운임관련 공지를 확인하세요.<br></span>
            <span class="txt_desc asterisk" style="top:6px;">공지를 확인하셨다면 우측의 체크박스를 체크하세요.</span>
            <input
              v-model="oogChk"
              type="checkbox"
              id="agree"
              name="agree"
            >
            <label for="agree" style="top: 5px;"><span></span>{{ $t('msg.ADMN030G020.042') }}</label><!--  -->
          </span>
          <p class="txt_desc asterisk" v-show="raOrRm === 'RM'">
            {{ $t('msg.VOSD100_M5.008') }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<!-- SC No. 생성을 위해서는 운임확정이 필요합니다. --> <!-- 문구와 운임확정 버튼 간격을 위해 &nbsp; 추가함 -->
            <span class="ml5" v-show="checkCharge === 'N'">
              <!--<a class="button sm" href="#" @click.prevent="fnAccept">{{ $t('msg.VOSD100_M5.009') }}</a> 운임확정 -->
              <a class="button blue sm" href="#" @click="acceptQuote()">{{ $t('msg.VOSD100_M5.009') }}</a><!-- 운임확정 -->
            </span>
            <!-- 운임확정 예 클릭한 경우 -->
            <span class="ml5" v-show="checkCharge === 'Y'">
              <a class="button select sm" href="#">{{ $t('msg.VOSD100_M5.009') }}<!-- 운임확정 --></a>
              <span class="color_blue font_medium">(SC No. : {{ frtAppNoConfirm }})</span>
            </span>
          </p>
        </div>
        <div v-else>
          <!-- 프로모션 -->
          <!-- Destination Freetime -->
          <p class="txt_desc asterisk" v-show="parentInfo.hotDealYn !== 'Y' && this.containerTypeCode === 'GP'">
            <!-- 프로모션이면서 RA 운임인경우 할인 대상에 포함되고 운임확정 필요함 -->
            {{ $t('msg.VOSD100_M5.008') }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<!-- SC No. 생성을 위해서는 운임확정이 필요합니다. --> <!-- 문구와 운임확정 버튼 간격을 위해 &nbsp; 추가함 -->
            <span class="ml5" v-show="checkCharge === 'N'">
              <a class="button blue sm" href="#" @click.prevent="acceptQuote">{{ $t('msg.VOSD100_M5.009') }}</a><!-- 운임확정 -->
            </span>
            <!-- 운임확정 예 클릭한 경우 -->
            <span class="ml5" v-show="checkCharge === 'Y'">
              <a class="button select sm" href="#">{{ $t('msg.VOSD100_M5.009') }}<!-- 운임확정 --></a>
              <span class="color_blue font_medium">(SC No. : {{ frtAppNoConfirm }})</span>
            </span>
          </p>
          <p class="txt_desc asterisk" v-html="$t('msg.CSBL250.052')"></p><!-- 확정된 프로모션 운임은 <b>해당 선명항차에만</b> 적용됩니다. -->
        </div>
      </div><!-- content_box // -->
      <!-- showReqQtBtn 값은 비온라인 화주인 경우 Y로 생성되어 KMTC ON 버튼이 노출되지 않고, 비온라인 화주의 RA 운임은 영업사원이 승인하므로 KMTC ON 버튼이 노출되면 안됨-->
      <div class="mt10 text_center" v-if="raOrRm === 'RA' && parentInfo.promotionChk !== 'Y' && negoMenuChk !== 'Y'">
        <span v-show="parentInfo.detailResp2 !== undefined && containerTypeCode !== 'OT' && containerTypeCode !== 'FR'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" href="#" @click.prevent="goBooking">Booking</a></span>
        <span v-show="containerTypeCode === 'OT' || containerTypeCode === 'FR' && negoMenuChk !== 'Y'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" @click.prevent="clickOpen('oog-cargo-chk-pop')">{{ $t('msg.CSBK100.510') }}</a><!-- 카고 체크 --></span>
        <a v-show="showReqQtBtn !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn === 'Y' && parentInfo.kmtcPremiumNegoYn === 'Y' && parentInfo.detailResp2.bkgClose !== 'Y'" class="button green lg mr5" href="#" @click.prevent="goReqQtPop('K')">{{ $t('msg.MAIN010G030.038') }} NOW로 운임 협의하기<!-- KMTC ON --></a>
        <a v-show="showReqQtBtn === 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y' && negoMenuChk !== 'Y'" class="button blue lg" href="#" @click.prevent="goReqQtPop('N')">{{ $t('msg.VOSD100_M5.026') }}<!-- 운임문의 : 오프라인 화주 --></a>
        <a v-show="showReqQtBtn !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y' && negoMenuChk !== 'Y'" class="button blue lg" href="#" @click.prevent="fnFrtConfirm()">{{ $t('msg.VOSD100_M5.026') }}<!-- 운임문의  : 온라인 화주--></a>
        <a v-show="showNegoBtn === 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y' && negoMenuChk !== 'Y'" class="button blue lg" href="#" @click.prevent="goReqQtPop('Y')">{{ $t('msg.VOSD100_M5.010') }}<!-- 네고요청 --></a>
        <a v-show="showExtensionBtn === 'Y'" class="button blue lg" href="#" @click.prevent="validityRequest">{{ $t('tit.CSBL250.004') }}<!-- 연장요청 --></a>
        <a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a>
        <a class="button lg" href="javascript:none(0)" @click.prevent="screenPrint"><span class="btn_icon print"></span>인쇄</a>
        <a class="button lg" href="javascript:none(0)" @click.prevent="screenToPdfDownload">다운</a>
      </div>
      <div class="mt10 text_center" v-if="raOrRm === 'RM' && parentInfo.promotionChk !== 'Y' && negoMenuChk !== 'Y'">
        <span v-show="parentInfo.raTsParam !== undefined && containerTypeCode !== 'OT' && containerTypeCode !== 'FR'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" href="#" @click.prevent="goBooking">Booking</a></span>
        <span v-show="containerTypeCode === 'OT' || containerTypeCode === 'FR' && negoMenuChk !== 'Y'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" @click.prevent="clickOpen('oog-cargo-chk-pop')">{{ $t('msg.CSBK100.510') }}</a><!-- 카고 체크 --></span>
        <a v-show="showReqQtBtn !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn === 'Y' && parentInfo.kmtcPremiumNegoYn === 'Y' && parentInfo.detailResp2.bkgClose !== 'Y'" class="button green lg mr5" href="#" @click.prevent="goReqQtPop('K')">{{ $t('msg.MAIN010G030.038') }} NOW로 운임 협의하기<!-- KMTC ON --></a>
        <a v-show="showReqQtBtn !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y' && negoMenuChk !== 'Y'" class="button blue lg" href="#" @click.prevent="fnFrtConfirm()">{{ $t('msg.VOSD100_M5.026') }}<!-- 운임문의 : 온라인 화주 --></a>
        <span v-show="checkCharge === 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y' && negoMenuChk !== 'Y'">&nbsp;<a class="button blue lg" href="#" @click.prevent="goReqQtPop('Y')">{{ $t('msg.VOSD100_M5.010') }}<!-- 네고요청 --></a></span>
        &nbsp;<span><a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a></span>
        &nbsp;<span><a class="button lg" href="javascript:none(0)" @click.prevent="screenPrint"><span class="btn_icon print"></span>인쇄</a></span>
        <a class="button lg" href="javascript:none(0)" @click.prevent="screenToPdfDownload">다운</a>
      </div>
      <div class="mt10 text_center" v-if="parentInfo.promotionChk === 'Y'">
        <span v-show="containerTypeCode !== 'OT' && containerTypeCode !== 'FR'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" href="#" @click.prevent="goPromotionBooking">Booking</a></span>
        &nbsp;<span><a class="button gray lg" href="#" @click.prevent="closeNmove()">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a></span>
        &nbsp;<span><a class="button lg" href="javascript:none(0)" @click.prevent="screenPrint"><span class="btn_icon print"></span>인쇄</a></span>
        <a class="button lg" href="javascript:none(0)" @click.prevent="screenToPdfDownload">다운</a>
      </div>
      <div class="mt10 text_center" v-if="negoMenuChk === 'Y'">
        <span v-show="parentInfo.raTsParam !== undefined && containerTypeCode !== 'OT' && containerTypeCode !== 'FR'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" href="#" @click.prevent="goBooking">Booking</a></span>
        &nbsp;<span><a class="button gray lg" href="#" @click.prevent="closeNmove()">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a></span>
        &nbsp;<span><a class="button lg" href="javascript:none(0)" @click.prevent="screenPrint"><span class="btn_icon print"></span>인쇄</a></span>
        <a class="button lg" href="javascript:none(0)" @click.prevent="screenToPdfDownload">다운</a>
      </div>
      <div class="flex_box mt10" v-show="showReqQtBtn !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn === 'Y' && parentInfo.kmtcPremiumNegoYn === 'Y' && parentInfo.detailResp2.bkgClose !== 'Y'">
        <span style="color: #075bb9; font-weight: 500; font-size: 15px; margin-left: 27%; margin-top:-10px;">KMTC ON NOW를 클릭해보세요. e-KMTC에서 운임을 즉시 협의하실 수 있습니다.</span>
      </div>
      <div id="pdf_area"></div>
    </div>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import schedule from '@/api/rest/schedule/schedule'
import myScheduleApi from '@/api/rest/schedule/mySchedule'
import { rootComputed } from '@/store/helpers'
import countryCode from '@/plugins/country.json'

export default {
  name: 'FreSurchargePop',
  components: {
    OogDgInquiryPop: () => import('@/pages/trans/popup/OogDgInquiryPop'),
    'oog-notice': () => import('@/pages/schedule/popup/OogNotice'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'oog-cargo-chk-pop': () => import('@/pages/trans/popup/OogCargoChkPop')
  },
  computed: {
    ...rootComputed
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          etd: '',
          frtAppNo: '',
          frtEstnYn: '',
          vslCd: '',
          vslNm: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: '',
          negoYN: '',
          rmPopOpenFlag: 'N',
          etaBookingMsg: '',
          detailResp2: {},
          promotionChk: 'N',
          hotDealYn: 'N',
          scenarioCd: '',
          promoNo: '',
          applyFr: '',
          applyTo: '',
          ofCntrSize01: '',
          ofCntrType01: '',
          ofDcRate01: 0,
          ofDcType01: '',
          ofCntrSize02: '',
          ofCntrType02: '',
          ofDcRate02: 0,
          ofDcType02: '',
          dtCntrSize01: '',
          dtCntrType01: '',
          dtDcRate01: 0,
          dtDcType01: '',
          dtCntrSize02: '',
          dtCntrType02: '',
          dtDcRate02: 0,
          dtDcType02: '',
          kmtcSpotYn: 'N',
          kmtcPremiumNegoYn: 'N',
          apv20Ft: '',
          apv40Ft: '',
          workingPrg: 'N'
        }
      }
    }
  },
  data: function () {
    return {
      startDate: '',
      endDate: '',
      startDateRM: '',
      endDateRM: '',
      strDt: '',
      endDt: '',
      polNm: '',
      podNm: '',
      surChargeList: [],
      surChargeNoteList: [],
      surChargeListDry: [],
      surChargeListRee: [],
      surChargeListFlat: [],
      surChargeListTank: [],
      surChargeListOT: [],
      surChargeListEtc: [],
      surChargeList2Show: [],
      rateFreeDdsList: [],
      frtAppNo: '',
      frtAppNoConfirm: '',
      frtEstnYn: '',
      frtExtsStsCd: '',
      cstBidgMgrNo: '',
      of20Rate: '',
      of40Rate: '',
      showExtensionBtn: 'N',
      // containerType: '',
      containerType: [],
      freAppNoList: [],
      expDate: '',
      containerTypeCode: '',
      raOrRm: 'RA',
      checkCharge: 'N',
      showNegoBtn: 'N',
      showReqQtBtn: 'N',
      bfSurChargeList: [],
      bfHz20Rate: '',
      bfHz40Rate: '',
      bfGp20Rate: '',
      bfGp40Rate: '',
      spread20: 0,
      spread40: 0,
      legMenuChk: 'N',
      negoMenuChk: 'N',
      urlOrNot: false, // JamSin DSM
      oogChk: false,
      customComponent: null,
      lssPc: '',
      kmtcPremiumFrtChk: false,
      countryCode: countryCode,
      dcYn: '' // KMTC ON NEGO 여부
    }
  },
  created () {
    console.log('>>>>>> [freSurcharge] this.parentInfo <<<<<<')
    console.log(this.parentInfo)
    this.urlOrNot = this.$ekmtcCommon.isNotEmpty(this.parentInfo.urlOrNot) ? this.parentInfo.urlOrNot : false // JamSin DSM 트래킹을 위해 스케줄에서 urlOrNot 넘김

    // 운임 프로모션 조회를 위해 배너의 시나리오 코드, 프로모션 번호 파라미터 세팅
    const params = {
      scenarioCd: this.parentInfo.scenarioCd,
      promoNo: this.parentInfo.promoNo
    }

    // 운임 프로모션인 경우 서버에서 할인 운임 계산하여 전달함
    if (this.parentInfo.promotionChk === 'Y') {
      myScheduleApi.getPromotionDcAmt(params).then(res => {
        this.parentInfo.applyFr = res.data[0].applyFr
        this.parentInfo.applyTo = res.data[0].applyTo
        this.parentInfo.ofCntrSize01 = res.data[0].ofCntrSize01
        this.parentInfo.ofCntrType01 = res.data[0].ofCntrType01
        this.parentInfo.ofDcRate01 = res.data[0].ofDcRate01
        this.parentInfo.ofDcType01 = res.data[0].ofDcType01
        this.parentInfo.ofCntrSize02 = res.data[0].ofCntrSize02
        this.parentInfo.ofCntrType02 = res.data[0].ofCntrType02
        this.parentInfo.ofDcRate02 = res.data[0].ofDcRate02
        this.parentInfo.ofDcType02 = res.data[0].ofDcType02
        this.parentInfo.dtCntrSize01 = res.data[0].dtCntrSize01
        this.parentInfo.dtCntrType01 = res.data[0].dtCntrType01
        this.parentInfo.dtDcRate01 = res.data[0].dtDcRate01
        this.parentInfo.dtDcType01 = res.data[0].dtDcType01
        this.parentInfo.dtCntrSize02 = res.data[0].dtCntrSize02
        this.parentInfo.dtCntrType02 = res.data[0].dtCntrType02
        this.parentInfo.dtDcRate02 = res.data[0].dtDcRate02
        this.parentInfo.dtDcType02 = res.data[0].dtDcType02
      }).catch(e => {
        console.log(e)
      })
    }

    // 메뉴 경로로 현재 스케줄 메뉴 여부 확인
    if (this.$route.path === '/schedule/leg') {
      this.legMenuChk = 'Y'
    }

    // 메뉴 경로로 현재 KMTC ON NOW 메뉴 여부 확인
    // 운임메뉴와 메인화면에서 조회시 부킹 버튼 비노출 세팅
    if (this.$route.path === '/kmtc-premium' || this.$route.path === '/shipping-cost/inquiry' || this.$route.path === '/shipping-cost/my' || this.$route.path === '/main') {
      this.negoMenuChk = 'Y'
      this.parentInfo.negoYn = 'Y'
    }

    // 메뉴 경로로 현재 진행중인 업무 메뉴 확인
    if (this.$route.path === '/working/progress') {
      this.negoMenuChk = 'Y' // 각종 버튼 제어시 네고 메뉴 노출 버튼과 동일하게 맞추기 위해 세팅함
      this.parentInfo.workingPrg = 'Y'
    }

    // 메인에서 운임문의 할 경우 운임확인 팝업창의 KMTC ON 버튼 노출
    if (this.$route.path === '/main') {
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotUserYn: this.parentInfo.kmtcSpotUserYn })
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotLineYn: 'N' })
    }

    // 운임 메뉴에서 운임확인 할 경우 운임확인 팝업창의 KMTC ON 버튼 강제 비노출 처리
    // 노출이 필요한 경우 kmtcSpotUserYn 항목을 메인과 똑같이 맞추면 버튼 노출됨
    if (this.$route.path === '/shipping-cost/inquiry' || this.$route.path === '/shipping-cost/my' || this.$route.path === '/kmtc-premium' || this.$route.path === '/working/progress') {
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotUserYn: 'N' })
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotLineYn: 'N' })
    }

    if (this.parentInfo.detailResp2.bkgClose === 'Y' && this.parentInfo.detailResp2.kmtcSpotClosYn === 'N') {
      this.kmtcPremiumFrtChk = 'P'
    }

    this.getFrtAppNo()
  },
  methods: {
    // R/A
    async getFrtAppNo () {
      await vslScheduleInfo.popFreAppNoList(this.parentInfo).then((rtnData) => {
        this.freAppNoList = rtnData.data.surChargeList
        let listOrNot = false
        if (this.freAppNoList.length > 0) {
          for (const item of this.freAppNoList) {
            if (this.parentInfo.promotionChk !== 'Y') {
              if (item.frtAppNo === this.parentInfo.frtAppNo) {
                this.parentInfo.cntrTypCd = item.cntrTypCd
                this.parentInfo.hotDealYn = item.hotDealYn
                this.startDateRM = item.strDt
                this.endDateRM = item.obrdDt
                this.dcYn = item.dcYn
                listOrNot = true
              }
            } else { // 프로모션 배너로 진입한 경우 parentInfo.frtAppNo가 없으므로 여기서 조회한 값을 사용하여 RA운임 조회되도록 함
              this.parentInfo.frtAppNo = item.frtAppNo
              this.parentInfo.cntrTypCd = item.cntrTypCd
              this.parentInfo.hotDealYn = item.hotDealYn
              this.startDateRM = item.strDt
              this.endDateRM = item.obrdDt
              listOrNot = true
            }
          }
        } else {
          listOrNot = false
        }
        if (listOrNot) {
          for (const item of this.freAppNoList) {
            if (item.frtAppNo === this.parentInfo.frtAppNo) {
              // 운임 유효기간
              this.startDate = this.$ekmtcCommon.changeDatePattern(item.strDt, '-')
              this.endDate = this.$ekmtcCommon.changeDatePattern(item.obrdDt, '-')
              this.frtEstnYn = item.frtEstnYn
              this.frtExtsStsCd = item.frtExtsStsCd
              this.cstBidgMgrNo = item.cstBidgMgrNo
            }
          }
        } else {
          //this.freAppNoList = []
          //this.freAppNoList.push({ frtAppNo: this.parentInfo.frtAppNo })
          this.startDate = this.$ekmtcCommon.changeDatePattern(this.parentInfo.startDt, '-')
          this.endDate = this.$ekmtcCommon.changeDatePattern(this.parentInfo.endDt, '-')
          this.startDateRM = this.$ekmtcCommon.changeDatePattern(this.parentInfo.startDt, '-')
          this.endDateRM = this.$ekmtcCommon.changeDatePattern(this.parentInfo.endDt, '-')
          this.strDt = this.parentInfo.startDt
          this.endDt = this.parentInfo.endDt
          this.frtEstnYn = this.parentInfo.frtEstnYn
          this.frtExtsStsCd = this.parentInfo.frtExtsStsCd
          this.cstBidgMgrNo = this.parentInfo.cstBidgMgrNo
        }
      })

      if (this.parentInfo.rmPopOpenFlag) {
        if (this.parentInfo.rmPopOpenFlag === 'Y') {
          await this.getCntrList()
        } else {
          this.showReqQtBtn = 'Y'
        }
      }
      await this.getFreSurcharge()
    },
    // R/M
    async getCntrList () {
      await vslScheduleInfo.popFreRateNoCntr(this.parentInfo).then((rtnData) => {
        // console.log('>>>>>> [freSurcharge] popFreRateNoCntr <<<<<<')
        // console.log(rtnData)
        this.containerType = rtnData.data.cntrTypList
        if (this.containerType.length > 0) {
          this.parentInfo.reqRno = this.containerType[0].reqRno
          this.cntrTypCd2 = this.containerType[0].cntrTypCd
        }

        for (const item of this.containerType) {
          let type = 'RM'
          let frtAppNo = ''
          for (const item2 of this.freAppNoList) {
            if (this.$ekmtcCommon.isNotEmpty(item2.cntrTypCd) && item2.cntrTypCd.indexOf(item.cntrTypCd) > -1) {
              type = 'RA'
              frtAppNo = item2.frtAppNo
            }
          }
          item.frtAppNo = frtAppNo
          item.type = type
          item.cd = item.cntrTypCd
          item.cdNm = item.asCntrTypCd
        }
        // this.getFreSurcharge()
        /*
        for (const item of this.containerType) {
          if (item.cd === 'GP' && item.type === 'RA') {
            for (const item2 of this.cntrTypList) {
              if (item2.cntrTypCd !== 'GP') {
                this.containerType.push({ cdNm: item2.asCntrTypCd, cd: item2.cntrTypCd, type: 'RM', reqRno: item2.reqRno })
              }
            }
          }
          if (item.cd === 'RF' && item.type === 'RA') {
            for (const item2 of this.cntrTypList) {
              if (item2.cntrTypCd !== 'RF') {
                this.containerType.push({ cdNm: item2.asCntrTypCd, cd: item2.cntrTypCd, type: 'RM', reqRno: item2.reqRno })
              }
            }
          }
        }
        */
      })
    },
    changeFrtAppNo (e) {
      const val = e.target.value
      for (const item of this.freAppNoList) {
        if (item.frtAppNo === val) {
          this.startDate = this.$ekmtcCommon.changeDatePattern(item.strDt, '-')
          this.endDate = this.$ekmtcCommon.changeDatePattern(item.obrdDt, '-')
          this.parentInfo.endDt = item.obrdDt
          this.frtEstnYn = item.frtEstnYn
          this.frtExtsStsCd = item.frtExtsStsCd
          this.cstBidgMgrNo = item.cstBidgMgrNo
          this.parentInfo.cntrTypCd = item.cntrTypCd
        }
      }
      this.surChargeListDry = []
      this.surChargeListRee = []
      this.surChargeListFlat = []
      this.surChargeListTank = []
      this.surChargeListEtc = []
      this.surChargeNoteList = []
      this.surChargeListOT = []
      //this.containerType = []
      this.parentInfo.frtAppNo = val
      this.getFreSurcharge()
    },
    // R/A
    async getFreSurcharge () {
      this.frtAppNo = this.parentInfo.frtAppNo
      this.frtEstnYn = this.parentInfo.frtEstnYn
      // 출발, 도착지명
      var polnm = this.parentInfo.polNm.split(',')
      var podnm = this.parentInfo.podNm.split(',')
      if (polnm.length > 1) {
        this.polNm = polnm[1] + ' / ' + polnm[0]
      } else {
        this.polNm = this.parentInfo.polNm
      }
      if (podnm.length > 1) {
        this.podNm = podnm[1] + ' / ' + podnm[0]
      } else {
        this.podNm = this.parentInfo.podNm
      }
      /*
      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.cntrTypCd) && this.parentInfo.cntrTypCd.indexOf('RF') > -1) {
        this.parentInfo.cntrTypCd = 'RF'
      }
      */

      // 운임 유효기간
      // this.startDate = this.parentInfo.startDt.substring(0, 4) + '-' + this.parentInfo.startDt.substring(4, 6) + '-' + this.parentInfo.startDt.substring(6, 8)
      // this.endDate = this.parentInfo.endDt.substring(0, 4) + '-' + this.parentInfo.endDt.substring(4, 6) + '-' + this.parentInfo.endDt.substring(6, 8)
      // 운임 Data
      let dry = 'N'
      let reefee = 'N'
      let flat = 'N'
      let tank = 'N'
      let ot = 'N'

      // KMTC ON 프리미엄 구간인 경우 특정 사용자(CHP007_001)가 컨테이너 정보를 조회 하지 않는 경우가 있어 아래 방어 로직 추가함
      // RM운임 번호와 cntrTypCd2 정보 세팅함
      if (this.parentInfo.kmtcSpotYn === 'Y' && this.cntrTypCd2 === undefined) {
        await this.getCntrList()
      }

      this.parentInfo.cntrTypCd = this.cntrTypCd2
      await vslScheduleInfo.getPopFreSurcharge(this.parentInfo).then((rtnData) => {
        this.surChargeList = rtnData.data.surChargeList
        this.rateFreeDdsList = rtnData.data.rateFreeDdsList
        // bfSurChargeList는 프로모션 운임인 경우 할인 전 금액 조회용으로 사용하고, KMTC ON의 경우 KMTC ON 운임 조회용으로 사용한다.
        this.bfSurChargeList = rtnData.data.bfSurChargeList
        this.schgReqRno = rtnData.data.schgReqRno
        // 할인전 운임 세팅
        if (this.parentInfo.promotionChk === 'Y' && this.bfSurChargeList !== undefined) {
          for (const item of this.bfSurChargeList) {
            if (item.cgoTypCd === '01' && item.cgoTypNm === 'HZ') {
              this.bfHz20Rate = item.rate20
              this.bfHz40Rate = item.rate40
            } else {
              this.bfGp20Rate = item.rate20
              this.bfGp40Rate = item.rate40
            }
          }
        }

        for (const item of this.surChargeList) {
          if (item.cntrTypCd === 'GP' || item.cntrTypCd === 'HC') {
            this.surChargeListDry.push(item)
            dry = 'Y'
          } else if (item.cntrTypCd === 'RF' || item.cntrTypCd === 'RH') {
            this.surChargeListRee.push(item)
            reefee = 'Y'
          } else if (item.cntrTypCd === 'FR') {
            this.surChargeListFlat.push(item)
            flat = 'Y'
          } else if (item.cntrTypCd === 'TK') {
            this.surChargeListTank.push(item)
            tank = 'Y'
          } else if (item.cntrTypCd === 'OT') {
            this.surChargeListOT.push(item)
            ot = 'Y'
          } else {
            this.surChargeListEtc.push(item)
          }
          if (item.frtCd === 'DCF' || item.frtCd === 'SRD' || item.frtCd === 'AFS') {
            this.surChargeNoteList.push(item)
          }
        }

        if (dry === 'Y') {
          if (this.containerType.length === 0 || this.containerType.filter(vo => vo.cd === 'GP').length === 0) {
            this.containerType.push({ cdNm: 'Dry (GP or HC)', cd: 'GP', type: 'RA', frtAppNo: rtnData.data.frtAppNo })
          }
        }
        if (reefee === 'Y') {
          if (this.containerType.length === 0 || this.containerType.filter(vo => vo.cd === 'RF').length === 0) {
            this.containerType.push({ cdNm: 'REEFER (RF or RH)', cd: 'RF', type: 'RA', frtAppNo: rtnData.data.frtAppNo })
          }
        }
        if (flat === 'Y') {
          if (this.containerType.length === 0 || this.containerType.filter(vo => vo.cd === 'FR').length === 0) {
            this.containerType.push({ cdNm: 'Flat Rack', cd: 'FR', type: 'RA', frtAppNo: rtnData.data.frtAppNo })
          }
        }
        if (tank === 'Y') {
          if (this.containerType.length === 0 || this.containerType.filter(vo => vo.cd === 'TK').length === 0) {
            this.containerType.push({ cdNm: 'Tank', cd: 'TK', type: 'RA', frtAppNo: rtnData.data.frtAppNo })
          }
        }

        if (ot === 'Y') {
          if (this.containerType.length === 0 || this.containerType.filter(vo => vo.cd === 'OT').length === 0) {
            this.containerType.push({ cdNm: 'Open Top', cd: 'OT', type: 'RA', frtAppNo: rtnData.data.frtAppNo })
          }
        }

        if (dry === 'Y') {
          this.surChargeList2Show = this.surChargeListDry
          this.containerTypeCode = 'GP'
        } else if (reefee === 'Y') {
          this.surChargeList2Show = this.surChargeListRee
          this.containerTypeCode = 'RF'
        } else if (flat === 'Y') {
          this.surChargeList2Show = this.surChargeListFlat
          this.containerTypeCode = 'FR'
        } else if (tank === 'Y') {
          this.surChargeList2Show = this.surChargeListTank
          this.containerTypeCode = 'TK'
        } else if (ot === 'Y') {
          this.surChargeList2Show = this.surChargeListOT
          this.containerTypeCode = 'OT'
        } else {
          this.surChargeList2Show = this.surChargeListEtc
        }
        this.checkExtension()
        /*
        if (dry !== 'Y' || reefee !== 'Y') {
          // R/M info
          this.getCntrList()
        }
        */

        /*
        var layer = {
          event: 'set_check_freight_popup_info',
          cntr_type: '',
          gp_20_gp: '',
          gp_20_gp_hz: '',
          gp_40_gp: '',
          gp_40_gp_hz: '',
          gp_40_hc: '',
          gp_40_hc_hz: '',
          rf_20_rf: '',
          rf_20_rf_hz: '',
          rf_40_rf: '',
          rf_40_rf_hz: '',
          rf_40_hc: '',
          rf_40_hc_hz: ''
        }
        */
        var layerEcom = {
          event: 'ga4_purchase',
          ecommerce: {
            currency: '',
            value: '0',
            affiliation: this.auth.orgUserId + ' ' + this.auth.userName,
            transaction_id: this.parentInfo.frtAppNo,
            items: []
          }
        }

        for (const item of this.surChargeList2Show) {
          if (item.frtCd === 'O/F') {
            if (item.cntrTypCd === 'GP') {
              if (item.cgoTypCd === '01') {
                layerEcom.ecommerce.currency = item.curCd
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'gp_20_gp_hz',
                  price: item.rate20,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'gp_40_gp_hz',
                  price: item.rate40,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'gp_40_hc_hz',
                  price: item.rateHc,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
              } else {
                this.apv20Ft = item.rate20
                this.apv40Ft = item.rate40
                layerEcom.ecommerce.currency = item.curCd
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'gp_20_gp',
                  price: item.rate20,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'gp_40_gp',
                  price: item.rate40,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'gp_40_hc',
                  price: item.rateHc,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
              }
            } else if (item.cntrTypCd === 'RF') {
              if (item.cgoTypCd === '01') {
                layerEcom.ecommerce.currency = item.curCd
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'rf_20_rf_hz',
                  price: item.rate20,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'rf_40_rf_hz',
                  price: item.rate40,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'gp_40_hc_hz',
                  price: item.rateHc,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
              } else {
                layerEcom.ecommerce.currency = item.curCd
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'rf_20_rf',
                  price: item.rate20,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'rf_40_rf',
                  price: item.rate40,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
                layerEcom.ecommerce.items.push({
                  item_name: this.parentInfo.polNm + ' ' + this.parentInfo.podNm,
                  item_id: 'rf_40_hc',
                  price: item.rateHc,
                  item_variant: this.parentInfo.startDt + '-' + this.parentInfo.endDt,
                  quantity: '1'
                })
              }
            }
          }
        }

        window.dataLayer.push(layerEcom)
      })
    },
    // R/A or R/M search
    getFreSurchargeRAM () {
      this.frtAppNo = this.parentInfo.frtAppNo
      this.frtEstnYn = this.parentInfo.frtEstnYn
      // 출발, 도착지명
      // var polnm = this.parentInfo.polNm.split(',')
      // var podnm = this.parentInfo.podNm.split(',')
      // if (polnm.length > 1) {
      //   this.polNm = polnm[1] + ' / ' + polnm[0]
      // } else {
      //   this.polNm = this.parentInfo.polNm
      // }
      // if (podnm.length > 1) {
      //   this.podNm = podnm[1] + ' / ' + podnm[0]
      // } else {
      //   this.podNm = this.parentInfo.podNm
      // }
      // 운임 유효기간
      // this.startDate = this.parentInfo.startDt.substring(0, 4) + '-' + this.parentInfo.startDt.substring(4, 6) + '-' + this.parentInfo.startDt.substring(6, 8)
      // this.endDate = this.parentInfo.endDt.substring(0, 4) + '-' + this.parentInfo.endDt.substring(4, 6) + '-' + this.parentInfo.endDt.substring(6, 8)
      // 운임 Data
      let dry = 'N'
      let reefee = 'N'
      let flat = 'N'
      let tank = 'N'
      this.surChargeList2Show = []
      this.surChargeListDry = []
      this.surChargeListRee = []
      this.surChargeListFlat = []
      this.surChargeListTank = []
      this.surChargeListEtc = []
      this.surChargeNoteList = []
      this.surChargeListOT = []
      vslScheduleInfo.getPopFreSurcharge(this.parentInfo).then((rtnData) => {
        // console.log('######### [R/A] getPopFreSurcharge #########')
        // console.log(rtnData)
        this.surChargeList = rtnData.data.surChargeList
        this.rateFreeDdsList = rtnData.data.rateFreeDdsList
        this.of20Rate = rtnData.data.of20Rate
        this.of40Rate = rtnData.data.of40Rate
        // 운임 유효기간
        if (this.raOrRm === 'RM') {
          this.startDateRM = this.$ekmtcCommon.changeDatePattern(rtnData.data.strDt, '-')
          this.endDateRM = this.$ekmtcCommon.changeDatePattern(rtnData.data.endDt, '-')
          this.strDt = rtnData.data.strDt
          this.endDt = rtnData.data.endDt
          this.startDate = this.$ekmtcCommon.changeDatePattern(rtnData.data.strDt, '-')
          this.endDate = this.$ekmtcCommon.changeDatePattern(rtnData.data.endDt, '-')
          this.schgReqRno = rtnData.data.schgReqRno
        }
        for (const item of this.surChargeList) {
          if (item.cntrTypCd === 'GP' || item.cntrTypCd === 'HC') {
            this.surChargeListDry.push(item)
            dry = 'Y'
          } else if (item.cntrTypCd === 'RF' || item.cntrTypCd === 'RH') {
            this.surChargeListRee.push(item)
            reefee = 'Y'
          } else if (item.cntrTypCd === 'FR') {
            this.surChargeListFlat.push(item)
            flat = 'Y'
          } else if (item.cntrTypCd === 'TK') {
            this.surChargeListTank.push(item)
            tank = 'Y'
          } else if (item.cntrTypCd === 'OT') {
            this.surChargeListOT.push(item)
          } else {
            this.surChargeListEtc.push(item)
          }
          if (item.frtCd === 'DCF' || item.frtCd === 'SRD' || item.frtCd === 'AFS') {
            this.surChargeNoteList.push(item)
          }
        }
        if (this.containerTypeCode === 'GP') {
          this.surChargeList2Show = this.surChargeListDry
        } else if (this.containerTypeCode === 'RF') {
          this.surChargeList2Show = this.surChargeListRee
        } else if (this.containerTypeCode === 'FR') {
          this.surChargeList2Show = this.surChargeListFlat
        } else if (this.containerTypeCode === 'TK') {
          this.surChargeList2Show = this.surChargeListTank
        } else if (this.containerTypeCode === 'OT') {
          this.surChargeList2Show = this.surChargeListOT
        } else {
          this.surChargeList2Show = this.surChargeListEtc
        }

        if (this.freAppNoList !== null) {
          for (let i = 0; i < this.freAppNoList.length; i++) {
            if (this.frtAppNo === this.freAppNoList[i].frtAppNo) {
              if (this.freAppNoList[i].strDt !== null && this.freAppNoList[i].strDt.length === 8 &&
              this.freAppNoList[i].obrdDt !== null && this.freAppNoList[i].obrdDt.length === 8) {
                this.startDate = this.freAppNoList[i].strDt.substring(0, 4) + '-' + this.freAppNoList[i].strDt.substring(4, 6) + '-' + this.freAppNoList[i].strDt.substring(6, 8)
                this.endDate = this.freAppNoList[i].obrdDt.substring(0, 4) + '-' + this.freAppNoList[i].obrdDt.substring(4, 6) + '-' + this.freAppNoList[i].obrdDt.substring(6, 8)
                break
              }
            }
          }
        }

        this.checkExtension()
      })
    },
    changeCntnType (e) {
      const val = e.target.value
      this.surChargeList2Show = []
      if (val === 'GP') {
        this.surChargeList2Show = this.surChargeListDry
      } else if (val === 'RF') {
        this.surChargeList2Show = this.surChargeListRee
      } else if (val === 'FR') {
        this.surChargeList2Show = this.surChargeListFlat
      } else if (val === 'TK') {
        this.surChargeList2Show = this.surChargeListTank
      } else if (val === 'OT') {
        this.surChargeList2Show = this.surChargeListOT
      } else {
        this.surChargeList2Show = this.surChargeListEtc
      }
    },
    changeCntnType2 (e) {
      const val = e.target.value

      // 컨테이너 타입 GP 아닌 경우 운임 선택 일반으로 강제 세팅
      if (val !== 'GP') {
        this.kmtcPremiumFrtChk = 'R'
      }

      this.containerTypeCode = val
      this.parentInfo.cntrTypCd = val
      this.checkCharge = 'N'
      this.frtAppNoConfirm = ''
      this.oogChk = false
      for (const item of this.containerType) {
        if (item.cd === val) {
          if (item.type === 'RA') {
            this.parentInfo.frtAppNo = item.frtAppNo
            this.parentInfo.frtResult = 'Y'
            this.parentInfo.reqRno = ''
            this.raOrRm = 'RA'
          } else {
            this.parentInfo.reqRno = item.reqRno
            this.parentInfo.frtResult = 'A'
            this.parentInfo.frtAppNo = ''
            this.raOrRm = 'RM'
          }
          this.getFreSurchargeRAM()
        }
      }
    },
    acceptQuote () {
      if (this.parentInfo.detailResp2.bkgClose === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('마감된 구간의 운임은 확정할 수 없습니다.'))
        return
      }

      if (this.auth.userCtrCd === 'KR' && (this.containerTypeCode === 'OT' || this.containerTypeCode === 'FR')) {
        if (!this.oogChk) {
          this.$ekmtcCommon.alertDefault('특수화물 운임확정을 위해서는 공지사항 확인표시가 필수입니다')
          return
        }
      }
      window.app.$Simplert.close() //컨텀창 안닫혀서 추가
/*
      let startDt = ''
      let overDt = ''

      // 프로모션인 경우 운임의 유효일자는 프로모션 기간과 동일하게 세팅함
      if (this.parentInfo.promotionChk === 'Y') {
        startDt = this.parentInfo.applyFr
        overDt = this.parentInfo.applyTo
      } else {
        startDt = this.strDt
        overDt = this.endDt
      }
*/
      const params = {
        porCtrCd: this.parentInfo.porCtrCd,
        porPlcCd: this.parentInfo.porPlcCd,
        dlyCtrCd: this.parentInfo.dlyCtrCd,
        dlyPlcCd: this.parentInfo.dlyPlcCd,
        etd: this.parentInfo.etd,
//        strDt: startDt,
//        endDt: overDt,
        strDt: this.strDt,
        endDt: this.endDt,
        reqRno: this.parentInfo.reqRno,
        rteCd: this.parentInfo.rteCd,
        vslCd: this.parentInfo.vslCd,
        voyNo: this.parentInfo.voyNo,
        schgReqRno: this.schgReqRno,
        refFrtAppNo: this.parentInfo.refFrtAppNo,
        of20Rate: this.of20Rate,
        of40Rate: this.of40Rate,
        cntrTypeCd: this.containerTypeCode,
        eiCatCd: this.parentInfo.eiCatCd,
        promotionChk: this.parentInfo.promotionChk,
        scenarioCd: this.parentInfo.scenarioCd,
        promoNo: this.parentInfo.promoNo,
        promofrtAppNo: this.parentInfo.frtAppNo,
        spread20: this.spread20,
        spread40: this.spread40,
        hotDealYn: this.containerTypeCode === 'GP' ? 'Y' : 'N'
      }
      vslScheduleInfo.insertAcceptQuote(params).then((rtnData) => {
        // console.log('######### [freSurcharge] insertAcceptQuote #########')
        // console.log(rtnData)
        this.surChargeList = rtnData.data.surChargeList
        this.frtAppNoConfirm = rtnData.data.frtAppNo
        this.checkCharge = 'Y'
        this.parentInfo.frtResult = rtnData.data.frtResult
        this.parentInfo.frtAppNo = rtnData.data.frtAppNo
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.SCHD010P071.001') }) // 문의하신 구간의 운임 SC No. 가 생성되었습니다.
        this.surChargeList2Show = []
        this.surChargeListDry = []
        this.surChargeListRee = []
        this.surChargeListFlat = []
        this.surChargeListTank = []
        this.surChargeListEtc = []
        this.surChargeNoteList = []
        this.surChargeListOT = []
        this.getFrtAppNo()
      })
    },
    goReqQtPop (negoYN) {
      if (negoYN === 'K') {
        if (this.parentInfo.detailResp2.kmtcSpotLineYn === 'Y' && !this.kmtcPremiumFrtChk) {
          this.$ekmtcCommon.alertDefault('운임을 선택해주세요.')
          return
        }

        if (this.kmtcPremiumFrtChk === 'P') {
          this.$ekmtcCommon.alertDefault('KMTC ON PREMIUM 운임은 협의 대상이 아닙니다.')
          return
        }

        if (this.dcYn === 'Y') {
          this.$ekmtcCommon.alertDefault('이미 KMTC ON NOW로 협의 완료된 운임입니다.')
          return
        }

        if (this.raOrRm !== 'RA' && this.checkCharge !== 'Y') {
          this.$ekmtcCommon.alertDefault('운임 확정 후 KMTC ON NOW 페이지로 이동 가능합니다.')
          return
        }

        const params = {
          frtAppNo: this.parentInfo.frtAppNo,
          porCtrNm: countryCode[this.parentInfo.porCtrCd].CountryNameEN,
          porPlcNm: this.parentInfo.polNm.split(',')[0],
          dlyCtrNm: countryCode[this.parentInfo.dlyCtrCd].CountryNameEN,
          dlyPlcNm: this.parentInfo.podNm,
          expDate: this.$ekmtcCommon.changeDatePattern(this.parentInfo.startDt, '-') + ' ~ ' + this.$ekmtcCommon.changeDatePattern(this.parentInfo.endDt, '-'),
          apv20Ft: this.apv20Ft,
          apv40Ft: this.apv40Ft,
          porPlcCd: this.parentInfo.porPlcCd,
          porCtrCd: this.parentInfo.porCtrCd,
          dlyPlcCd: this.parentInfo.dlyPlcCd,
          dlyCtrCd: this.parentInfo.dlyCtrCd
        }

        this.$emit('close', this.parentInfo)
        this.$router.push({ name: 'kmtc-premium', query: params }).catch(() => {})
      } else {
        this.parentInfo.negoYN = negoYN
        this.$emit('close', this.parentInfo)
      }
    },
    checkExtension () {
      this.expDate = app.$ekmtcCommon.getStrToDate(this.parentInfo.endDt)
      let today = new Date()
      let twoWeekAfter = new Date()
      twoWeekAfter = new Date(twoWeekAfter.setDate(twoWeekAfter.getDate() + 14))
      // 오프라인 화주의 경우
      // 1. 운임 유효기한 만료 임박(2주전~금일) >> 오늘 > 만료일 > 2주후
      // 2. 유효기한 만료된 건은 ‘연장요청’ 버튼 노출 >> 만료건의 경우 검색불가
      // frtEstnYn = undefined
      if ((today <= this.expDate && this.expDate <= twoWeekAfter) && this.frtEstnYn !== 'I' && this.frtExtsStsCd === '00' && this.cstBidgMgrNo === undefined) {
        this.showExtensionBtn = 'Y'
      } else if (today > this.expDate && this.frtEstnYn !== 'I' && this.frtExtsStsCd === '00' && this.$ekmtcCommon.isEmpty(this.cstBidgMgrNo)) {
        this.showExtensionBtn = 'Y'
      }

      if (today < this.expDate) {
        this.showNegoBtn = 'Y'
      }

      // KMTC ON 대상 회원에게 네고요청 버튼은 안보이도록 해야 함
      if (this.parentInfo.kmtcSpotYn === 'Y') {
        this.showNegoBtn = 'N'
      }
    },
    validityRequest () {
      // 버튼 클릭 시 ‘A01’ 얼럿메시지 호출 후 유효기한 3개월 연장, 연장요청 완료 후 버튼 비활성
      // 연장요청
      schedule.validityRequest({ frtAppNo: this.frtAppNo }).then((response) => {
        // console.log('######### [freSurcharge] validityRequest #########')
        // console.log(response)
        if (response.headers.respcode === 'C0000') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.FARE020T010.028'))
          this.showExtensionBtn = 'N'
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.FARE020T010.029'))
        }
      })
    },
    async getCntrTypeMessage () {
      let message = this.$t('msg.CSCT060G040.010') + '<br><br>'

      message += '<div class="d_flex">'
      message += '  <div class="inp_space30">Type : </div>'
      message += '  <div class="inp_space70">'
      message += '  <select id="popCntrTypCd">'
      message += '  <option value="">' + this.$t('msg.CSBL200.075') + '</option>'
      message += '  <option value="GP">Dry</option>'
      message += '  <option value="RF">Reefer</option>'
      message += '  <option value="FR">Flat Rack</option>'
      message += '  <option value="OT">Open Top</option>'
      message += '  </select>'
      message += '  </div>'
      message += '</div>'

      let rtnVal = ''
      const isOk = await this.$ekmtcCommon.asyncAlertMessage({
        message: message,
        useConfirmBtn: true,
        onConfirmSelf: (resolve, $Simplert) => {
          const popCntrTypCd = document.querySelector('#popCntrTypCd')

          if (this.$ekmtcCommon.isEmpty(popCntrTypCd.value)) {
            this.$ekmtcCommon.showErrorTooltip(popCntrTypCd, this.$t('msg.CSBK100.147'))
          } else {
            rtnVal = popCntrTypCd.value
            resolve(true)
            $Simplert.close()
          }
        }
      })

      return {
        isOk: isOk,
        rtnVal: rtnVal
      }
    },
    async fnFrtConfirm () {
      const parentInfo = this.parentInfo
      const params = {
        porCtrCd: parentInfo.porCtrCd,
        porPlcCd: parentInfo.porPlcCd,
        dlyCtrCd: parentInfo.dlyCtrCd,
        dlyPlcCd: parentInfo.dlyPlcCd,
        etd: parentInfo.etd,
        eiCatCd: parentInfo.eiCatCd
      }

      const cntrMsgInfo = await this.getCntrTypeMessage()

      if (cntrMsgInfo.isOk) {
        params.cntrTypCd = cntrMsgInfo.rtnVal

        await schedule.fnPopFreReq(params).then((response) => {
          const result = response.data.insertRateMngResult
          let msg = ''
          if (result !== undefined && result === 1) {
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
          } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
            msg = this.$t('msg.FARE010T010.024')
          } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
          } else {
            msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
          }
          this.$ekmtcCommon.alertDefault(msg)
        })
      }
    },
    async goBooking () {
      if (this.bfSurChargeList !== undefined && !this.kmtcPremiumFrtChk) {
        this.$ekmtcCommon.alertDefault(this.$t('운임을 선택해주세요.'))
        return
      }

      if (!this.$ekmtcCommon.isEmpty(this.parentInfo.etaBookingMsg)) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.parentInfo.etaBookingMsg }) // 해당 국가 ( 한국 ) 수출화물에 대해서만 부킹이 가능합니다.
        return
      }

      if (this.parentInfo.hongkongTsYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.parentInfo.hongkongTsMsg) // 중국 춘절 기간동안 홍콩 T/S가 중단
        return
      }

      if (this.parentInfo.detailResp2.bkgClose === 'Y' && this.kmtcPremiumFrtChk !== 'P') {
        this.$ekmtcCommon.alertDefault(this.$t('마감된 구간은 부킹할 수 없습니다.'))
        return
      }

      // 일반운임 선택시 프리미엄이 아닌 일반 구간으로 진행함
      if (this.kmtcPremiumFrtChk !== 'P') {
        this.parentInfo.kmtcSpotYn = 'N'
        this.parentInfo.detailResp2.kmtcSpotLineYn = 'N'
      }

      // KMTC ON 프리미엄 운임 선택시 운임 생성
      if (this.kmtcPremiumFrtChk === 'P') {
        const params = {
          porCtrCd: this.parentInfo.porCtrCd, // [11]출발지 국가코드
          porPlcCd: this.parentInfo.porPlcCd, // [9]출발지 포트코드
          dlyCtrCd: this.parentInfo.dlyCtrCd, // [12]도착지 국가코드
          dlyPlcCd: this.parentInfo.dlyPlcCd, // [10]도착지 포트코드
          etd: this.parentInfo.etd, // [5]입항일
          rteCd: this.parentInfo.rteCd,
          vslCd: this.parentInfo.vslCd, // [0]선명코드
          voyNo: this.parentInfo.voyNo, // [1]항차
          eiCatCd: this.parentInfo.eiCatCd,
          of20Rate: this.bfSurChargeList.filter(vo => vo.frtCd === 'O/F' && vo.cntrTypCd === 'GP')[0].rate20,
          of40Rate: this.bfSurChargeList.filter(vo => vo.frtCd === 'O/F' && vo.cntrTypCd === 'GP')[0].rate40,
          strDt: this.startDate.replace(/-/gi, ''),
          endDt: this.endDate.replace(/-/gi, '')
        }

        await vslScheduleInfo.kmtcSpotAcceptQuote(params).then((rtnData) => {
          this.parentInfo.detailResp2.frtAppNo = rtnData.data.frtAppNo
          this.parentInfo.detailResp2.refFrtAppNo = ''
        })

        if (this.parentInfo.detailResp2.frtAppNo === '' && this.parentInfo.detailResp2.frtAppNo === undefined) {
          this.$ekmtcCommon.alertDefault(this.$t('운임확정 오류발생'))
          return
        }
      }

      // emit의 booking 파라미터 실행시 ScheduleLeg.vue 파일의 bookingBooking2() 실행되도록 세팅됨
      this.$emit('booking', this.parentInfo.detailResp2, this.kmtcPremiumFrtChk)
      this.$emit('close')
    },
    goPromotionBooking () {
      const params = {
        vslCd: this.parentInfo.vslCd,
        voyNo: this.parentInfo.voyNo,
        pol: this.parentInfo.porPlcCd,
        pod1: this.parentInfo.dlyPlcCd,
        frtAppNo: this.parentInfo.frtAppNo
      }

      // emit의 booking 파라미터 실행시 ScheduleLeg.vue 파일의 bookingBooking2() 실행되도록 세팅됨
      this.$emit('booking', params)
      this.$emit('close')
    },
    calcPromotionDC (cntrType, originAmt, frtCd, cntrTypCd, cgoTypNm) {
      let calcAmt = 0
      let calcDcRate = 0

      // originAmt 숫자가 아닌 경우
      if (originAmt <= 0 || originAmt === 'NaN') {
        return this.$ekmtcCommon.changeNumberFormat(originAmt, { isComma: true })
      }

      if (frtCd === 'O/F' && cntrTypCd === 'GP') {
        // GP and Cargo HZ 가 아니면서 직접할인 이면, 차액계산 저장하여 Cargo HZ 할인시 사용함
        if (cgoTypNm === undefined && cntrType === '20') {
          this.spread20 = originAmt - this.parentInfo.dtDcRate01
        } else if (cgoTypNm === undefined && cntrType === '40') {
          this.spread40 = originAmt - this.parentInfo.dtDcRate02
        }

        // 프로모션의 offset과 직접운임간 우선순위가 결정되지 않아 우선 offset에 우선순위를 두고 계산함.
        // 금액 계산시 %, 정액 할인은 원단위 올림
        // offset 금액 계산 1
        if (cntrType === this.parentInfo.ofCntrSize01) {
          if (this.parentInfo.ofDcType01 === '$') {
            calcAmt = originAmt - this.parentInfo.ofDcRate01
          } else { // % 계산
            calcDcRate = this.parentInfo.ofDcRate01 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // offset 금액 계산 2
        if (cntrType === this.parentInfo.ofCntrSize02) {
          if (this.parentInfo.ofDcType02 === '$') {
            calcAmt = originAmt - this.parentInfo.ofDcRate02
          } else { // % 계산
            calcDcRate = this.parentInfo.ofDcRate02 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // 직접운임 금액 계산 1
        if (cntrType === this.parentInfo.dtCntrSize01) {
          if (this.parentInfo.dtDcType01 === '$') {
            if (cgoTypNm === 'HZ') {
              calcAmt = originAmt - this.spread20
            } else {
              calcAmt = this.parentInfo.dtDcRate01
            }
          } else { // % 계산
            calcDcRate = this.parentInfo.dtDcRate01 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // 직접운임 금액 계산 2
        if (cntrType === this.parentInfo.dtCntrSize02) {
          if (this.parentInfo.dtDcType02 === '$') {
            if (cgoTypNm === 'HZ') {
              calcAmt = originAmt - this.spread40
            } else {
              calcAmt = this.parentInfo.dtDcRate02
            }
          } else { // % 계산
            calcDcRate = this.parentInfo.dtDcRate02 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }
      }
      return this.$ekmtcCommon.changeNumberFormat(originAmt, { isComma: true })
    },
    closeNmove () {
      this.$emit('close')
      if (this.parentInfo.promotionChk === 'Y') {
        this.$router.push('/main')
      }
    },
    screenToPdfDownload () {
      this.$ekmtcCommon.screenToPdfForSurcharge({ cssSelector: 'body > div .content_box', fileName: `KMTC_${this.frtAppNo}.pdf`, download: 'Y' })
    },
    screenPrint () {
      const ele = document.querySelector('.capture_area')
      this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

      const options = {
        cssPageSelector: '.capture_sub_area',
        page: 'FreeSurcharge'
      }

      this.$ekmtcCommon.screenToPrintPageSplit(options).then(res => {
        if (res === undefined || res === null) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.244'))
        }
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      }).catch(() => {
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      })
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.popup_wrap > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.popup_wrap > .popup_dim')
    },
    clickOpen (kind) {
      this.openPopup(kind)
    }
  }
}
</script>
<style scoped>
.content_box {
  border : 0px !important;
}
</style>
